
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
export default {
	name: "Notification",
	data() {
		return {
			data          : [],
			inbox         : [],
			current_page  :  1,
			last_page     :  1,
			per_page      :  10,
			total         :  0,
			page          :  1,
			type          :  "revenue",
			currency: getLocal('currency') || 'RM',
		};
	},
	methods: {
		getlog(type,sw) {
			this.type = type ;
			var per_page = 12;

			if(sw){
				this.data = [];
				this.page = 1;
				$('.notMore').css('display', 'none');
			}

			var data = {
				type : type,
				per_page :per_page,
			}

			this.$http("", "/home/account/incomelog?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
				// console.log(_.size(result.data.data))
					this.data         = _.union(this.data,result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.data         = result.data.data;
						// console.log(this.data)
					if(_.size(result.data.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					}
				}

			});
		},
		redate(date) {
			return date.substring(0, date.length - 3);
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		scroll() {
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1;
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					this.page++;
					this.getlog(this.type,false) ;
				}
			}
		}
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/');
		// console.log(token)
		this.getlog('revenue',false);
	},
	mounted() {
		var that = this;
		window.addEventListener('scroll', this.scroll);
	},
	unmounted() {
	},
	watch: {
		$route(to, from) {
			window.removeEventListener('scroll', this.scroll);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
}
.listing-log {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px 0px;
}
.listing-log li {
    display: flex;
    color: $white;
    background: rgba($black-100,0.6);
    border-bottom: dashed 1px $black-300;
    padding: 18px 30px;
    justify-content: space-between;
}
.listing-log .title {
    display: flex;
}
.listing-log .title .icon {
    margin-right: 10px;
}
.listing-log h2 {
    font-size: 14px;
    margin: 0;
    padding: 0;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.listing-log span {
    font-size: 13px;
    margin: 0;
    padding: 0;
    color: $gray-600;
}
.listing-log .transfer span {
    font-size: 14px;
    color: $yellow-400;
}
.incomelog.nav-tabs .nav-item {
    /* width: 25%; */
    text-align: center;
    margin: 0 auto;
    padding: 0;
}
.incomelog.nav-tabs .nav-link.active {
    color: $yellow-300;
	background: none;
	border: none;
	border-color:$yellow-300!important;
	border-bottom: 2px solid $yellow-300;
	outline: none;
}
.incomelog .nav-link {
    background: none;
    border: none;
	display: flex!important;
    color: $gray-400!important;
    padding: 14px 14px;
}
.incomelog.nav-tabs {
    border-bottom: none;
	justify-content: center;
	align-items: center;
}
.main-container {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.incomelog.nav-link:focus {
    border-color: map-get($theme-colors, 'danger');
    isolation: isolate;
}
.incomelog  button:focus {
    outline: none;
}
.listing-log .revenue span {
    font-size: 14px;
    color: map-get($theme-colors, 'success');
}
.listing-log .expenditure span {
    font-size: 14px;
    color: map-get($theme-colors, 'danger');
}
.listing-log .recharge span {
    display: block;
    font-size: 14px;
    color: $blue;
}
</style>

<template>
  <base-layout3
	:page-title="$t('account_incomelog_income_breakdown')"
	:show-back-button="true"
	:show-footer="false"
	mode=""
  >
	<div class="">
		<div class="main-container ">


			<ul class="nav nav-tabs incomelog" id="myTab" role="tablist">
				<li class="nav-item" role="presentation" @click="getlog('revenue',true)">
					<button class="nav-link" :class="type=='revenue' ?'active':''">{{$t('account_revenue') }}</button>
				</li>
				<li class="nav-item" role="presentation" @click="getlog('expenditure',true)">
					<button class="nav-link" :class="type=='expenditure' ?'active':''">{{$t('account_expenditure') }}</button>
				</li>
				<li class="nav-item" role="presentation" @click="getlog('recharge',true)">
					<button class="nav-link" :class="type=='recharge' ?'active':''">{{$t('account_recharge') }}</button>
				</li>
			</ul>

			<div class="mt-1" id="myTabContent">
				<div class="tab-pane fade show active" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
					<ul class="listing-log">
						<li  v-for="(item, k) in  data"  :key="k"  >
							<div class="title">
								<div class="icon"  v-if="type=='revenue'"><img src="@/assets/images/svg2/revenue.svg" width="30" height="30" alt=""/></div>
								<div class="icon"  v-if="type=='voucher'"><img src="@/assets/images/svg2/transfer.svg" width="30" height="30" alt=""/></div>
								<div class="icon"  v-if="type=='expenditure'"><img src="@/assets/images/svg2/expenditure.svg" width="30" height="30" alt=""/></div>
								<div class="icon"  v-if="type=='recharge'"><img src="@/assets/images/svg2/recharge.svg" width="30" height="30" alt=""/></div>

								<div>
									<h2 class="" style="color: #fff;font-size: 12px;" v-if="type!='recharge' && type!='recharge'">{{$t(item.title) }}</h2>
									<h2 class="" style="color: #fff;font-size: 12px;" v-if="type=='recharge'">{{item.order_no}}</h2>
									<span>
										<font-awesome-icon :icon="['far', 'calendar']" class="me-auto" />
										{{ redate(item.create_time) }}
									</span>
								</div>
							</div>
							<div :class="type=='gametransfer'?'transfer':type">
								<span v-if="type=='revenue'" class=""><sup>{{currency}}</sup> {{item.num}}</span>

								<span v-if="type=='recharge'" class=""><sup>{{currency}}</sup> {{item.credit2}}</span>

								<span v-if="type=='voucher'" class="" ng-class="item.num >0 ?'plus':'minus'">{{item.num}}</span>

								<span v-if="type=='expenditure'" class="" ><sup>{{currency}}</sup> {{item.num}}</span>
							</div>
						</li>
					</ul>
					<div class="col-12 pb-5" style="display: block;text-align: center;width:100%;">
						<span class="notMore text-white">{{$t('drag_more') }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
  </base-layout3>
</template>