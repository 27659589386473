import { createI18n } from 'vue-i18n'
import cn from './cn'
import en from './en'

const i18n = createI18n({
  locale: window.localStorage.getItem('lang') || process.env.VUE_APP_LANG,
  messages: {
    cn: cn,
    en: en
  }
});
export default i18n