
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
import moment from 'moment-timezone';
// import "@/assets/css/trade-history.css";
import { formatSeconds2, getLocalTime, toFixed } from "@/utils/chart.js";
import { huobiWsUrl } from "@/views/mobile3/trade/config.js";
import "@/assets/scss/trade_history.scss";

export default {
	name: "Notification",
	data() {
		return {
			data          	: [],
			allData		  	: [],
			category      	: [],
			current_page  	:  1,
			last_page     	:  1,
			per_page      	:  10,
			total         	:  0,
			page          	:  1,
			currency      	: getLocal('currency') || 'RM',
			img_url       	: process.env.VUE_APP_IMAGE_URL,
			type          	: 'hold',
			orderlist     	: [],
			ftime         	: 0,
			index 			: -1,
			haurl			: huobiWsUrl,
			socketK			: {},
		  	toFixed			: toFixed,
			reroute			: false,
			recall			: false,
			proprice		: [],
			isInitWebsoket  : true,
			orderlistInterval  : null,
		};
	},
	methods: {
		push_content(url,id)
		{
			this.$router.push(url+'?id='+id);
		},
		callf(type,sw){
			this.type=type;
			this.getdata(sw);
		},
		getdata(sw) {

			if(sw){
				this.data = [];
				this.page = 1;
				$('.notMore').css('display', 'none');
			}
			var data = {ostaus:this.type=="hold"?0:1};
			var u = '/home/api/orderlist2';

			this.$http("", u+"?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
					// console.log(result.data.data)
					this.data         = _.union(this.data,result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.data         = result.data.data;

					if (!sw) this.allData = [];

					if(_.size(result.data.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					}

					// } else {
					// 	var that = this;
					// 	$.each(this.data, function(k, v) {
					// 		that.allData.push(v);
					// 	})
					// }
				}

			});
		},
		getprice() {
			this.$http("", "/home/api/get_price", {},'post')
			.then((result) => {
				if (result.code == '0001') {
					this.proprice = result.data;
					this.showOrderList();
				}
			})
		},
		getHoldOrderList() {
			if(this.isInitWebsoket){
				console.log("initsocket")
				this.handleInitWebsoket();
			}
			this.recall=false;
			clearInterval(this.orderlistInterval);
			this.$http("", "/home/api/orderlist2", { ostaus: 0 },'post')
			.then((result) => {
				if (result.code == '0001') {
					this.orderlist = result.data.data;
					// console.log(this.orderlist);
					if(this.orderlist){
						if(this.orderlist.length >= 1){
							this.ftime = (new Date().getTime())/1000;
						}else{
							var timestamp = Date.parse(new Date());
							this.ftime = timestamp/1000;
						}

						var that = this;
						// that.getprice()
						// this.showOrderList();
						// this.timer_get_price = setInterval(function() { that.getprice(); }, 1000);
						// this.timer_orderlist = setInterval(function() { that.showOrderList(); }, 1000);
						// console.log("111")
						this.timer_orderlist = setInterval(function() {
							that.ftime = (new Date().getTime())/1000;
							that.ftime++;
						 }, 1000);

						this.orderlistInterval = setInterval(function() {
							clearInterval(this.timer_orderlist);
							that.getHoldOrderList();
						}, 2000);
					}

					if(_.size(this.orderlist)==0){
						// console.log("clearaaaaaaaaaaaaa")
						clearInterval(this.timer_orderlist);
					}

				}
			})
		},
		handleInitWebsoket(isReconnect = 0) {
			var self = this;
			this.isInitWebsoket=false;

			self.socketK = new WebSocket(self.haurl);
			self.socketK.onopen = function () {
				if (isReconnect === 1) {
					console.log('reconnected', 'ws');
					self.socketK.send(JSON.stringify({action: 'subb', msg: 'Order detail page Reconnected', sub: 'order_detail_page_ticker'}));
				} else {
					console.log('connected', 'ws');
					self.socketK.send(JSON.stringify({action: 'subb', msg: 'Order detail page Connected', sub: 'order_detail_page_ticker'}));
				}
			};
			self.socketK.onmessage = function (event) {
				// console.log(event, 'event')
				if (typeof event.data !== "undefined") {
					let blob = JSON.parse(event.data);

					if (typeof blob.type !== 'undefined' && blob.type === 'ping') {
						self.socketK.send(JSON.stringify({ pong: blob.type }));
					} else {

						if (blob.action.indexOf('market') > -1) {
							// console.log(blob.data.tick, blob.action)

							// console.log(self.product[j], 'product i: ' + i)
							if (typeof blob.data.tick !== 'undefined') {
								let data = blob.data.tick;

								let arr = blob.action.split('.');
								let product = arr[1];
								// console.log(data, product)
								// if (blob.action.indexOf('ticker') > -1) {
									// let price = data.price;
									if (typeof data.price === 'undefined') {
										self.proprice[product] = data.close;
									} else {
										self.proprice[product] = data.price;
									}
								// } else {
								// 	let price = data.price;
								// }

							}
						}
					}
				}
				// console.log(self.proprice, 'this.proprice')
			};
	  		self.socketK.onclose = function () {
				console.log('reconnecting', 'ws');
				self.handleInitWebsoket(1);
			};
		},
		convertime(time){
			return getLocalTime(time);
		},
		conversecond(v){
			 var _end_time = (v.selltime - this.ftime);
			// console.log(this.ftime)
			// console.log(formatSeconds2(_end_time))
			return formatSeconds2(_end_time);
		},
		converbaifenbi(v){
			var that = this;
			var _end_time = (v.selltime - this.ftime);
			var baifenbi = (_end_time/v.endprofit)*100;

			if(baifenbi<=0 && this.recall==false){
				that.recall=true;
				setTimeout(function() {
					that.getHoldOrderList();
				}, 500);
			}
			return baifenbi;
		},
		getcloseprice(v,type){
			type = type||'price';
			var that     = this;
			var newprice = that.proprice[v.procode];
			var chaprice = newprice-v.buyprice;
			var closeprice = 0;
			var closeprice_class = '';

			if(v.ostyle==0){
				if(newprice >v.buyprice){
					closeprice = v.fee*(100*10+v.endloss*10)/1000;
					closeprice_class = 'in_money';
					closeprice_class = 'text-success';
				}else if(newprice < v.buyprice){
					closeprice = v.fee*(100 - v.endloss)/100;
					closeprice_class = 'out_money';
					closeprice_class = 'text-danger';
				}else{
					closeprice = v.fee;
					closeprice_class = '';
				}
			}else{
				if(newprice <v.buyprice){
					closeprice = v.fee*(100*10+v.endloss*10)/1000;
					closeprice_class = 'in_money';
					closeprice_class = 'text-success';
				}else if(newprice > v.buyprice){
					closeprice = v.fee*(100 - v.endloss)/100;
					closeprice_class = 'out_money';
					closeprice_class = 'text-danger';
				}else{
					closeprice = v.fee;
					closeprice_class = '';
				}
			}
			return type=='price'?closeprice:closeprice_class;
		},
		showOrderList() {
			var html = '';
			// console.log(this.orderlist.length, 'this.orderlist.length')
			if(this.orderlist.length == 0){
				// $('.trade_history_list ul').html('');
				return false;
			}
			this.ftime++;
			var that = this;
			$.each(this.orderlist, function(k,v){

				if (typeof v === 'undefined') return;

				var timestamp = Date.parse(new Date());
				var  _end_time = (v.selltime - that.ftime);
				var baifenbi = (_end_time/v.endprofit)*100;
				var newprice = that.proprice[v.procode];
				var ostyle_class, ostyle_class2, ostyle_name;

				if(_end_time >0){

					var chaprice = newprice-v.buyprice;
					var closeprice = 0;
					var closeprice_class = '';
					if(v.ostyle == 0){
						ostyle_class = "buytop";
						ostyle_class2 = 'in_money';
						ostyle_name = that.$t('exchange_buyUp');
						if(chaprice >0){
							closeprice = v.fee*(100*10+v.endloss*10)/1000;
							closeprice_class = 'in_money';
						}else if(chaprice <0){
							closeprice = v.fee*(100 - v.endloss)/100;
							closeprice_class = 'out_money';
						}else{
							closeprice = v.fee;
							closeprice_class = '';
						}
					}else{
						ostyle_class = "buydown";
						ostyle_name = that.$t('exchange_buyFall');
						ostyle_class2 = 'out_money';

						if(chaprice <0){
							closeprice = v.fee*(100*10+v.endloss*10)/1000;
							closeprice_class = 'in_money';
						}else if(chaprice >0){
							closeprice = v.fee*(100 - v.endloss)/100;
							closeprice_class = 'out_money';
						}else{
							closeprice = v.fee;
							closeprice_class = '';
						}
					}

					html += '<li class="">\
				<section>\
					<p style="margin: 0;font-size:12px;line-height:15px;">\
						<span class="" >'+v.ptitle+'</span>\
						<span class=" '+ostyle_class2+'"><i class="'+ostyle_class+'" ></i></span>\
					</p>\
					<p style="margin: 0;font-size:12px;line-height:15px;">\
						<span class=" '+ostyle_class2+'">'+ostyle_name+'（' + that.currency + v.fee+'）</span>\
					</p>\
					<p style="margin: 0;font-size:12px;line-height:15px;" class="">\
						'+v.buyprice+'-<span  class=" '+closeprice_class+'">'+newprice+'</span>\
					</p>\
					<p style="margin: 0;font-size:12px;line-height:15px;" class="">'+getLocalTime(v.buytime)+'</p>\
				</section><section>\
					<p style="margin: 0px;font-size:12px;line-height:15px;" class=" '+closeprice_class+'"><span>' + that.$t('exchange_prospective_result') + ": </span> <br/>" + closeprice +that.currency+'</p>\
					<p style="margin: 0;font-size:12px;line-height:15px;" class="">'+formatSeconds2(_end_time)+'</p>\
				</section>\
				<article class="">\
				<span class="move_width" style="width: '+baifenbi+'%; transition-duration: 1s;">\
				</span>\
				<i>\
					<em></em>\
				</i>\
				</article>\
			</li>';

					$('.trade_history_list ul').html(html);
					if (html != '') {
						var a = $('.trade_history_list').attr('style');
						if(typeof a === 'undefined') $('.trade_history_list').css('padding-bottom', '50px');
					} else {
						$('.trade_history_list').removeAttr('style');
					}
					// console.log(html, 'html')
				}else{
					that.orderlist.splice(k,1);
				}
			});
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		parseDate(date)
		{
			return moment(date).format('YYYY-MM-DD hh:mm:ss');
		},
		showOrderDetail(d) {
			// var nodes = Array.prototype.slice.call( document.querySelector('.listing-log').children );
			// var self = ele.target;
			// var i = nodes.indexOf( self );

			// console.log(nodes, this.index)
				// console.log(this.data);
				// console.log(d);
			var a = _.find(this.data, function(item){
				return item.oid == d.oid;
			});
			// console.log(a);
			this.allData = a ;
			this.index = 1;
		},
		closeOrderDetail() {
			this.index = -1;
		},
		scroll() {
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1
			// console.log($(document).scrollTop(), v);
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					this.page++;
					this.getdata(false) ;
				}
			}
		}
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/');
		// console.log(token)
		// this.getdata(false);
		this.getHoldOrderList();
	},
	mounted() {
		this.page = 1;
		var that = this;

		window.addEventListener("scroll", this.scroll);
	},
	unmounted() {
		clearInterval(this.timer_orderlist);
		clearInterval(this.orderlistInterval);
	},
	watch:{
		$route (to, from){
			// console.log(to,from)
			window.removeEventListener('scroll', this.scroll);
			if (this.socketK.close) {
				// this.socketK.send(JSON.stringify({action: 'unsub', msg: 'Mainpage Disconnected', sub: 'mainpage_ticker'}));
				this.reroute = true;
				this.socketK.close();
			}
			clearInterval(this.timer_orderlist);
			clearInterval(this.orderlistInterval);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
	color:$black-100;
	font-size: 14px;
	background: $black-400;
}
.sub-mission-text2 {
	color: $gray-500!important;
	font-size: 14px;
	margin-top: auto;
}
.selected_cat {
	background: $orange!important;
	color: $white!important;
	padding-left: 5px;
	padding-right: 5px;
}
.outline-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161)!important;
	border-radius: 10px !important;
}
.movie-title {
	font-size: 17px;
	color: $black-300;
	font-weight: 700;
	padding-right: 5px;
	flex-shrink: 1;
}
.grade {
	font-weight: 700;
	color: $yellow-400;
	font-size: 15px;
}
.detail {
	line-height: 24px;
}
.line-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.ml-7 {
	margin-left: 7px;
}
.active_btm {
	background: -webkit-linear-gradient(map-get($theme-gradient-colors, 'primary'));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 30px;
		left: 0;
		right: 0;
		bottom: -5px;
		margin: 0 auto;
		transition: all .3s ease-in-out;
		background-color: $yellow-400;
		background-image: linear-gradient(to bottom, map-get($theme-gradient-colors, 'primary'));
	}

	// width: 20px;
	// height: 2px;
	// border-radius: 30px;
	// background-color: #FAE76D;
	// background-image: linear-gradient(to bottom, #fae76d, #ffa660);
	// margin-top: 5px;
	// transition: all .3s ease-in-out;
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.flex-box-center {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}
.rank-text {
	font-size: 40px;
	font-weight: bold;
	color: $gray-500;
}
p{color:$gray-500;}
.up-case {
	text-transform: uppercase;
}
.active_btm{
	margin-top:0;
}
.trade_history_list ul li {
	height: 90px;
}
.trade_history_list ul, .trade_history_list ul li {
	/* padding: 0 !important; */
	background: rgba($black-100, 0.376);
	border-top: none;
	border-bottom: 1px solid rgba($white, 0.502);
}
.trade_history_list ul{
	border-bottom: 0;
}
.trade_history_list ul li article span {
	margin-top: 3.84px;
	z-index: 6!important
}
.trade_history_list p, .trade_history_list ul li section:nth-of-type(1) p:nth-of-type(3) {
	color: $white;
}
.order-detail1 {
	background: $black-400;
	height: 100%;
	position: fixed;
	width: 100%;
	// top: 54px;
	top: 0;
	left:0;
	z-index: 1000;

	td, th {
		border-top: 1px solid rgba($white, 0.376);
		border-bottom: 1px solid rgba($white, 0.376);
		margin: 20px;
		padding: 20px 30px;
	}

	a.close {
		position: fixed;
		color: $white;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		width: 100%;
		background: rgba($black-100, 0.376);
		bottom: 0;
		padding: 20px;
	}
}
</style>

<template>
  <base-layout3
	:page-title="$t('exchange_position_details')"
	:show-back-button="true"
	:show-footer="true"
	mode=""

  >
	<div class="">

		<div class="container py-1 " style="padding-left:0;padding-right:0;">
			<div class="row text-white">
				<div class="col-6  flex-box-center-column position-relative px-2" style="font-size: 18px;"  @click='callf("hold",true);'>
					<div :class="type=='hold' ?'active_btm':''">{{$t('trade_position_details') }}</div>
					<!-- <div :class="type=='hold' ?'active_btm':''" ></div> -->
				</div>
				<div class="col-6 flex-box-center-column bold position-relative px-2" style="font-size: 18px;" @click='callf("history",true);'>
					<div :class="type=='history' ?'active_btm':''">{{$t('trade_history_details') }}</div>
					<!-- <div :class="type=='history' ?'active_btm':''" ></div> -->
				</div>
				<div class="col-12 me-bar2" style=""></div>
			</div>
			<div class="tab-pane fade trade_history_list pt-3" :class="this.type == 'hold' ? 'show active' : 'd-none'" id="hold" role="tabpanel" aria-labelledby="hold-tab">
				<ul class="">
				</ul>
			</div>
		</div>

		<div class="tab-pane fade trade_history_list pt-3" :class="this.type == 'hold' ? 'show active' : 'd-none'" id="hold" role="tabpanel" aria-labelledby="hold-tab">
			<ul class="">
				<li  v-for="(item, k) in  orderlist"  :key="k" >
					<section>
						<p style="margin: 0;font-size:12px;line-height:15px;">
							<span class="">{{item.ptitle}}</span>
							<span :class="item.ostyle==0?'text-success':'text-danger'"><i :class="item.ostyle==0?'buytop':'buydown'"></i></span>
						</p>
						<p style="margin: 0;font-size:12px;line-height:15px;">
							<!-- <template v-if="(proprice[item.procode]-item.buyprice)>0"> -->
							<template v-if="item.ostyle==0">
								<span class="text-success">
									{{$t('exchange_buyUp')}}
									（{{item.fee}} {{currency}}）
								</span>
							</template>
							<template v-if="item.ostyle!=0">
								<span class="text-danger">
									{{$t('exchange_buyFall')}}
									（{{item.fee}} {{currency}}）
								</span>
							</template>
						</p>
						<p style="margin: 0;font-size:12px;line-height:15px;" class="">
							{{item.buyprice}} -
							<span :class="getcloseprice(item,'style')">{{proprice[item.procode]}}</span>
						</p>
						<p style="margin: 0;font-size:12px;line-height:15px;" class="">{{convertime(item.buytime)}}</p>
					</section>
					<section>
						<p style="margin: 0px;font-size:12px;line-height:15px;" :class="getcloseprice(item,'style')">
							<span>{{$t('exchange_prospective_result')}}: <br /> </span>{{getcloseprice(item)}} {{currency}}
						</p>
						<p style="margin: 0;font-size:12px;line-height:15px;" class="">{{conversecond(item)}}</p>
					</section>
					<article class=""><span class="move_width" :style="'width:'+converbaifenbi(item)+'%; transition-duration: 1s;'"></span><i><em></em></i></article>
				</li>
			</ul>
		</div>

		<div class="tab-pane fade" :class="this.type != 'hold' ? 'show active' : 'd-none'" id="order-history" role="tabpanel" aria-labelledby="order-history-tab" style="padding-bottom:50px">
			<ul class="listing-log">
				<li  v-for="(item, k) in  data"  :key="k"  @click="this.showOrderDetail(item)" >
					<div class="title">
						<div>
							<h2 class="" style="color: #fff;font-size: 12px;" >
								{{item.ptitle}}
								<span v-if="item.ostyle==0" style="margin-left: 10px;" class="text-success">
									{{$t('exchange_buyUp') }}
									({{item.fee}})
								</span>
								<span v-if="item.ostyle==1" style="margin-left: 10px;" class="text-danger">
									{{$t('exchange_buyFall') }}
									({{item.fee}})
								</span>
							</h2>
							<span class="text-white">{{item.buyprice}}</span> - <span :class="item.ostyle==0?'text-success':'text-danger' ">{{item.sellprice}}</span>
							<span v-if="item.ostyle==0" style="margin-left: 10px;" class="text-success">
								<img src="@/assets/images/3/buytop.png" class=""/>
							</span>
							<span v-if="item.ostyle==1" style="margin-left: 10px;" class="text-danger">
								<img src="@/assets/images/3/buydown.png" class=""/>
							</span>
						</div>
						<span style="font-size:10px">{{ parseDate(item.buytime)}}</span>
					</div>
					<div>
						<div style="float: right;">
							<span v-if="item.ploss <=0" class="text-danger text-bold">{{currency}} {{item.ploss.toFixed(2)}}</span>
							<span v-if="item.ploss >1" class="text-success text-bold">{{currency}} {{item.ploss.toFixed(2)}}</span>
						</div>
						<br/>
						<span style="font-size:10px;float: right;">{{ parseDate(item.selltime)}}</span>
					</div>
				</li>
			</ul>
			<div class="col-12 pt-2 pb-5" style="display: block;text-align: center;width:100%;">
				<span class="notMore text-white">{{$t('drag_more') }}</span>
			</div>
		</div>

		<div class="order-detail1" :class="this.index === -1 ? 'd-none' : ''">
			<table class="table">
				<tbody>
					<tr>
						<th>{{$t('trade_name')}}</th>
						<td>{{typeof this.allData !== 'undefined' ? this.allData.ptitle : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_transaction_price')}}</th>
						<td>{{currency}}{{typeof this.allData !== 'undefined' ? this.allData.buyprice : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_settlement_price')}}</th>
						<td>{{currency}}{{typeof this.allData !== 'undefined' ? this.allData.sellprice : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_fee')}}</th>
						<td>{{typeof this.allData !== 'undefined' ? this.allData.web_poundage : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_profit_loss')}}</th>
						<td>{{currency}}{{typeof this.allData !== 'undefined' ? this.allData.ploss : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_close_time')}}</th>
						<td>{{typeof this.allData !== 'undefined' ? this.allData.buytime : ''}}</td>
					</tr>
					<tr>
						<th>{{$t('trade_settle_time')}}</th>
						<td>{{typeof this.allData !== 'undefined' ? this.allData.selltime : ''}}</td>
					</tr>
				</tbody>
			</table>
			<a @click="this.closeOrderDetail()" class="close">{{$t('trade_close')}}</a>
		</div>
	</div>
  </base-layout3>
</template>