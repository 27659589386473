<script>
import { getLocal} from '@/utils/local.js';
import {_} from 'vue-underscore';
import * as $ from "jquery";
export default {

	data() {
			return {
				promo: {},
				login : false,
				page : 1,
			}
		},
	components: {
	},
	created() {},
	mounted() {
		this.getlog();
		var that = this;
		window.addEventListener("scroll", function(){
			var v = $(document).height() - $(window).height() - 1;
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					that.page++;
					that.getPost() ;
				}
			}
		});
	},
	beforeMount() {
		let token  = getLocal('token') || null;
		this.login = token==null?false:true;
	},
	computed: {
	},
	methods : {
		getlog (){
			var data = {
				type : 6,
				per_page :10,
			}
			this.$http("", "/home/faq/index?page="+this.page,data,"post").then((result) => {
				if(result.code == "0001"){
					this.promo = _.union(this.promo,result.data.data);
					// console.log(this.data)

					if(_.size(result.data.data)==0)
						$('.notMore').css('display', 'block').html(this.$t('no_more'))
				}else{
					this.$toast.success((result.message));
				}
			});

		},

	}
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.section-title {
    background: $black-400;
    color: $white;
    display: block;
    margin-bottom: 0;
    padding: 13px 30px;
    font-size: 17px;
    position: relative;
}
/* .container img {
    width: 100%!important;
} */
</style>

<template>
	<base-layout3 :page-title="$t('promotions_index')" :show-logo="false" :showBackButton=true mode="with-logo">
		<div class="container">
					<ul>
						<li class=""  v-for="(item, k) in  promo"  :key="k" >
							<div class="mailItem" v-if="item.is_display==1">
								<div class="mailMessage">
									<div class="mailTitle" @click="iscollapse(item.id)">
										<!-- <h4 class="normal-font-bold" style="padding: 10px;">{{item.title}}</h4> -->
										<h3 class="section-title">{{item.title}}</h3>
										<small class="text-white"><span>{{item.create_time}}</span></small>
									</div>
									<div class="expand" id="c{{item.id}}">
										<ul class="child">
											<li>
												<div v-html="item.detail "></div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>
					</ul>
			</div>


			<div class="col-12 pb-5" style="display: block;text-align: center;width:100%;">
				<span class="notMore ">{{$t('drag_more') }}</span>
			</div>
	</base-layout3>
</template>
