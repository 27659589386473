<template>
	<a @click='push_content(post.nid)'   :class="post.is_read == 1 ? 'bg-white' : ''" >
		<li class="msg-list">
			<div class="title">
				<div class="icon"><img :src="getImgUrl(post.is_read)" width="30" height="30" alt="" /></div>
				<div :data-nid="post.nid">
					<h2 class="text-start" :class="post.is_read == 1 ? 'is-read': ''">{{ get_title(post) }}</h2>
					<span>{{post.title}}</span>
				</div>
			</div>
			<div>
				<small>{{ parseDate(post.send_date) }}</small>
				<br/>
				<small>{{ parseDate2(post.send_date) }}</small>
			</div>
		</li>
	</a>
</template>

<script>
import moment from 'moment-timezone';
import {_} from 'vue-underscore';
export default {
	props: ["post",'data'],
		methods:{
			push_content(id)
			{
				this.$router.push('/account/notification_detail?id='+id);
			},
			test()
			{
				alert('2');
			},
			parseDate(date)
			{
				return moment(date).format('YYYY/MM/DD');
			},
			parseDate2(date)
			{
				return moment(date).format('HH:mm:s');
			},
			get_title(item)
			{
				// console.log(this.data.notification_type)
				// console.log(this.$i18n.locale)
				var find = _.find(this.data.notification_type, function(i){
					return i.type_id== item.type;
				});
				if(!_.isUndefined(find)){
					return find.name[this.$i18n.locale];
				}
			},
			getImgUrl(is_read) {
				var images;
				if (is_read == 0)
					images = require("@/assets/images/3/megaphone2.png");
				else 
					images = require("@/assets/images/3/megaphone.png");				
				return images;
			}
		}
};
</script>
<style>
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.listing-announcement {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 0px 0px;
}
.listing-announcement li {
	display: flex;
	color: #fff;
	background: rgba(0,0,0,0.65);
	border-bottom: dashed 1px #333;
	padding: 18px 25px;
	justify-content: space-between;
}
.listing-announcement .title {
    display: flex;
}
.listing-announcement .title .icon {
    margin-right: 10px;
}
.listing-announcement h2 {
    font-size: 16px;
    margin: 0;
    padding: 0;
}
.listing-announcement span {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #999;
}
a {
    /* color: #fff; */
    text-decoration: none;
}
.listing-announcement small {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #999;
}
.main-container  {
	padding-bottom: 54px;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: relative; */
}
.msg-list .icon {
	background: grey;
    border-radius: 100%;
    padding: 8px;
}


.text-start.is-read {
    color:#c8c8c8;
    
}
</style>