<template>
  <!-- <the-header></the-header>-->
  <!-- <keep-alive>
    <component :is="this.headerFooter.header"></component>
  </keep-alive> -->
  <div>
    <router-view></router-view>
  </div>
  <!-- <the-footer></the-footer>-->
  <!-- <keep-alive>
    <component :is="this.headerFooter.footer"></component>
  </keep-alive> -->
</template>
<script>
//import { onMounted, ref } from 'vue';
//import TheHeader from "@/components/layouts/TheHeader.vue";
//import TheFooter from "@/components/layouts/TheFooter.vue";

export default {
  name: "App",
  components: {
    //TheHeader,
    //TheFooter,
    //BaseCard,
    //Index
  },
  // mounted() {
  //   let code = this.getQueryVariable("inviteCode");
  //   this.browserRedirect(code);
  // },
  computed: {
    // headerFooter() {
    //   return this.$route.path != "/error"
    //     ? {
    //         header: TheHeader,
    //         footer: TheFooter,
    //       }
    //     : {
    //         header: TheHeader,
    //       };
    // },
  },
  // mounted() {
  //   console.log("init");
  // },
  setup() {
    /*
      const target = ref();
      const sticking = ref(false);
      const observer = new IntersectionObserver(
          ([entry]) => {
              console.log(entry);
              sticking.value = !entry.isIntersecting;
          },
          { threshold: 0.0 }
      );
      onMounted(() => {
          observer.observe(target.value);
      });
      return {
          target,
          sticking
      };
      */
  },

  methods: {
    browserRedirect(code) {
      let sUserAgent = navigator.userAgent.toLowerCase();
      let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      let bIsMidp = sUserAgent.match(/midp/i) == "midp";
      let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      let bIsAndroid = sUserAgent.match(/android/i) == "android";
      let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        let url =
          window.location.protocol +
          "//h5." +
          window.location.host.replace("www.", "");

        //if have invitation code
        if (code) {
          location.href = url + "/login/register?i=" + code;
          return;
        }
        location.href = url;
      }
    },
    getQueryVariable(variable) {
      var query = window.location.href;
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0].indexOf(variable) !== -1) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>
