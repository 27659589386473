module.exports = {
    "base_control_member_not_found":"用户不存在",
	"login_phone":"请输入手机号码",
	"login_username":"请输入用户名",
	"login_pass":"请输入登录密码",
	"login_code":"请输入验证码",
	"login_phone_err":"请输入正确的手机号码",
	"login_username_err":"请输入正确的用户名",
	"login_pass_err":"请输入正确的密码",
	"login_captcha_err":"验证码错误",
	"login_no_acc":"没有账号?马上",
	"login_repass_err":"密码不一致",
	"login_parentid_err":"邀请码错误",
    "auth_control_wrong_password":"请输入正确的密码",
	"login_check_err":"您必须同意条款和条件",
	"c_no_login":"您已被登出",
	"login_register":"会员注册",
	"login_livechat":"在线客服",
	"login_btn":"登录",
	"login_forgot":"忘记密码？",
	"login_label_phone":"手机号码",
	"login_label_username":"用户名",
	"login_label_pass":"登录密码",
	"login_label_captcha":"验证码",

	"reg_title":"注册账号",
	"reg_phone":"请输入手机号码",
	"reg_pass":"请输入登录密码",
	"reg_repass":"请输入确认密码",
	"reg_code":"请输入验证码",
	"reg_invite":"邀请码",
	"reg_username": "请输入用户名",
    "reg_realname": "请输入姓名",
    "reg_secpass": "请输入支付密码",
    "reg_re_secpass": "请输入确认支付密码",
	"reg_term1":"已满18岁，且同意本站",
	"reg_term2":"用户注册协议",
	"reg_1":"立即注册",
	"reg_2":"已有账户？马上",
	"reg_login":"登录",
	"reg_label_phone":"手机号码",
	"reg_label_pass":"登录密码",
	"reg_label_confirm_pass":"确认密码",
	"reg_label_captcha":"验证码",
	"reg_label_username":"用户名",
	"reg_label_realname":"姓名",
	"reg_label_sec_pass":"支付密码",
	"reg_label_confirm_sec_pass":"确认支付密码",
	"reg_username_err":"请输入正确的用户名",
    "reg_realname_err":"请输入正确的姓名",
	"reg_secpass_err":"请输入正确的支付密码",
	"reg_resecpass_err":"支付密码不一致",

	"account_index":"用户中心",
	"account_settings":"设置",
	"account_index_total_downline_comission":"推广收益",
	"account_my_wallet":"我的钱包",
	"account_index_available_balance":"个人余额",
	"account_index_available_voucher":"个人折扣卷",
	"account_index_invitation_code":"邀请码",
	"account_index_copy":"复制",
	"account_index_personal_info":"个人信息",
	"account_index_account_binding": "实名认证",
	"account_index_bank_card_binding":"银行卡绑定",
	"account_index_profit_record":"账变记录",
	"account_index_invite_good_friends":"邀请好友",
	"account_index_help":"帮助手册",
	"account_index_credit_center":"信用中心",
	"account_index_application_download":"应用下载",
	"account_index_task_record" : "任务记录",
	"account_index_my_task" : "我的任务",
	"account_index_release_management" : "发布管理",
	"account_index_today_earnings" : "今日收入",
	"account_index_withdrawn" : "已提现",
	"account_week_earning" : "一周收益",
	"account_month_earning" : "本月收益",
	"account_lastmonth_earning" : "上月收益",
	"account_index_total_income" : "累计收入",
	"account_index_completed_task" : "今日承接任务",
	"account_index_remaining_task" : "今日剩余任务",
	"account_index_logout":"退出",
	"account_confirm_logout":"确定登出账户?",
	"account_always_used":"常用功能",
	"account_basic":"基础工具",
	"account_ticket":"兑换影票",
	"account_setting":"设置",
	"account_welcome":"欢迎您 ！",
	"account_balance":"可用余额",
	"account_pic":"帐号图",
	"account_profits_details":"利润详情",
	"account_copied_referral_code":"拷贝推荐码成功",

	"recharge_index_recharge":"充值",
	"account_index_withdraw":"提现",
	"wallet_title":"我的钱包",
	"wallet_recharge_details":"充值细节",
	"wallet_recharge_log":"充值记录",
	"wallet_withdraw_log":"提现记录",
	"wallet_user_cash_fail":"已拒绝",
	"wallet_user_cash_success":"已完成",
	"wallet_user_cash_pending":"待支付",
	"wallet_user_withdraw_fail":"已驳回",
	"wallet_user_withdraw_success":"提现成功",
	"wallet_user_withdraw_pending":"待审核",

	"account_index_bank_bound":"银行卡已绑定",
	"account_bank_info":"银行卡信息",

	"wallet_recharge":"充值",
	"recharge_index_name":"汇款人姓名",
	"recharge_index_real_name":"请输入汇款户口持有人全名",
	"recharge_payment_method":"付费渠道",
	"recharge_index_balance":"余额",
	"recharge_index_detail":"明细",
	"recharge_index_amount":"充值金额",//"充值金额",
	"maximum_amount":"* 金额可以自行输入，单笔充值最高5000",
	"minimum_amount":"* 金额可以自行输入，单笔充值最低_min_起",
	"recharge_note_2":"* 如果您是让朋友转账，那充值订单上就需要填写您朋友的名字，即是汇款户口持有人全名。",
	"recharge_index_terms":"《充值规则说明》",
	"recharge_index_confirm_recharge":"确定充值",
	"recharge_index_min_err":"充值金额不能少于",
	"recharge_index_realname_enter":"真实姓名不能为空",
	"recharge_note_3":"* 温馨提示: 点击收款账号和姓名可复制其内容",
	"recharge_note_4":"* 温馨提示: 确认转账成功后再进行充值金额提交",
	"recharge_index_ref_no":"转账附言",
	"recharge_ref_no_err":"请输入转账附言",
	"recharge_amount_err":"请输入充值金额",
	"recharge_payment_method_err":"请选择付费渠道",

	"recharge_payment_order_number":"订单号",
	"recharge_payment_copy_text":"复制文本",
	"recharge_payment_success":"充值成功",

	"recharge_payment_bank_transfer0":"银行转账",
	"recharge_payment_transfer_funds0":"请按以下的信息对指定银行账户进行转账。",
	"recharge_payment_bank_transfer3":"ERC20转账",
	"recharge_payment_transfer_funds3":"请按以下的信息对指定ERC20账户进行转账。",
	"recharge_payment_bank_transfer4":"TRC20转账",
	"recharge_payment_transfer_funds4":"请按以下的信息对指定TRC20账户进行转账。",

	"recharge_wallet_addr":"交易地址",
	"recharge_txn_hash":"交易哈希值",
	"recharge_txn_hash_err":"请输入交易哈希值",


	"task_detail_copy":"复制",
	"account_info_account_name":"收款姓名",//"户口名字",
	"recharge_payment_bank":"开户银行",//"银行",
	"recharge_payment_bank_account_number":"收款卡号",//"户口号码",
	"recharge_copy_success":"复制成功",
	"recharge_payment_confirm_submit":"确定提交",
	"recharge_payment_screenshot_upload":"截图上传",
	"recharge_payment_no_found":"无效转账信息",
	"recharge_payment_pic_no_found":"请上传图片",
	"recharge_payment_note":"备注",

	"withdraw_payment_method":"提现渠道",
	"withdraw_payment_method_err": "请选择付提现渠道",
	"account_withdraw_withdraw":"提现",
	"withdraw_amount":"提现金额",
	"account_withdraw_terms":"《提现规则说明》",
	"account_withdraw_confirm_withdraw":"确定提现",
	"account_withdraw_verify_first":"提现前请先完成【实名认证】",
	"account_index_note":"感谢您的提交，请静待审核认证",
	"account_index_completed_information":"您已完善资料",
	"account_withdraw_damaling":"打码量",
	"account_withdraw_damaling1":"需要消费才能提款",
	"account_withdraw_minmax":"提现限制",
	"account_withdraw_time":"提现时间",
	"account_withdraw_forgot_pass":"若忘记支付密码,请,",
	"account_withdraw_findback":"点击找回",

	"account_review_account_bind":"实名认证",
	"account_review_upload_photo":"上传图片",
	"account_review_note_1" : "请上传1张社交ID或者身份证截图",
	"account_review_note_2" : "(如微信ID，FB 账号，护照身份证)",
	"account_review_social_id" : "请输入您的名字",
	"account_review_social_id1" : "名字",
	"account_review_name1" : "昵称",
	"account_review_name" : "请输入您的昵称",
	"account_identity" : "身份证号码",
	"account_identity2" : "请输入您的身份证号码",
	"system_bank_card" : "请填写银行卡号",
	"system_bank_card2" : "银行卡号",
	"system_bank_card1" : "银行卡号",
	"system_bank_branch" : "开户行",
	"account_info_bank_card_info" : "银行户口信息",
	"review_note_bank" : "注意： 为了避免提现错误，请确保您填写的户口号码号码正确",
	"account_review_submit" : "提交",
	"review_zoom" : "放大",
	"account_review_wechat_id" : "请输入您的名字",
	"account_review_wechat_name" : "请输入您的昵称",
	"bank_account_err" : "请填写户口号码",
	"bank_account_name_err" : "请填写真实姓名",
	"bank_branch_err" : "开户行",

	"account_withdraw_bank_card":"银行卡",
	"account_withdraw_account_name":"用户名",
	"account_withdraw_real_name":"真实姓名",
	"account_withdraw_real_name1":"真实姓名",
	"account_withdraw_rate":"手续费",
	"account_withdraw_cash":"兑现",
	"account_withdraw_balance":"余额",
	"withdraw_total_payment":"实际到账",
	"account_withdraw_mobile_phone":"请输入手机号",
	"account_withdraw_enter_mobile":"手机号",
	"vip_control_points_less":"积分提现金额不能少于50元",
	"withdraw_less":"提现金额不正确",
	"withdraw_spend_required":"还需要花费金额",
	"withdraw_spend_requiredend":"才能提款",
	"withdraw_phone_err":"手机号不能空",
	"withdraw_secpassword":"支付密码",
	"withdraw_secpassword_err":"支付密码不能空",

	"account_index_user_center":"用户中心",
	"account_index_user_details":"用户资料",
	"account_info_username":"用户名",
	"account_info_level":"等级",
	"account_info_referral_code":"推荐码",
	"account_info_account_infos":"账号信息",
	"account_info_wechat_id":"社交ID",
	"account_info_wechat_nickname":"昵称",
	"account_info_change_password":"更换密码",
	"account_info_change_secpassword":"更换支付密码",
	"account_system_language":"系统语言",
	"account_info_unbound":"未绑定",
	"account_info_bounding":"绑定中",
	"account_info_bounded":"已绑定",
	"account_info_bank_account":"户口号码",
	"account_info_bank_branch":"银行名字",

	"transfer_index":"转账",

	"profile_password_change_password":"更换密码",
	"profile_password_old_password":"输入原始密码",
	"profile_password_new_password":"输入新密码",
	"profile_password_reenter_password":"输入确认新密码",


	"profile_password_old_password_text":"原始密码",
	"profile_password_new_password_text":"新密码",
	"profile_password_reenter_password_text":"确认新密码",
	"profile_update_success":"重复新密码成功,请重新登入！",

	"profile_password_change_secpassword":"更换支付密码",
	"profile_password_old_secpassword":"输入原始支付密码",
	"profile_password_new_secpassword":"输入新支付密码",
	"profile_password_reenter_secpassword":"输入确认新支付密码",


	"profile_password_old_secpassword_text":"原始支付密码",
	"profile_password_new_secpassword_text":"新支付密码",
	"profile_password_reenter_secpassword_text":"确认新支付密码",

	"vip_index_vip_purchase":"VIP购买",
	"vip_index_free":"免费",
	"vip_index_obtained":"已获得",
	"vip_index_join_now":"立即加入",
	"vip_index_task_times":"次",
	"vip_index_daily_task":"每日购票",
	"vip_index_voucher":"折扣卷",
	"vip_index_withdraw":"提款次数",
	"vip_index_withdraw_fees":"提款佣金",
	"vip_index_reward":"红利",
	"quick_task_function":"*可以开启一键任务完成功能",
	"vip_index_confirm_purchase":"确认购买",
	"vip_index_continue_purchase":"请问您是否继续购买？",
	"vip_index_purchase":"确定",
	"vip_index_cancel":"取消",
	"credit_no_enough":"余额不足，请进行充值",
	"vip_control_purchase_vip":"购买VIP成功",

	"common_error":"无效出错了",

	"account_incomelog_income_breakdown":"收入明细",
	"account_revenue":"收入",
	"account_expenditure":"支出",
	"account_recharge":"充值",
	"account_voucher":"折扣卷",

	"invite_index_invite_friend":"邀请好友",
	"invite_index_save_qr":"长按保存二维码 ",
	"invite_index_share_moment":"发布到朋友圈",
	"invite_index_invite_link":"邀请链接",
	"invite_index_referral_code":"推荐码",

	"team_reports":"团队报表",
	"team_reports_my_team":"我的团队",
	"myteam_user":"用户",
	"myteam_recharge":"充值",
	"vip_control_withdrawal":"提现",
	"myteam_commission":"返点",

	"team_reports_team_recharge":"团队充值",
	"team_reports_team_withdraw":"团队提现",
	"team_reports_team_recharge_num":"团队充值次数",
	"team_reports_first_charge":"首充人数",
	"team_reports_first_push":"首推人数",
	"team_reports_team_size":"团队人数",
	"team_reports_new_team":"团队新增",
	"team_reports_downline_total_commision":"下线总利润",

	"system_maintenance":"系统维护",
	"system_maintenance_note_1":"抱歉，由于系统维护，团队报表页面暂时不可浏览。",
	"validate_task_start_time":"开始时间!",
	"validate_task_end_time":"结束时间!",
	"system_maintenance_note_2":"团队报告页面预计将关闭1小时左右。!",
	"system_maintenance_note_3":"对于给您带来的任何不便，我们深表歉意。!",
	"system_maintenance_back":"回去我的",
	"team_reports_level_1":"一级",
	"team_reports_level_2":"二级",
	"team_reports_level_3":"三级",
	"team_reports_recharge_amount":"充值金额",
	"team_reports_recharge_number":"充值人数",
	"team_reports_total_commission":"总利润",
	"team_reports_search":"搜索",

	"footer_home":"首页",
	"footer_movie":"电影",
	"footer_ticket":"影票",
	"footer_plan":"排行榜",
	"footer_me":"我的",
	"footer_movie2":"电影中心",
	"footer_me2":"个人中心",
	"footer_question":"常见问题",
	"footer_abt":"联络我们",
	"footer_hotspot":"热点",
	"footer_orderlist":"交易记录",
	"footer_market":"市场",

	"movie_cat":"类别",
	"movie_all":"全部",
	"movie_rating":"观众评",
	"movie_title":"电影",
	"movie_on":"热映",
	"movie_on_rank":"热映榜",
	"movie_wait":"待映",
	"movie_category":"类型",
	"movie_des":"简介",
	"movie_price":"票价",
	"movie_reward":"红利",
	"movie_buy":"购票",
	"movie_num":"数量",
	"movie_num_err":"购票数量不可以小于0",
	"movie_total":"总价",
	"movie_voucher":"折扣卷",
	"movie_voucher_use":"使用折扣卷?",
	"buy_credit_no_enuf":"余额不足",
	"buy_voucher_no_enuf":"折扣卷不足",
	"buy_err_invalid_movie":"找不到此电影记录",
	"buy_ticket_err":"购买电影失败!",
	"buy_ticket_success":"购买电影成功!",
	"buy_ticket_in_remaining":"购买影票的次数已达上限!",

	"ticket_title":"订购电影票资讯",
	"ticket_code":"购票编号",
	"ticket_more":"详情",
	"ticket_pending":"未完成",
	"ticket_complete":"完成",
	"ticket_balance_used":"已用余额",
	"ticket_voucher_used":"已用折扣卷",
	"ticket_details":"影票详情",
	"ticket_status":"状态",
	"ticket_reward":"红利",
	"ticket_rebate":"返回",
	"ticket_home":"返回",
	"ticket_payment_success":"付款成功",
	"ticket_payment_time":"付款时间",
	"ticket_reward_time":"奖励时间",
	"ticket_rebate_time":"返回时间",

	"index_movie":"电影",
	"index_ticket":"影票",
	"index_rank":"利息宝",
	"index_vip":"VIP",
	"index_me":"个人信息",
	"index_buy":"购票",
	"index_watch":"想看",
	"index_register":"注册",
	"index_login":"登入",

	"index_open":"交易中",
	"index_close":"关闭",

	"index_hot_products":"热门产品",
	"index_latest":"最新",
	"index_status":"状态",
	"index_recharge":"充值",
	"index_withdraw":"提现",
	"index_speed_contract":"极速合约",
	"index_interest":"利息宝",
	"index_download":"下载",

	"index_inbox":"信息",
	"inbox_control_mail_abnormal":"信息异常",
	"inbox_details":"信息详情",

	"hotspot_title":"热点",
	"hotspot_news":"最新资讯",
	"hotspot_promo":"优惠促销",
	"hotspot_details":"内容详情",

	"common_balance":"余额",
	"common_voucher":"折扣卷",
	"open_app":"打开App",
	"drag_more":"拖动加载更多",
	"no_more":"没有更多！",
	"common_point":"分",
	"common_copy":"复制",
	"common_close":"关闭",
	"common_confirm":"确定",

	"previous":"前一页",
	"next":"下一页",

	"faq_title":"常见问题",

	"about_title":"联络我们",
	"about_title2":"您可透过以下管道联络我们！",
	"about_email":"电邮",
	"about_wechat":"微信",
	"about_time":"营业时间",

	"plan_title":"利息宝",
	"plan_days":"天",
	"plan_intrest":"利息",
	"plan_price":"价格",
	"plan_buy":"购买",
	"plan_details":"利息宝详情",
	"plan_details_balance":"可用余额",
	"plan_details_cycle":"投资期限",
	"plan_details_interest":"投资收益",
	"plan_details_min":"起投金额",
	"plan_details_max":"最大投资金额",
	"plan_details_amt":"投资金额",
	"plan_details_amt_enter":"请输入投资金额",
	"plan_details_comfirm":"确认提交",
	"plan_project_no_start":"项目还未开始",
	"plan_invalid":"无效利息宝项目",
	"plan_invalid_money":"无效投资金额",
	"plan_limit_per_invest":"该项目每人限投资",
	"plan_limit_time_invest":"该项目同时间限投资",
	"plan_balance_no_enuf":"余额不足，请充值后再进行投资！",
	"plan_invalid_invest_min":"投资金额小于项目最小投资额度！",
	"plan_invalid_invest_max":"投资金额大于项目的最高金额",
	"plan_invest_fail":"投资失败！",
	"plan_invest_success":"投资成功！",
	"plan_mobile_times":"次",
	"plan_order_details":"投资细节",
	"plan_order_view_details":"查看内容",
	"plan_order_view_distribute":"分派中",
	"plan_order_view_done":"完成",
	"plan_day_no":"投资天数",
	"plan_payback":"投注回酬",


	"register_user_agreement":"《用户协议》",
	"recharge_withdraw_terms":"充值和提现规则说明",
	"terms_1":"1. 每次充值金额不能少过{min}。",
	"terms_2":"2. 每次充值金额不能多过{max}。",
	"terms_3":"3. 每次充值请确保填写真实全名。",
	"terms_4":"4. 平台根据活动运营能力设置不同档位的提现额度，具体以提现页面展示为准。用户每次提现时可以选择所需的一档提现额度，剰余金额可在下次满足前述提现额度时申请提现。",
	"terms_5":"5. —个帐号每日最多可提现{withdraws}次， 一个设备每日最多可提现{withdraws}次。",
	"terms_6":"6. 您可以选择提现到银行卡, 绑定银行卡需要前往实名认证进行提交审核。",
	"terms_7":"7. 提现一般10分钟内到账（请您理解并同意如遇提现高峰，提现到账时间会延长）。",
	"terms_8":"8. 为保证您顺利提现，请先确保您的银行卡符合第三方支付平台的要求（请务必完成实名认证)，否则将无法提现成功。",
	"terms_9":"9. 请您理解并同意我们应用先进的人工智能分析用户的行为，如发现用户曾经存在、出现或经Sonhador合理怀疑存在违背诚实信用原则的行为等其他违规情形，我们有权阻止用户抢单、赚钱、提现以及取消用户获得的奖励。",

	"common_missing":"缺少参数",
	"common_acc_freez":"您的账户已被冻结",
	"forgetpass_block":"您的账户已被冻结",

	"maximumPosition":"最大投资金额为",
	"minimumPosition":"最小投资金额金额为",
	"order_invest_fail":"下单失败！",
	"order_invest_success":"下单成功！",
	"order_invalid":"无效产品项目",
	"order_invalid_Sec":"时间参数异常",

	"index_movieunit":"部",
	"index_trailer":"预告片",
	"index_on_showing":"正在热映",
	"index_on_s":"上映",
	"index_on_month":"月",
	"index_on_date":"日",
	"index_coming_soon":"即将上映",

	"exchange_title":"交易",
	"exchange_holdPositions":"持仓",
	"exchange_buyUp":"买涨",
	"exchange_buyFall":"买跌",
        "exchange_buyDown":"买跌",
	"exchange_buyOrFall":"买跌",
	"exchange_confirm_order":"确认订单",
	"exchange_balance":"余额",
	"exchange_service_charge":"手续费",
	"exchange_order_confirmation":"订单确认",
	"exchange_expiration_date":"到期时间",
	"exchange_other_amount":"其他金额",
	"exchange_insufficient":"投资金额余额不足，请充值！",
	"exchange_order_max_price":"单笔投资金额不超过{amt}",
	"exchange_order_min_price":"单笔投资金额不少于{amt}",
	"exchange_order_max_price_fee":"单笔投注金额与手续费不超过{amt}",
	"exchange_investment_amount":"投资金额",
	"exchange_name":"名称",
	"exchange_direction":"方向",
	"exchange_present_price":"现价",
	"exchange_money":"金额",
	"exchange_prospective_yield":"预期收益",
	"exchange_prospective_result":"预期结果",
	"exchange_guaranteed_amount":"保底金额",
	"exchange_settlement_time":"结算时间",
	"exchange_minute":"分钟",
	"exchange_profit":"收益",
	"exchange_open":"开盘",
	"exchange_low":"最低",
	"exchange_high":"最高",
	"exchange_succeeded":"交易成功，等待结算",
	"exchange_time_remaining":"剩余时间",
	"exchange_execution_price":"执行价",
	"exchange_forecast_results":"预测结果",
	"exchange_please_wait":"请稍后",
	"exchange_submitting_order":"正在提交订单",
	"exchange_due_settlement_completed":"到期结算完成",
	"exchange_transaction_price":"成交价",
	"exchange_continue_order":"继续下单",
	"exchange_position_details":"持仓明细",
	"exchange_balance_err":"资金不足，请先充值",
	"exchange_balance_min_err":"最小下注金额为",
	"exchange_balance_max_err":"最大下注金额为",
	"exchange_title_market":"市场",
	"exchange_login_required":"请先登录",
	"exchange_amount_box_all":"全部金额",

	"trade_order_list":"交易记录",
	"trade_position_details":"持仓记录",
	"trade_history_details":"历史明细",
	"trade_name":"商品行情",
	"trade_transaction_price":"成交价",
	"trade_settlement_price":"结算价",
	"trade_fee":"手续费",
	"trade_profit_loss":"盈亏",
	"trade_close_time":"成交时间",
	"trade_settle_time":"到期时间",
	"trade_close":"关闭",

	"product_category_0":"全部",
	"product_category_1":"油",
	"product_category_2":"金属",
	"product_category_4":"指数",
	"product_category_5":"外汇",
	"product_category_6":"币",

	"market_products":"热门产品",

	"promotions_index":"优惠促销",

	"下单":"下单",
	"结单":"结单",
    "结单-本金":"结单-本金",
	"后台会员充值":"后台会员充值",
	"实名认证奖励":"实名认证奖励",
	"理财计划奖励-本金":"理财计划奖励-本金",
	"理财计划奖励":"理财计划奖励",

	"account_withdrawal_1":"请联络管理员来操作此动作！",
	"account_recharge_1":"请联络管理员来操作此动作！",

        "reg_label_email":"用户邮箱",
        "reg_email_err" : "邮箱格式不正确",
}
