<template>
  <base-layout3 :show-logo="true" :page-title="$t('footer_home')" mode="">
    <Post />
    <section>
      <div class="container-fluid p-0 overflow-hidden">
        <div id="carousel_" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              data-bs-target="#carousel_"
              data-bs-interval="10000"
              :data-bs-slide-to="k"
              :class="k == 0 ? 'active' : ''"
              v-for="(item, k) in banner.mobile"
              :key="k"
            >
              1
            </button>
          </div>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              :class="k == 0 ? 'active' : ''"
              v-for="(item, k) in banner.mobile"
              :key="k"
            >
              <img
                class="d-block w-100"
                :src="img_url + '/uploads/' + item.thumb"
                :alt="item.title"
                @click="gopage(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="announcement">
      <div class="notice">
        <div class="layout">
          <span style="padding: 5px">
            <img
              src="@/assets/images/svg3/announcement.svg"
              width="30"
              height="30"
            />
          </span>
          <marquee width="90%" class="text-white">
            {{ announcements }}
          </marquee>
        </div>
      </div>
    </section>

    <section class="index-menu row">
      <a
        class="menu-item col-3"
        @click="redirect('/account/deposit')"
        v-if="recharge_btn == '1'"
      >
        <img src="@/assets/images/3/recharge.png" />
        <p class="text-primary">{{ $t('index_recharge') }}</p>
      </a>

      <a
        class="menu-item col-3"
        @click="
          dialogIsVisible = true;
          dialogtext = 'account_recharge_1';
        "
        v-if="recharge_btn == '0'"
      >
        <img src="@/assets/images/3/recharge.png" />
        <p class="text-primary">{{ $t('index_recharge') }}</p>
      </a>
      <a class="menu-item col-3" v-if="withdraw_btn == '1'">
        <img
          src="@/assets/images/3/withdraw.png"
          @click="redirect('/account/withdraw')"
        />
        <p class="text-primary">{{ $t('index_withdraw') }}</p>
      </a>
      <a class="menu-item col-3" v-if="withdraw_btn == '0' && speed_contract_btn == '0'">
        <img
          src="@/assets/images/3/withdraw.png"
          @click="
            dialogIsVisible = true;
            dialogtext = 'account_withdrawal_1';
          "
        />
        <p class="text-primary">{{ $t('index_withdraw') }}</p>
      </a>
      <a class="menu-item col-3" v-if="speed_contract_btn == '1'" @click="redirect_speed_contract()">
        <img
          src="@/assets/images/3/withdraw.png"
        />
        <p class="text-primary">{{ $t('index_speed_contract') }}</p>
      </a>
      <a class="menu-item col-3">
        <img
          src="@/assets/images/3/interest.png"
          @click="redirect('/plan/plan')"
        />
        <p class="text-primary">{{ $t('index_interest') }}</p>
      </a>
      <a class="menu-item col-3">
        <img src="@/assets/images/3/download.png" @click="godownload()" />
        <p class="text-primary">{{ $t('index_download') }}</p>
      </a>
    </section>

    <div
      v-if="dialogIsVisible"
      class="backdrop"
      @click="$emit('hideDialog')"
    ></div>
    <transition name="modal">
      <dialog
        open
        v-if="dialogIsVisible"
        :class="modal_mode"
        style="height: auto"
      >
        <div class="modal-header bg-modal">
          <button
            type="button"
            class="btn-close text-light"
            @click="hideDialog"
          >
            X
          </button>
        </div>
        <div
          class="modal-body text-center"
          style="padding: 10px; overflow-y: auto"
        >
          <h4 class="mb-3">{{ $t(dialogtext) }}</h4>
        </div>
        <div class="modal-footer p-0">
          <button
            type="button"
            class="btn btn-default text-dark"
            @click="hideDialog"
          >
            {{ $t('common_close') }}
          </button>
        </div>
      </dialog>
    </transition>

    <div class="container pt-1 pb-1 product">
      <div class="p-0">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('index_hot_products') }}</th>
              <th>{{ $t('index_latest') }}</th>
              <th>{{ $t('index_status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, k) in product"
              :key="k"
              @click="push_content('/trade/exchange', item.procode)"
            >
              <td style="white-space: nowrap">
                <img
                  :src="img_url + '/uploads/' + item.img"
                  :alt="item.title"
                  class="product-icon"
                />&nbsp; {{ item.ptitle }}
              </td>
              <td
                :class="{
                  'text-success': item.zf >= 0,
                  'text-danger': item.zf < 0,
                }"
              >
                {{ toFixed(item.Price ? item.Price : 0, 4) }} <br /><span
                  >{{ item.zf }}%</span
                >
              </td>
              <td>
                <button
                  type="button"
                  class="btn buy-btn text-primary"
                  v-if="item.isopen == 1"
                >
                  {{ $t('index_open') }}
                </button>
                <button
                  type="button"
                  class="btn buy-btn text-primary"
                  v-if="item.isopen == 0"
                >
                  {{ $t('index_close') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="order-detail1" :class="!isiframe ? 'd-none' : ''">
      <!-- <iframe :src="appurl+'?type=h5'" class=""  id="downloadframe"></iframe> -->
      <div class="text-center" style="margin-top: 25vh">
        <img :src="logo" alt="" style="width: 60px" />
        <div class="m-4">
          <a @click="this.goto(android_link)">
            <img
              src="~@/assets/images/download-ios.png"
              alt=""
              style="width: 250px"
            />
          </a>
        </div>
        <div class="m-4">
          <a @click="this.goto(ios_link)">
            <img
              src="~@/assets/images/download-android.png"
              alt=""
              style="width: 250px"
            />
          </a>
        </div>
      </div>
      <a @click="this.closeiframe()" class="close bg-dark">{{
        $t('trade_close')
      }}</a>
    </div>

    <!--
		<div id="scoC5c" class="" :class="issupport ? 'd-none' : ''">
			<div class="psmtc_oC5c move" style="z-index: 2147483647; position: fixed; bottom: 60px; right: 2px;">
				<a class="data-open-cs2"  style=""><img name="psoC5cimage" @click="gosupport()"  src="@/assets/images/support2.png" style="display:block;border:0"></a>
			</div>
			<div class="psmw_oC5c" style="z-index: 2147483647; display: none; position: relative;"></div>
		</div> -->
  </base-layout3>
</template>
<script>
import { _ } from 'vue-underscore';
import { getLocal } from '@/utils/local.js';
import { isMobile } from '@/plugin/isMobile.js';
import * as $ from 'jquery';
import BaseModal from '@/components/UI/BaseModal.vue';
import Post from '@/components/Modal.vue';
import { huobiWsUrl } from '@/views/mobile3/trade/config.js';
import { toFixed } from '@/utils/chart.js';

export default {
  data() {
    return {
      data: {},
      banner: [],
      config: {},
      product: {},
			default_product: '',
      announcements: null,
      login: false,
      img_url: process.env.VUE_APP_IMAGE_URL + '/uploads/',
      dialogIsVisible: false,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
      page: 1,
      haurl: huobiWsUrl,
      socketK: {},
      toFixed: toFixed,
      reroute: false,
      isiframe: false,
      issupport: false,
      appurl: null,
      android_link: null,
      ios_link: null,
      logo: null,
      close_socket: false,
      recharge_btn: process.env.VUE_APP_RECHAGE_BTN,
      withdraw_btn: process.env.VUE_APP_WITHDRAW_BTN,
			speed_contract_btn: process.env.VUE_APP_SPEED_CONTRACT_BTN,
    };
  },
  created() {},
  mounted() {
    this.getConfig();
    // this.getNotice();
    this.getProductInfo();
    this.drag_div();
  },
  beforeMount() {
    let token = getLocal('token') || null;
    this.login = token == null ? false : true;
    this.img_url = process.env.VUE_APP_IMAGE_URL;
  },
  unmounted() {
    if (this.socketK && this.socketK.readyState === WebSocket.OPEN) {
      this.close_socket = true;
      this.socketK.close();
      this.socketK = null;
    }
  },
  watch: {
    $route(to, from) {
      if (this.socketK.close) {
        // this.socketK.send(JSON.stringify({action: 'unsub', msg: 'Mainpage Disconnected', sub: 'mainpage_ticker'}));
        this.reroute = true;
        this.socketK.close();
      }
    },
  },
  components: {
    // BaseModal,
    Post,
  },
  methods: {
    drag_div() {
      var $find = false;
      $('html')
        .find('.psmtc_oC5c')
        .each(function (e) {
          var dv = document.getElementsByClassName('psmtc_oC5c')[0];
          $find = true;
          var s = dv;
          // ma1 = 0,
          var u = !1,
            d = document.documentElement.clientHeight,
            h = document.documentElement.clientWidth,
            ma1 = h;

          s.addEventListener('touchstart', function (e) {
            (u = !0),
              s.setCapture && s.setCapture(),
              $('.psmtc_oC5c').removeClass('move');
          }),
            s.addEventListener('touchmove', function (e) {
              $('.psmtc_oC5c').removeClass('move'),
                e.preventDefault(),
                (e = e.touches ? e.touches[0] : event),
                u &&
                  (e.pageY < s.clientHeight / 2
                    ? (s.style.bottom = d - s.clientHeight + 'px')
                    : e.pageY > d - 5 - s.clientHeight / 2
                    ? (s.style.bottom = '5px')
                    : (s.style.bottom =
                        d - e.pageY - s.clientHeight / 2 + 'px'),
                  e.pageX < s.clientWidth / 2
                    ? (s.style.right = h - s.clientWidth + 'px')
                    : e.pageX > h - s.clientWidth / 2
                    ? (s.style.right = '0px')
                    : (s.style.right = h - e.pageX - s.clientWidth / 2 + 'px'),
                  (ma1 = e.pageX));
            }),
            s.addEventListener('touchend', function (e) {
              (u = !1),
                $('.psmtc_oC5c').addClass('move'),
                (s.style.right = ma1 > h / 2 ? 0 : h - s.clientWidth + 'px');
            });
        });

      return $find;
    },
    goto(item) {
      window.location.href = item;
    },
    hideDialog() {
      this.dialogIsVisible = false;
    },
    closeiframe() {
      this.isiframe = false;
      this.issupport = false;
    },
    godownload() {
      // console.log("here"+this.appurl)
      this.isiframe = true;
      var that = this;
    },
    gosupport() {
      // console.log("here"+this.appurl)
      this.isiframe = true;
      this.issupport = true;
    },
    push_content(url, id) {
      console.log(url+'?product='+id)
      this.$router.push(url + '?product=' + id);
    },
		redirect_speed_contract(){
			// const postId = this.default_product;
      // const path = `/trading/exchange/${postId}`;
			// this.$router.push(path);
			// console.log(postId);
      // this.$router.push(url + '?product=' + `${this.default_product}`);
      this.$router.push(`/trade/exchange?product=btcusdt`);
		},
    redirect(url) {
      this.$router.push(url);
    },
    getConfig() {
      var that = this;
      this.$http('', '/home/index/config_all', '').then((result) => {
        if (result.code == '0001') {
          this.data = result.data;
          this.config = result.data.config.setting;
          // this.banner = result.data.banners;
          this.logo = this.img_url + '/uploads/' + this.config.logo || '-';
          this.appurl = this.config.appurl || '-';
          this.ios_link = this.config.ios_link || '-';
          this.android_link = this.config.android_link || '-';

          //console.log(this.logo);
          //console.log(this.appurl);
          var b = [];
          var b2 = [];
          _.each(result.data.banners, function (item) {
            if (item.type == 0) b.push(item);
            else b2.push(item);
          });
          this.banner['mobile'] = b;
          this.banner['desktop'] = b2;
          // console.log(this.config);

          var d = _.sortBy(result.data.notices, function (i) {
            return -i.order_by;
          });

          var f = _.find(d, function (item) {
            return item.language == that.$i18n.locale;
          });

          this.announcements = (typeof f != 'undefined' && f.title) || '';
        } else {
          // return mui.toast(result.message,{ duration:'short', type:'div' })
        }
      });
    },
    getNotice() {
      var that = this;
      this.$http('', '/home/index/notice.html', '', 'get').then((result) => {
        if (result.code == '0001') {
          var d = _.sortBy(result.data, function (i) {
            return -i.order_by;
          });

          var f = _.find(d, function (item) {
            //return item.language == that.$i18n.locale;
            return item;
          });
          // console.log(f)
          this.announcements = (typeof f != 'undefined' && f.title) || '';
        } else {
          // return mui.toast(result.message,{ duration:'short', type:'div' })
        }
      });
    },
    getProductInfo() {
      var msg = this.$t('no_more');
      // console.log(msg)
      this.$http(
        '',
        '/home/api/product_info?page=' + this.page,
        { per_page: this.per_page },
        'post'
      ).then((result) => {
        this.data = result.data;
        this.data.sort((a, b) => a.pid - b.pid);
        this.product = _.union(this.product, result.data);
				this.default_product = result.data[0];

        // this.current_page = result.data.current_page || 1;
        // this.last_page    = result.data.last_page || 1;
        if (result.code == '0001') {
          // if(_.size(result.data.data)==0)
          // 	$('.notMore').css('display', 'block').html(msg);

          this.handleInitWebsoket();
        }
      });
    },
    handleInitWebsoket(isReconnect = 0) {
      var self = this;

      self.socketK = new WebSocket(self.haurl);
      self.socketK.onopen = function () {
        if (isReconnect === 1) {
          console.log('reconnected', 'ws');
          self.socketK.send(
            JSON.stringify({
              action: 'subb',
              msg: 'Mainpage Reconnected',
              sub: 'mainpage_ticker',
            })
          );
        } else {
          console.log('connected', 'ws');
          self.socketK.send(
            JSON.stringify({
              action: 'subb',
              msg: 'Mainpage Connected',
              sub: 'mainpage_ticker',
            })
          );
        }
      };
      self.socketK.onmessage = function (event) {
        // console.log(event, 'event')
        if (typeof event.data !== 'undefined') {
          let blob = JSON.parse(event.data);

          if (typeof blob.type !== 'undefined' && blob.type === 'ping') {
            self.socketK.send(JSON.stringify({ pong: blob.type }));
          } else {
            var i;
            var len = self.product.length;
            // if (window.location.href.indexOf('local') > -1)
            // console.log(len, 'len')
            for (i = 0; i < len; i++) {
              var v = self.product[i];

              // console.log(v.procode, 'v : ' + i)
              let req = 'market.' + v.procode.toLowerCase() + '.ticker';
              // console.log(req, blob.action);
              // console.log(blob.data.tick, req)
              if (req === blob.action) {
                let j = i;

                // console.log(self.product[j], 'product i: ' + i)

                if (typeof blob.data.tick !== 'undefined') {
                  let data = blob.data.tick;
                  if (typeof data.ptitle !== 'undefined')
                    self.product[j].ptitle = data.ptitle;
                  if (typeof data.price !== 'undefined')
                    self.product[j].Price = data.price;
                  if (typeof data.isopen !== 'undefined')
                    self.product[j].isopen = data.isopen;
                  if (typeof data.zf !== 'undefined')
                    self.product[j].zf = data.zf;
                }

                // console.log(self.product[j], 'product : ' + i)
              }
            }
          }
        }
      };
      self.socketK.onclose = function () {
        if (!self.close_socket) {
          self.handleInitWebsoket(1);
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
table {
  > :not(:first-child) {
    border-top: 2px solid $brown-300;
  }
  thead {
    // display: block;
  }
  tbody {
    // display: block;
    /*
		height: 300px;
		overflow-y: scroll; */
  }
  td td:first-child {
    text-align: left;
    padding-left: 20px;
    width: 1%;
  }
  tr {
    height: 65px;

    th,
    td {
      text-align: center;
      border: none !important;
      // width: 1%;
      vertical-align: middle;
      padding-left: 0;
      padding-right: 0;
      font-size: 12px;
    }
    th {
      // padding-left: 28px;
      font-size: 14px;
    }
    td.text-success {
      color: map-get($theme-colors, 'success') !important;
      font-weight: bolder;
      font-size: 14px;

      span {
        font-weight: normal;
      }
    }
    td.text-danger {
      color: map-get($theme-colors, 'danger') !important;
      font-weight: bolder;
      font-size: 16px;

      span {
        font-weight: normal;
      }
    }
  }
}
.buy-btn {
  background: $brown-100;
  border-radius: 12px;
  font-size: 12px;
}
.announcement {
  background: rgba($brown-100, 0.396);
  .layout {
    display: flex;
    align-items: center;
  }
}
.index-menu {
  text-align: center;
  background: rgba($black-400, 0.396);
  border-radius: 12px;
  padding: 20px 0 0px 0;

  .menu-item {
    text-decoration: none;
    p {
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 12px;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.product > div {
  padding: 20px;
  text-align: center;
  background: rgba($black-400, 0.396);
  border-radius: 12px;
  margin: 20px auto;
}
.product-icon {
  width: 25px;
}
@media (max-width: 360px) {
  .buy-btn {
    font-size: 10px;
  }
}
body {
  overflow-x: hidden !important;
}
.order-detail1 {
  background: $black-400;
  background-image: url(~@/assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  // height: 100%;
  height: calc(100vh - 64px);
  position: fixed;
  width: 100%;
  // top: 54px;
  top: 0;
  left: 0;
  z-index: 1000;

  a.close {
    position: fixed;
    color: $white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    width: 100%;
    background: rgba($black-100, 0.376);
    bottom: 0;
    padding: 20px;
  }
}

iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: calc(100vh - 54px); /* Viewport-relative units */
  width: 100vw;
}

.psmtc_oC5c {
  border-radius: 50%;
}
div.psmtc_oC5c {
  z-index: 9997 !important;
}
div.psmtc_oC5c.move {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.psmtc_oC5c img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgb(229, 50, 251);
  background: -moz-linear-gradient(
    114deg,
    rgba(229, 50, 251, 1) 0%,
    rgba(213, 19, 218, 1) 100%
  );
  background: -webkit-linear-gradient(
    114deg,
    rgba(229, 50, 251, 1) 0%,
    rgba(213, 19, 218, 1) 100%
  );
  background: linear-gradient(
    114deg,
    rgba(229, 50, 251, 1) 0%,
    rgba(213, 19, 218, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e532fb",endColorstr="#d513da",GradientType=1);
}
.product table tr th:first-child,
.product table tr td:first-child {
  text-align: left !important;
  padding-left: 20px;
  padding-right: -10px;
}
</style>
