<template>
  <base-layout3
    :page-title="this.productInfo.ptitle"
    :show-nav="true"
    :show-back-button="true"
    :show-footer="false"
    :show-item="false"
    mode=""
  >
    <div class="content trade-view">
      <div class="data-box">
        <div class="left">
          <!-- 仅供展示 -->
          <h3>{{ toFixed(this.state.titleCal.end_price, 4) }}</h3>
          <!-- <div class="rate">
            ≈{{ toFixed(this.state.titleCal.end_price, 4) }} USD
          </div> -->
        </div>
        <div class="other-data">
            <p>{{$t('exchange_open')}}</p>
            <p class="rise data-open" style="">{{toFixed(this.state.titleCal.start_price, 4)}}</p>
        </div>
        <div class="other-data">
            <p>{{$t('exchange_low')}}</p>
            <p class="rise data-low" style="">{{toFixed(this.state.titleCal.min_price, 4)}}</p>
        </div>
        <div class="other-data">
            <p>{{$t('exchange_high')}}</p>
            <p class="rise data-high" style="">{{toFixed(this.state.titleCal.max_price, 4)}}</p>
        </div>
      </div>
      <!-- 导航按钮 -->
      <div class="nav-box">
        <div
          class="item"
          :class="this.state.currentNav === index ? 'active' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="handleNavClick(index, item.opt)"
        >
          {{ item.text }}
        </div>
      </div>

      <div
        class="chart-box"
        :style="{ backgroundImage: 'url(' + bgImg + ')' }"
        @click.stop="handleChartClick($event)"
      >
        <div class="top">
          <span
            class="yellow-color"
            :style="{ color: item.color }"
            v-for="(item, index) in this.state.postTop"
            :key="index"
          >
            {{ item.name }}:{{ toFixed(item.value) }}
          </span>
        </div>

        <div class="middle">
          <span
            class="base-color"
            v-for="(item, index) in this.state.chengJiao"
            :key="index"
            :style="{ color: item.color }"
          >
            {{ item.name }}:{{ toFixed(item.value) }}
          </span>
        </div>

        <!-- 数据显示栏 -->
        <div
          v-show="this.state.showTool"
          class="tool-box"
          :class="this.state.dataPos ? 'pos-right' : 'pos-left'"
        >
          <p v-for="(item, index) in this.state.toolList1" :key="index">
            <template v-if="index === 5">
              <span>{{ item.name }}</span>
              <span :class="item.value >= 0 ? 'color_green' : 'color_red'">{{
                toFixed(item.value)
              }}</span>
            </template>
            <template v-else-if="index === 6">
              <span>{{ item.name }}</span>
              <span :class="item.value >= 0 ? 'color_green' : 'color_red'"
                >{{ toFixed(item.value) }}%</span
              >
            </template>
            <template v-else>
              <span>{{ item.name }}</span>
              <span>{{ item.name === '时间' ? item.value : toFixed(item.value) }}</span>
            </template>
          </p>
        </div>
        <!-- 图表 -->
        <div class="chart" ref="chartBoxRef"></div>

        <div class="trade_bar">
          <section @click="this.toggle_history_order_panel()" class="">
            <img :src="holdImg" />
            <p>
              {{ $t("exchange_holdPositions")
              }}<span class="d-none" style="">(0)</span>
            </p>
          </section>
          <section
            @click="
              this.toggle_order_confirm_panel('lookup')
            "
            class=""
          >
            <img :src="buyUpImg" />
            <p>{{ $t("exchange_purchase") }}</p>
          </section>
          <section
            @click="
              toggle_order_confirm_panel('lookdown')
            "
            class=""
          >
            <img :src="buyDownImg" />
            <p>{{ $t("exchange_buyFall") }}</p>
          </section>
        </div>
        <div class="pro_mengban">
          <div class="order-confirm-panel">
            <div class="panel-header">
              <div>
                {{ $t("exchange_order_confirmation") }}
                <div class="close" @click="this.toggle_order_close_panel()">
                  X
                </div>
              </div>
            </div>
            <div class="panel-body">
              <div class="period">
                <p class="end_time">{{ $t("exchange_expiration_date") }}</p>
                <div
                  direction="x"
                  class="scroll-view ionic-scroll scroll-x"
                  ><div
                    class="scroll"
                    style="transform: translate3d(0px, 0px, 0px) scale(1)"
                  >
                    <div class="period-widget-view">

                      <div v-for="(item, k) in this.productInfo.protime" :key="k"
                        class="period-widget"
                        :class="protimeChosen === item ? 'active' : ''"
                        :data-sen="item*60"
                        :data-shouyi="this.productInfo.proscale[k] ? this.productInfo.proscale[k] : this.productInfo.proscale[0]"
                        @click="this.handlePeriodWidget(item, (this.productInfo.proscale[k] ? this.productInfo.proscale[k] : this.productInfo.proscale[0]), item*60)"
                      >
                        <div class="period-widget-header">
                          {{ $t("exchange_settlement_time") }}
                        </div>
                        <div class="period-widget-content">
                          <span class="final_time">{{item*1}}</span>
                          <span class="final_unit">{{ $t("exchange_minute") }}</span>
                        </div>
                        <div class="period-widget-footer period_footer">
                          {{ $t("exchange_profit") }} {{this.productInfo.proscale[k] ? this.productInfo.proscale[k] : this.productInfo.proscale[0]}}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="scroll-bar scroll-bar-h">
                    <div
                      class="scroll-bar-indicator scroll-bar-fade-out"
                      style="
                        transform: translate3d(0px, 0px, 0px) scaleX(1);
                        width: 289px;
                      "
                    ></div></div
                ></div>
              </div>
              <div class="amount">
                <p class="invest_account">
                  {{ $t("exchange_investment_amount") }}
                  <span
                    :class="noMoney ? 'no-money' : 'd-none'"
                    >{{ $t("exchange_insufficient") }}</span
                  >
                  <span class="d-none no-max"
                    >{{
                      $t("exchange_order_max_price", {'amt': this.productInfo.order_max_price})
                    }}</span
                  >
                  <span class="d-none  no-min"
                    >{{
                      $t("exchange_order_min_price", {'amt': this.productInfo.order_min_price})
                    }}</span
                  >
                </p>
                <div
                  direction="x"
                  class="scroll-view ionic-scroll scroll-x"
                  ><div
                    class="scroll"
                    style="transform: translate3d(0px, 0px, 0px) scale(1)"
                  >
                    <div class="amount-view">
                      <div v-for="(item, k) in this.productInfo.pay_choose" :key="k"
                        class="amount-box"
                        :class="amountBox === item ? 'active' : ''"
                        :data-price="item"
                        @click="this.handleAmountBox(item)"
                      >
                        {{item}}
                      </div>
                    </div>
                  </div>
                  <div class="scroll-bar scroll-bar-h">
                    <div
                      class="scroll-bar-indicator scroll-bar-fade-out"
                      style="
                        transform: translate3d(0px, 0px, 0px) scaleX(1);
                        width: 192px;
                      "
                    ></div></div
                ></div>
                <label class="other-amount">
                  <input
                    type="number"
                    :placeholder="$t('exchange_other_amount')"
					v-model.trim="inputAmount.val"
                    class=""
                    @keyup="this.handleOtherAmount()"
                  />
                </label>
              </div>
              <div class="info-view">
                <div class="my-money">
                  {{ $t("exchange_balance") }}: {{currency}}
                  <span class="pay_mymoney">{{this.balance}}</span>
                </div>
                <div class="">
                  {{ $t("exchange_service_charge") }}：<span>{{this.productInfo.web_poundage}}</span>%
                </div>
              </div>
              <div class="order-detail-view">
                <div class="order-detail">
                  <div class="row fields">
                    <div class="col">{{ $t("exchange_name") }}</div>
                    <div class="col">{{ $t("exchange_direction") }}</div>
                    <div class="col">{{ $t("exchange_present_price") }}</div>
                    <div class="col">{{ $t("exchange_money") }}</div>
                  </div>
                  <div class="row">
                    <div class="col qoute_name">{{this.productInfo.ptitle}}</div>
                    <div class="col order_type">{{ $t("exchange_buyFall") }}</div>
                    <div class="col rise col-nowprice">{{toFixed(this.newPrice, 4)}}</div>
                    <div class="col" id="money">{{this.inputAmount.val}}</div>
                  </div>
                  <div class="row btn_confirm">
                    <div class="col">
                      <button class="button" :class="loadingAddOrder ? 'disabled' : ''" @click="this.handleAddOrder()">
                        {{ $t("exchange_confirm_order") }}
                        <img class="loading" :src="this.loadingImg" :class="this.loadingAddOrder === false ? 'd-none' : ''"/>
                      </button>
                    </div>
                  </div>
                  <p class="expect_profit">
                    <span class=""
                      >{{ $t("exchange_prospective_yield") }} : {{currency}}
                      <span id="yuqi">{{this.yuqi}}</span></span
                    >
                    &nbsp;&nbsp;
                    <span class="">{{ $t("exchange_guaranteed_amount") }} : {{currency}} 0.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order_mengban">
            <div class="order-state-panel">
                <div class="panel-header">
                    <div class="">
                        {{this.productInfo.ptitle}}
                        <div class="close" @click="this.close_order()">
                            X
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="paysuccess  d-none">
                        <div class="circle_wrapper">
                            <div class="right_circle">
                                <img class="img_circle_right" style="-webkit-animation: run 60s linear;" :src="rightCircleImg">
                            </div>
                            <div class="left_circle">
                                <img class="img_circle_lift" style="-webkit-animation: runaway 60s linear;" :src="leftCircleImg">
                            </div>
                        </div>
                        <div class="row remaining count_remaining">
                            <div class="col">
                                <div class=" pay_order_sen"></div>
                                <div>{{$t('exchange_present_price')}}</div>
                                <div class="newprice">{{toFixed(this.newPrice, 4)}}</div>
                            </div>
                        </div>
                        <div class="pupil_success d-none">
                            <p>{{$t('exchange_succeeded')}}</p>
                            <p class="">
                                <span>{{$t('exchange_time_remaining')}}：</span>
                                {{$t('exchange_minute')}}Invalid Date
                            </p>
                        </div>
                        <div class="row info_list">
                            <div class="col col-15 first_info">
                                <p>{{$t('exchange_direction')}}</p>
                                <p class=" pay_order_type"></p>
                            </div>
                            <div class="col col-30">
                                <p>{{$t('exchange_money')}}</p>
                                <p class="">{{currency}} <span class="pay_order_price"></span></p>
                            </div>
                            <div class="col col-30">
                                <p>{{$t('exchange_execution_price')}}</p>
                                <p class=" pay_order_buypricee"></p>
                            </div>
                            <div class="col col-25 last_info">
                                <p>{{$t('exchange_forecast_results')}}</p>
                                <p  class=" yuce"> {{currency}} {{this.yuce}} </p>
                            </div>
                        </div>
                    </div>

                    <div class="wait">
                        <div class="row">
                            <div class="col ">
                                <i class="ion-paper-airplane"></i>
                                {{$t('exchange_please_wait')}}……
                            </div>
                        </div>
                    </div>
                    <div class="fail d-none">
                        <div class="row">
                            <div class="col ">
                                <i class="ion-close-circled"></i>
                                {{$t('exchange_submitting_order')}}
                            </div>
                        </div>
                    </div>

                    <div class="fail d-none order_fail">
                        <div class="row">
                            <div class="col ">
                                <i class="ion-close-circled"></i>
                                <span class="fail-info" style="    font-size: 18px;color: #fff;"></span>
                            </div>
                        </div>
                    </div>

                    <div class="ordersuccess d-none" style="">
                        <div class="row remaining finish_remaining">
                            <div class="col">
                                <div class="result_profit  "  style="">{{currency}} 0</div>
                                <div class="expired_statements">{{$t('exchange_due_settlement_completed')}}</div>
                            </div>
                        </div>
                        <div class="row info_list">
                            <div class="col col-15 first_info">
                                <p>{{$t('exchange_direction')}}</p>
                                <p  class=" pay_order_type"></p>
                            </div>
                            <div class="col col-30">
                                <p>{{$t('exchange_money')}}</p>
                                <p class="">$<span class="pay_order_price"></span></p>
                            </div>
                            <div class="col col-30">
                                <p>{{$t('exchange_execution_price')}}</p>
                                <p class=" pay_order_buypricee"></p>
                            </div>

                            <div class="col col-25 last_info">
                                <p>{{$t('exchange_transaction_price')}}</p>
                                <p class=" rise endprice" style=""></p>
                            </div>
                        </div>
                    </div>

                    <div class="row button_row">
                        <div class="col">
                            <button class="button" @click="this.continue_order()">{{$t('exchange_continue_order')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-panel" ng-include="1">
            <div class="panel-header" >
                {{$t('exchange_position_details')}}
                <div class="close" @click="this.toggle_history_order_panel()">
                    X
                </div>
            </div>
            <div class="trade_history_list">
                <div style="height: 100%" class="scroll-view ionic-scroll scroll-y"><div class="scroll" style="transform: translate3d(0px, 0px, 0px) scale(1);">
                    <ul>
                    </ul>
                </div><div class="scroll-bar scroll-bar-v"><div class="scroll-bar-indicator scroll-bar-fade-out" style="transform: translate3d(0px, 0px, 0px) scaleY(1); height: 0px;"></div></div></div>
            </div>
        </div>
      </div>
    </div>
  </base-layout3>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import { navList, huobiWsUrl2 as huobiWsUrl, colors } from "./config.js";
import { toFixed, getFormatDate, formatSeconds2, getLocalTime, getFloatLength, randomIntFromInterval } from "../../../utils/chart.js";
import { getLocal} from '@/utils/local.js';
import utils from "../../../utils/chart";
import bgImg from "../../../assets/images/3/bg2.png";
import holdImg from "../../../assets/images/3/hold.png";
import buyUpImg from "../../../assets/images/3/buy_up.png";
import buyDownImg from "../../../assets/images/3/buy_down.png";
import loadingImg from "../../../assets/images/3/loading.gif";
import rightCircleImg from "../../../assets/images/3/right_circle1.png";
import leftCircleImg from "../../../assets/images/3/left_circle1.png";
import * as echarts from "echarts";
import "@/assets/css/exchange.less";
import "@/assets/css/exchange.css";
import "@/assets/css/trade-history.css";
import { mapState } from "vuex";
import pako from 'pako'

export default {
  name: "exchange",
  components: {},
  data() {
    return {
      product: "NGS",
      currencyUrl: "",
      navList: navList,
      toFixed: toFixed,
      bgImg: bgImg,
      holdImg: holdImg,
      buyUpImg: buyUpImg,
      buyDownImg: buyDownImg,
      loadingImg: loadingImg,
      rightCircleImg: rightCircleImg,
      leftCircleImg: leftCircleImg,
      state: {},
      user: [],
	  balance: 0,
      productInfo: [],
      yuqi: 0,
      orderShouyi: 0,
      amountBox: 0,
      inputAmount: { val: "" },
      payChooseFirst: 0,
      noMoney: false,
      protimeChosen: '',
      loadingAddOrder: false,
      orderSen: 0,
      orderType: '',
      newPrice: 0,
      selltime: 0,
      sell_time_: 0,
      orderData: [],
      yuce: 0,
      timer: null,
      timerOrderlist: null,
      resorderlist: [],
      ftime_: 0,
      currency: getLocal('currency') || 'RM',
    };
  },
  created() {
    let params = this.$route.query.product;
    this.product = (params || "BTCUSDT").toLowerCase();
    this.currencyUrl = "/static/kline.html?product=" + this.product;
    this.chartBoxRef = ref(null);
    this.state = {
      usdtPrice: 8, // https://api.huobiasia.vip/market/tickers 可以调用这个借口获取，这里默认为8
      currentNav: 0,
      currencyInfo: { name: this.product, enName: this.product },
      showFenshiBox: false,
      dataPos: false,
      toolList1: [],
      postTop: [],
      postMiddle: [],
      chengJiao: [],
      showTool: false,
      chartData: [],
      titleCal: {
        cny: 0,
        end_price: 0,
        max_price: 0,
        min_price: 0,
        num: 0,
        per: 0,
        start_price: 0,
        ud_price: 0,
      },
      interval: null,
      flag: false,
      // K 线相关
      haurl: huobiWsUrl,
      requestK: { req: "market." + this.product + ".kline.1min" },
      subK: { sub: "market." + this.product + ".kline.1min" },
      socketK: {},
      chartOtherData: [],
      Zstart: 100,
      Zend: 90,
    };
  },
  mounted() {
    this.state.requestK.req = `market.${this.state.currencyInfo.name}.kline.1min`;
    this.state.subK.sub = `market.${this.state.currencyInfo.name}.kline.1min`;
    // console.log(this.$refs.chartBoxRef, 'this.$refs')
    this.chartBoxRef = this.$refs.chartBoxRef;
    // 异步展示k线图
    setTimeout(() => {
      // 初始化websoket
      // K线图websoket连接
      this.handleInitWebsoket();
      this.handleNavClick(0, "1min");
    }, 1000);
  },
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timerOrderlist);
    // 离开页面关闭连接
    if (this.state.socketK.close) this.state.socketK.close();
    window.clearInterval(this.state.interval);
    this.state.currencyInfo = ""; //this.$route.query
    // 更改k线图请求接口
    this.state.requestK.req = `market.${this.state.currencyInfo.name}.kline.1min`;
    this.state.subK.sub = `market.${this.state.currencyInfo.name}.kline.1min`;
    // 异步展示k线图
    setTimeout(() => {
      // 初始化websoket
      // K线图websoket连接
      this.handleInitWebsoket();
      this.handleNavClick(0, "1min");
    }, 1000);
  },
  beforeMount() {
    let token = getLocal('token') || '';
    if(token)
        this.get_user_init();

    this.get_product_init();
  },
  computed: {
    ...mapState({
      setCoin: (state) => this.state.setCoin,
    }),
  },
  watch: {},
  methods: {
    get_user_init() {
        this.balance= 0 ;
        this.$http("", "/home/account/basic", [],'post')
        .then((result) => {
            // console.log(result)
            if(result.code == "0001") {
                this.user = result.data;
				this.balance = this.user.credit2 || null;
            }
        })
        .catch((e) => {
            console.log(e, 'get_user_init');
        });
    },
    get_product_init() {
        const obj = {
            "procode": this.product.toLowerCase()
        }
        this.$http("", "/home/api/product_details", obj,'post')
        .then((result) => {
            // console.log(result)
            if(result.code == "0001") {
                this.productInfo = result.data;
                this.orderShouyi = this.productInfo.proscale[0];
                this.amountBox = this.finalAmount = this.payChooseFirst = this.inputAmount.val = this.productInfo.pay_choose[0];
                this.noMoney = this.balance < this.payChooseFirst;``
                this.protimeChosen = this.productInfo.protime[0];
                this.newPrice = this.productInfo.Price;
                this.orderSen = this.protimeChosen*60;
                this.yuqi = (this.orderShouyi*0.01  *this.inputAmount.val) .toFixed(2);
            }
        })
        .catch((e) => {
            console.log(e, 'get_product_init');
        });
    },
    handleAmountBox(amount) {
        this.inputAmount.val = amount;
        const orderPrice = amount;
        this.amountBox = amount;

        this.noMoney = this.balance < orderPrice
         this.yuqi = (this.orderShouyi*0.01  *orderPrice) .toFixed(2);
    },
    handleOtherAmount() {
        this.noMoney = parseFloat(this.balance) < parseFloat(this.inputAmount.val)
        const orderPrice = this.inputAmount.val

        this.yuqi = (this.orderShouyi*0.01  *orderPrice) .toFixed(2);
    },
    handlePeriodWidget(item, proscale, sen) {
        this.protimeChosen = item;
        this.orderSen = sen;
        this.orderShouyi = proscale;
        const orderPrice = this.inputAmount.val;
         this.yuqi = (this.orderShouyi*0.01  *orderPrice) .toFixed(2);
    },
    handleAddOrder() {
        if (this.loadingAddOrder) return;

        this.loadingAddOrder = true;

        const orderPrice = this.inputAmount.val*1;
        const orderMinPrice = this.productInfo.order_min_price*1;
        const orderMaxPrice = this.productInfo.order_max_price*1;

        if(orderPrice > this.balance){
            this.$toast.error('资金不足，请先充值');
            this.loadingAddOrder = false;
            return;
        }
        if(orderPrice < orderMinPrice){
            this.$toast.error('最小下注金额为'+orderMinPrice);
            this.loadingAddOrder = false;
            return;
        }
        if(orderPrice > orderMaxPrice){
            this.$toast.error('最大下注金额为'+orderMaxPrice);
            this.loadingAddOrder = false;
            return;
        }
        console.log(this.orderShouyi)
        const obj = {
            order_sen:this.orderSen,
            order_pid:this.productInfo.id,
            order_type:this.orderType,
            newprice:this.newPrice,
            order_price:this.inputAmount.val,
        }

        this.$http("", "/home/api/addorder", obj,'post')
        .then((result) => {
            // console.log(result)
            if(result.code == '0001'){

                this.toggle_order_close_panel();
                document.querySelector('.order_mengban').classList.add('glass_mask');
                document.querySelector('.paysuccess').classList.add('d-none');

                document.querySelector('.order-state-panel').classList.add('open');
                document.querySelector('.order-state-panel').classList.remove('d-none');
                document.querySelector('.order-state-panel .wait').classList.remove('d-none');

                //倒计时
                document.querySelector('.pay_order_sen').innerHTML = (formatSeconds2(result.data.endprofit));
                document.querySelector('.img_circle_right').setAttribute('style','-webkit-animation: run '+result.data.endprofit+'s linear;')
                document.querySelector('.img_circle_lift').setAttribute('style','-webkit-animation: runaway '+result.data.endprofit+'s linear;')

                //下方提示
                if(result.data.ostyle == 0){
                    document.querySelector('.pay_order_type').innerHTML = this.$t('exchange_buyUp');
                    document.querySelector('.pay_order_type').classList.add('rise');
                    document.querySelector('.pay_order_type').classList.remove('fall');
                }else{
                    document.querySelector('.pay_order_type').innerHTML = this.$t('exchange_buyFall');
                    document.querySelector('.pay_order_type').classList.add('fall');
                    document.querySelector('.pay_order_type').classList.remove('rise');
                }

                // //document.querySelector('.order-state-panel').hide();
                document.querySelector('.order-state-panel .wait').classList.add('d-none');

                document.querySelector('.pay_order_price').innerHTML = (result.data.fee);
                document.querySelector('.pay_order_buypricee').innerHTML = (result.data.buyprice);

                document.querySelector('.order-state-panel .wait').classList.add('d-none');
                document.querySelector('.order-state-panel .paysuccess').classList.remove('d-none');
                document.querySelector('.order-state-panel .paysuccess').classList.add('success');
                //余额
                document.querySelector('.pay_mymoney').innerHTML = (result.data.commission);
                //转盘倒计时


                this.selltime = result.data.selltime;
                this.orderData = result.data;
                document.querySelector('.paysuccess').classList.remove('d-none');
                this.sell_time_ = this.orderData.selltime - this.orderData.buytime;
                var that = this;
                this.timer = setInterval(function(){that.endtimes()}, 1000);

                this.$toast.success(this.$t(result.message));
            }else{
                if (result.code == '2001') {
                    this.$toast.error(this.$t('exchange_order_max_price_fee', {amt: result.message}));
                } else if (result.code == '2002') {
                    this.$toast.error(this.$t('exchange_order_min_price', {amt: result.message}));
                } else if (result.code == '2003') {
                    this.$toast.error(this.$t('exchange_order_max_price', {amt: result.message}));
                } else {
                    this.$toast.error(this.$t(result.message));
                }
            }
            this.loadingAddOrder = false;
        })
        .catch((e) => {
            console.log(e, 'handleAddOrder');
            this.loadingAddOrder = false;
        });
    },
    handleNavClick(index, opt) {
      const { name } = this.state.currencyInfo;
    //   console.log(name, "namenamenamenamenamename");
      if (index !== 0) {
        this.state.Zstart = 90;
        this.state.Zend = 80;
      }
      this.state.chartData = [];
      this.state.chartOtherData = [];
      if (this.state.socketK.close) {
        this.state.socketK.close();
      }
      clearTimeout(this.state.interval);
      this.state.currentNav = index;
      this.state.requestK.req = `market.${name}.kline.${opt}`;
      this.state.subK.sub = `market.${name}.kline.${opt}`;
      this.handleInitWebsoket();
      setTimeout(() => {
        this.draw()
      }, 1000);
    },

    handleInitWebsoket() {
      let self = this.state;
      var _this = this;
      self.socketK = new WebSocket(self.haurl);
      self.socketK.onopen = function () {
        self.socketK.send(JSON.stringify(self.subK));
        self.socketK.send(JSON.stringify(self.requestK));
      };
      self.socketK.onmessage = function (event) {
        // console.log(event, 'event')
        if (typeof event.data !== "undefined") {
          let blob = event.data

          //local ws
          // blob = JSON.parse(blob);
          // _this.handleData(blob.data)

          // return
          // console.log(_this.state.requestK.req, blob.action);
          // if (_this.state.requestK.req != blob.action) return;

          // console.log(_this.state.requestK.req)
          // console.log(blob.data)
          // console.log(event, 'event')
          let reader = new FileReader()
          reader.onload = function(e) {
            let ploydata = new Uint8Array(e.target.result)
            let msg = pako.inflate(ploydata, { to: 'string' })
            _this.handleData(JSON.parse(msg))
          }
          if (typeof blob === 'object')
          reader.readAsArrayBuffer(blob, 'utf-8')
        }
      };
    },

    handleData(msg) {
      // console.log(msg, "msg");
      // let data = JSON.parse(msg)
      let data = msg;
      // if (window.location.href.indexOf('local') > -1)
        // console.log(data, 'data')

      if (typeof data === "undefined") return;
      if (typeof data.ping !== "undefined" && data.ping) {
        this.state.socketK.send(JSON.stringify({ pong: data.ping }));
      } else if (data.status == "ok" && data.data) {
        console.log(data.data, 'datadata')
        if (this.state.chartOtherData.length < 10) {
          this.state.chartOtherData = data.data;
          let arr = [];
          const info = this.state.chartOtherData;
          for (let i = 0; i < info.length - 1; i++) {
            if (i === info.length - 1) {
              let el = info[i];
              el.close = this.fengkong(el.close);
              this.state.titleCal = {
                cny: 8 * el.close,
                end_price: el.close,
                max_price: el.high,
                min_price: el.low,
                num: el.count,
                per: (el.close - el.open) / el.open,
                start_price: el.open,
                ud_price: 8,
              };
            }
            let el = info[i];
            // console.log(el, 'el')
            el.time = getFormatDate(el.id);
            // console.log(el.time, 'el.time')
            let arr2 = [el.time, el.open, el.close, el.low, el.high, el.count];
            arr.push(arr2);
          }
          this.state.chartData = arr;
        }
      } else if (data.tick) {
        // console.log("here")
        const el = data.tick;

        el.close = this.fengkong(el.close);
        this.state.titleCal = {
          cny: 8,
          end_price: el.close,
          max_price: el.high,
          min_price: el.low,
          num: el.count,
          per: (el.close - el.open) / el.open,
          start_price: el.open,
          ud_price: 8,
        };
        el.time = getFormatDate(el.id);
        let arr2 = [el.time, el.open, el.close, el.low, el.high, el.vol];
        let lastDate =
          this.state.chartData[this.state.chartData.length - 1] &&
          this.state.chartData[this.state.chartData.length - 1][0];
        let flag = arr2[0] && arr2[0] == lastDate;
        if (flag) {
          this.state.chartData.splice(this.state.chartData.length - 1, 1, arr2);
        }
        if (!flag) {
          this.state.chartOtherData.push(data.tick);
          this.state.chartData.push(arr2);
          this.state.chartData.shift();
        }
        this.newPrice = el.close;
      }

      setTimeout(() => {
        this.draw();
      }, 1000);
    },
    fengkong(price) {
      let point_low = parseFloat(this.productInfo.point_low);
      let point_top = parseFloat(this.productInfo.point_top);

      const FloatLength = getFloatLength(point_top);
      const jishu_rand = Math.pow(10, FloatLength);
      point_low = point_low * jishu_rand;
      point_top = point_top * jishu_rand;
      const rand = randomIntFromInterval(point_low, point_top) / jishu_rand;

      const _new_rand = randomIntFromInterval(0, 10);
      if (_new_rand % 2 == 0) {
        price = price + rand;
      } else {
        price = price - rand;
      }
      return price;
    },
    // 发送pong
    sendHeartMessage(ping) {
      this.state.socketK.send(JSON.stringify({ pong: ping }));
    },

    // 点击k线图
    handleChartClick(e) {
      this.state.showTool = true;
      const chartBox = this.chartBoxRef;
      const pos = chartBox.offsetWidth / e.clientX;
      if (pos > 2) {
        this.state.dataPos = true;
      } else {
        this.state.dataPos = false;
      }
    },

    draw() {
      const self = this.state;
      let myChart = echarts.init(this.chartBoxRef);
      // 绿色、红色、紫色、浅蓝、深蓝、黄色
      let data = utils.splitData(this.state.chartData);
      let Zstart = self.Zstart;
      let Zend = self.Zend;
      const option = {
        tooltip: {
          show: true,
          trigger: "axis",
          triggerOn: "mousemove|click",
          confine: true,
          axisPointer: {
            type: "line",
            crossStyle: {
              color: "#6b809b",
              width: 1,
            },
            lineStyle: {
              width: 6,
              color: "rgba(201,219,246,0.3)",
            },
          },
          formatter: function (params) {
            if (params.length > 0) {
              // 格式化数据
              const { toolList1, postTop, chengJiao } = utils.postSelect(
                params,
                colors
              );
              self.toolList1 = toolList1;
              self.postTop = postTop;
              self.chengJiao = chengJiao;
            }
          },
        },
        xAxis: [
          {
            show: false,
            scale: true,
            nameGap: 15,
            gridIndex: 0,
            splitNumber: 0,
            axisLine: {
              lineStyle: {
                color: "#4a657a",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: colors[4],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: data.times,
            axisPointer: {
              label: {
                show: false,
              },
            }, //主图禁用下标显示
          },
          {
            show: false,
            scale: false,
            nameGap: 15,
            gridIndex: 1,
            splitNumber: 5,
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: data.times,
            axisPointer: {
              label: {
                show: false,
              },
            }, //附图1禁用下标显示
          },
          {
            show: true,
            scale: true,
            gridIndex: 2,
            axisLine: {
              lineStyle: {
                color: "transparent",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#4a657a",
              },
            },
            data: data.times,
          },
          {
            gridIndex: 3,
            show: false,
            type: "value",
          },
        ],
        yAxis: [
          {
            position: "right",
            show: true,
            scale: true,
            gridIndex: 0,
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            axisTick: {
              show: false,
            },
            minorTick: {
              show: false,
            },
            nameTextStyle: {
              width: "200px",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: colors[4],
              },
            },
            //网格样式
            splitLine: {
              show: false,
              lineStyle: {
                color: colors[4],
                width: 1,
                type: "solid",
              },
            },
          },
          {
            position: "right",
            gridIndex: 1,
            show: false,
            splitNumber: 2,
            axisLine: {
              lineStyle: {
                color: "#4a657a",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#4a657a",
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            position: "right",
            gridIndex: 2,
            show: false,
            splitNumber: 3,
            offset: 10,
            max: function (value) {
              return value.max + 0.0096;
            },
            axisLine: {
              lineStyle: {
                color: "#4a657a",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#4a657a",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "4a657a",
                type: "dashed",
              },
            },
          },
        ],
        dataZoom: [
          {
            show: false,
            type: "inside",
            start: Zstart,
            end: Zend,
            xAxisIndex: [0, 0],
          },
          {
            show: false,
            type: "slider",
            start: Zstart,
            end: Zend,
            xAxisIndex: [0, 1],
          },
          {
            show: false,
          },
        ],
        axisPointer: {
          show: true,
          type: "line",
          label: {
            show: true,
          },
          link: [
            {
              xAxisIndex: [0, 1],
            },
          ],
        },
        series: [
          {
            type: "candlestick",
            name: "K",
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: data.datas,
            markLine: {
              symbol: "",
              data: [
                {
                  yAxis:
                    data.datas && data.datas.length
                      ? data.datas[data.datas.length - 1][1]
                      : [],
                  label: {
                    position: "end",
                    padding: 0,
                  },
                  lineStyle: {
                    type: "dashed",
                    color: "#347bef",
                  },
                },
              ],
            },
            itemStyle: {
              color: colors[0],
              color0: colors[1],
              borderColor: colors[0],
              borderColor0: colors[1],
            },
          },
          {
            type: "line",
            name: "MA5",
            data: utils.calculateMA(data, 5),
            smooth: 0.5,
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: colors[5],
              width: 1.5,
            },
            itemStyle: {
              color: colors[5],
              borderWidth: 12,
              borderColor: "rgba(255,255,255,0.3)",
            },
          },
          {
            type: "line",
            smooth: 0.9,
            symbol: "circle",
            name: "MA10",
            data: utils.calculateMA(data, 10),
            showSymbol: false,
            symbolSize: 2,
            lineStyle: {
              color: colors[0],
              width: 1.5,
            },
            itemStyle: {
              color: colors[0],
              borderWidth: 12,
              borderColor: "rgba(255,255,255,0.3)",
            },
          },
          {
            type: "line",
            name: "MA15",
            data: utils.calculateMA(data, 15),
            smooth: 0.9,
            symbol: "circle",
            showSymbol: false,
            symbolSize: 2,
            lineStyle: {
              color: colors[2],
              width: 1.5,
            },
            tooltip: {
              backgroundColor: "transparent",
            },
            itemStyle: {
              color: colors[2],
              borderWidth: 12,
              borderColor: "rgba(255,255,255,0.3)",
            },
          },
          {
            type: "line",
            name: "MA10",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: utils.calculateMA(data, 10),
            symbol: "none",
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
          {
            type: "line",
            name: "MA5",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: utils.calculateMA(data, 5),
            symbol: "none",
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
          {
            type: "bar",
            name: "成交量",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data.vols,
            barCategoryGap: "20%",
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList;
                  if (
                    data.datas[params.dataIndex][1] >=
                    data.datas[params.dataIndex][0]
                  ) {
                    colorList = colors[0];
                  } else {
                    colorList = colors[1];
                  }
                  return colorList;
                },
              },
            },
          },
        ],
        grid: [
          {
            top: "8%",
            left: 5,
            right: "12%",
            bottom: "25%",
          },
          {
            left: 5,
            right: "12%",
            top: "75%",
            bottom: "6%",
          },
          {
            left: 5,
            top: "80%",
            right: "12%",
            bottom: "5%",
          },
          {
            left: "88",
            right: "0",
          },
        ],
      };
      myChart.setOption(option);
      // 定时
      setTimeoutFn();
      // 刷新数据
      refreshData();
      function setTimeoutFn() {
        self.interval = setTimeout(() => {
          clearTimeout(self.interval);
          refreshData();
          setTimeoutFn();
        }, 3000);
      }
      function refreshData() {
        data = utils.splitData(self.chartData);
        data.datas.shift();
        data.times.shift();
        data.vols.shift();
        myChart.setOption({
          xAxis: [
            { data: data.times },
            { data: data.times },
            { data: data.times },
          ],
          series: [{ data: data.datas }],
        });
      }
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    toggle_order_confirm_panel(type) {
      var order_type, typename;

      const up = this.$t('exchange_buyUp');
      const down = this.$t('exchange_buyFall');

      if (type == "lookup") {
        typename = up;
        order_type = 0;

        document.querySelector(".order_type").classList.remove("fall");
        document.querySelector(".order_type").classList.add("rise");
      } else {
        typename = down;
        order_type = 1;

        document.querySelector(".order_type").classList.add("fall");
        document.querySelector(".order_type").classList.remove("rise");
      }

    this.orderType = order_type;
      document.querySelector(".order_type").innerHTML = typename;
      document.querySelector(".pro_mengban").classList.add("glass_mask");
      document.querySelector(".order-confirm-panel").classList.add("open");
    },
    toggle_order_close_panel(){
        document.querySelector('.pro_mengban').classList.remove('glass_mask');
        document.querySelector('.order-confirm-panel').classList.remove('open');
        this.loadingAddOrder = false;
    },
    //转盘倒计时
    endtimes() {
        var timestamp = Date.parse(new Date());
        timestamp = timestamp / 1000;
        this.sell_time_--;
        var newsen = this.sell_time_;
        // console.log(newsen, 'newsen')
        if(newsen<=360){
            document.querySelector('.pay_order_sen').innerHTML = (newsen);
        }else{
            document.querySelector('.pay_order_sen').innerHTML = (formatSeconds2(newsen));
        }

        // var old_price = document.querySelector('.data-price').innerHTML;
        var yuce_case = 0;
        if(this.orderData.buyprice*10 < this.newPrice*10){
            document.querySelector('.data-price').classList.remove('fall');
            document.querySelector('.data-price').classList.add('rise');

            if(this.orderData.ostyle == 0){     //买涨
                yuce_case = '+'+(this.orderData.fee*1 + (this.orderData.fee*this.orderData.endloss/100));
                document.querySelector('.yuce').classList.remove('fall');
                document.querySelector('.yuce').classList.add('rise');
            }else{
                yuce_case = this.orderData.fee*-1;
                document.querySelector('.yuce').classList.remove('rise');
                document.querySelector('.yuce').classList.add('fall');
            }

        }else if(this.orderData.buyprice*10 > this.newPrice*10){
            document.querySelector('.data-price').classList.add('fall');
            document.querySelector('.data-price').classList.remove('rise');

            if(this.orderData.ostyle == 0){     //买涨
                yuce_case = this.orderData.fee*-1;
                document.querySelector('.yuce').classList.remove('rise');
                document.querySelector('.yuce').classList.add('fall');
            }else{
                yuce_case = '+'+(this.orderData.fee*1 + (this.orderData.fee*this.orderData.endloss/100));
                document.querySelector('.yuce').classList.remove('fall');
                document.querySelector('.yuce').classList.add('rise');
            }
        }else{
            yuce_case = this.orderData.fee;
        }
        this.yuce = yuce_case;

        if(newsen <= 0){
            document.querySelector('.paysuccess').classList.add('d-none');
            document.querySelector('.paysuccess').classList.remove('success');
            document.querySelector('.order-state-panel .wait').classList.remove('d-none');
            //请求检测订单
            //get_this_order();
            clearInterval(this.timer)
            //go order
            // goorder();
        }
    },
    close_order() {
        clearInterval(this.timer)
        document.querySelector('.order_mengban').classList.remove('glass_mask');
        document.querySelector('.order-state-panel').classList.add('d-none');
        document.querySelector('.ordersuccess').classList.remove('success');
        document.querySelector('.ordersuccess').classList.add('d-none');
        document.querySelector('.order_fail').classList.add('d-none');
    },
    continue_order() {
        this.close_order();
        var _type = 'lookdown';
        if(this.orderType == 0){
            _type = 'lookup';
        }
        this.toggle_order_confirm_panel(_type);
    },
    toggle_history_order_panel() {
        var type = document.querySelector('.history-panel').getAttribute('ng-include');
        if(type == 1){

            //ajax order
            var obj = {
                pid: this.productInfo.id,
                ostaus: 0
            }
            this.$http("", "/home/api/orderlist2/", obj,'post')
            .then((result) => {
              if(result.code == "0001") {
                this.resorderlist = result.data.data;
                  // console.log(result.time)

                    if(this.resorderlist.length >= 1){
                        // this.ftime_ = this.resorderlist[0]['time'];
                        console.log(result.time)
                        this.ftime_ = result.time;
                    }else{
                        var timestamp = Date.parse(new Date());
                        this.ftime_ = timestamp/1000;
                    }

                    var that = this;
                    this.timerOrderlist = setInterval(function(){that.show_order_list();}, 1000);
                    document.querySelector('.history-panel').style.top = '15%';
                    // document.querySelector('.history-panel').style.bottom = '10%';
                    document.querySelector('.history-panel').setAttribute('ng-include',0);
                }
            })
            .catch((e) => {
                console.log(e, 'toggle_history_order_panel');
            });

        }else{
            document.querySelector('.history-panel').style.top = '500%';
            // document.querySelector('.history-panel').style.bottom = '100%';
            document.querySelector('.history-panel').setAttribute('ng-include',1);
            clearInterval(this.timer_orderlist)
        }
    },
    show_order_list() {
        var  html = '';

        if(this.resorderlist.length == 0){
            document.querySelector('.trade_history_list ul').innerHTML = '';
            return false;
        }

        this.ftime_++;
        var k;
        var len = this.resorderlist.length;
        for (k = 0; k < len; k ++) {
            var v = this.resorderlist[k];
            if (typeof v === 'undefined') return;
            // console.log(v, 'v')
            // console.log(ftime_);
            var timestamp = Date.parse(new Date());
            var  _end_time = (v.selltime - this.ftime_);
            var baifenbi = (_end_time/v.endprofit)*100;
            // console.log(this.ftime_, _end_time);
            if(_end_time >0){

                var chaprice = this.newPrice-v.buyprice;
                var closeprice = 0;
                var closeprice_class = '';
                var ostyle_class, ostyle_class2, ostyle_name;
                if(v.ostyle == 0){
                    ostyle_class = "buytop";
                    ostyle_class2 = 'in_money';
                    ostyle_name = this.$t("exchange_buyUp");
                    if(chaprice >0){
                        closeprice = v.fee*(100*10+v.endloss*10)/1000;
                        closeprice_class = 'in_money';
                    }else if(chaprice <0){
                        closeprice = v.fee*(-1);
                        closeprice_class = 'out_money';
                    }else{
                        closeprice = v.fee;
                        closeprice_class = '';
                    }
                }else{
                    ostyle_class = "buydown";
                    ostyle_name = this.$t("exchange_buyFall");
                    ostyle_class2 = 'out_money';

                    if(chaprice <0){
                        closeprice = v.fee*(100*10+v.endloss*10)/1000;
                        closeprice_class = 'in_money';
                    }else if(chaprice >0){
                        closeprice = v.fee*(-1);
                        closeprice_class = 'out_money';
                    }else{
                        closeprice = v.fee;
                        closeprice_class = '';
                    }
                }

                html += '<li ng-repeat="o in trade_order_list" class="">\
                            <section>\
                                <p style="margin: 0">\
                                    <span class="ng-binding">'+v.ptitle+'</span>\
                                    <span class="ng-binding '+ostyle_class2+'"><i class="'+ostyle_class+'"></i>'+ostyle_name+' </span>\
                                </p>\
                                <p style="margin: 0" class="ng-binding">\
                                    '+v.buyprice+'-<span  class="ng-binding '+closeprice_class+'">'+this.newPrice+'</span>\
                                </p>\
                                <p style="margin: 0" class="ng-binding">'+getLocalTime(v.buytime)+'</p>\
                            </section><section>\
                                <p style="margin: 0px;" class="ng-binding '+closeprice_class+'">'+closeprice+'</p>\
                                <p style="margin: 0" class="ng-binding">'+formatSeconds2(_end_time)+'</p>\
                            </section>\
                            <article class="">\
                            <span class="move_width" style="width: '+baifenbi+'%; transition-duration: 1s;">\
                            </span>\
                            <i>\
                                <em></em>\
                            </i>\
                            </article>\
                        </li>';

                document.querySelector('.trade_history_list ul').innerHTML = (html);

            }else{

                this.resorderlist.splice(k,1);
            }
        }
    }
  },
};
</script>
<style lang="css" scoped>
.chart-box .chart {
  /* height: unset; */
}

</style>
