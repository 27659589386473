<template>
	<div>
		<div v-if="dialogIsVisible" class="backdrop" @click="$emit('hideDialog')"></div>
		<transition name="modal">
			<dialog open v-if="dialogIsVisible" :class="modal_mode">
				<div class="modal-header bg-modal">
					<img :src="imageSrc" >
					<button type="button" class="btn-close text-light" @click="hideDialog">X</button>
				</div>
				<div class="modal-body text-center" style="padding: 10px; overflow-y: auto;">
						<h4 class="mb-3">{{ann_title}}</h4>
						<span v-html="ann_detail" class="modal1"></span>
				</div>
				<div class="modal-footer p-0">
					<button type="button" class="btn btn-default text-dark"   @click="hideDialog">{{$t('common_close') }}</button>
					<button type="button" class="btn btn-info "  @click="read()" >{{$t('common_confirm') }}</button>
				</div>
			</dialog>
		</transition>
	</div>
</template>
<style>
.bg-modal {
	/* background: url(~@/assets/images/promo-banner.png) center center;min-height:120px;
	background: url(~@/assets/images/promo-banner3.png) center center;min-height:120px;
	background-size: cover;
	padding: 0 !important; */
	position: relative;
	padding: 0!important;;
    overflow: hidden;
}
.bg-modal img{
	width: 100%;
	height: 120px;
	object-fit: cover;
	object-position: right;
}
.btn-close{
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 1
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}
dialog {
  position: fixed;
  top: 1vh;
  width: 98%;
  height: 98%;
  margin: auto auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 12px;
  /* padding: 1rem; */
  /* background: linear-gradient(45deg, #ffffff, #aecdf9); */
  z-index: 100;
  border: none;
  display: flex;
  flex-direction: column;

  /* box-shadow: inset 5px 5px 10px #93c2ff, inset -5px -5px 10px #93c2ff; */
  /* animation: modal 0.3s ease-out forwards; */
}
dialog.full_modal {
  /* position: fixed; */
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  z-index: 1031;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}
.modal1 img{
    width: 100%!important;
}
.modal_body {
  position: relative;
  oveflow-y:auto;

}
.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
	opacity: 0;
	transform: translateY(-50px) scale(0.9);
  }

  to {
	opacity: 1;
	transform: translateY(0) scale(1);
  }
}

@media(min-width:1025px){
  dialog{
	width: 27%;
  }
}
</style>
<script>
import {_} from 'vue-underscore';
import { getLocal} from '@/utils/local.js';
import moment from 'moment-timezone';
// import BaseModal from "@/components/UI/BaseModal.vue";
export default {
	data() {
		return {
			ann_title : null,
			ann_detail : null,
			ann_id : null,
			dialogIsVisible: false,
      		promo_image: process.env.VUE_APP_PROMO_IMG,
		};
	},
	methods:{
		hideDialog() {
			this.dialogIsVisible = false;
		},
		read() {
			var count = getLocal("isreadNoticeCount"+this.ann_id) || 0;

			this.dialogIsVisible = false;
			var storage = window.localStorage;
			storage.setItem("isreadNotice"+this.ann_id, this.ann_id);
			storage.setItem("isreadNoticeCount"+this.ann_id, (count*1)+1);

			var date = moment(new Date()).format("YYYY-MM-DD HH:mm:s");
			storage.setItem("isreadNoticeCountTime"+this.ann_id, moment(new Date()).format("YYYY-MM-DD"))
			storage.setItem("isreadNoticetime"+this.ann_id, date)
		},
		getNotice() {
			var that=this;
			var config = getLocal('config');
			var popout_times   = config.popout_times || 1;
			var popout_minutes = config.popout_minutes*1 || 60;
			// console.log(popout_times)
			// console.log(popout_minutes)

			this.$http("", "/home/index/notice", [],'post')
			.then((result) => {
				console.log('Promo Banner:')
				console.log(result);
				if(result.code == "0001") {
					var d = _.sortBy(result.data, function(i){
						return -i.order_by;
					});

					var f = _.find(d, function(item){
						return item.language == that.$i18n.locale;
					});

					// console.log(_.size(f))
					if(_.size(f)!=0){
						let isreadNotice  = getLocal('isreadNotice'+f.id) || null;
						let isreadNoticetime  = getLocal('isreadNoticetime'+f.id) || null;
						let isreadNoticeCount  = getLocal('isreadNoticeCount'+f.id) || null;
						let isreadNoticeCountTime  = getLocal('isreadNoticeCountTime'+f.id) || null;
						var storage = window.localStorage;

						if(isreadNotice!=null && isreadNoticetime!=null){

							var time = moment().format("YYYY-MM-DD HH:mm:s")
							var time2 = moment(isreadNoticetime).add(popout_minutes, 'minutes').format("YYYY-MM-DD HH:mm:s");
							var compare = moment(time).isAfter(time2);
							// console.log(time,time2)
							// console.log(compare)
							if(compare){
								// console.log("sad")
								storage.removeItem("isreadNotice"+f.id);
								storage.removeItem("isreadNoticetime"+f.id);
								this.dialogIsVisible=true;
							}

						}

						if(isreadNotice==null){
							// console.log(1)
							this.dialogIsVisible=true;
						}

						if(isreadNoticeCountTime!=null){
							var date = moment().format("YYYY-MM-DD");
							if(isreadNoticeCountTime !=  date){
								console.log("here")
								storage.removeItem("isreadNoticeCountTime"+f.id);
							}
						}

						// console.log(isreadNoticeCount,popout_times)
						if(isreadNoticeCount >= popout_times){
							this.dialogIsVisible = false;
						}


						this.ann_id = f.id || '';
						this.ann_detail = f.detail || '';
						this.ann_title  = f.title || '';
					}
					// console.log(this.ann_title)
				}
			})
			.catch((e) => {
				console.log(e);
				this.lock = 0;
				//this.$toast.error(e.message);
			});
		},
	},
	components: {
	// BaseModal,
	},
	beforeMount() {
		this.getNotice();
	},
	computed: {
		imageSrc() {
			//var promo_image = this.promo_image;
			/*
			if (this.promo_image == 'C') {
				return require('@/assets/images/promo-banner3.png');
			} else if (this.promo_image == 'B') {
				return require('@/assets/images/promo-banner2.png');
			} else {
				return require(`@/assets/images/${promo_image}`);
			}*/
			return require(`@/assets/images/${this.promo_image}`);
		}
	}
};
</script>