module.exports = {
    "base_control_member_not_found": "User does not exist",
    "login_phone": "Please enter your phone number",
    "login_username": "Please enter your username",
    "login_pass": "Please enter the login password",
    "login_code": "Please enter the verification code",
    "login_phone_err": "Please enter the correct phone number",
    "login_username_err": "Please enter the correct username",
    "login_pass_err": "Please enter the correct password",
    "login_captcha_err": "Captcha error",
    "login_no_acc": "No account? Immediately",
    "login_repass_err": "The password is inconsistent",
    "login_parentid_err": "Invitation code error",
    "auth_control_wrong_password": "Please enter the correct password",
    "login_check_err": "You must agree to the terms and conditions",
    "c_no_login": "You are logged out",
    "login_register": "Register",
    "login_livechat":"Live Chat",
    "login_btn": "Login",
    "login_forgot": "Forgot your password?",
    "login_label_phone": "Mobile phone number",
    "login_label_username": "Username",
    "login_label_pass": "Login password",
    "login_label_captcha": "Captcha",

    "reg_title": "Register Account",
    "reg_phone": "Please enter the phone number",
    "reg_pass": "Please enter the login password",
    "reg_repass": "Please enter the confirmation password",
    "reg_code": "Please enter the verification code",
    "reg_invite": "Invitation Code",
    "reg_username": "Please enter the username",
    "reg_realname": "Please enter the name",
    "reg_secpass": "Please enter the security password",
    "reg_re_secpass": "Please enter the confirmation security password",
    "reg_term1": "I am over 18 years old and agree to this site",
    "reg_term2": "User Registration Agreement",
    "reg_1": "Register now",
    "reg_2": "Already have an account? Immediately",
    "reg_login": "Login",
    "reg_label_phone": "Mobile phone number",
    "reg_label_pass": "Login password",
    "reg_label_confirm_pass": "Confirm Password",
    "reg_label_captcha": "Captcha",
    "reg_label_username":"Username",
	"reg_label_realname":"Name",
	"reg_label_sec_pass":"Security Password",
	"reg_label_confirm_sec_pass":"Confirm Security Password",
    "reg_username_err":"Please enter correct username",
    "reg_realname_err":"Please enter correct name",
    "reg_secpass_err":"Please enter correct security_password",
    "reg_resecpass_err": "The security password is inconsistent",

    "account_index": "User Center",
    "account_settings": "Settings",
    "account_index_total_downline_comission": "Promotional Revenue",
    "account_my_wallet": "My Wallet",
    "account_index_available_balance": "Personal Balance",
    "account_index_available_voucher": "Personal Discount Voucher",
    "account_index_invitation_code": "Invitation Code",
    "account_index_copy": "Copy",
    "account_index_personal_info": "Personal Information",
    "account_index_account_binding": "Real-name authentication",
    "account_index_bank_card_binding": "Bank Card Binding",
    "account_index_profit_record": "Transaction Record",
    "account_index_invite_good_friends": "Invite Friends",
    "account_index_help": "Help Manual",
    "account_index_credit_center": "Credit Center",
    "account_index_application_download": "Application Download",
    "account_index_task_record": "Task record",
    "account_index_my_task": "My Task",
    "account_index_release_management": "Release Management",
    "account_index_today_earnings": "Today's Earnings",
    "account_index_withdrawn": "Withdrawn",
    "account_week_earning": "Weekly Earnings",
    "account_month_earning": "Monthly Earnings",
    "account_lastmonth_earning": "Last month earnings",
    "account_index_total_income": "Total Income",
    "account_index_completed_task": "Today's task completed",
    "account_index_remaining_task": "Today's remaining tasks",
    "account_index_logout": "Logout",
    "account_confirm_logout": "Are you sure you want to log out of your account?",
    "account_always_used": "Common Functions",
    "account_basic": "Basic Tools",
    "account_ticket": "Redeem ticket",
    "account_setting": "Settings",
    "account_welcome": "Welcome!",
    "account_balance": "Available Balance",
    "account_pic": "Account Picture",
    "account_profits_details": "Profit Details",
	"account_copied_referral_code":"Copied referral code successfully",

    "recharge_index_recharge": "Recharge",
    "account_index_withdraw": "Withdrawal",
    "wallet_title": "My Wallet",
    "wallet_recharge_details": "Recharge Details",
    "wallet_recharge_log": "Recharge Record",
    "wallet_withdraw_log": "Withdrawal Log",
    "wallet_user_cash_fail": "Declined",
    "wallet_user_cash_success": "Completed",
    "wallet_user_cash_pending": "Pending payment",
    "wallet_user_withdraw_fail": "Withdrawn",
    "wallet_user_withdraw_success": "Withdraw successfully",
    "wallet_user_withdraw_pending": "Pending review",

    "account_index_bank_bound": "Bank card bound",
    "account_bank_info": "Bank Card Information",

    "wallet_recharge": "Recharge",
    "recharge_index_name": "Name",
    "recharge_index_real_name": "Please enter the full name of the remittance account holder",
    "recharge_payment_method": "Payment Channel",
    "recharge_index_balance": "Balance",
    "recharge_index_detail": "Detail",
    "recharge_index_amount": "Recharge Amount",
    "maximum_amount": "* The amount can be entered by yourself, the maximum value of a single deposit is 5000",
    "minimum_amount": "* The amount can be entered by yourself, the minimum value of a single deposit is _min_",
    "recharge_note_2": "* If you are asking a friend to transfer money, you need to fill in your friend's name on the stored value order, that is, the full name of the remittance account holder.",
    "recharge_index_terms": "Description of Recharge Rules",
    "recharge_index_confirm_recharge": "Confirm Recharge",
    "recharge_index_min_err": "The amount of recharge cannot be less than",
    "recharge_index_realname_enter": "Real name cannot be empty",
	"recharge_note_3":"* Reminder: Click on the account number and name of the recipient to copy its content",
	"recharge_note_4":"* Reminder: Submit the recharge amount after confirming that the transfer is successful",
	"recharge_index_ref_no":"Reference Number",
    "recharge_ref_no_err":"Please enter reference number",
	"recharge_amount_err":"Please enter recharge amount",
    "recharge_payment_method_err":"Please choose Payment Channel",

    "recharge_payment_order_number": "Order Number",
    "recharge_payment_copy_text": "Copy Text",
    "recharge_payment_success":"Recharge Success",

    "recharge_payment_bank_transfer": "Bank Transfer",
    "recharge_payment_transfer_funds": "Please follow the information below to transfer funds to the designated bank account.",
    "recharge_payment_bank_transfer0":"Bank Transfer",
	"recharge_payment_transfer_funds0":"Please follow the information below to transfer funds to the designated bank account.",
	"recharge_payment_bank_transfer3":"ERC20 Transfer",
	"recharge_payment_transfer_funds3":"Please follow the information below to transfer funds to the designated ERC20 account.",
	"recharge_payment_bank_transfer4":"TRC20 Transfer",
	"recharge_payment_transfer_funds4":"Please follow the information below to transfer funds to the designated TRC20 account.",

    "recharge_wallet_addr":"Wallet Address",
	"recharge_txn_hash":"Trx Id",
	"recharge_txn_hash_err":"Please enter trx id",

    "task_detail_copy": "Copy",
    "account_info_account_name": "Account Name",
    "recharge_payment_bank": "Bank",
    "recharge_payment_bank_account_number": "Account Number",
    "recharge_copy_success": "Copy succeeded",
    "recharge_payment_confirm_submit": "Confirm to submit",
    "recharge_payment_screenshot_upload": "Screenshot upload",
    "recharge_payment_no_found": "Invalid transfer information",
    "recharge_payment_pic_no_found": "Please upload a picture",
    "recharge_payment_note": "Note",

    "withdraw_payment_method": "Withdrawal channel",
    "withdraw_payment_method_err": "Please choose Withdrawal channel",
    "account_withdraw_withdraw": "Withdrawal",
    "withdraw_amount": "Withdrawal Amount",
    "account_withdraw_terms": "Withdrawal Rules Description",
    "account_withdraw_confirm_withdraw": "Confirm Withdrawal",
    "account_withdraw_verify_first": "Please complete [real-name verification] before withdrawing",
    "account_index_note": "Thank you for your submission, please wait for the review and certification",
    "account_index_completed_information": "You have completed the information",
    "account_withdraw_damaling": "Damaling amount",
    "account_withdraw_damaling1": "Require consumption to withdraw money",
	"account_withdraw_minmax":"Withdrawal limit",
	"account_withdraw_time":"Withdrawal time",
	"account_withdraw_forgot_pass":"If you forget your payment password, please,",
	"account_withdraw_findback":"click to retrieve",

    "account_review_account_bind": "Real-name authentication",
    "account_review_upload_photo": "Upload picture",
    "account_review_note_1": "Please upload a screenshot of your ID card or driver's license",
    "account_review_note_2": "(such as ID card, driver's license, passport)",
    "account_review_social_id": "Please enter your name",
    "account_review_social_id1": "Name",
    "account_review_name1": "Nickname",
    "account_review_name": "Please enter your nickname",
    "account_identity" : "Identity Number",
	"account_identity2" : "Please enter your Identity Number",
    "system_bank_card": "Enter account number",
    "system_bank_card2": "Account",
    "system_bank_card1": "Account Number",
	"system_bank_branch" : "Bank of Account",
    "account_info_bank_card_info": "Bank Account Information",
    "review_note_bank": "Note: To avoid withdrawal errors, please make sure the account number you fill in is correct",
    "account_review_submit": "Submit",
    "review_zoom": "Zoom in",
    "account_review_wechat_id": "Please enter your name",
    "account_review_wechat_name": "Please enter your nickname",
    "bank_account_err": "Enter your account number",
    "bank_account_name_err": "Please fill in your real name",
    "bank_branch_err": "Bank of Account",

    "account_withdraw_bank_card": "Bank Card",
    "account_withdraw_account_name": "Username",
    "accountt_withdraw_real_name": "Real name",
    "account_withdraw_real_name": "Real Name",
    "account_withdraw_rate": "Handling fee",
    "account_withdraw_cash": "Cash out",
    "account_withdraw_balance": "Balance",
    "withdraw_total_payment": "Fianl Withdraw amount",
    "account_withdraw_mobile_phone": "Please enter the phone number",
    "account_withdraw_enter_mobile": "Mobile number",
    "vip_control_points_less": "The amount of points to withdraw cannot be less than 50 yuan",
    "withdraw_less": "The withdrawal amount is incorrect",
    "withdraw_spend_required": "Amount to be spent",
    "withdraw_spend_requiredend": "To withdraw money",
    "withdraw_phone_err": "The phone number cannot be empty",
	"withdraw_secpassword":"Security password",
	"withdraw_secpassword_err":"The security password cannot be empty",

    "account_index_user_center": "User Center",
    "account_index_user_details": "User Details",
    "account_info_username": "Username",
    "account_info_level": "Level",
    "account_info_referral_code": "referral code",
    "account_info_account_infos": "Account Information",
    "account_info_wechat_id": "Social ID",
    "account_info_wechat_nickname": "Nickname",
    "account_info_change_password": "Change password",
    "account_info_change_secpassword":"Change security password",
    "account_system_language": "System Language",
    "account_info_unbound": "Unbound",
    "account_info_bounding": "Bounding",
    "account_info_bounded": "Bounded",
    "account_info_bank_account": "Account number",
    "account_info_bank_branch": "Bank Name",

    "transfer_index":"Transfer",

    "profile_password_change_password": "Change password",
    "profile_password_old_password": "Enter original password",
    "profile_password_new_password": "Enter new password",
    "profile_password_reenter_password": "Enter new password to confirm",


    "profile_password_old_password_text": "Original password",
    "profile_password_new_password_text": "New password",
    "profile_password_reenter_password_text": "Confirm new password",
    "profile_update_success": "Repeat the new password successfully, please log in again!",

    "profile_password_change_secpassword":"Change Payment Password",
	"profile_password_old_secpassword":"Enter Original payment password",
	"profile_password_new_secpassword":"Enter new payment password",
	"profile_password_reenter_secpassword":"Confirm new payment password",


    "profile_password_old_secpassword_text":"Original Payment Password",
	"profile_password_new_secpassword_text":"New Payment Password",
	"profile_password_reenter_secpassword_text":"Confirm New Payment Password",

    "vip_index_vip_purchase": "VIP purchase",
    "vip_index_free": "Free",
    "vip_index_obtained": "Obtained",
    "vip_index_join_now": "Join now",
    "vip_index_task_times": "times",
    "vip_index_daily_task": "Daily Ticket Purchase",
    "vip_index_voucher": "Discount Voucher",
    "vip_index_withdraw": "Number of withdrawals",
    "vip_index_withdraw_fees": "Withdrawal commission",
    "vip_index_reward": "Reward",
    "quick_task_function": "*You can enable one-key task completion function",
    "vip_index_confirm_purchase": "Confirm Purchase",
    "vip_index_continue_purchase": "Would you like to continue purchasing?",
    "vip_index_purchase": "OK",
    "vip_index_cancel": "Cancel",
    "credit_no_enough": "Insufficient balance, please recharge",
    "vip_control_purchase_vip": "Successful purchase of VIP",

    "common_error": "Invalid error",

    "account_incomelog_income_breakdown": "Income Details",
    "account_revenue": "Revenue",
    "account_expenditure": "Expenditure",
    "account_recharge": "Recharge",
    "account_voucher": "Discount Voucher",

    "invite_index_invite_friend": "Invite a friend",
    "invite_index_save_qr": "Long press to save the QR code",
    "invite_index_share_moment": "Post to Moments",
    "invite_index_invite_link": "Invite Link",
    "invite_index_referral_code": "referral code",

    "team_reports": "Team Reports",
    "team_reports_my_team": "My Team",
    "myteam_user": "User",
    "myteam_recharge": "Recharge",
    "vip_control_withdrawal": "Withdrawal",
    "myteam_commission": "Rebates",

    "team_reports_team_recharge": "Team Recharge",
    "team_reports_team_withdraw": "Team Withdrawal",
    "team_reports_team_recharge_num": "Number of team recharges",
    "team_reports_first_charge": "Number of First Chargers",
    "team_reports_first_push": "Number of first pushes",
    "team_reports_team_size": "Team Size",
    "team_reports_new_team": "New Team",
    "team_reports_downline_total_commision": "Total Downline Profit",

    "system_maintenance": "System Maintenance",
    "system_maintenance_note_1": "Sorry, the team report page is temporarily unavailable due to system maintenance.",
    "validate_task_start_time": "Start time!",
    "validate_task_end_time": "End time!",
    "system_maintenance_note_2": "The team report page is expected to be closed for about an hour.!",
    "system_maintenance_note_3": "We apologize for any inconvenience caused.!",
    "system_maintenance_back": "Go back to mine",
    "team_reports_level_1": "Level 1",
    "team_reports_level_2": "Level 2",
    "team_reports_level_3": "Level 3",
    "team_reports_recharge_amount": "Recharge Amount",
    "team_reports_recharge_number": "Number of recharges",
    "team_reports_total_commission": "Total Profit",
    "team_reports_search": "Search",

    "footer_home": "Home",
    "footer_movie": "Movie",
    "footer_ticket": "movie ticket",
    "footer_plan": "Leaderboard",
    "footer_me": "Profile",
    "footer_movie2": "Movie Center",
    "footer_me2": "Personal Center",
    "footer_question": "FAQ",
    "footer_abt": "Contact Us",
    "footer_hotspot": "Hotspot",
    "footer_orderlist": "Transaction History",
    "footer_market":"Market",

    "movie_cat": "Category",
    "movie_all": "All",
    "movie_rating": "Viewer Rating",
    "movie_title": "Movie",
    "movie_on": "Hot Movie",
    "movie_on_rank": "Hot Movie List",
    "movie_wait": "To be shown",
    "movie_category": "Type",
    "movie_des": "Introduction",
    "movie_price": "Price",
    "movie_reward": "Reward",
    "movie_buy": "Buy Tickets",
    "movie_num": "Number",
    "movie_num_err": "The number of tickets purchased cannot be less than 0",
    "movie_total": "Total Price",
    "movie_voucher": "Discount Voucher",
    "movie_voucher_use": "Use a coupon?",
    "buy_credit_no_enuf": "Insufficient balance",
    "buy_voucher_no_enuf": "Not enough discount vouchers",
    "buy_err_invalid_movie": "The movie record could not be found",
    "buy_ticket_err": "Failed to buy movie!",
    "buy_ticket_success": "Buy the movie successfully!",
    "buy_ticket_in_remaining": "The maximum number of times to buy movie tickets has been reached!",

    "ticket_title": "Order movie ticket information",
    "ticket_code": "Ticket code",
    "ticket_more": "Details",
    "ticket_pending": "Not completed",
    "ticket_complete": "Complete",
    "ticket_balance_used": "Balance used",
    "ticket_voucher_used": "Discount Voucher Used",
    "ticket_details": "Ticket Details",
    "ticket_status": "Status",
    "ticket_reward": "Bonus",
    "ticket_rebate": "return",
    "ticket_home": "return",
    "ticket_payment_success": "Payment successful",
    "ticket_payment_time": "Payment Time",
    "ticket_reward_time": "Reward Time",
    "ticket_rebate_time": "Rebate time",

    "index_movie": "Movie",
    "index_ticket": "movie ticket",
    "index_rank": "Interest Treasure",
    "index_vip": "VIP",
    "index_me": "Personal Information",
    "index_buy": "Buy Tickets",
    "index_watch": "Want to watch",
    "index_register": "Register",
    "index_login": "Login",

    "index_open": "Transaction",
    "index_close": "Close",

    "index_hot_products": "Hot Products",
    "index_latest": "Latest",
    "index_status": "Status",
    "index_recharge": "Recharge",
    "index_withdraw": "Withdrawal",
	"index_speed_contract":"Speed Contract",
    "index_interest": "Interest Treasure",
    "index_download": "Download",

    "index_inbox": "Information",
    "inbox_control_mail_abnormal": "Message Abnormal",
    "inbox_details": "Information Details",

    "hotspot_title": "Hotspot",
    "hotspot_news": "Latest News",
    "hotspot_promo": "Offer promotion",
    "hotspot_details": "Content Details",

    "common_balance": "Balance",
    "common_voucher": "Discount Voucher",
    "open_app": "Open App",
    "drag_more": "Drag to load more",
    "no_more": "No more!",
    "common_point": "Point",
    "common_copy": "Copy",
    "common_close": "Close",
    "common_confirm": "Confirm",

    "previous": "Previous page",
    "next": "Next page",

    "faq_title": "FAQ",

    "about_title": "Contact Us",
    "about_title2": "You can contact us through the following channels!",
    "about_email": "Email",
    "about_wechat": "WeChat",
    "about_time": "Business hours",

    "plan_title": "Interest Treasure",
    "plan_days": "Days",
    "plan_intrest": "Interest",
    "plan_price": "price",
    "plan_buy": "Buy",
    "plan_details": "Interest Treasure Details",
    "plan_details_balance": "Available Balance",
    "plan_details_cycle": "Investment Period",
    "plan_details_interest": "Investment Income",
    "plan_details_min": "Minimum investment amount",
    "plan_details_max": "Maximum investment amount",
    "plan_details_amt": "Investment Amount",
    "plan_details_amt_enter": "Please enter the investment amount",
    "plan_details_comfirm": "Confirm Submit",
    "plan_project_no_start": "The project has not started yet",
    "plan_invalid": "Invalid Interest Treasure Project",
    "plan_invalid_money": "Invalid Investment Amount",
    "plan_limit_per_invest": "The project is limited to investment per person",
    "plan_limit_time_invest": "The project has the same time limit investment",
    "plan_balance_no_enuf": "Insufficient balance, please recharge before investing!",
    "plan_invalid_invest_min": "The investment amount is less than the minimum investment amount of the project!",
    "plan_invalid_invest_max": "The investment amount is greater than the maximum amount of the project",
    "plan_invest_fail": "Investment failed!",
    "plan_invest_success": "Investment successful!",
    "plan_mobile_times": "times",
    "plan_order_details": "Investment Details",
    "plan_order_view_details": "View Contents",
    "plan_order_view_distribute": "Distributing",
    "plan_order_view_done": "Done",
	"plan_day_no":"Investmetn Period",
	"plan_payback":"Investment Payback",


    "register_user_agreement": "User Agreement",
    "recharge_withdraw_terms": "Description of deposit and withdrawal rules",
    "terms_1": "1. The amount of each deposit cannot be less than {min}.",
    "terms_2": "2. The amount of each top-up cannot exceed {max}.",
    "terms_3": "3. Please make sure to fill in the real full name each time you top up.",
    "terms_4": "4. The platform sets the withdrawal amount of different grades according to the activity operation capability, which is subject to the withdrawal page display. The user can choose the required withdrawal amount for each withdrawal, and the remaining amount can meet the aforementioned requirements next time. Apply for withdrawal when withdrawing the limit.",
    "terms_5": "5. An account can withdraw a maximum of {withdraws} times a day, and a device can withdraw a maximum of {withdraws} times a day.",
    "terms_6": "6. You can choose to withdraw cash to a bank card. To bind a bank card, you need to go to the real-name authentication for submission and review.",
    "terms_7": "7. Withdrawals generally arrive within 10 minutes (please understand and agree that in the event of a peak withdrawal, the time for withdrawals to arrive will be extended).",
    "terms_8": "8. In order to ensure your smooth withdrawal, please make sure that your bank card meets the requirements of the third-party payment platform (please complete the real-name authentication), otherwise the withdrawal will not be successful.",
    "terms_9": "9. Please understand and agree that we use advanced artificial intelligence to analyze the user's behavior. If we find that the user has, or has been reasonably suspected by Sonhador, has violated the principle of good faith and other violations, we have the right to Prevent users from grabbing orders, earning money, withdrawing cash, and canceling user rewards.",

    "common_missing": "Missing parameter",
    "common_acc_freez": "Your account has been frozen",
    "forgetpass_block": "Your account has been blocked",

    "maximumPosition": "The maximum investment amount is",
    "minimumPosition": "The minimum investment amount is",
    "order_invest_fail": "Order failed!",
    "order_invest_success": "Order successfully placed!",
    "order_invalid": "Invalid product item",
    "order_invalid_Sec": "The time parameter is abnormal",

    "index_movieunit": "Department",
    "index_trailer": "Trailer",
    "index_on_showing": "Only showing",
    "index_on_s": "Show",
    "index_on_month": "month",
    "index_on_date": "Day",
    "index_coming_soon": "Coming soon",

    "exchange_title": "Exchange",
    "exchange_holdPositions": "Hold Positions",
    "exchange_buyUp": "Buy Up",
    "exchange_buyFall": "Buy Fall",
    "exchange_buyDown": "Buy Down",
    "exchange_confirm_order": "Confirm order",
    "exchange_balance": "Balance",
    "exchange_service_charge": "Service Fee",
    "exchange_order_confirmation": "Order Confirmation",
    "exchange_expiration_date": "Expiration time",
    "exchange_other_amount": "Other Amount",
    "exchange_insufficient": "The balance of the investment amount is insufficient, please recharge!",
    "exchange_order_max_price": "A single investment amount does not exceed {amt}",
    "exchange_order_min_price": "Single investment amount is not less than {amt}",
    "exchange_order_max_price_fee": "A single bet amount and handling fee shall not exceed {amt}",
    "exchange_investment_amount": "Investment Amount",
    "exchange_name": "Name",
    "exchange_direction": "Direction",
    "exchange_present_price": "Present Price",
    "exchange_money": "Amount",
    "exchange_prospective_yield": "Expected return",
    "exchange_prospective_result":"Expected result",
    "exchange_guaranteed_amount": "guaranteed amount",
    "exchange_settlement_time": "Settlement Time",
    "exchange_minute": "minute",
    "exchange_profit": "Profit",
    "exchange_open": "Open",
    "exchange_low": "Lowest",
    "exchange_high": "Highest",
    "exchange_succeeded": "Successful transaction, waiting for settlement",
    "exchange_time_remaining": "Remaining time",
    "exchange_execution_price": "Execution Price",
    "exchange_forecast_results": "Forecast Results",
    "exchange_please_wait": "Please wait",
    "exchange_submitting_order": "Submitting order",
    "exchange_due_settlement_completed": "Due settlement completed",
    "exchange_transaction_price": "Transaction Price",
    "exchange_continue_order": "Continue to place an order",
    "exchange_position_details": "Position Details",
    "exchange_balance_err": "Insufficient funds, please recharge first",
    "exchange_balance_min_err": "Minimum bet amount is",
    "exchange_balance_max_err": "The maximum bet amount is",
	"exchange_title_market":"Market",
	"exchange_login_required":"Please login first",
    "exchange_amount_box_all":"All balance",

    "trade_order_list": "Trade History",
    "trade_position_details": "Position record",
    "trade_history_details": "History Details",
    "trade_name": "Commodity Quotes",
    "trade_transaction_price": "Transaction Price",
    "trade_settlement_price": "Settlement Price",
    "trade_fee": "Trade Fee",
    "trade_profit_loss": "Profit and Loss",
    "trade_close_time": "Close time",
    "trade_settle_time": "Expiration Time",
    "trade_close": "Close",

	"product_category_0":"All",
    "product_category_1":"Oil",
	"product_category_2":"Metal",
	"product_category_4":"Index",
	"product_category_5":"Forex",
	"product_category_6":"Crypto",

    "market_products":"Products",

	"promotions_index":"Promotions",

	"结单":"Statement",
    "结单-本金":"Statement- Principal",
    "下单":"Order",
    "后台会员充值":"Backstage Member Recharge",
	"实名认证奖励":"Realname Authetication Reward",
	"理财计划奖励-本金":"Financial Plan Rewards - Principal",
	"理财计划奖励":"Financial Plan Incentives",

    "account_withdrawal_1":"Please contact customer service if you want to process the withdraw service.",
    "account_recharge_1":"Please contact customer service if you want to process the recharge service.",
    "reg_label_email":"Email Address",
    "reg_email_err" : "Email address format invalid",

}