<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

.main-container{
 background: $black-500;
}
</style>
<template>
  <base-layout3
    :page-title="$t('register_user_agreement')"
    :show-back-button="true"
    :show-footer="false"
    :show-item="false"
    mode=""
  >
    <main
      class="container-fluid h-100 main-container login pt-4 register_middle"
    >
      <div class="pt-1 pb-1 text-gray">
        <div class="p-1">
          <div class="text-white">{{ $t('register_user_agreement') }}</div>
          <div class="row mt-1 outline-sd p-1">
            <div
              class="col-12"
              style="
                font-size: 14px;
                text-align: justify;
                line-height: 18px;
                padding: 10px 8px;
              "
            >
              Terms of Use <br /><br />
              PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR SITES
              Sonhador. IF YOU ARE UNDER THE AGE OF 18, YOU MAY NOT USE OUR
              SITES. BY USING ANY SITE(S), YOU SIGNIFY YOUR AGREEMENT TO THESE
              TERMS OF USE AS WELL AS OUR PRIVACY POLICY. IF YOU DO NOT AGREE
              WITH THE TERMS AND CONDITIONS OF THESE TERMS OF USE AND OUR
              PRIVACY POLICY, PLEASE DO NOT USE THE SITES. IF YOU ARE A MINOR,
              BE SURE TO GET YOUR PARENT OR LEGAL GUARDIAN TO REVIEW AND AGREE
              TO THESE TERMS OF USE AND OUR PRIVACY POLICY ON YOUR BEHALF.<br /><br />1.
              Changes To the Sites and the Terms of Use. Sonhador reserves the
              right, in its sole discretion, to modify or update these Terms of
              Use at any time. We will notify users of updates with notification
              to users who have provided their email address for such purposes
              and/or by posting notification of the update on the Sites. You
              should review these Terms of Use periodically. You can determine
              when the Terms of Use were last updated by referring to the LAST
              UPDATED legend at the top of these Terms of Use. In addition, when
              using the Sites, you agree that you are subject to any additional
              posted guidelines, rules, or terms and conditions applicable to
              services made available on the Sites, which are hereby
              incorporated by reference into this Agreement. <br /><br />
              We also reserve the right, at any time and from time to time to
              modify or discontinue one or more Sites, or any portion thereof,
              including any features, products, promotions, content or services
              offered through the Sites, with or without notice and/or to offer
              opportunities to some or all users of the Sites. You agree that we
              shall not be liable to you or to any third party for any
              modification, suspension or discontinuance of the Sites, in whole
              or in part, or of any feature, product, promotion, content or
              service offered through the Sites. Your continued use of the Sites
              after such changes will indicate your acceptance of such changes.
              <br /><br />
              2. License. Your use of the Sites creates a legally binding
              agreement by and between you and Sonhador. You acknowledge that
              these Terms of Use are entered into by and between Sonhador and
              you ⎯ and not with any third party distributor or operator of any
              platform through which you obtain or access the Sites (a Third
              Party Distributor).
              <br /><br />
              The Sites, including the software (including, without limitation,
              software, code, files, images, contained in or generated by the
              software, accompanying data, Boot ROM code and other embedded
              software), documentation and any accompanying fonts, and changes
              and updates to the Sites, are licensed to you by Sonhador for your
              own personal, non-commercial use, subject to the following:
              Neither title nor any intellectual property rights are transferred
              to you, but rather remain with Sonhador, who owns full and
              complete title, and Sonhador reserves all rights not expressly
              granted to you. The rights granted to you are non-transferable,
              and are limited to Sonhador's intellectual property rights in the
              Sites and do not include any other patents or intellectual
              property rights.
              <br /><br />
              Subject to the terms and conditions of these Terms of Use, we
              grant you a non-exclusive, worldwide, and perpetual license to
              download, perform, display, and use our mobile applications on one
              device. You are solely responsible for any data and other charges
              incurred by using and accessing our Sites, including our mobile
              applications. You have permission to use the Sites on one device -
              including an iPhone or iPad device - at a time. You may not make
              the Sites available over a network where it could be used on
              multiple devices at the same time. These Terms of Use do not grant
              you any rights to use Sonhador proprietary interfaces or other
              intellectual property in the design, development, manufacture,
              licensing or distribution of third-party devices and accessories
              for use with the Sites. Any use of the Sites in any manner not
              allowed under these Terms of Use or the LAEULA (defined below),
              including, without limitation, resale, transfer, modification or
              distribution of the Sites or copying or distribution of text,
              pictures, music, video, data, hyperlinks, displays and other
              content provided by the Sites is prohibited. These Terms of Use do
              not entitle you to receive and does not obligate Sonhador to
              provide hard-copy documentation, support, telephone assistance, or
              enhancements or updates to the Sites. You may not modify, alter,
              copy, publicly display or perform, distribute, create derivative
              works, of the Sites. These Terms of Use is effective until
              terminated.
              <br /><br />
              YOUR RIGHTS UNDER THESE TERMS OF USE WILL TERMINATE AUTOMATICALLY
              WITHOUT NOTICE FROM Sonhador IF YOU FAIL TO COMPLY WITH ANY
              PROVISIONS OF THESE TERMS OF USE. UPON THE TERMINATION OF THESE
              TERMS OF USE, YOU MUST CEASE ALL USE OF THE SITES.
              <br /><br />
              2.1 Special Provisions re Apple. If you access the Sites through
              Apple, Inc. (Apple), you acknowledge that Apple and its
              subsidiaries are third-party beneficiaries of these Terms of Use
              and that Apple has the right (and is deemed to have accepted the
              right) to enforce these Terms of Use. You also acknowledge that
              Apple has no obligation whatsoever to furnish any maintenance and
              support services with respect to the Sites. You further
              acknowledge that you have reviewed the App Store Terms and
              Conditions (located online at
              http://www.apple.com/legal/itunes/us/terms.html#APPS). These Terms
              of Use incorporate by reference the Licensed Application End User
              License agreement (the LAEULA) published by Apple, (located online
              at http://www.apple.com/legal/itunes/appstore/dev/stdeula/). If
              any terms of these Terms of Use conflict with the terms of the
              LAEULA, the provisions of these Terms of Use will be controlling.
              <br /><br />
              2.2 Special Provisions re Google. If you access the Sites through
              Google, including through Google Play for mobile applications, you
              also acknowledge that Google has no obligation whatsoever to
              furnish any maintenance and support services with respect to the
              Sites. You further acknowledge that you have reviewed and agreed
              to the Google Play Terms of Service (located online at
              https://play.google.com/intl/en-us_us/about/play-terms.html).
              <br /><br />
              3. Registration. You may be provided the opportunity to register
              to become a member of one or more of the Sites. In some instances,
              Sonhador may also require registration to gain access to certain
              Site areas or services. If you choose to register on our Sites,
              you agree to (a) provide accurate, current and complete
              information about yourself as prompted by the applicable
              registration form, and (b) maintain and update your information
              (including your e-mail address) to keep it accurate, current and
              complete. You acknowledge that, if any information provided by you
              is untrue, inaccurate, not current, or incomplete, we reserve the
              right to terminate your use of our Sites. You may not include in
              your member profile any telephone numbers or street addresses. You
              (or your parent/legal guardian if you are a minor) must be the
              email account holder for any email addresses that you list in your
              account information or subsequently provide in connection with the
              Sites. You will be responsible for the confidentiality of your
              username and password and agree not to transfer or resell your use
              of or access to the Sites to any third party. YOU ARE ENTIRELY
              RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME
              AND PASSWORD AND FOR ANY AND ALL ACTIVITIES (INCLUDING PURCHASES,
              AS APPLICABLE) THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
              <br /><br />
              4. Proprietary Rights in Materials on the Sites. Sonhador and/or
              its licensors and suppliers own and retain all proprietary rights
              in the Sites and in information and materials provided through the
              Sites, including but not limited to the characters, logos,
              graphics, illustrations, data, text, artwork, images, audio and
              video, icons, software, including but not limited to images or
              files incorporated in or generated by the software or data
              accompanying such software, and links (collectively, the
              Materials) provided through the Sites. No Materials may be copied,
              reproduced, republished, uploaded, posted, transmitted, or
              distributed in any way or decompiled, reverse engineered or
              disassembled, unless the Material is in the public domain or you
              have obtained written permission and maintain all legal notices
              intact or as provided by Sonhador.
              <br /><br />
              The trademarks, logos, and service marks displayed on the Sites
              (collectively, the Trademarks) are the registered and unregistered
              trademarks of Sonhador, its licensors and suppliers, and others.
              Nothing contained on the Sites should be construed as granting, by
              implication, estoppel or otherwise, any license or right to use
              any Trademark without the express written permission of Sonhador,
              its licensors or suppliers, or the third party owner of any such
              Trademark. Misuse of any Trademarks is prohibited, and Sonhador
              will enforce its intellectual property rights in such Trademarks.
              <br /><br />
              5. Submitted Content. While using the Sites, you may have the
              opportunity to post or transmit to the Sites notes, messages,
              e-mails, billboard postings, photos, drawings, profiles, opinions,
              ideas, images, videos, audio files or other materials or
              information posted or transmitted to the Sites (collectively,
              Submitted Content). By sending or transmitting Submitted Content
              to us, or by posting Submitted Content to any area of the Sites,
              you grant us and our designees a worldwide, non-exclusive,
              sublicensable (through multiple tiers), assignable, royalty-free,
              perpetual, irrevocable right to use, reproduce, distribute
              (through multiple tiers), create derivative works of, publicly
              perform, publicly display, make, have made, sell, offer for sale
              and import such Submitted Content in any media now known or
              hereafter developed, for any purpose whatsoever, commercial or
              otherwise, without compensation to you or any third party. You
              hereby represent and warrant that you have all rights necessary
              for you to grant the licenses granted in this section, and that
              the Submitted Content, and your provision thereof to and through
              the Sites, complies with all applicable laws, rules and
              regulations. You further irrevocably waive any moral rights or
              other rights with respect to attribution of authorship or
              integrity of materials regarding the Submitted Content that you
              may have under any applicable law under any legal theory.
              <br /><br />
              Sonhador reserves the right, but has no obligation, to (a)
              evaluate Submitted Content before allowing it to be posted on the
              Sites, and (b) monitor the Submitted Content posted on the Sites.
              You acknowledge and agree that Sonhador may, in its sole
              discretion, (y) review, edit, reject, refuse to post and/or delete
              any Submitted Content; and (z) disclose any Submitted Content, and
              the circumstances surrounding their transmission, to any third
              party in order to operate the Site; to protect Sonhador and its
              employees, officers, directors, shareholders, agents,
              representatives and affiliates, and the Sites' users and visitors;
              to comply with legal obligations or governmental requests; to
              enforce these Terms of Use; or for any other reason or purpose.
              Notwithstanding these rights, YOU REMAIN SOLELY RESPONSIBLE FOR
              THE SUBMITTED CONTENT THAT YOU POST IN THE PUBLIC AREAS OF THE
              SITES AND IN YOUR PRIVATE E-MAIL MESSAGES.
              <br /><br />
              We neither endorse nor are responsible for information or content
              posted in the public areas of the Sites, or for any opinion,
              advice, information, or other statement made or displayed on the
              Site by third parties. The opinions expressed in such postings
              reflect solely the opinions of the participants and may not
              reflect the opinions of Sonhador. We are not responsible for any
              errors, omissions or unauthorized content in articles or postings,
              for hyperlinks embedded in messages, or for any results obtained
              from the use of such information. Under no circumstances will we
              or our affiliates, suppliers or agents be liable TO YOU for any
              loss or damage caused by your reliance on such information
              obtained through the Sites.
              <br /><br />
              6. Creative Submissions/Communications. Sonhador does not accept
              or consider creative ideas, suggestions or materials other than
              those Sonhador has specifically requested. This is to avoid the
              possibility of future misunderstandings when projects
              independently developed by Sonhador or its agents might seem to
              others to be similar to their own creative ideas, suggestions or
              materials. If you do send us or post any unsolicited creative
              materials, including creative suggestions, ideas, notes, drawings,
              concepts or other information or if you transmit to the Sites by
              electronic mail or otherwise, communications including any data,
              questions, comments, suggestions, or the like (collectively, the
              Information), the Information shall be deemed to be, and shall
              remain, the property of Sonhador. None of the Information shall be
              subject to any obligation of confidence on the part of Sonhador,
              and Sonhador shall not be liable for any use or disclosure of any
              Information. You hereby assign to Sonhador all right, title and
              interest in and to the Information, including all intellectual
              property rights now known or hereafter existing throughout the
              universe, and Sonhador shall be entitled to unrestricted use of
              the Information for any purpose whatsoever, commercial or
              otherwise, without compensation to the provider of the
              Information.
              <br /><br />
              7. Rules of Conduct on the Sites.We expect users of the Sites to
              respect the rights and dignity of others. Your use of the Sites is
              conditioned on your compliance with the rules of conduct set forth
              in this section:
              <br /><br />
              a. You may not post or transmit Submitted Content (even if made in
              a joking, sarcastic or unintended manner) that:
              <br /><br />
              -is patently offensive to the online community, such as Submitted
              Content that promotes racism, bigotry, hatred or physical harm of
              any kind against any group or individual;<br />
              -harasses or advocates harassment of another person;<br />
              -involves the transmission of junk mail, chain letters, or
              unsolicited mass mailing or spamming;<br />
              -expresses or implies that any statements you make are endorsed by
              us, without our prior written consent;<br />
              -promotes information that is false, misleading or promotes
              illegal activities or conduct that is abusive, threatening,
              obscene, defamatory or libelous;<br />
              -infringes upon or violates any third party's rights (including,
              but not limited to, intellectual property rights, rights of
              privacy (including without limitation unauthorized disclosure of a
              person's name, email address, physical address or phone number) or
              rights of publicity);<br />
              -promotes an illegal or unauthorized copy of another person's
              trade secrets or copyrighted work, such as providing pirated
              computer programs or links to them, providing information to
              circumvent manufacture-installed copy-protect devices, or
              providing pirated music or links to pirated music files;<br />
              -contains restricted or password only access pages, or hidden
              pages or images (those not linked to or from another accessible
              page);<br />
              -provides material that exploits people in a sexual or violent
              manner;<br />
              -solicits personally identifiable information from anyone under
              18;<br />
              -harvests or collects information about Site visitors without
              their express consent;<br />
              -provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone's privacy,
              or providing or creating computer viruses;<br />
              -contains video, photographs, or images of another person age 18
              or older without his or her express written consent and
              permission, or those of any minor (regardless of whether you have
              the minor's or his or her legal guardian's consent);<br />
              -tries to gain unauthorized access to the Sites or to profiles,
              blogs, communities, account information, bulletins, friend
              request, or other areas of the Sites or solicits passwords or
              personal identifying information from other users for purposes
              that are commercial, unlawful, or otherwise contrary to these
              Terms of Use;<br />
              -engages in commercial activities and/or sales without Sonhador's
              prior written consent such as contest, sweepstakes, barter,
              advertising and pyramid schemes;<br />
              -solicits gambling or engages in any gambling activity;<br />
              -contains viruses, time bombs, trojan horses, cancelbots, worms or
              other harmful or deleterious components or devices;<br />
              -interferes with another user's use and enjoyment of the Sites or
              any other individual's user and enjoyment of similar services,
              such as hacking or defacing any portion of the Sites; or<br />
              -refers or links to any website or URL that, in the sole
              discretion of Sonhador, contains material that is inappropriate
              for the Sites, contains content that would be prohibited on the
              Sites, or violates the letter or spirit of these Terms of Use.
              <br /><br />
              b. You may not use any robot, spider, site search/retrieval
              application or other manual or automatic device to retrieve,
              index, scrape, data mine or in any way reproduce or circumvent the
              navigational structure or presentation of the Sites without
              Sonhador's prior written consent.
              <br /><br />
              c. You must use the Sites and Materials in a manner consistent
              with any and all applicable laws and regulations. Illegal and/or
              unauthorized uses of the Sites, including without limitation,
              collecting user names and/or email addresses of users of the Sites
              by electronic or other means for the purpose of sending
              unsolicited email and unauthorized framing of or linking to the
              Sites, are not permitted.
              <br /><br />
              d. You may not engage in advertising to, or solicitation of, other
              users through the Sites to buy or sell any products or services.
              You may not transmit any chain letters or unsolicited commercial
              or junk email to other users. Although Sonhador cannot monitor the
              conduct of its users off the Sites, it is also a violation of
              these rules to use any information obtained from the Sites in
              order to harass, abuse, or harm another person, or in order to
              contact, advertise to, solicit, or sell to any users without their
              prior express consent. In order to protect our registered members
              from such advertising or solicitation, Sonhador reserves the right
              to restrict the number of messages or emails that a member may
              send through the Sites to other registered members in any 24-hour
              period to a number that Sonhador deems appropriate in its sole
              discretion.
              <br /><br />
              8. Online Purchases. If you wish to purchase digital
              subscriptions, products or services described on the Sites, you
              will be asked by Sonhador or the third party provider of the
              product or service to supply certain information applicable to
              your purchase, including, without limitation, credit card and
              other information. You understand that any such information will
              be treated by Sonhador in the manner described in our Privacy
              Policy, which can be found here. You agree that all information
              that you provide to Sonhador or such third party provider will be
              accurate, current and complete. You agree to pay all charges
              incurred by you or any users of your account and credit card (or
              other applicable payment mechanism) at the price(s) in effect when
              such charges are incurred. You will also be responsible for paying
              any applicable taxes and shipping charges relating to your
              purchases.
              <br /><br />
              Descriptions or images of, or references to, products or services
              on the Site do not imply Sonhador's endorsement of such products
              or services. Sonhador reserves the right, without prior
              notification, to change such descriptions or references, to limit
              the order quantity on any product or service and/or to refuse
              service to you. Verification of information applicable to a
              purchase may be required prior to Sonhador's acceptance of any
              order. Price and availability of any product(s) is/are subject to
              change without notice. Your correspondence or business dealings
              with, or participation in promotions of, advertisers or other
              third parties found on or through the Sites, including payment and
              delivery of related goods or services, and any other terms,
              conditions, warranties or representations associated with such
              dealings, are solely between you and such advertiser or other
              third party. Sonhador shall not be responsible or liable for any
              loss or damage of any sort incurred as the result of any such
              dealings or as the result of the presence, acts or omissions of
              such advertisers or third parties on the Sites.
              <br /><br />
              When you purchase a digital product, the price will be made clear
              during the order process. You agree to pay the price that is
              stated at the time of your order, as well as any applicable taxes.
              You also agree to the billing frequency stated at the time of your
              order. All digital product purchases are final and no refunds will
              be given. If you reside in a country where applicable law provides
              for a period of cancellation if you change your mind after
              purchasing a paid subscription, Sonhador will issue a full refund
              if requested within the statutory period, but only if you have not
              logged in or otherwise redeemed or started to consume the
              subscription. Digital content is only available for purchase by
              users with a credit card that has a billing address located in one
              of the following countries: United, States, Canada, United
              Kingdom, Ireland, Australia, New Zealand, South Africa,
              Philippines, Singapore, and India. By opening an account with
              Sonhador to access and download digital content, you represent and
              warrant to Sonhador that you reside in one of these listed
              countries and that you are capable of entering a contract under
              the laws that apply to you.
              <br /><br />
              9. Contests and Sweepstakes, and Other Activities. Sonhador may
              provide rules for certain promotional activities on the Sites
              including, without limitation, contests and sweepstakes by
              Sonhador and in conjunction with third parties. These Terms of Use
              are incorporated by reference to the specific promotion rules or
              terms and conditions, which may appear in connection with
              information on the Sites about a particular activity. To the
              extent that any conflict exists between these Terms of Use and
              specific rules for a promotional activity, the rules for the
              promotional activity in which you choose to participate shall
              govern that promotion but shall not otherwise modify the Terms of
              Use.
              <br /><br />
              10. Hyperlinks. The appearance of websites or external hyperlinks
              on the Sites does not constitute endorsement by Sonhador or its
              subsidiaries and affiliates of the opinions or views expressed by
              these linked sites. Sonhador has no control over these linked
              sites, and is not responsible for the availability of such sites,
              and Sonhador does not verify, endorse, or take responsibility for
              the accuracy, currency, completeness or quality of the content
              contained in such linked sites.
              <br /><br />
              YOU AGREE THAT YOUR USE OF LINKED WEBSITES AND RESOURCES,
              INCLUDING YOUR USE OF ANY CONTENT, INFORMATION, DATA, ADVERTISING,
              PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE THROUGH SUCH WEBSITES
              AND RESOURCES, IS AT YOUR OWN RISK AND IS SUBJECT TO THE TERMS AND
              CONDITIONS OF USE APPLICABLE TO SUCH SITES AND RESOURCES.
              <br /><br />
              Sonhador welcomes links to the Sites. You may establish a link to
              the Sites, subject to the following express conditions: (a) the
              link from your site to the Sites does not state or imply any
              sponsorship or endorsement of your site by Sonhador, its
              affiliates, or any company, group or individual affiliated with
              Sonhador; (b) you may not reproduce or otherwise commercially
              exploit any Site or any intellectual property contained therein in
              any manner or medium whatsoever, nor may you allow or authorize
              others to do so; (c) your site may not include, advertise or be
              associated with any of the following items: (i) adult or sex
              related items; (ii) tobacco products; (iii) alcoholic beverages;
              (iv) firearms, fireworks, ammunition and other weapons; (v) legal
              or illegal gambling; (vi) illegal drugs; (vii) unlawful,
              harassing, defamatory, libelous, abusive, threatening, harmful,
              vulgar or obscene communications; (viii) computer worm, virus,
              hacking or spam; or (ix) any unlawful activity; (d) you do not use
              on your site any Trademarks or Materials appearing on the Sites,
              including but not limited to any logos or characters, without our
              prior express written consent or the consent of any third party
              owner of such Trademarks or Materials; and (e) you may not frame
              or otherwise incorporate into another website any of the Materials
              on the Sites without our prior express written consent.
              <br /><br />
              11. Copyrights and Copyright Agent. Sonhador respects the rights
              of all copyright holders and in this regard, Sonhador has adopted
              and implemented a policy that provides for the termination in
              appropriate circumstances of users, members and account holders
              who infringe the rights of copyright holders.
              <br /><br />
              -A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;<br />
              -Identification of the copyright work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;<br />
              -Identification of the material that is claimed to be infringing
              or to be the subject of infringing activity and that is to be
              removed or access to which is to be disabled, and information
              reasonably sufficient to permit us to locate the material;<br />
              -Information reasonably sufficient to permit us to contact the
              complaining party;<br />
              -A statement that the complaining party has a good-faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law; and<br />
              -A statement that the information in the notification is accurate,
              and under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed. <br /><br />
              12. User Disputes. You are solely responsible for your
              interactions with other users. Sonhador reserves the right, but
              has no obligation to monitor communications and any disputes
              between you and other users.
              <br /><br />
              13. Disclaimers and Limitation on Liability. Your use and browsing
              of the Sites is at your risk. If you are dissatisfied with
              anything contained in the Sites, or with and of these Terms of
              Use, your sole and exclusive remedy is to discontinue accessing
              and using the Sites.
              <br /><br />
              Sonhador assumes no responsibility for any error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              unauthorized access to, or alteration of, user or member
              communications. Sonhador is not responsible for any problems or
              technical malfunction of any telephone network or lines, computer
              online systems, servers or providers, computer equipment,
              software, failure of email or players on account of technical
              problems or traffic congestion on the Internet or at any Sites or
              combination thereof, including injury or damage to users and/or
              members or to any other person's computer related to or resulting
              from participating or downloading materials in connection with the
              Internet and/or in connection with the Sites. Under no
              circumstances will Sonhador be responsible for any loss or damage,
              including personal injury or death, resulting from anyone's use of
              the Sites or any Materials posted on the Sites or transmitted to
              members, or any interactions between users of the Sites, whether
              online or offline. Sonhador cannot guarantee and does not promise
              any specific results from use of the Sites.
              <br /><br />
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
              NEITHER Sonhador NOR ANY OTHER PARTY INVOLVED IN CREATING,
              PRODUCING OR DELIVERING THE SITES SHALL BE LIABLE TO YOU OR ANY
              THIRD PARTY CLAIMING THROUGH YOU FOR ANY INCIDENTAL,
              CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
              LOST PROFITS ARISING OUT OF YOUR ACCESS TO, OR USE OF, THE SITES,
              LOSS OF DATA, LOSS OF OTHER INTANGIBLES, LOSS OF SECURITY OF
              INFORMATION YOU HAVE PROVIDED IN CONNECTION WITH YOUR USE OF THE
              SITES, OR UNAUTHORIZED INTERCEPTION OF ANY SUCH INFORMATION BY
              THIRD PARTIES, EVEN IF Sonhador HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE FOREGOING,
              EVERYTHING ON THE SITES IS PROVIDED TO YOU AS IS WITHOUT WARRANTY
              OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
              TO, THE IMPLIED WARRANTIES OR MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THE MAXIMUM
              LIABILITY TO Sonhador FOR ALL DAMAGES, LOSSES AND CAUSES OF
              ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION,
              NEGLIGENCE) OR OTHERWISE, SHALL BE THE TOTAL AMOUNT PAID BY YOU,
              IF ANY, TO ACCESS THE SITES. Some states do not allow the
              exclusion or limitation of incidental or consequential damages, so
              the above limitation or exclusion may not apply to you.
              <br /><br />
              14. Indemnification. You agree to indemnify, defend and hold
              harmless, Sonhador, it affiliates, and their respective officers,
              directors, employees, agents, licensors, representatives, and
              third party providers to the Sites from and against all losses,
              expenses, damages and costs, including reasonable attorneys' fees,
              resulting from (a) your use of, or activities in connection with,
              the Site, or (b) any violation of these Terms of Use by you.
              Sonhador reserves the right to assume, at its sole expense, the
              exclusive defense and control of any matter subject to
              indemnification by you, in which event you will fully cooperate
              with Sonhador in asserting any available defenses.
              <br /><br />
              15. Termination. These Terms of Use shall remain effective until
              terminated in accordance with their provisions. Sonhador reserves
              the right to immediately terminate these Terms of Use, and/or your
              access to and use of the Sites or any portion thereof, at any time
              and for any reason, with or without cause, including without
              limitation, if Sonhador believes, in its sole discretion, that you
              have violated or acted inconsistently with these Terms of Use.
              Upon such termination, your right to use the Sites shall
              immediately cease, and you shall destroy all Materials obtained
              from the Sites and all copies thereof, whether made under these
              Terms of Use or otherwise.
              <br /><br />
              16. Other. These Terms of Use contains the entire agreement
              between you and Sonhador regarding the use of the Sites and
              supersedes all prior understandings related to the Sites, cannot
              be changed orally, and if any provision or portion of a provision
              of these Terms of Use is held invalid, the remainder of this
              agreement and, if applicable, the affected provision shall
              continue in full force and effect. No waiver by either party of
              any breach of default hereunder shall be deemed to be a waiver or
              any preceding or subsequent breach or default.
              <br /><br />
              <br />Sonhador
            </div>
          </div>
        </div>
      </div>
    </main>
  </base-layout3>
</template>

