<template>
  <div v-if="open" class="backdrop" @click="$emit('close')"></div>
  <transition name="modal">
    <dialog open v-if="open" :class="modal_mode">
      <div class="modal_body">
        <slot></slot>
      </div>
    </dialog>
  </transition>
</template>

<script>
export default {
  props: ["open", "modal_mode"],
  emits: ["close"],
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}
dialog {
  position: fixed;
  top: 15vh;
  width: 85%;
  margin: 0 auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 12px;
  padding: 1rem;
  /* background: linear-gradient(45deg, #ffffff, #aecdf9); */
  z-index: 100;
  border: none;
  /* box-shadow: inset 5px 5px 10px #93c2ff, inset -5px -5px 10px #93c2ff; */
  /* animation: modal 0.3s ease-out forwards; */
}
dialog.full_modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  z-index: 1031;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}
.modal_body {
  position: relative;
}
.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@media(min-width:1025px){
  dialog{
    width: 40%;
  }
}
</style>
