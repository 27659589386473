
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
export default {
	name: "Notification",
	data() {
		return {
			data          : [],
			category      : [],
			current_page  :  1,
			last_page     :  1,
			per_page      :  10,
			total         :  0,
			page          :  1,
			currency      : getLocal('currency') || 'RM',
			img_url       : process.env.VUE_APP_IMAGE_URL,
		};
	},
	methods: {
		get_movie(sw) {

			if(sw){
				this.data = [];
				this.page = 1;
				$('.notMore').css('display', 'none');
			}

			var data = {};
			var u = '/home/api/planlist';

			this.$http("", u+"?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
				console.log(result.data)
					this.data         = _.union(this.data,result.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.data         = result.data.data;
						// console.log(this.data)
					if(_.size(result.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					}
				}

			});
		},
		getItem (item){
			if(_.size(item) ==0)
				return ;

			let lang = getLocal('lang') || "cn";
			var i    = JSON.parse(item);
			// console.log(i)
			var find = _.find(i, function(item, k){
				return k== lang;
			});

			return find || "-";
		},
		callf (type,sw){
			this.type = type;
			this.get_movie(sw);
		},
		getbycat (type){
			this.cat = type;
			this.get_movie(true);
		},
		redate(date) {
			return date.substring(0, date.length - 3);
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/');
		// console.log(token)
		this.get_movie(false);
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function(){
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1;
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					that.page++;
					that.get_movie(false) ;
				}
			}
		});
	},
	unmounted() {
	}
};
</script>

<style scoped>
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
	color:black;
	font-size: 14px;
	background: #212121;
}
.sub-mission-text2 {
	color: #A6A6A6!important;
	font-size: 14px;
	margin-top: auto;
}
.selected_cat {
	background: #fa6b0b!important;
	color: white!important;
	padding-left: 5px;
	padding-right: 5px;
}
.outline-sd {
	box-shadow: 0px 0px 6px #00000029!important;
	border-radius: 10px !important;
}
.movie-title {
	font-size: 17px;
	color: #333;
	font-weight: 700;
	padding-right: 5px;
	flex-shrink: 1;
}
.grade {
	font-weight: 700;
	color: #faaf00;
	font-size: 15px;
}
.detail {
	line-height: 24px;
}
.line-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.ml-7 {
	margin-left: 7px;
}
.active_btm {
	width: 20px;
	border-bottom: 2px solid #f51c40;
	margin-top: 5px;
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.flex-box-center {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}
.rank-text {
    font-size: 40px;
    font-weight: bold;
    color: #a59d9d;
}
p{color:#8f8f94;}
.up-case {
    text-transform: uppercase;
}
</style>

<template>
  <base-layout
	:page-title="$t('plan_title')"
	:show-back-button="true"
	:show-footer="true"
	mode=""
	style="padding-bottom:100px"
  >
	<div class="">

			<div class="container pt-1 pb-1 mt-5">
				<div class="p-1">
					<div ng-repeat="(k,item) in movie"  v-for="(item, k) in  data"  :key="k"  >
						<div class="row mt-3"  >
							<div class="col-12">
								<div class="row p-1">
									<div class="col-6 text-left pl-0">
										<img class="img-fluid" :src="img_url+'/uploads/'+item.img" :alt="item.title" style="border-radius: 5px;">
									</div>
									<div class="col-4 p-0 ">
										<div class="movie-title">
											<div class="movie-title line-ellipsis v2dimax_title up-case grade">{{item.title}}</div>
											<span class="version v2d imax"></span>
										</div>

										<div class="mt-1 detail">
											<div class="score line-ellipsis">
												<span class="grade">{{item.day+" "}}</span>
												<span class="score-suffix text-white">{{$t('plan_days' ) }}  </span>
											</div>
											<div class="score line-ellipsis">
												<span class="grade">{{"+ "+item.rate}}% </span>
												<span class="score-suffix text-white">{{$t('plan_intrest' )}}  </span>
											</div>
											<div class="score line-ellipsis">
												<span class="grade">{{+item.min}} - {{+item.max+" "}} </span>
												<span class="score-suffix text-white">{{$t('plan_price' ) }}  </span>
											</div>
										</div>
									</div>
									<div class="col-2 ">
										<button type="button" class="btn buy-btn text-white" style="background: #fa6b0b;"   @click="push_content('/trade',item.id)">
											{{$t('plan_buy' ) }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 pb-5 mt-4" style="display: block;text-align: center;width:100%;">
				<span class="notMore text-white">{{$t('drag_more') }}</span>
			</div>

	</div>
  </base-layout>
</template>