<template>
  <base-layout3
    :page-title="this.productInfo.ptitle"
    :show-footer="true"
    show-home-button="true"
    show-burger-button="true"
    :show-back-button="true"
    :showBurgerPage="true"
    mode=""
    style="padding-bottom: 100px"
    @return-product="changeProduct"
  >
    <div class="content trade-view">
      <iframe
        :src="currencyUrl"
        v-if="this.currencyUrl != ''"
        class="chart"
      ></iframe>
      <div class="trade_bar">
        <section @click="this.toggle_history_order_panel()" class="">
          <img :src="holdImg" />
          <p>
            {{ $t('exchange_holdPositions')
            }}<span class="d-none" style="">(0)</span>
          </p>
        </section>
        <section @click="this.toggle_order_confirm_panel('lookup')" class="">
          <img :src="buyUpImg" />
          <p>{{ $t('exchange_buyUp') }}</p>
        </section>
        <section @click="toggle_order_confirm_panel('lookdown')" class="">
          <img :src="buyDownImg" />
          <p>{{ $t('exchange_buyFall') }}</p>
        </section>
      </div>
      <div class="pro_mengban">
        <div class="order-confirm-panel">
          <div class="panel-header">
            <div>
              {{ $t('exchange_order_confirmation') }}
              <div class="close" @click="this.toggle_order_close_panel()">
                X
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="period">
              <p class="end_time">{{ $t('exchange_expiration_date') }}</p>
              <div direction="x" class="scroll-view ionic-scroll scroll-x">
                <div
                  class="scroll"
                  style="transform: translate3d(0px, 0px, 0px) scale(1)"
                >
                  <div class="period-widget-view">
                    <div
                      v-for="(item, k) in this.productInfo.protime"
                      :key="k"
                      class="period-widget"
                      :class="protimeChosen === item ? 'active' : ''"
                      :data-sen="item * 60"
                      :data-shouyi="
                        this.productInfo.proscale[k]
                          ? this.productInfo.proscale[k]
                          : this.productInfo.proscale[0]
                      "
                      @click="
                        this.handlePeriodWidget(
                          item,
                          this.productInfo.proscale[k]
                            ? this.productInfo.proscale[k]
                            : this.productInfo.proscale[0],
                          item * 60
                        )
                      "
                    >
                      <div class="period-widget-header">
                        {{ $t('exchange_settlement_time') }}
                      </div>
                      <div class="period-widget-content">
                        <span class="final_time">{{ item * 1 }}</span>
                        <span class="final_unit">{{
                          $t('exchange_minute')
                        }}</span>
                      </div>
                      <div class="period-widget-footer period_footer pb-1">
                        {{ $t('exchange_profit') }}
                        {{
                          this.productInfo.proscale[k]
                            ? this.productInfo.proscale[k]
                            : this.productInfo.proscale[0]
                        }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll-bar scroll-bar-h">
                  <div
                    class="scroll-bar-indicator scroll-bar-fade-out"
                    style="
                      transform: translate3d(0px, 0px, 0px) scaleX(1);
                      width: 289px;
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="amount">
              <p class="invest_account">
                {{ $t('exchange_investment_amount') }}
                <span :class="noMoney ? 'no-money' : 'd-none'">{{
                  $t('exchange_insufficient')
                }}</span>
                <span class="d-none no-max">{{
                  $t('exchange_order_max_price', {
                    amt: this.productInfo.order_max_price,
                  })
                }}</span>
                <span class="d-none no-min">{{
                  $t('exchange_order_min_price', {
                    amt: this.productInfo.order_min_price,
                  })
                }}</span>
              </p>
              <div direction="x" class="scroll-view ionic-scroll scroll-x">
                <div
                  class="scroll"
                  style="transform: translate3d(0px, 0px, 0px) scale(1)"
                >
                  <div class="amount-view">
                    <div
                      class="amount-box data-price"
                      :class="amountBox === 'all' ? 'active' : ''"
                      data-price="all"
                      @click="this.handleAmountBox('all')"
                    >
                      {{ $t('exchange_amount_box_all') }}
                    </div>
                    <div
                      v-for="(item, k) in this.productInfo.pay_choose"
                      :key="k"
                      class="amount-box data-price"
                      :class="
                        amountBox === item && otherAmount.val == ''
                          ? 'active'
                          : ''
                      "
                      data-price="{{item}}"
                      @click="this.handleAmountBox(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
                <div class="scroll-bar scroll-bar-h">
                  <div
                    class="scroll-bar-indicator scroll-bar-fade-out"
                    style="
                      transform: translate3d(0px, 0px, 0px) scaleX(1);
                      width: 192px;
                    "
                  ></div>
                </div>
              </div>
              <label class="other-amount">
                <input
                  type="number"
                  :placeholder="$t('exchange_other_amount')"
                  v-model.trim="otherAmount.val"
                  class=""
                  id="other-amount"
                  @keyup="this.handleOtherAmount()"
                />
              </label>
            </div>
            <div class="info-view">
              <div class="my-money">
                {{ $t('exchange_balance') }}:
                <span class="pay_mymoney"
                  >{{ this.balance }} {{ currency }}</span
                >
              </div>
              <div class="my-service">
                {{ $t('exchange_service_charge') }}:<span
                  >{{ this.productInfo.web_poundage }} %</span
                >
              </div>
            </div>
            <div class="order-detail-view">
              <div class="order-detail">
                <div class="row fields">
                  <div class="col">{{ $t('exchange_name') }}</div>
                  <div class="col">{{ $t('exchange_direction') }}</div>
                  <div class="col">{{ $t('exchange_present_price') }}</div>
                  <div class="col">{{ $t('exchange_money') }}</div>
                </div>
                <div class="row mt-3">
                  <div class="col qoute_name">
                    {{ this.productInfo.ptitle }}
                  </div>
                  <div class="col order_type">{{ $t('exchange_buyFall') }}</div>
                  <div class="col rise col-nowprice">
                    {{ toFixed(this.newPrice, 4) }}
                  </div>
                  <div class="col" id="money">{{ this.inputAmount.val }}</div>
                </div>
                <div class="row btn_confirm">
                  <div class="col">
                    <button
                      class="button"
                      :class="loadingAddOrder ? 'disabled' : ''"
                      @click="this.handleAddOrder()"
                    >
                      {{ $t('exchange_confirm_order') }}
                      <img
                        class="loading"
                        :src="this.loadingImg"
                        :class="this.loadingAddOrder === false ? 'd-none' : ''"
                      />
                    </button>
                  </div>
                </div>
                <p class="expect_profit">
                  <span class=""
                    >{{ $t('exchange_prospective_yield') }} : {{ currency }}
                    <span id="yuqi">{{ this.yuqi }}</span></span
                  >
                  &nbsp;&nbsp;
                  <span class=""
                    >{{ $t('exchange_guaranteed_amount') }} : {{ currency
                    }}{{ this.baodi }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_mengban">
        <div class="order-state-panel">
          <div class="panel-header">
            <div class="">
              {{ this.productInfo.ptitle }}
              <div class="close" @click="this.close_order()">X</div>
            </div>
          </div>
          <div class="panel-body">
            <div class="paysuccess d-none">
              <div class="circle_wrapper">
                <div class="right_circle">
                  <img
                    class="img_circle_right"
                    style="-webkit-animation: run 60s linear"
                    :src="rightCircleImg"
                  />
                </div>
                <div class="left_circle">
                  <img
                    class="img_circle_lift"
                    style="-webkit-animation: runaway 60s linear"
                    :src="leftCircleImg"
                  />
                </div>
              </div>
              <div class="row remaining count_remaining">
                <div class="col">
                  <div class="pay_order_sen"></div>
                  <div>{{ $t('exchange_present_price') }}</div>
                  <div class="newprice">{{ toFixed(this.newPrice, 4) }}</div>
                </div>
              </div>
              <div class="pupil_success d-none">
                <p>{{ $t('exchange_succeeded') }}</p>
                <p class="">
                  <span>{{ $t('exchange_time_remaining') }}：</span>
                  {{ $t('exchange_minute') }}Invalid Diate
                </p>
              </div>
              <div class="row info_list">
                <div class="col col-15 first_info">
                  <p style="height: 40px">{{ $t('exchange_direction') }}</p>
                  <p class="pay_order_type"></p>
                </div>
                <div class="col col-30">
                  <p style="height: 40px">{{ $t('exchange_money') }}</p>
                  <p class="">
                    <span class="pay_order_price"></span> {{ currency }}
                  </p>
                </div>
                <div class="col col-30">
                  <p style="height: 40px">
                    {{ $t('exchange_execution_price') }}
                  </p>
                  <p class="pay_order_buypricee"></p>
                </div>
                <div class="col col-25 last_info">
                  <p style="height: 40px">
                    {{ $t('exchange_forecast_results') }}
                  </p>
                  <p class="yuce">{{ this.yuce }} {{ currency }}</p>
                </div>
              </div>
            </div>

            <div class="wait">
              <div class="row">
                <div class="col">
                  <i class="ion-paper-airplane"></i>
                  {{ $t('exchange_please_wait') }}……
                </div>
              </div>
            </div>
            <div class="fail d-none">
              <div class="row">
                <div class="col">
                  <i class="ion-close-circled"></i>
                  {{ $t('exchange_submitting_order') }}
                </div>
              </div>
            </div>

            <div class="fail d-none order_fail">
              <div class="row">
                <div class="col">
                  <i class="ion-close-circled"></i>
                  <span
                    class="fail-info"
                    style="font-size: 18px; color: #fff"
                  ></span>
                </div>
              </div>
            </div>

            <div class="ordersuccess d-none" style="">
              <div class="row remaining finish_remaining">
                <div class="col">
                  <div class="result_profit" style="">
                    {{ this.orderdetail ? this.orderdetail.ploss : 0 }}
                    {{ currency }}
                  </div>
                  <div class="expired_statements">
                    {{ $t('exchange_due_settlement_completed') }}
                  </div>
                </div>
              </div>
              <div class="row info_list">
                <div class="col col-15 first_info mt-3">
                  <p>{{ $t('exchange_direction') }}</p>
                  <p class="pay_order_type">
                    {{
                      this.orderdetail
                        ? $t(`exchange_${this.orderdetail.ostyle_name}`)
                        : ''
                    }}
                  </p>
                </div>
                <div class="col col-30 mt-3">
                  <p>{{ $t('exchange_money') }}</p>
                  <p class="">
                    {{ currency }}
                    <span class="pay_order_price">{{
                      this.orderdetail ? this.orderdetail.fee : 0
                    }}</span>
                  </p>
                </div>
                <div class="col col-30 mt-3">
                  <p>{{ $t('exchange_execution_price') }}</p>
                  <p class="pay_order_buypricee">
                    {{
                      this.orderdetail
                        ? toFixed(this.orderdetail.buyprice, 4)
                        : 0
                    }}
                  </p>
                </div>
                <div class="col col-25 last_info mt-3">
                  <p>{{ $t('exchange_transaction_price') }}</p>
                  <p class="rise endprice" style="">
                    {{
                      this.orderdetail
                        ? toFixed(this.orderdetail.sellprice, 4)
                        : 0
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row button_row">
              <div class="col">
                <button class="button" @click="this.continue_order()">
                  {{ $t('exchange_continue_order') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="history-panel" ng-include="1">
        <div class="panel-header">
          {{ $t('exchange_position_details') }}
          <div class="close" @click="this.toggle_history_order_panel()">X</div>
        </div>
        <div class="trade_history_list">
          <div style="height: 100%" class="scroll-view ionic-scroll scroll-y">
            <div
              class="scroll"
              style="transform: translate3d(0px, 0px, 0px) scale(1)"
            >
              <ul></ul>
            </div>
            <div class="scroll-bar scroll-bar-v">
              <div
                class="scroll-bar-indicator scroll-bar-fade-out"
                style="
                  transform: translate3d(0px, 0px, 0px) scaleY(1);
                  height: 0px;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-layout3>
</template>
<script>
import { onMounted, reactive, ref, watch } from 'vue';
import { navList, huobiWsUrl, colors } from './config.js';
import {
  toFixed,
  getFormatDate,
  formatSeconds2,
  getLocalTime,
  getFloatLength,
  randomIntFromInterval,
} from '../../../utils/chart.js';
import { getLocal } from '@/utils/local.js';
import utils from '../../../utils/chart';
import bgImg from '../../../assets/images/3/bg2.png';
import holdImg from '../../../assets/images/3/hold.png';
import buyUpImg from '../../../assets/images/3/buy_up.png';
import buyDownImg from '../../../assets/images/3/buy_down.png';
import loadingImg from '../../../assets/images/3/loading.gif';
import rightCircleImg from '../../../assets/images/3/right_circle1.png';
import leftCircleImg from '../../../assets/images/3/left_circle1.png';
// import "@/assets/css/exchange.less";
import '@/assets/scss/exchange.scss';
import '@/assets/scss/trade_history.scss';
// import "@/assets/css/trade-history.css";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: 'exchange',
  components: {},
  data() {
    return {
      product: 'btcusdt',
      currencyUrl: '',
      toFixed: toFixed,
      bgImg: bgImg,
      holdImg: holdImg,
      buyUpImg: buyUpImg,
      buyDownImg: buyDownImg,
      loadingImg: loadingImg,
      rightCircleImg: rightCircleImg,
      leftCircleImg: leftCircleImg,
      user: [],
      balance: 0,
      productInfo: [],
      yuqi: 0,
      orderShouyi: 0,
      amountBox: 0,
      inputAmount: { val: '' },
      otherAmount: { val: '' },
      payChooseFirst: 0,
      noMoney: false,
      protimeChosen: '',
      loadingAddOrder: false,
      orderSen: 0,
      orderType: '',
      newPrice: 0,
      selltime: 0,
      sell_time_: 0,
      orderData: [],
      yuce: 0,
      timer: null,
      timerOrderlist: null,
      resorderlist: [],
      ftime_: 0,
      currency: getLocal('currency') || 'RM',
      socketK: {},
      haurl: huobiWsUrl,
      orderdetail: null,
      baodi: 0,
    };
  },
  created() {
    let params = this.$route.query.product;
    this.product = params || 'NGS';

    this.currencyUrl = ''; // "/static/kline.html?product=" + this.product;
    if (this.currency === 'USD') this.currency = '$';
  },
  mounted() {},
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timerOrderlist);
    document.querySelector('body').style.overflowY = 'scroll';
  },
  beforeMount() {
    let token = getLocal('token') || '';
    if (token) this.get_user_init();

    this.get_product_init();

    document.querySelector('body').style.overflowY = 'hidden';
  },
  computed: {
    ...mapState({
      setCoin: (state) => state.setCoin,
    }),
  },
  watch: {},
  methods: {
    changeProduct(id) {
      this.product = id;
      //alert('change product'+id);
      let token = getLocal('token') || '';
      if (token) this.get_user_init();

      this.get_product_init();

      document.querySelector('body').style.overflowY = 'hidden';
    },
    get_user_init() {
      this.balance = 0;
      this.$http('', '/home/account/basic', [], 'post')
        .then((result) => {
          // console.log(result.code)
          if (result.code == '0001') {
            this.user = result.data;
            this.balance = this.user.credit2 || null;
          } else if (result.code == '111') {
            // console.log(123)
            this.$toast.error(result.message);
            setTimeout(() => {
              this.$router.push('/login');
            }, 500);
          }
        })
        .catch((e) => {
          console.log(e, 'get_user_init');
        });
    },
    get_product_init() {
      const obj = {
        procode: this.product.toLowerCase(),
      };
      this.$http('', '/home/api/product_details', obj, 'post')
        .then((result) => {
          console.log(result)
          if (result.code == '0001') {
            this.productInfo = result.data;
            this.orderShouyi = this.productInfo.proscale[0];
            this.amountBox =
              this.finalAmount =
              this.payChooseFirst =
              this.inputAmount.val =
                this.productInfo.pay_choose[0];
            this.noMoney = this.balance < this.payChooseFirst;
            ``;
            this.protimeChosen = this.productInfo.protime[0];
            this.newPrice = this.productInfo.Price;
            this.orderSen = this.protimeChosen * 60;
            this.yuqi = (
              (1 + this.orderShouyi * 0.01) *
              this.inputAmount.val
            ).toFixed(2);
            this.baodi = (
              (1 - this.orderShouyi * 0.01) *
              this.inputAmount.val
            ).toFixed(2);

            this.currencyUrl = this.productInfo.kline_url;
            console.log(this.currencyUrl, 'currencyUrl');
            // if (this.productInfo.cid === 5) {
            //   this.currencyUrl = "/static/kline_forex.html?product=" + this.product;
            // } else {
            //   this.currencyUrl = "/static/kline.html?product=" + this.product;
            // }
            this.handleInitWebsoket();
          }
        })
        .catch((e) => {
          console.log(e, 'get_product_init');
        });
    },
    handleInitWebsoket() {
      var self = this;

      self.socketK = new WebSocket(self.haurl);
      self.socketK.onopen = function () {
        self.socketK.send(JSON.stringify({ msg: 'Exchange Connected' }));
      };
      self.socketK.onclose = function (event) {
        console.log(event, 'event close');
      };
      self.socketK.onerror = function (event) {
        console.log(event, 'event error');
      };
      self.socketK.onmessage = function (event) {
        // console.log(event, 'event')

        if (typeof event.data !== 'undefined') {
          let blob = JSON.parse(event.data);

          if (typeof blob.type !== 'undefined' && blob.type === 'ping') {
            self.socketK.send(JSON.stringify({ pong: blob.type }));
          } else {
            let req =
              'market.' + self.productInfo.procode.toLowerCase() + '.ticker';

            // console.log(req, blob.action);

            if (req === blob.action) {
              // console.log(blob.data.tick, 'data')
              if (typeof blob.data.tick !== 'undefined') {
                let data = blob.data.tick;
                // console.log(data.price, data)
                self.newPrice = data.price;
              }
            }
          }
        }
      };
    },
    handleAmountBox(amount) {
      this.amountBox = amount;
      if (amount == 'all')
        amount = Math.round(
          this.balance * ((100 - this.productInfo.web_poundage) / 100),
          4
        );
      this.inputAmount.val = amount;
      const orderPrice = amount;
      this.otherAmount.val = '';

      this.noMoney = this.balance < orderPrice;
      this.yuqi = ((1 + this.orderShouyi * 0.01) * orderPrice).toFixed(2);
      this.baodi = ((1 - this.orderShouyi * 0.01) * orderPrice).toFixed(2);
    },
    handleOtherAmount() {
      this.noMoney =
        parseFloat(this.balance) < parseFloat(this.otherAmount.val);
      const orderPrice = this.otherAmount.val;
      this.inputAmount.val = this.otherAmount.val;

      this.yuqi = ((1 + this.orderShouyi * 0.01) * orderPrice).toFixed(2);
      this.baodi = ((1 - this.orderShouyi * 0.01) * orderPrice).toFixed(2);
    },
    handlePeriodWidget(item, proscale, sen) {
      this.protimeChosen = item;
      this.orderSen = sen;
      this.orderShouyi = proscale;
      const orderPrice = this.inputAmount.val;
      this.yuqi = ((1 + this.orderShouyi * 0.01) * orderPrice).toFixed(2);
      this.baodi = ((1 - this.orderShouyi * 0.01) * orderPrice).toFixed(2);
    },
    handleAddOrder() {
      if (this.loadingAddOrder) return;

      this.loadingAddOrder = true;

      const orderPrice = this.inputAmount.val * 1;
      const orderMinPrice = this.productInfo.order_min_price * 1;
      const orderMaxPrice = this.productInfo.order_max_price * 1;

      if (this.user === []) {
        this.$toast.error(this.$t('exchange_login_required'));
        this.loadingAddOrder = false;
        return;
      }

      if (orderPrice > this.balance) {
        this.$toast.error(this.$t('exchange_balance_err'));
        this.loadingAddOrder = false;
        return;
      }
      if (orderPrice < orderMinPrice) {
        this.$toast.error(this.$t('exchange_balance_min_err') + orderMinPrice);
        this.loadingAddOrder = false;
        return;
      }
      if (orderPrice > orderMaxPrice) {
        this.$toast.error(this.$t('exchange_balance_max_err') + orderMaxPrice);
        this.loadingAddOrder = false;
        return;
      }
      // console.log(this.orderShouyi)
      const obj = {
        order_sen: this.orderSen,
        order_pid: this.productInfo.pid,
        order_type: this.orderType,
        newprice: this.newPrice,
        order_price: this.inputAmount.val,
      };

      this.$http('', '/home/api/addorder', obj, 'post')
        .then((result) => {
          // console.log(result)
          if (result.code == '0001') {
            this.toggle_order_close_panel();
            document
              .querySelector('.order_mengban')
              .classList.add('glass_mask');
            document.querySelector('.paysuccess').classList.add('d-none');

            document.querySelector('.order-state-panel').classList.add('open');
            document
              .querySelector('.order-state-panel')
              .classList.remove('d-none');
            document
              .querySelector('.order-state-panel .wait')
              .classList.remove('d-none');

            //倒计时
            document.querySelector('.pay_order_sen').innerHTML = formatSeconds2(
              result.data.endprofit
            );
            document
              .querySelector('.img_circle_right')
              .setAttribute(
                'style',
                '-webkit-animation: run ' + result.data.endprofit + 's linear;'
              );
            document
              .querySelector('.img_circle_lift')
              .setAttribute(
                'style',
                '-webkit-animation: runaway ' +
                  result.data.endprofit +
                  's linear;'
              );

            //下方提示
            if (result.data.ostyle == 0) {
              document.querySelector('.pay_order_type').innerHTML =
                this.$t('exchange_buyUp');
              document.querySelector('.pay_order_type').classList.add('rise');
              document
                .querySelector('.pay_order_type')
                .classList.remove('fall');
            } else {
              document.querySelector('.pay_order_type').innerHTML =
                this.$t('exchange_buyFall');
              document.querySelector('.pay_order_type').classList.add('fall');
              document
                .querySelector('.pay_order_type')
                .classList.remove('rise');
            }

            // //document.querySelector('.order-state-panel').hide();
            document
              .querySelector('.order-state-panel .wait')
              .classList.add('d-none');

            document.querySelector('.pay_order_price').innerHTML =
              result.data.fee;
            document.querySelector('.pay_order_buypricee').innerHTML = toFixed(
              result.data.buyprice,
              4
            );

            document
              .querySelector('.order-state-panel .wait')
              .classList.add('d-none');
            document
              .querySelector('.order-state-panel .paysuccess')
              .classList.remove('d-none');
            document
              .querySelector('.order-state-panel .paysuccess')
              .classList.add('success');
            //余额
            document.querySelector('.pay_mymoney').innerHTML =
              result.data.commission;
            //转盘倒计时

            this.selltime = result.data.selltime;
            this.orderData = result.data;
            document.querySelector('.paysuccess').classList.remove('d-none');
            this.sell_time_ = this.orderData.selltime - this.orderData.buytime;
            var that = this;
            this.timer = setInterval(function () {
              that.endtimes();
            }, 1000);

            this.$toast.success(result.message);
          } else {
            /*if (result.code == '2001') {
                    this.$toast.error(this.$t('exchange_order_max_price_fee', {amt: result.message}));
                } else if (result.code == '2002') {
                    this.$toast.error(this.$t('exchange_order_min_price', {amt: result.message}));
                } else if (result.code == '2003') {
                    this.$toast.error(this.$t('exchange_order_max_price', {amt: result.message}));
                } else {*/
            this.$toast.error(result.message);
            // }
          }
          this.loadingAddOrder = false;
        })
        .catch((e) => {
          console.log(e, 'handleAddOrder');
          this.loadingAddOrder = false;
        });
    },
    toggle_order_confirm_panel(type) {
      if (typeof this.user.credit2 === 'undefined') this.get_user_init();

      var order_type, typename;

      const up = this.$t('exchange_buyUp');
      const down = this.$t('exchange_buyFall');

      if (type == 'lookup') {
        typename = up;
        order_type = 0;

        document.querySelector('.order_type').classList.remove('fall');
        document.querySelector('.order_type').classList.add('rise');
      } else {
        typename = down;
        order_type = 1;

        document.querySelector('.order_type').classList.add('fall');
        document.querySelector('.order_type').classList.remove('rise');
      }

      this.orderType = order_type;
      document.querySelector('.order_type').innerHTML = typename;
      document.querySelector('.pro_mengban').classList.add('glass_mask');
      document.querySelector('.order-confirm-panel').classList.add('open');

      if ($('.amount-box').length > 1) {
        var totalWidth =
          $('.amount-box')[1].clientWidth * $('.amount-box').length + 100;
        $('.amount-view').css('width', totalWidth + 'px');

        totalWidth =
          $('.period-widget')[1].clientWidth * $('.period-widget').length + 100;
        $('.period-widget-view').css('width', totalWidth + 'px');
      }
    },
    toggle_order_close_panel() {
      document.querySelector('.pro_mengban').classList.remove('glass_mask');
      document.querySelector('.order-confirm-panel').classList.remove('open');
      this.loadingAddOrder = false;
    },
    //转盘倒计时
    endtimes() {
      var timestamp = Date.parse(new Date());
      timestamp = timestamp / 1000;
      this.sell_time_--;
      var newsen = this.sell_time_;
      // console.log(newsen, 'newsen')
      if (newsen <= 360) {
        document.querySelector('.pay_order_sen').innerHTML = newsen;
      } else {
        document.querySelector('.pay_order_sen').innerHTML =
          formatSeconds2(newsen);
      }

      // var old_price = document.querySelector('.data-price').innerHTML;
      var yuce_case = 0;
      if (this.orderData.buyprice * 10 < this.newPrice * 10) {
        document.querySelector('.data-price').classList.remove('fall');
        document.querySelector('.data-price').classList.add('rise');

        if (this.orderData.ostyle == 0) {
          //买涨
          yuce_case =
            this.orderData.fee * 1 +
            (this.orderData.fee * this.orderData.endloss) / 100;
          document.querySelector('.yuce').classList.remove('fall');
          document.querySelector('.yuce').classList.add('rise');
        } else {
          yuce_case =
            this.orderData.fee -
            (this.orderData.fee * this.orderData.endloss) / 100;
          document.querySelector('.yuce').classList.remove('rise');
          document.querySelector('.yuce').classList.add('fall');
        }
      } else if (this.orderData.buyprice * 10 > this.newPrice * 10) {
        document.querySelector('.data-price').classList.add('fall');
        document.querySelector('.data-price').classList.remove('rise');

        if (this.orderData.ostyle == 0) {
          //买涨
          yuce_case =
            this.orderData.fee -
            (this.orderData.fee * this.orderData.endloss) / 100;
          document.querySelector('.yuce').classList.remove('rise');
          document.querySelector('.yuce').classList.add('fall');
        } else {
          yuce_case =
            this.orderData.fee * 1 +
            (this.orderData.fee * this.orderData.endloss) / 100;
          document.querySelector('.yuce').classList.remove('fall');
          document.querySelector('.yuce').classList.add('rise');
        }
      } else {
        yuce_case = this.orderData.fee;
      }
      this.yuce = yuce_case;

      if (newsen <= 0) {
        document.querySelector('.paysuccess').classList.add('d-none');
        document.querySelector('.paysuccess').classList.remove('success');
        document
          .querySelector('.order-state-panel .wait')
          .classList.remove('d-none');
        //请求检测订单
        this.get_this_order();
        clearInterval(this.timer);
        //go order
        // goorder();
      }
    },
    close_order() {
      clearInterval(this.timer);
      document.querySelector('.order_mengban').classList.remove('glass_mask');
      document.querySelector('.order-state-panel').classList.add('d-none');
      document.querySelector('.ordersuccess').classList.remove('success');
      document.querySelector('.ordersuccess').classList.add('d-none');
      document.querySelector('.order_fail').classList.add('d-none');
    },
    continue_order() {
      this.close_order();
      var _type = 'lookdown';
      if (this.orderType == 0) {
        _type = 'lookup';
      }
      this.toggle_order_confirm_panel(_type);
    },
    toggle_history_order_panel() {
      if (typeof this.user.credit2 === 'undefined') this.get_user_init();
      var type = document
        .querySelector('.history-panel')
        .getAttribute('ng-include');
      if (type == 1) {
        //ajax order
        var obj = {
          pid: this.productInfo.pid,
          ostaus: 0,
        };
        this.$http('', '/home/api/orderlist2/', obj, 'post')
          .then((result) => {
            if (result.code == '0001') {
              this.resorderlist = result.data.data;
              // console.log(result.time)

              if (this.resorderlist.length >= 1) {
                // this.ftime_ = this.resorderlist[0]['time'];
                // console.log(result.time)
                this.ftime_ = result.time;
              } else {
                var timestamp = Date.parse(new Date());
                this.ftime_ = timestamp / 1000;
              }

              var that = this;
              this.timerOrderlist = setInterval(function () {
                that.show_order_list();
              }, 1000);
              document.querySelector('.history-panel').style.top = '0';
              // document.querySelector('.history-panel').style.bottom = '10%';
              document
                .querySelector('.history-panel')
                .setAttribute('ng-include', 0);
            }
          })
          .catch((e) => {
            console.log(e, 'toggle_history_order_panel');
          });
      } else {
        document.querySelector('.history-panel').style.top = '500%';
        // document.querySelector('.history-panel').style.bottom = '100%';
        document.querySelector('.history-panel').setAttribute('ng-include', 1);
        clearInterval(this.timer_orderlist);
      }
    },
    show_order_list() {
      var html = '';

      if (this.resorderlist.length == 0) {
        document.querySelector('.trade_history_list ul').innerHTML = '';
        return false;
      }

      this.ftime_++;
      var k;
      var len = this.resorderlist.length;
      for (k = 0; k < len; k++) {
        var v = this.resorderlist[k];
        if (typeof v === 'undefined') return;
        // console.log(v, 'v')
        // console.log(ftime_);
        var timestamp = Date.parse(new Date());
        var _end_time = v.selltime - this.ftime_;
        var baifenbi = (_end_time / v.endprofit) * 100;
        // console.log(this.ftime_, _end_time);
        if (_end_time > 0) {
          var chaprice = this.newPrice - v.buyprice;
          var closeprice = 0;
          var closeprice_class = '';
          var ostyle_class, ostyle_class2, ostyle_name;
          if (v.ostyle == 0) {
            ostyle_class = 'buytop';
            ostyle_class2 = 'in_money';
            ostyle_name = this.$t('exchange_buyUp');
            if (chaprice > 0) {
              closeprice = (v.fee * (100 * 10 + v.endloss * 10)) / 1000;
              closeprice_class = 'in_money';
            } else if (chaprice < 0) {
              closeprice = (v.fee * (100 - v.endloss)) / 100;
              closeprice_class = 'out_money';
            } else {
              closeprice = v.fee;
              closeprice_class = '';
            }
          } else {
            ostyle_class = 'buydown';
            ostyle_name = this.$t('exchange_buyFall');
            ostyle_class2 = 'out_money';

            if (chaprice < 0) {
              closeprice = (v.fee * (100 * 10 + v.endloss * 10)) / 1000;
              closeprice_class = 'in_money';
            } else if (chaprice > 0) {
              closeprice = (v.fee * (100 - v.endloss)) / 100;
              closeprice_class = 'out_money';
            } else {
              closeprice = v.fee;
              closeprice_class = '';
            }
          }

          html +=
            '<li class="">\
                            <section>\
                                <p style="margin: 0;font-size:12px;line-height:15px;">\
                                    <span class="">' +
            v.ptitle +
            '</span>\
                                    <span" class=" ' +
            ostyle_class2 +
            '"><i class="' +
            ostyle_class +
            '"></i>' +
            ostyle_name +
            ' </span>\
                                </p>\
                                <p style="margin: 0;font-size:12px;line-height:15px;" class="">\
                                    ' +
            v.buyprice +
            '-<span  class="newprice ' +
            closeprice_class +
            '">' +
            toFixed(this.newPrice, 4) +
            '</span>\
                                </p>\
                                <p style="margin: 0;font-size:12px;line-height:15px;" class="">' +
            getLocalTime(v.buytime) +
            '</p>\
                            </section><section>\
                                <p style="margin: 0;font-size:12px;line-height:15px;" class=" ' +
            closeprice_class +
            '"><span style="font-size:14px;">' +
            this.$t('exchange_prospective_result') +
            ': </span> <br/>' +
            closeprice +
            this.currency +
            '</p>\
                                <p style="margin: 0;font-size:12px;line-height:15px;" class="">' +
            formatSeconds2(_end_time) +
            '</p>\
                            </section>\
                            <article class="">\
                            <span class="move_width" style="width: ' +
            baifenbi +
            '%; transition-duration: 1s;">\
                            </span>\
                            <i>\
                                <em></em>\
                            </i>\
                            </article>\
                        </li>';

          document.querySelector('.trade_history_list ul').innerHTML = html;
        } else {
          this.resorderlist.splice(k, 1);
        }
      }
    },
    get_this_order() {
      if (
        document
          .querySelector('.order-state-panel')
          .getAttribute('class')
          .indexOf('open') === -1
      ) {
        return;
      }
      var self = this;
      var obj = { oid: this.orderData.id };
      this.$http('', '/home/api/orderdetail/', obj, 'post')
        .then((result) => {
          if (result.code == '0001') {
            this.orderdetail = result.data;
            var resdata = this.orderdetail;
            // console.log(this.orderdetail)
            if (this.orderdetail.ostaus == 0) {
              setTimeout(function () {
                self.get_this_order();
              }, 1000);
              return;
            }

            document
              .querySelector('.order-state-panel .wait')
              .classList.add('d-none');
            document.querySelector('.ordersuccess').classList.remove('d-none');
            document.querySelector('.ordersuccess').classList.add('success');

            if (resdata.is_win == 1) {
              document.querySelector('.result_profit').classList.add('rise');
              document.querySelector('.result_profit').classList.remove('fall');
              var _ploss = (resdata.ploss * 10 + resdata.fee * 10) / 10;
              document.querySelector('.result_profit').innerHTML =
                `${this.currency}` + _ploss.toFixed(3);

              document.querySelector('.endprice').classList.add('rise');
              document.querySelector('.endprice').classList.remove('fall');
            } else if (resdata.is_win == 2) {
              document.querySelector('.result_profit').classList.add('fall');
              document.querySelector('.result_profit').classList.remove('rise');
              document.querySelector('.result_profit').innerHTML =
                `${this.currency}` + resdata.ploss.toFixed(3);
              document.querySelector('.endprice').classList.add('fall');
              document.querySelector('.endprice').classList.remove('rise');
            } else {
              document.querySelector('.result_profit').classList.remove('rise');
              document.querySelector('.result_profit').classList.remove('fall');
              document.querySelector('.result_profit').innerHTML =
                `${this.currency}` + resdata.ploss.toFixed(3);
              document.querySelector('.endprice').classList.remove('rise');
              document.querySelector('.endprice').classList.remove('fall');
            }
            if (resdata.ostyle == 0) {
              document.querySelector(
                '.order-state-panel .ordersuccess .pay_order_type'
              ).innerHTML = this.$t('exchange_buyUp');
              document
                .querySelector(
                  '.order-state-panel .ordersuccess .pay_order_type'
                )
                .classList.add('rise');
              document
                .querySelector(
                  '.order-state-panel .ordersuccess .pay_order_type'
                )
                .classList.remove('fall');
            } else {
              document.querySelector(
                '.order-state-panel .ordersuccess .pay_order_type'
              ).innerHTML = this.$t('exchange_buyFall');
              document
                .querySelector(
                  '.order-state-panel .ordersuccess .pay_order_type'
                )
                .classList.add('fall');
              document
                .querySelector(
                  '.order-state-panel .ordersuccess .pay_order_type'
                )
                .classList.remove('rise');
            }
            document.querySelector('.order-state-panel .endprice').innerHTML =
              this.currency + resdata.sellprice.toFixed(2);
          }
        })
        .catch((e) => {
          console.log(e, 'get_this_order');
        });
    },
  },
};
</script>
<style lang="less" scoped>
iframe {
  width: 100%;
  height: 500px;
}
// .expect_profit, .period_footer, .no-money {
.expect_profit,
.no-money {
  display: none !important;
}
.period-widget {
  // height: 75px;
}
.scroll-x .scroll {
  overflow-y: hidden;
}
.glass_mask {
  z-index: 9999;
}
</style>
