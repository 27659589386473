<template>
	<base-layout3 :page-title="$t('profile_password_change_secpassword')" :show-logo="false" :showBackButton=true mode="with-logo">
		<div class="container pt-4">
				<div class="main-container container">
					<!--<h3 class="section-title">{{$t('profile_password_change_password')}}</h3>-->

					<form @submit.prevent="submitPassword">
						<ul class="menu_listing">
							<li>
								<div class="menu_item">{{$t('profile_password_old_secpassword_text')}}</div>
								<div class="col-7">
									<input type="password" class="form-control b-none" :placeholder="$t('profile_password_old_secpassword')" v-model="old_password.val"
										@blur="clearValidity('old_password')"
										/>
										<div v-if="!old_password.isValid" class="error_message p10">
											{{ $t("profile_password_old_secpassword") }}
										</div>
								</div>
							</li>
							<li>
								<div class="menu_item">{{$t('profile_password_new_secpassword_text')}}</div>
								<div class="col-7">
									<input type="password" class="form-control b-none" :placeholder="$t('profile_password_new_secpassword')" v-model="password.val"
										@blur="clearValidity('password')"
										/>
										<div v-if="!password.isValid" class="error_message p10">
											{{ $t("profile_password_new_secpassword") }}
										</div>
								</div>
							</li>
							<li>
								<div class="menu_item">{{$t('profile_password_reenter_secpassword_text')}}</div>
								<div class="col-7">
									<input type="password" class="form-control b-none" :placeholder="$t('profile_password_reenter_secpassword')" v-model="password_confirm.val"
										@blur="clearValidity('password_confirm')"
										/>
										<div v-if="!password_confirm.isValid" class="error_message p10">
											{{ $t("profile_password_reenter_secpassword") }}
										</div>
								</div>
							</li>
						</ul>

						<div class="largeButtonContainer mt-3">
							<base-button mode="default-btn" type="submit" class="largeGoldButton opacity-unset" @click="submitPassword()">{{$t('account_review_submit')}}</base-button>
						</div>
					</form>
				</div>
		</div>
	</base-layout3>
</template>

<script>
import BaseLayout3 from '@/components/UI/themes/theme1/BaseLayout3.vue';
export default {
	name: "login",
	components: {BaseLayout3},
	data() {
		return {
			lock:0,
			old_password: {
				val: "",
				isValid: true,
			},
			password: {
				val: "",
				isValid: true,
			},
			password_confirm: {
				val: "",
				isValid: true,
			},
			formIsValid: true,
		};
	},
	mounted() {},
	created() {},
	updated() {},
	unmounted() {},
	methods: {
		clearValidity(input) {
			this[input].isValid = true;
		},
		validateForm() {
			this.formIsValid = true;
			if (this.old_password.val === "") {
				this.old_password.isValid = false;
				this.formIsValid = false;
			}
			if (this.password.val === "") {
				this.password.isValid = false;
				this.formIsValid = false;
			}
			if (this.password_confirm.val === "") {
				this.password_confirm.isValid = false;
				this.formIsValid = false;
			}
		},
		submitPassword() {
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}

			let obj = {
				old_password  : this.old_password.val,
				password: this.password.val,
				password_confirm : this.password_confirm.val,
                is_secpass: 1
			};
			// return console.log(1)

			this.$http("", "/home/profile/password.html", obj,'post')
				.then((result) => {
					if(result.code == "0001"){
						this.$toast.success((result.message));
						// setTimeout(() => {
						// 	this.$store.commit("deleteSession");
						// 	this.$router.push('/login');
						// }, 1000);
					} else {
						this.$toast.error(result.message);
						this.lock = 0;
					}
				})
				.catch((e) => {
						console.log(e);
						this.lock = 0;
				});


		},
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
ul.menu_listing .menu_item {
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.menu_listing input.form-control{
	color: $yellow-500;
	box-shadow: 0 0 5px $yellow-500;
}
ul.menu_listing li {
    display: flex;
    color: $white;
    border-bottom: solid 1px $black-300;
    padding: 18px 0;
    justify-content: space-between;
}
.b-none {
    border: none!important;
}
.form-control, .form-control:focus {
    color: $white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $gray-600;
    border-radius: 0;
    padding: 10px 10px 10px 10px;
    box-shadow: none;
}
.p10{
	margin: 0 0 0 10px;
}
.error_message{
	color: map-get($theme-colors, 'danger');
	font-size: 12px;
	margin: 5px auto 0 0;
}
</style>
