<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { isMobile } from "@/plugin/isMobile.js";
import { getLocal } from "@/utils/local.js";
export default {
  name: "Notification",
  data() {
    return {
      data: [],
      category: [],
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
      page: 1,
      currency: getLocal("currency") || "RM",
      img_url: process.env.VUE_APP_IMAGE_URL,
    };
  },
  methods: {
    push_content(url, id) {
      this.$router.push(url + "?id=" + id);
    },
    getdata(sw) {
      if (sw) {
        this.data = [];
        this.page = 1;
        $(".notMore").css("display", "none");
      }

      var data = {};
      var u = "/home/api/plan_list";

      this.$http("", u + "?page=" + this.page, data, "post").then((result) => {
        if (result.code == "0001") {
          console.log(result.data);
          this.data = _.union(this.data, result.data);
          this.current_page = result.data.current_page || 1;
          this.last_page = result.data.last_page || 1;
          this.per_page = result.data.per_page || 1;
          this.total = result.data.total || 10;
          // this.data         = result.data.data;
          // console.log(this.data)
          if (_.size(result.data) == 0) {
            $(".notMore").css("display", "block").html(this.$t("no_more"));
          }
        }
      });
    },
    checkNull(item) {
      if (_.isUndefined(item) || item == null || _.size(item) == 0)
        return false;

      return true;
    },
  },
  beforeMount() {
    let token = getLocal("token") || "";
    if (!token) this.$router.push("/login");
    // console.log(token)
    this.getdata(false);
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,
body {
  height: 100%;
  margin: auto;
  overflow: initial;
  color: $black-100;
  font-size: 14px;
  /* background: #212121; */
}
.purchase_card{
	box-shadow: 0 0 2px rgba($yellow-100, 0.655);
        flex-direction :column;
        display:block;
        text-align: center;
}
.sub-mission-text2 {
  color: $gray-500 !important;
  font-size: 14px;
  margin-top: auto;
}
.selected_cat {
  background: $orange !important;
  color: $white !important;
  padding-left: 5px;
  padding-right: 5px;
}
.outline-sd {
  box-shadow: 0px 0px 6px rgba($black-100, 0.161) !important;
  border-radius: 10px !important;
}
.movie-title {
  font-size: 17px;
  color: $black-300;
  font-weight: 700;
  padding-right: 5px;
  flex-shrink: 1;
}
.grade {
  font-weight: 700;
  color: $yellow-500;
  font-size: 15px;
}
.detail {
  line-height: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction:column;
}
.line-ellipsis span:first-child{
  color: $gray-400;
}
.line-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ml-7 {
  margin-left: 7px;
}
.active_btm {
  width: 20px;
  border-bottom: 2px solid map-get($theme-colors, 'danger');
  margin-top: 5px;
}
.flex-box-center-column {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.flex-box-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.rank-text {
  font-size: 40px;
  font-weight: bold;
  color: $gray-500;
}
p {
  color: $gray-500;
}
.up-case {
  text-transform: uppercase;
}
.fs12 {
  font-size: 12px !important;
}
</style>

<template>
  <base-layout3
    :page-title="$t('plan_title')"
    :show-back-button="true"
    :show-footer="true"
    mode=""
    style="padding-bottom: 100px"
  >
    <div class="">
      <h3 class="section-title">
        <span>{{ $t("plan_buy") }}</span>
        <base-button
          mode="default-btn"
          type="button"
          class="btn buy-btn text-white"
          style="/*background: #fa6b0b;*/ float: right; margin: unset; width: auto;"
          @click="push_content('/plan/orderlist', null)"
        >
          {{ $t("plan_order_details") }}
        </base-button>
      </h3>
      <div class="container mt-3">
        <div class="row row-cols-2 g-2">
          <div class="col my-2" v-for="(item, k) in data" :key="k">
            <base-card class="purchase_card">
              <img
                class="img-fluid mx-auto"
                :src="img_url + '/uploads/' + item.img"
                :alt="item.title"
                style="border-radius: 5px"
              />
              <div class="movie-title mx-auto">
                <div
                  class="movie-title line-ellipsis v2dimax_title up-case grade mt-2"
                >
                  {{ item.title }}
                </div>
                <span class="version v2d imax"></span>
              </div>
              <div class="mt-1 detail">
                <div class="score line-ellipsis">
                  <span>{{ $t('plan_day_no') }}: </span><br>
                  <span class="fs12 grade">{{ item.day + " " }}</span>
                  <span class="fs12 score-suffix text-white"
                    >{{ $t("plan_days") }}
                  </span>
                </div>
                <div class="score line-ellipsis">
                  <span>{{ $t('plan_payback') }}: </span><br>
                  <span class="fs12 grade">{{ "+ " + item.rate }}% </span>
                  <span class="fs12 score-suffix text-white"
                    >{{ $t("plan_intrest") }}
                  </span>
                </div>
                <div class="score line-ellipsis">
                  <span>{{ $t('plan_details_amt') }}: </span><br>
                  <span class="fs12 grade"
                    >{{ +item.min }} - {{ +item.max + " " }}
                  </span>
                  <span class="fs12 score-suffix text-white"
                    >
                  </span>
                </div>
              </div>
              <base-button
                mode="purchase-btn"
								class="w-100 mt-3"
                @click="push_content('/plan/details', item.id)"
              >
                {{ $t("plan_buy") }}
              </base-button>
            </base-card>
          </div>
        </div>
      </div>
    </div>
  </base-layout3>
</template>
