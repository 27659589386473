<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
export default {
  name: "login",
  components: {},
  data() {
    return {
      data: {},
      lock: 0,
      balance: 0,
      id: null,
      amt: {
        val: '',
        isValid: true,
      },
      currency: getLocal("currency") || "RM",
    };
  },
  created() {},
  updated() {},
  unmounted() {},
  beforeMount() {
    let token = getLocal("token") || "";
    this.token = token;
    this.id = this.$route.query.id;
    if (!token && !this.id) {
      this.$router.push("/");
    }

    this.getdetails();
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    redirect(url) {
      this.$router.push(url);
    },
    validateForm() {
      this.formIsValid = true;
      if (this.amt.val === "" || this.amt.val === 0  || this.amt.val == null) {
        this.amt.isValid = false;
        this.formIsValid = false;
      }
    },
    submitForm() {
      var that = this;
      this.validateForm();
      if (this.lock==1 ) {        
        return;
      }
      
      if (!this.formIsValid ) {
        this.lock = 0;    
        return;
      }
      var data = {
        id: this.data.id,
        money: this.amt.val,
      };
       this.lock = 1;
      this.$http("", "/home/api/plan_order", data, "post")
        .then((result) => {
          if (result.code == "0001") {
            this.$toast.success((result.message));
            setTimeout(() => this.$router.push("/plan/plan"), 1000);
          } 
         /* else if (result.code == 2001) {
           
            this.$toast.error(
              this.$t("plan_limit_time_invest") +
                " " +
                result.message +
                " " +
                this.$t("plan_mobile_times")
            );
          } else if (result.code == 2002) {
         
            this.$toast.error(
              this.$t("plan_limit_per_invest") +
                " " +
                result.message +
                " " +
                this.$t("plan_mobile_times")
            );
          }*/
           else {
          
            this.$toast.error((result.message));
          }
            this.lock = 0;
        })
        .catch((e) => {
          this.lock = 0;
          console.log(e);
        });
    },
    getdetails() {
      var that = this;
      var per_page = 12;
      var data = {
        l: this.id,
      };
      var a = this.$t("no_more");
      // console.log(a)
      this.$http(
        "",
        "/home/api/plan_detail.html?page=" + this.page,
        data,
        "post"
      ).then((result) => {
        if (result.code == "0001") {
          console.log(result.data);
          this.data = result.data;
          this.balance = result.balance;
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container {
  padding-bottom: 0;
}
.btn-select button.active {
  border: 1px solid $yellow-500;
  color: $yellow-500;
}
.btn-select button {
  position: relative;
  text-align: center;
  border: 1px solid $white;
  margin: 0 10px 10px 0;
  padding: 10px 10px;
  color: $white;
  border-radius: 12px;
  font-weight: bold;
}
.section-padding {
  padding: 20px 15px;
}
.recharge_amount {
  display: flex;
  font-size: 24px;
  color: $white;
  padding: 20px 0 0;
}
.recharge_amount .currency {
  width: 10%;
}
.recharge_amount input {
  width: 90%;
  margin-left: 20px;
  background: none;
  border: none;
  color: $yellow-500;
}
.recharge_name {
  display: flex;
  font-size: 18px;
  color: $white;
  padding: 20px 0;
}
.recharge_name .name {
  width: 20%;
}
.recharge_name input {
  width: 80%;
  margin-left: 20px;
  background: none;
  border: none;
  color: $yellow-500;
}
.termsandconditions {
  padding: 10px 0 20px;
}
label {
  display: inline-block;
}
.bottomMessage {
  padding: 40px 0;
  color: $yellow-500;
}

ul.details-list {
    background: rgba($black-500, 0.251);
    border-radius:6px;
    padding: 10px 15px;
}

ul.details-list > li {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
  /* border-bottom: solid 1px #333; */
  color: $white;
}

ul.details-list > li:nth-child(even){
  background: rgba($black-100,.45);
}

ul.details-list li > div:nth-child(2) {
  color: map-get($theme-colors, 'primary');
}

ul.details-list > li:last-child {
    flex-direction:column;
    background:transparent;
}

.listing-log .recharge .status-reject {
  color: map-get($theme-colors, 'danger');
}
.listing-log li {
  display: flex;
  color: $white;
  background: rgba($black-100, 0.6);
  border-bottom: dashed 1px $black-300;
  padding: 18px 30px;
  justify-content: space-between;
}
.listing-log .title {
  display: flex;
}
.listing-log .recharge {
  display: flex;
}
.listing-log .more {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.form-control,
.form-control:focus {
  color: $gray-200;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $brown-200;
  border-radius: 0;
  padding: 10px 10px 10px 10px;
  box-shadow: none;
  font-size:14px;
}

input::placeholder {
    color: $brown-200;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $brown-200;
}


.font-red {
  color: #ff0000;
}
</style>
<template>
  <base-layout3
    :page-title="$t('plan_details')"
    :show-back-button="true"
    :show-footer="false"
    mode=""
    style="padding-bottom: 100px"
  >
    <div class="withdraw">
      <div class="main-container" style="color: white">
        <div>
          <h3 class="section-title">
            {{ $t("plan_details") }} - {{ data.title }}
          </h3>
          <div class="section-padding">
            <ul class="details-list">
              <li>
                <div>{{ $t("plan_details_balance") }}</div>
                <div>{{ balance }}</div>
              </li>
              <li>
                <div>{{ $t("plan_details_cycle") }}</div>
                <div>{{ data.day }} {{ $t("plan_days") }}</div>
              </li>
              <li>
                <div>{{ $t("plan_details_interest") }}</div>
                <div>{{ data.rate }} %</div>
              </li>
              <li>
                <div>{{ $t("plan_details_min") }}</div>
                <div>{{ data.min }} {{ currency }}</div>
              </li>
              <li>
                <div>{{ $t("plan_details_max") }}</div>
                <div>{{ data.max }} {{ currency }}</div>
              </li>
              <!-- <li>
                <div style="width: 100%">{{ $t("plan_details_amt") }}</div>
                <div>
                  <input
                    type="text"
                    class="form-control text-end"
                    v-model="amt.val"
                    :placeholder="$t('plan_details_amt_enter')"
                    @blur="clearValidity('amt')"
                  />
                  <div v-if="!amt.isValid" class="font-red p10">
                    {{ $t("plan_details_amt_enter") }}
                  </div>
                </div>
              </li> -->
                  <li>
                      <div class="my-3">
              <label class="label text-primary fw-bold">{{ $t("plan_details_amt") }}</label>
              <input
                type="text"
                class="form-control text-start"
                v-model="amt.val"
                :placeholder="$t('plan_details_amt_enter')"
                @blur="clearValidity('amt')"
              />
              <div v-if="!amt.isValid" class="font-red p10">
                {{ $t("plan_details_amt_enter") }}
              </div>
            </div>

            <div class="largebtncontainer mt-3" @click="submitForm()">
              <base-button type="submit" mode="default-btn" class="font-16 w-100 fw-bold">
                {{ $t("plan_details_comfirm") }}
              </base-button>
			  <!-- <button type="submit" class="largeGoldButton">
                {{ $t("plan_details_comfirm") }}
              </button> -->
            </div>
              </li>
            </ul>

            
          </div>
        </div>
      </div>
    </div>
  </base-layout3>
</template>
