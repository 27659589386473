<script>
import { getLocal } from '@/utils/local.js';
import { ref } from 'vue';
import { _ } from 'vue-underscore';
export default {
	data() {
		return {
			qrcode: [],
			user: [],
			name: null,
			balance: 0,
			level: '',
			avatar: null,
			vipthum: null,
			currency: getLocal('currency') || 'RM',
			// appurl: getLocal('appurl') || '-',
			isiframe: false,
			is_bind: 0,
			status: 0,
			dialogIsVisible: false,
			recharge_btn : process.env.VUE_APP_RECHAGE_BTN,
			withdraw_btn : process.env.VUE_APP_WITHDRAW_BTN,
			team_btn : process.env.VUE_APP_TEAMPAGE||"0",
		};
	},
	setup() {
		const activeName = ref('1');
		return { activeName };
	},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal('token') || '';
		if (!token) {
			this.$router.push('/login');
		}

		if (token) this.get_user_init();
		// console.log(token)
	},
	methods: {
		closeiframe() {
			this.isiframe = false;
			this.issupport = false;
		},
		hideDialog() {
			this.dialogIsVisible = false;
		},
		checkNull(item) {
			if (_.isUndefined(item) || item == null || _.size(item) == 0)
				return false;

			return true;
		},
		// godownload() {
		//   // console.log("here"+this.appurl)
		//   this.isiframe = true;
		// },
		get_user_init() {
			this.balance = 0;
			this.$http('', '/home/account/index', [], 'post')
				.then((result) => {
					console.log('Result data:');
					console.log(result);
					if (result.code == '111') {
						this.$router.push('/login');
					}

					if (result.code == '0001') {
						this.user = result.data;
						var url =
							window.location.protocol + '//' + window.location.host + '/';
						// return console.log(url)
						this.qrcode =
							'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
							url +
							'/register.html?i=' +
							result.data.uid +
							'&choe=UTF-8';
						this.avatar = require('@/assets/images/avatar/' +
							this.user.member.avatar);
						this.vipthum =
							process.env.VUE_APP_IMAGE_URL +
							'/uploads/' +
							this.user.member_thumb;
						this.level = this.user.vip[this.user.member.level];
						this.name = this.user.member.realname || '-';
						this.balance = this.user.member.credit2 || null;
						this.status = this.user.member.status || 0;
						this.is_bind = this.user.member.is_bind || 0;

						this.credit_today = this.user.credit.today.credit2;
						this.withdraw = this.user.withdraw.credit2;
						this.weekly_income = this.user.weekly_income.credit2;
						this.monthly_income = this.user.monthly_income.credit2;
						this.lastmonth_income = this.user.lastmonth_income.credit2;
						this.total_credit = this.user.credit.total.credit2;

						// console.log(this.avatar);
					} else {
						// this.$toast.error(result.message);
					}
				})
				.catch((e) => {
					console.log(e);
					this.lock = 0;
					//this.$toast.error(e.message);
				});
		},
		refreshBalance() {
			this.balance = 0;
			this.$http('', '/home/account/basic', [], 'post')
				.then((result) => {
					console.log('Result basic:');
					console.log(result);
					if (result.code == '0001') {
						this.balance = result.data.credit2 || null;

						// console.log(result.data)
					} else {
						// this.$toast.error(result.message);
					}
				})
				.catch((e) => {
					console.log(e);
					this.lock = 0;
					//this.$toast.error(e.message);
				});
		},
		redirect(url) {
			setTimeout(() => this.$router.push(url), 200);
		},

		signUp() {
			var that = this;
			console.log(that.$t('c_no_login'));
			this.$toast.show(that.$t('c_no_login'));
			setTimeout(() => {
				this.$store.commit('deleteSession');
				this.redirect('/login');
			}, 500);
			return false;
		},
	},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

html,
body {
	background: url(~@/assets/images/3/bg.png) !important;
	background-size: cover;
	overflow-y: scroll !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.avatar-img {
	width: 90px;
}
.wallet-btn {
	padding: 5px 6px;
	border-radius: 30px;
	border: 1px solid $white;
	background: none;
	color: $white;
}
.person-bgs {
	// background-image: linear-gradient(to bottom, #ad54cf, #be61d0, #cd6ed1, #db7cd2, #e78bd5);
}
.o-tr-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161) !important;
	border-top-right-radius: 15px !important;
	border-top-left-radius: 15px !important;
	background: $yellow-500;
}
.flex-box-center {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}
.text-acc {
	text-align: right;
	display: block;
	font-weight: bolder !important;
	color: $blue;
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}
.o-bm-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161) !important;
	border-bottom-right-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
}
.outline-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161) !important;
	border-radius: 10px !important;
}
.vl {
	border-left: 4px solid $orange;
	height: 20px;
	padding-left: 5px !important;
}
.top-title {
	margin: 0;
	font-size: 14px;
	color: $black-300;
	margin-bottom: 12px;
}
.bold {
	font-weight: bold;
}

.activity-btn {
	border-radius: 15px !important;
	// box-shadow: 0px 0px 10px #00000029 !important;
	/* padding: 10px; */
	margin-bottom: 5px;
	// background: #fce9ea;
	// background: -moz-linear-gradient(top, #fce9ea 0%, #fdffff 100%);
	// background: -webkit-linear-gradient(top, #fce9ea 0%, #fdffff 100%);
	// background: linear-gradient(to bottom, #fce9ea 0%, #fdffff 100%);
	height: 45px;
	width: 45px;
}
.account {
	margin-top: -54px;
	height: 240px;
	.avatar {
		width: 70px;
		height: 70px;
		margin: 0 auto;
		> img {
			width: 100%;
		}
	}
	.container {
		.row {
			.col-6 {
				span {
					font-size: 14px;
					white-space: nowrap;
				}
			}
		}
	}
}
.setting {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	// top: -54px;
	// right: -12px;
	color: $white;
	padding: 10px 20px;
	font-size: 13px;
}
.index-menu {
	// padding: 20px;
	text-align: center;
	background: rgba($black-400, 0.396);
	border-radius: 12px;
	padding: 20px 0;

	.menu-item {
		text-decoration: none;
		p {
			margin-top: 15px;
			font-size: 12px;
		}
		img {
			width: 30px;
			height: 30px;
		}

		.text-primary {
			margin-top: 5px;
			margin-bottom: 0px;
			color: map-get($theme-colors, 'primary') !important;
		}
	}
}

.profile-curve-div {
	background: $black-500;
	position: absolute;
	width: 100%;
	height: 100px;
	top: 0;
	left: 0;
	z-index: -1;
}
.mobile {
	padding-bottom: 54px;
}

.profile_accordion_wrap {
	.profile_icon_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: baseline;
		width: 100%;
		.profile_icon_list_item {
			width: 23%;
			margin: 1%;
			img {
				width: 45px;
				height: 45px;
				display: block;
				margin: 0 auto 5px;
			}
			h3 {
				font-size: 12px;
				text-align: center;
				color: $white;
			}
		}
	}
	:deep().van-cell {
		background: transparent;
		padding: 10px;
		color: $white;
		border: solid $brown-200;
		border-width: 1px 0 0;
	}
	:deep().van-cell__title {
		font-weight: bold;
	}
	:deep().van-collapse-item__title--expanded {
		color: map-get($theme-colors, 'primary');
	}
	// /deep/.van-collapse-item:last-child .van-collapse-item__wrapper{
	//   border-bottom: 1px solid #766F63;
	//   border-width: 1px 0;
	// }
	:deep().van-cell:after {
		border-bottom: 0;
	}
	:deep().van-collapse-item__content {
		padding: 0;
	}
	:deep().van-collapse-item__content {
		background: transparent;
	}
	:deep().van-collapse-item--border:after {
		display: none;
	}
	:deep().van-hairline--top-bottom:after {
		border-width: 0;
	}
	:deep().van-cell__right-icon {
		color: $white;
	}
}

.profit-details-card {
	background: -webkit-linear-gradient(
		45deg,
		map-get($theme-gradient-colors, 'secondary')
	);
	background: linear-gradient(
		45deg,
		map-get($theme-gradient-colors, 'secondary')
	);
	position: relative;
	color: $white;
	border-radius: 5px;
	border: 1px solid $brown-200;
	box-shadow: 0 0px 12px rgba($brown-200, 0.5);
	margin-top: 12px;
	.profit-details-board {
		display: block;
		margin-top: -30px;
		width: 70%;
		border-radius: 50px;
		font-size: 16px;
		font-weight: bold;
		border: 0.2rem outset $brown-400;
		border-radius: 10px;
		background-image: linear-gradient(
			160deg,
			map-get($theme-gradient-colors, 'secondary')
		);
		color: map-get($theme-colors, 'primary');
	}
	.profit-details-card-content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px dashed $gray-400;
		.profit-details-info-left {
			p {
				font-size: 13px;
			}
		}
		.profit-details-info-right {
			p {
				font-size: 13px;
				color: $white;
				margin-bottom: 7px;
			}
			i {
				font-size: 30px;
				background: -webkit-linear-gradient(
					to bottom,
					map-get($theme-gradient-colors, 'primary')
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		img {
			width: 100px;
		}
	}
	.profits-box {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: flex-start;
		.profits_item {
			width: 31%;
			margin: 1%;
			text-align: center;
			position: relative;
			margin-bottom: 20px;
			padding: 0 1px;
			display: block;
			// flex-wrap: wrap;
			// justify-content: center;
			// align-items: flex-end;
			// align-content: flex-end;
			// column-count: 3;
			border: 1px solid transparent;
			height: 100%;
			span {
				display: block;
				font-size: 13px;
				color: map-get($theme-colors, 'primary');
			}
			span:first-child {
				color: $white;
				font-size: 11px;
				line-height: 11px;
				// height: 100%;
				height: 22px;
				width: 100%;
				margin-bottom: 10px;
				// flex: 1;
			}
			span:nth-child(2) {
				overflow-wrap: break-word;
			}
			&:not(:nth-child(3n)):after {
				content: '';
				right: -5px;
				top: 0;
				position: absolute;
				height: 100%;
				width: 1px;
				background: linear-gradient(
					to bottom,
					transparent,
					map-get($theme-colors, 'primary'),
					transparent
				);
			}
		}
	}
}

.order-detail1 {
	background: $black-400;
	// height: 100%;
	height: calc(100vh - 64px);
	position: fixed;
	width: 100%;
	// top: 54px;
	top: 0;
	left: 0;
	z-index: 1000;

	a.close {
		position: fixed;
		color: $white;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		width: 100%;
		background: rgba($black-100, 0.376);
		bottom: 0;
		padding: 20px;
	}
}

iframe {
	display: block; /* iframes are inline by default */
	border: none; /* Reset default border */
	height: calc(100vh - 54px);        /* Viewport-relative units */
	width: 100vw;
}


@media (min-width: 768px) {
	.account.container > img {
		margin-top: -120px !important;
	}
}

@media (min-width: 991px) {
	.account.container > img {
		margin-top: -200px !important;
	}
	.account {
		.avatar {
			margin: 0 30px 0 auto;
		}
	}
}
</style>
<style>
.bg-modal {
	background: url(~@/assets/images/promo-banner.png) center center;min-height:120px;
	background-size: cover;
	padding: 0 !important;
}
.btn-close{
	position: absolute;
	top: 5px;
	right: 5px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}
dialog {
  position: fixed;
  top: 1vh;
  width: 98%;
  height: 98%;
  margin: auto auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 12px;
  /* padding: 1rem; */
  /* background: linear-gradient(45deg, #ffffff, #aecdf9); */
  z-index: 100;
  border: none;
  display: flex;
  flex-direction: column;

  /* box-shadow: inset 5px 5px 10px #93c2ff, inset -5px -5px 10px #93c2ff; */
  /* animation: modal 0.3s ease-out forwards; */
}
dialog.full_modal {
  /* position: fixed; */
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 8px #8c87cb;
  border: solid 4px #6a7aa6;
  border-radius: 0;
  padding: 1rem;
  background-color: white;
  z-index: 1031;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}
.modal1 img{
    width: 100%!important;
}
.modal_body {
  position: relative;
  oveflow-y:auto;

}
.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
	opacity: 0;
	transform: translateY(-50px) scale(0.9);
  }

  to {
	opacity: 1;
	transform: translateY(0) scale(1);
  }
}

@media(min-width:1025px){
  dialog{
	width: 27%;
  }
}
</style>


<template>
	<base-layout3
		:page-title="$t('account_index')"
		:show-logo="false"
		:showBackButton="false"
		:showReloadButton="true"
		:show-nav="false"
		mode="with-logo"
		style="padding-bottom: 100px"
	>



			<div v-if="dialogIsVisible" class="backdrop" @click="$emit('hideDialog')"></div>
			<transition name="modal">
				<dialog open v-if="dialogIsVisible" :class="modal_mode"  style="height:auto">
					<div class="modal-header bg-modal">
						<button type="button" class="btn-close text-light" @click="hideDialog">X</button>
					</div>
					<div class="modal-body text-center" style="padding: 10px; overflow-y: auto;">
							<h4 class="mb-3">{{$t(dialogtext) }}</h4>
					</div>
					<div class="modal-footer p-0">
						<button type="button" class="btn btn-default text-dark"   @click="hideDialog">{{$t('common_close') }}</button>
					</div>
				</dialog>
			</transition>

		<div class="account p-0" style="position: relative">
			<div class="profile-curve-div"></div>
			<img
				class="position-absolute"
				src="@/assets/images/3/personal_bg.png"
				style="width: 100%; top: 100px; left: 0; z-index: -1"
			/>
			<div class="row">
				<div class="setting" @click="redirect('/account/settings')">
					<img
						src="@/assets/images/svg2/setting.svg"
						alt=""
						class="img"
						style="width: 20px; height: 20px"
					/>&nbsp;{{ $t('account_setting') }}
				</div>
			</div>
			<div class="container-fluid mt-3">
				<div
					class="row g-0"
					style="
						z-index: 10;
						margin-bottom: 15px;
						color: white;
						font-size: 12px;
					"
				>
					<div class="col-5 text-start">
						<div class="avatar">
							<img :src="avatar" class="img-fluid rounded-circle avatar-img" />
						</div>
					</div>
					<div class="col-7" style="margin-top: 9px">
						<div>{{ $t('account_welcome') }}</div>
						<!-- <br /> -->
						<strong style="font-size: 22px;overflow-wrap: break-word">{{ name }}</strong>
						<!-- <br /> -->
						<div>{{ $t('account_balance') }}:</div>
						<div>
							<span style="overflow-wrap: break-word">{{ this.balance }}</span>
							{{ currency }}
						</div>
						<div v-if="is_bind == 1">
							<a
								v-if="status == 1"
								style="color: #007aff; text-decoration: underline"
								>{{ $t('account_info_bounding') }}</a
							>
							<a
								v-if="status == 2"
								style="color: #007aff; text-decoration: underline"
								>{{ $t('account_info_bounded') }}</a
							>
						</div>
						<div v-if="is_bind == 0">
							<a
								v-if="status == 0"
								@click="redirect('/account/review')"
								style="color: #007aff; text-decoration: underline"
								>{{ $t('account_info_unbound') }}</a
							>
							<a
								v-if="status == 1"
								style="color: #007aff; text-decoration: underline"
								>{{ $t('account_info_bounding') }}</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="index-menu row">
			<a class="menu-item col-3" @click="redirect('/account/deposit')" v-if="recharge_btn =='1'">
				<img src="@/assets/images/3/recharge.png" />
				<p class="text-primary">{{ $t('index_recharge') }}</p>
			</a>
			<a class="menu-item col-3" @click="dialogIsVisible=true;dialogtext='account_recharge_1'" v-if="recharge_btn =='0'">
				<img src="@/assets/images/3/recharge.png" />
				<p class="text-primary">{{ $t('index_recharge') }}</p>
			</a>


			<a class="menu-item col-3" @click="redirect('/account/withdraw')" v-if="withdraw_btn =='1'">
				<img
					src="@/assets/images/3/withdraw.png"
				/>
				<p class="text-primary">{{ $t('index_withdraw') }}</p>
			</a>
			<a class="menu-item col-3" @click="dialogIsVisible=true;dialogtext='account_withdrawal_1'" v-if="withdraw_btn =='0'">
				<img
					src="@/assets/images/3/withdraw.png"
				/>
				<p class="text-primary">{{ $t('index_withdraw') }}</p>
			</a>
			<a class="menu-item col-3">
				<img
					src="@/assets/images/3/interest.png"
					@click="redirect('/plan/plan')"
				/>
				<p class="text-primary">{{ $t('index_interest') }}</p>
			</a>
			<a class="menu-item col-3">
				<img
					src="@/assets/images/3/icon-oderlist.png"
					@click="redirect('/market')"
				/>
				<p class="text-primary">{{ $t('footer_market') }}</p>
			</a>
		</section>

		<!-- Profits Details Box -->
		<div class="container">
			<div class="row mt-3 mb-5">
				<div class="col-12">
					<div class="profit-details-card display-block p-2">
						<div
							class="profit-details-board p-2 text-center mx-auto font-weight-bold"
						>
							{{ $t('account_profits_details') }}
						</div>
						<div class="profit-details-card-content w-100 my-3">
							<div class="profit-details-info-left mr-2">
								<p class="mb-0">
									{{ $t('account_index_available_balance') }}
									<font-awesome-icon
										:icon="['fas', 'sync-alt']"
										class="ml-5 me-auto"
										@click="refreshBalance()"
										style="font-size: 12px"
									/>
								</p>
								<h4 class="font-secondary-color pt-2">
									{{ balance }} {{ currency }}
								</h4>
							</div>
							<div class="profit-details-info-right text-center">
								<van-icon name="paid" @click="redirect('/account/wallet')" />
								<p>{{ $t('account_my_wallet') }}</p>
							</div>
						</div>
						<div class="profits-box">
							<div class="profits_item">
								<span>{{ $t('account_index_today_earnings') }}</span>
								<span>{{ credit_today }}&nbsp;{{ currency }}</span>
							</div>
							<div class="profits_item">
								<span>{{ $t('account_index_withdrawn') }}</span>
								<span>{{ withdraw }}&nbsp;{{ currency }}</span>
							</div>
							<div class="profits_item">
								<span>{{ $t('account_week_earning') }}</span>
								<span>{{ weekly_income }}&nbsp;{{ currency }}</span>
							</div>
							<div class="profits_item">
								<span>{{ $t('account_month_earning') }}</span>
								<span>{{ monthly_income }}&nbsp;{{ currency }}</span>
							</div>
							<div class="profits_item">
								<span>{{ $t('account_lastmonth_earning') }}</span>
								<span>{{ lastmonth_income }}&nbsp;{{ currency }}</span>
							</div>
							<div class="profits_item">
								<span>{{ $t('account_index_total_income') }}</span>
								<span>{{ total_credit }}&nbsp;{{ currency }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Accordion -->
		<div class="container profile_accordion_wrap">
			<van-collapse v-model="activeName" accordion class="my-3">
				<van-collapse-item :title="$t('account_always_used')" name="1">
					<div class="profile_icon_list">
						<div class="profile_icon_list my-2">
							<div
								class="profile_icon_list_item"
								@click="redirect('/account/info')"
							>
								<img
									src="@/assets/images/mobile/asettings.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('index_me') }}</h3>
							</div>
							<div
								class="profile_icon_list_item"
								@click="redirect('/account/deposit_log')"
							>
								<img
									src="@/assets/images/mobile/recharge_log.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('wallet_recharge_log') }}</h3>
							</div>
							<div
								class="profile_icon_list_item"
								@click="redirect('/account/withdraw_log')"
							>
								<img
									src="@/assets/images/mobile/recharge_log.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('wallet_withdraw_log') }}</h3>
							</div>
							<div
								class="profile_icon_list_item"
								@click="redirect('/trade/orderdetails')"
							>
								<img
									src="@/assets/images/mobile/trading_log.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('trade_order_list') }}</h3>
							</div>
						</div>
					</div>
				</van-collapse-item>
				<van-collapse-item :title="$t('account_basic')" name="2">
					<div class="profile_icon_list">
						<div class="profile_icon_list">
							<div
								class="profile_icon_list_item"
								@click="redirect('/account/income_log')"
							>
								<img
									src="@/assets/images/mobile/areport.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('account_index_profit_record') }}</h3>
							</div>

							<div
								class="profile_icon_list_item"
								@click="redirect('/account/team')"
								v-if="team_btn=='1'"
							>
								<img
									src="@/assets/images/mobile/areport.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('team_reports') }}</h3>
							</div>

							<div
								class="profile_icon_list_item"
								@click="redirect('/account/password')"
							>
								<img
									src="@/assets/images/mobile/areport.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('account_info_change_password') }}</h3>
							</div>
							<div
								class="profile_icon_list_item"
								@click="redirect('/account/secpassword')"
							>
								<img
									src="@/assets/images/mobile/areport.png"
									alt=""
									class="activity-btn bg-w"
								/>
								<h3>{{ $t('account_info_change_secpassword') }}</h3>
							</div>
						</div>
					</div>
				</van-collapse-item>
			</van-collapse>
		</div>

		<!-- <div class="container" style="font-size: 16px">
			<div style="padding: 15px !important">
				<div class="row outline-sd p-3" style="font-size: 11px">
					<p
						class="top-title mb-1 bold vl col-12 text-primary"
						style="margin-left: -10px"
					>
						{{ $t("account_always_used") }}
					</p>

					<span
						@click="redirect('/account/deposit')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/arecharge.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("recharge_index_recharge") }}
					</span>
					<span
						@click="redirect('/account/withdraw')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/awithdraw.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("account_index_withdraw") }}
					</span>
					<span
						@click="redirect('/account/info')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/asettings.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("index_me") }}
					</span>
					<span
						@click="redirect('/account/deposit_log')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/arecharge.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("wallet_recharge_log") }}
					</span>
					<span
						@click="redirect('/trade/orderdetails')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/arecharge.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("trade_order_list") }}
					</span>
				</div>
			</div>
		</div> -->

		<!-- btn -->
		<!-- <div class="container" style="font-size: 16px; margin-top: -15px">
			<div style="padding: 15px !important">
				<div class="row outline-sd p-3" style="font-size: 11px">
					<p
						class="top-title mb-1 bold vl col-12 text-primary"
						style="margin-left: -10px"
					>
						{{ $t("account_basic") }}
					</p>

					<span
						@click="redirect('/share')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/apoint.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("account_index_invite_good_friends") }}
					</span>
					<span
						@click="redirect('/account/income_log')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/areport.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("account_index_profit_record") }}
					</span>
					<span
						@click="redirect('/account/faq')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/apoint.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("faq_title") }}
					</span>
					<span
						@click="redirect('/account/about')"
						class="col-3 flex-box-center-column text-white font-weight-bold p-0"
					>
						<img
							src="@/assets/images/mobile/ranking.png"
							alt=""
							class="activity-btn bg-w"
						/>
						{{ $t("about_title") }}
					</span>
				</div>
			</div>
		</div> -->

		<div class="largebtncontainer my-4">
			<base-button mode="default-btn" type="submit" @click="signUp()">
				<font-awesome-icon :icon="['fas', 'power-off']" class="" />
				{{ $t('account_index_logout') }}
			</base-button>
		</div>

		<!-- <div class="order-detail1" :class="!isiframe ? 'd-none' : ''">
			<iframe :src="appurl" :class="!isiframe ? 'd-none' : ''"></iframe>
			<a @click="this.closeiframe()" class="close bg-dark">{{
				$t('trade_close')
			}}</a>
		</div> -->
	</base-layout3>
</template>


