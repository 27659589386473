<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { isMobile } from "@/plugin/isMobile.js";
import { getLocal } from "@/utils/local.js";
export default {
  name: "Notification",
  data() {
    return {
      data: [],
      category: [],
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
      page: 1,
      type: "showing",
      cat: "all",
      currency: getLocal("currency") || "RM",
    };
  },
  methods: {
    get_movie(sw) {
      if (sw) {
        this.data = [];
        this.page = 1;
        $(".notMore").css("display", "none");
      }

      var data = {};

      if (this.type == "showing") data = { type: 2, category: this.cat };
      if (this.type == "coming") data = { type: 3, category: this.cat };

      this.$http("", "/home/movie/index?page=" + this.page, data, "post").then(
        (result) => {
          if (result.code == "0001") {
            // console.log(_.size(result.data.data))
            this.data = _.union(this.data, result.data.data);
            this.current_page = result.data.current_page || 1;
            this.last_page = result.data.last_page || 1;
            this.per_page = result.data.per_page || 1;
            this.total = result.data.total || 10;
            // this.data         = result.data.data;
            console.log(this.data);
            if (_.size(result.data.data) == 0) {
              $(".notMore").css("display", "block").html(this.$t("no_more"));
            }
          }
        }
      );
    },
    getItem(item) {
      if (_.size(item) == 0) return;

      let lang = getLocal("lang") || "cn";
      var i = JSON.parse(item);
      // console.log(i)
      var find = _.find(i, function (item, k) {
        return k == lang;
      });

      return find || "-";
    },
    callf(type, sw) {
      this.type = type;
      this.get_movie(sw);
    },
    getbycat(type) {
      this.cat = type;
      this.get_movie(true);
    },
    getcat() {
      this.$http("", "/home/movie/category?page=" + this.page, {}, "post").then(
        (result) => {
          if (result.code == "0001") {
            this.category = result.data;
          }
        }
      );
    },
    redate(date) {
      return date.substring(0, date.length - 3);
    },
    checkNull(item) {
      if (_.isUndefined(item) || item == null || _.size(item) == 0)
        return false;

      return true;
    },
  },
  beforeMount() {
    let token = getLocal("token") || "";
    if (!token) this.$router.push("/");
    // console.log(token)
    this.getcat();
    this.get_movie(false);
  },
  mounted() {
    var that = this;
    window.addEventListener("scroll", function () {
      if (!isMobile()) return;

      var v = $(document).height() - $(window).height() - 1;
      if ($(document).scrollTop() >= v) {
        // console.log('我到底了');
        if ($(".notMore").css("display") != "block") {
          that.page++;
          that.get_movie(false);
        }
      }
    });
  },
  unmounted() {},
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: auto;
  overflow: initial;
  color: black;
  font-size: 14px;
}
.sub-mission-text2 {
  color: #a6a6a6 !important;
  font-size: 14px;
  margin-top: auto;
}
.selected_cat {
  background: #fa6b0b !important;
  color: white !important;
  padding-left: 5px;
  padding-right: 5px;
}
.outline-sd {
  box-shadow: 0px 0px 6px #00000029 !important;
  border-radius: 10px !important;
}
.movie-title {
  font-size: 17px;
  color: #333;
  font-weight: 700;
  padding-right: 5px;
  flex-shrink: 1;
}
.grade {
  font-weight: 700;
  color: #faaf00;
  font-size: 15px;
}
.detail {
  line-height: 24px;
}
.line-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ml-7 {
  margin-left: 7px;
}
.active_btm {
  width: 20px;
  border-bottom: 2px solid #f51c40;
  margin-top: 5px;
}
.flex-box-center-column {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.buy-btn {
  background: #ff443f;
  border-radius: 10px;
  color: white;
}
</style>

<template>
  <base-layout
    :page-title="$t('index_movie')"
    :show-back-button="true"
    :show-footer="true"
    mode=""
    style="padding-bottom: 100px"
  >
    <div class="">
      <div class="container pt-1 bg-white">
        <div class="row text-dark">
          <div
            class="col-6 flex-box-center-column bold pb-2"
            style="font-size: 18px"
          >
            <div @click="callf('showing', true)">{{ $t("movie_on") }}</div>
            <div :class="type == 'showing' ? 'active_btm' : ''"></div>
          </div>
          <div
            class="col-6 flex-box-center-column bold pb-2"
            style="font-size: 18px"
          >
            <div @click="callf('coming', true)">{{ $t("movie_wait") }}</div>
            <div :class="type == 'coming' ? 'active_btm' : ''"></div>
          </div>
        </div>
        <div class="col-12 me-bar2" style="margin-top: -20px"></div>
      </div>

      <div class="container mt-3">
        <ul class="nav mission-h1 pl-3 pr-2 pt-3">
          <li>
            <span class="sub-mission-text2 ml-7">
              {{ $t("movie_cat") }} :
            </span>
          </li>
          <li>
            <span
              class="sub-mission-text2 ml-7"
              :class="cat == 'all' ? 'badge selected_cat' : ''"
              @click="getbycat('all', true)"
            >
              {{ $t("movie_all") }}
            </span>
          </li>
          <li v-for="(item, k) in category" :key="k">
            <span
              class="sub-mission-text2 ml-7"
              :class="cat == item.title ? 'badge selected_cat' : ''"
              @click="getbycat(item.title, true)"
            >
              {{ item.title }}
            </span>
          </li>
        </ul>

        <div class="p-2">
          <div
            class="row mt-2 bg-white outline-sd p-1 pt-2"
            v-for="(item, k) in data"
            :key="k"
          >
            <div class="col-4 text-start pl-0">
              <img
                :src="item.url"
                class="img-fluid"
                onerror="this.style.visibility='hidden'"
                style="border-radius: 5px"
              />
            </div>
            <div class="col-5 p-0">
              <div class="movie-title">
                <div class="movie-title line-ellipsis v2dimax_title up-case">
                  {{ getItem(item.name) }}
                </div>
                <span class="version v2d imax"></span>
              </div>

              <div class="mt-2 detail">
                <div class="score line-ellipsis">
                  <span class="score-suffix">{{ $t("movie_rating") }} </span>
                  <span class="grade">{{ " " + item.rating }}</span>
                </div>
                <div
                  class="actor line-ellipsis"
                  v-html="item.description"
                  style="height: 30px; color: #8f8f94"
                ></div>
                <div
                  class="actor line-ellipsis"
                  style="font-size: 12px; color: #f0009f"
                >
                  <sup>{{ currency }} </sup>{{ item.price }}
                </div>
              </div>
            </div>
            <div class="col-3 text-right mt-1 align-self-center">
              <button
                type="button"
                class="btn buy-btn text-white"
                style="background: #fa6b0b"
                @click="go('/movie_details.html', item)"
                v-if="type == 'showing'"
              >
                {{ $t("index_buy") }}
              </button>
              <button
                type="button"
                class="btn buy-btn text-white"
                @click="go('/movie_details.html', item)"
                v-if="type == 'coming'"
              >
                {{ $t("index_watch") }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-12 pb-5"
          style="
            display: block;
            text-align: center;
            width: 100%;
            padding: 0.2rem 0;
          "
        >
          <span class="notMore text-white">{{ $t("drag_more") }}</span>
        </div>
      </div>
    </div>
  </base-layout>
</template>
