<template>
  <div>
    <slot></slot>
  </div>
</template>

<style scoped>
div {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: solid 4px #6a7aa6; */
  padding: 5px;
  border-radius: 8px;
  position: relative;
  background: #1A1516;
}
</style>
