<script>
import { getLocal } from "@/utils/local.js";
import * as $ from "jquery";
import { _ } from "vue-underscore";
import BaseModal from "@/components/UI/BaseModal.vue";
export default {
	data() {
		return {
			invite_id: 0,
			data: {},
			hongbaoredeem: {},
			tempdata: {},
			login: false,
			showangpao: false,
			showangpao2: false,
			qrcode: null,
			invite_link: null,
			tempid: null,
			currency: getLocal("currency") || "RM",
			firstCollapsed: true,
			img_url: process.env.VUE_APP_IMAGE_URL,
			dialogIsVisible: false,
		};
	},
	components: {
		BaseModal,
	},
	created() {},
	mounted() {
		this.getinvite();
		this.record();
		this.hongbaocheck();
	},
	beforeMount() {
		let token = getLocal("token") || null;
		this.login = token == null ? false : true;
		this.img_url = process.env.VUE_APP_IMAGE_URL;
	},
	computed: {},
	methods: {
		hideDialog() {
			this.dialogIsVisible = false;
		},
		getinvite() {
			var that = this;
			this.$http("", "/home/invite.html", "", "get").then((result) => {
				if (result.code == "0001") {
					that.invite = result.data;
					that.invite_id = that.invite.invite_id;
					// console.log(that.invite)
					var url =
						window.location.protocol + "//" + window.location.host + "/";
					that.qrcode =
						"https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" +
						url +
						"#/register?i=" +
						that.invite.invite_id +
						"&choe=UTF-8";
					that.invite_link = url + "#/register?i=" + that.invite.invite_id;
				}
			});
		},
		record() {
			var that = this;
			this.$http("", "/home/invite/record", "", "get").then((result) => {
				if (result.code == "0001") {
					that.data = result.data;
				}
			});
		},
		copyToClipboard(element) {
			var $temp = $("<input>");
			$("body").append($temp);
			$temp.val($(element).text()).select();
			document.execCommand("copy");
			$temp.remove();
			this.$toast.success(this.$t("recharge_copy_success"));
		},
		open(id) {
			// console.log(id)
			var element = document.getElementById(id);

			if ($("#" + id).hasClass("show")) {
				element.classList.remove("collapse");
				element.classList.remove("show");
				element.classList.add("collapsing");
				setTimeout(function () {
					element.classList.remove("collapsing");
					element.classList.add("collapse");
				}, 0);
				// console.log("here")
			} else {
				element.classList.remove("collapse");
				element.classList.add("collapsing");
				setTimeout(function () {
					element.classList.remove("collapsing");
					element.classList.add("collapse");
					element.classList.add("show");
				}, 0);
			}

			// element.classList.add("show");
			// this.$root.$emit('bv::toggle::collapse', id)
		},
		hongbao(id) {
			this.tempid = id;
			this.showangpao = true;
		},
		hongbao2() {
			var id = this.tempid;
			this.$http("", "/home/invite/hongbao", { q: id }, "post").then(
				(result) => {
					this.showangpao = false;
					this.tempid = null;
					if (result.code == "0001") {
						this.showangpao = false;
						this.hongbaocheck();
						this.$toast.success((result.data));
					} else {
						this.$toast.error((result.message));
					}
				}
			);
		},
		hongbaocheck() {
			this.$http("", "/home/invite/hongbaocheck", {}, "post").then((result) => {
				if (result.code == "0001") {
					// this.hongbaoredeem = result.data;
					var that = this;
					_.each(result.data, function (item) {
						that.hongbaoredeem[item.event_id] = item;
					});
					// console.log("hongbaoredeem",this.hongbaoredeem)
					// this.$toast.success(this.$t(result.data));
				} else {
					// this.$toast.error(this.$t(result.message));
				}
			});
		},
		hongbaocheckitem(item) {
			var id = item.id;
			// console.log("hongbaocheckitem",id,this.hongbaoredeem[id])
			if (!_.isUndefined(this.hongbaoredeem[id])) return false;
			else return true;
		},
		bonus(id) {
			this.$http("", "/home/invite/bonus", { q: id }, "post").then((result) => {
				if (result.code == "0001") {
					this.$toast.success((result.data));
				} else {
					this.$toast.error((result.message));
				}
			});
		},
		qiandao_redeem(type) {
			var a = this.data["q" + type] || 0;
			if (a == 0) return;

			this.$http("", "/home/invite/qiandao_redeem", { q: type }, "post").then(
				(result) => {
					if (result.code == "0001") {
						this.$toast.success((result.data));
						this.record();
					} else {
						this.$toast.error((result.message));
					}
				}
			);
		},
		showpromo(item) {
			// console.log(item)
			this.tempdata = item;
			this.dialogIsVisible = true;
		},
		qiandao1() {
			this.showangpao2 = true;
		},
		qiandao2() {
			this.$http("", "/home/invite/qiandao", "", "post").then((result) => {
				this.showangpao2 = false;
				if (result.code == "0001") {
					this.$toast.success((result.data));
					this.record();
				} else {
					this.$toast.error((result.message));
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.pb100 {
	padding-bottom: 100px;
}
.duplicate-btn {
	background-color: $yellow-500;
	color: $brown-100 !important;
	width: 100px;
	vertical-align: top;
	font-size: 14px;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.card {
	border: 3px solid $yellow-100;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(6%, #fff),
		color-stop(74%, #fff6e5),
		color-stop(102%, #fff)
	);
	background-image: -webkit-linear-gradient(135deg, map-get($theme-gradient-colors, 'tertiary'));
	background-image: linear-gradient(135deg, map-get($theme-gradient-colors, 'tertiary'));
}
.invite .card-body {
	padding: 20px;
}
#progressbar li {
	list-style-type: none;
	font-size: 12px;
	width: 33.3%;
	float: left;
	position: relative;
	font-weight: 400;
}
.invite .progress {
	height: 18px !important;
}
.redeem {
	border-radius: 10px;
	margin-top: 3rem;
	width: 95%;
	margin: auto;
	color: $black-400;
}
.redeem-inactive {
	border: 2px solid $gray-200;
	cursor: pointer;
	padding: 5px;
	margin-top: 42px !important;
	background: $gray-200;
	-webkit-box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
	-moz-box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
	box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
}
.redeem-active {
	border: 2px solid map-get($theme-colors, 'danger');
	cursor: pointer;
	padding: 5px;
	margin-top: 42px !important;
	color: $white;
	background: map-get($theme-colors, 'danger');
	-webkit-box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
	-moz-box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
	box-shadow: 2px 2px 2px 0px rgba($black-100, 0.35);
}
.angpau {
	position: absolute;
	z-index: 1;
	width: 80%;
	transform: translate(-50%, -50%);
	left: 51%;
	top: 35%;
}
.bdrop {
	background: rgba($black-100, 0.35);
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1;
	/* opacity: 0.1; */
	left: 0;
}
</style>
<style>
dialog {
	position: absolute !important;
	top: 0 !important;
}
</style>
<template>
	<base-layout3
		:page-title="$t('invite_index_invite_friend')"
		:showBackButton="true"
	>
		<div class="pb100 main-container container">
			<div class="bdrop" v-if="showangpao">
				<img
					src="~@/assets/images/angpao.gif"
					class="img-fluid angpau"
					alt=""
					@click="hongbao2()"
				/>
			</div>

			<div class="bdrop" v-if="showangpao2">
				<img
					src="~@/assets/images/angpao.gif"
					class="img-fluid angpau"
					alt=""
					@click="qiandao2()"
				/>
			</div>

			<base-modal @close="hideDialog" :open="dialogIsVisible">
				<h3 class="modal-header">
					{{ tempdata.name }}
					<base-button
						class="btn-close-rounded"
						@click="hideDialog"
						style="position: absolute; top: 0; right: -12px"
					>
						<font-awesome-icon :icon="['fas', 'close']"></font-awesome-icon>
					</base-button>
				</h3>
				<div
					class="modal-body text-center"
					style="text-align: unset !important; padding: 0"
				>
					<span v-html="tempdata.des" class=""></span>
				</div>
				<div class="col-md-12 text-center">
					<button
						type="button"
						class="btn btn-light text-center"
						@click="hideDialog"
					>
						{{ $t("common_close") }}
					</button>
				</div>
			</base-modal>

			<!-- invite -->
			<div class="col-12 text-center" style="padding: 10px 15px">
				<h3 style="color: #3595a5">
					{{ $t("invite_index_invite_friend") }}
				</h3>
				<h7 class="text-title-color text-white">
					{{ $t("invite_index_save_qr") }}
					{{ $t("invite_index_share_moment") }}
				</h7>
			</div>

			<div class="col-12 text-center" style="padding: 10px 15px">
				<img
					:src="qrcode"
					class="w-50 mb-3"
					style="border-radius: 5px; max-width: 300px; margin: auto"
				/>
			</div>

			<div class="col-12 p-0">
				<div class="col-12 d-flex flex-row flex-wrap align-items-center">
					<div
						class="col-8 copy-box text-truncate text-left text-white"
						style="padding: 0.25rem 0.4rem !important"
					>
						<span>{{ $t("invite_index_referral_code") }} :</span
						><span id="copy1">{{ invite_id }}</span>
					</div>
					<div class="col-4" style="padding: 0.25rem 0.2rem !important">
						<div
							class="btn duplicate-btn ml-1"
							@click="copyToClipboard('#copy1')"
						>
							<font-awesome-icon :icon="['far', 'clone']" />
							{{ $t("task_detail_copy") }}
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 p-0 mb-1">
				<div class="col-12 d-flex flex-row flex-wrap align-items-center">
					<div
						class="col-8 copy-box text-truncate text-left text-white"
						style="padding: 0.25rem 0.4rem !important"
					>
						<span>{{ $t("invite_index_invite_link") }} :</span
						><span id="copy2">{{ invite_link }}</span>
					</div>
					<div class="col-4" style="padding: 0.25rem 0.2rem !important">
						<div
							class="btn duplicate-btn ml-1"
							@click="copyToClipboard('#copy2')"
						>
							<font-awesome-icon :icon="['far', 'clone']" />
							{{ $t("task_detail_copy") }}
						</div>
					</div>
				</div>
			</div>
			<!-- invite END-->

			<!-- 领取各种红包 -->
			<div class="col-12">
				<a>
					<div class="card shadow-sm mb-1">
						<div class="card-body">
							<div class="row" id="headingOne">
								<div class="col-auto">
									<font-awesome-icon
										:icon="['far', 'clock']"
										class="me-auto text-danger"
									/>
								</div>
								<div class="col ps-0 align-self-center">
									<div class="bold text-dark">领取各种红包</div>
									<div style="color: #a7afaf">抢红包获得奖励</div>
								</div>
								<div class="col-auto">
									<div
										style="
											color: #000;
											padding: 5px;
											border: 2px solid #8405f1;
											border-radius: 10px;
											text-align: center;
											font-size: 10px;
										"
									>
										<div class="pointer" @click="open('collapse')">
											领取红包
										</div>
									</div>
								</div>

								<div
									class="collapse mt-2"
									id="collapse"
									aria-labelledby="headingOne"
								>
									<div v-for="(item, k) in data.hongbao" :key="k">
										<div class="row">
											<div class="col-auto" style="margin: auto">
												<span class="text-danger">-</span>
											</div>
											<div class="col ps-0 text-center" style="margin: auto">
												<div class="text-start bold text-dark">
													{{ item.name }}
												</div>
											</div>
											<div class="col-auto">
												<div
													v-if="hongbaocheckitem(item)"
													style="
														color: #000;
														padding: 5px;
														border: 2px solid #f10505;
														border-radius: 10px;
														text-align: center;
														font-size: 10px;
													"
												>
													<div @click="hongbao(item.id)" class="pointer">
														领取
													</div>
												</div>
												<div
													v-if="!hongbaocheckitem(item)"
													style="
														color: #000;
														padding: 5px;
														border: 2px solid rgb(5 241 56);
														border-radius: 10px;
														text-align: center;
														font-size: 10px;
													"
												>
													<div class="pointer" v-if="!hongbaocheckitem(item)">
														已领取
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			<!-- 领取各种红包 END -->

			<!-- 领取各种代金券 -->
			<div class="col-12" style="display:none">
				<a>
					<div class="card shadow-sm mb-1">
						<div class="card-body">
							<div class="row" id="heading2">
								<div class="col-auto">
									<font-awesome-icon
										:icon="['far', 'clock']"
										class="me-auto text-danger"
									/>
								</div>
								<div class="col ps-0 align-self-center">
									<div class="bold text-dark">领取各种代金券</div>
									<div style="color: #a7afaf">抢代金券</div>
								</div>
								<div class="col-auto">
									<div
										style="
											color: #000;
											padding: 5px;
											border: 2px solid #8405f1;
											border-radius: 10px;
											text-align: center;
											font-size: 10px;
											margin-bottom: 5px;
										"
									>
										<div @click="open('collapse2')" class="pointer">
											领取代金券
										</div>
									</div>
								</div>

								<div
									class="collapse mt-2"
									id="collapse2"
									aria-labelledby="heading2"
								>
									<div v-for="(item, k) in data.bonus" :key="k">
										<div class="row">
											<div class="col-auto" style="margin: auto">
												<span class="text-danger">-</span>
											</div>
											<div class="col ps-0 text-center" style="margin: auto">
												<div class="text-start bold text-dark">
													{{ item.name }}
												</div>
												<div>
													<img
														@click="showpromo(item)"
														:src="img_url + '/uploads/' + item.url"
														class="img-fluid"
														alt=""
													/>
												</div>
											</div>
											<div class="col-auto">
												<div
													style="
														color: #000;
														padding: 5px;
														border: 2px solid #f10505;
														border-radius: 10px;
														text-align: center;
														font-size: 10px;
													"
												>
													<div @click="bonus(item.id)" class="pointer">
														领取
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			<!-- 领取各种代金券 END -->

			<div class="col-12">
				<a>
					<div class="card shadow-sm mb-1">
						<div class="card-body">
							<div class="row">
								<div class="col-auto">
									<font-awesome-icon
										:icon="['far', 'edit']"
										class="me-auto text-danger"
									/>
								</div>
								<div class="col ps-0 align-self-center">
									<div class="bold text-dark">每日签到</div>
									<div style="color: #a7afaf">每日签到获得奖励</div>
								</div>
								<div class="col-auto">
									<div
										style="
											color: #000;
											padding: 5px;
											border: 2px solid #8405f1;
											border-radius: 10px;
											text-align: center;
											font-size: 10px;
										"
									>
										<div @click="qiandao1()" class="pointer">每日签到</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>

			<div class="col-12">
				<div class="card shadow-sm mb-3">
					<div class="card-body">
						<ul id="progressbar">
							<li style="font-size: 12px; color: black">
								<div>{{ data.qiandao_day1 }} 天</div>
								<div
									class="redeem"
									:class="
										data.qdnum >= data.qiandao_day1 && data.q1 >= 1
											? 'redeem-active'
											: 'redeem-inactive'
									"
									@click="qiandao_redeem(1)"
								>
									({{ currency }}) {{ data.qiandao_award1 }}
								</div>
							</li>
							<li style="font-size: 12px; color: black">
								<div>{{ data.qiandao_day2 }} 天</div>
								<div
									class="redeem"
									:class="
										data.qdnum >= data.qiandao_day2 && data.q2 >= 1
											? 'redeem-active'
											: 'redeem-inactive'
									"
									@click="qiandao_redeem(2)"
								>
									({{ currency }}) {{ data.qiandao_award2 }}
								</div>
							</li>
							<li style="font-size: 12px; color: black">
								<div>{{ data.qiandao_day_max }} 天</div>
								<div
									class="redeem"
									:class="data.q3 >= 1 ? 'redeem-active' : 'redeem-inactive'"
									@click="qiandao_redeem(3)"
								>
									({{ currency }}) {{ data.qiandao_award_max }}
								</div>
							</li>
						</ul>
						<div
							class="progress mb-2"
							style="
								width: 100%;
								margin-left: 0px;
								position: relative;
								top: -70px;
							"
						>
							<div
								class="progress-bar bg-danger"
								style="padding: 5px;width:{{ ((data.qdnum/data.qiandao_day_max)*100 )+1 }}%;min-width:10%"
								aria-valuenow="{{data.qdnum}}"
								aria-valuemin="0"
								aria-valuemax="{{data.qiandao_day_max}}"
							>
								<span id="setPercentage"> {{ data.qdnum }} 天 </span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</base-layout3>
</template>
