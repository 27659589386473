import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from './router'
import i18n from './assets/i18n'
import http from './plugin/http.js'
import store from './store/index.js'
import tmpl from './plugin/template.js'
//import BootstrapVue3  from 'bootstrap-vue-3'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'
// import "@/assets/css/variable.less"
// import "@/assets/css/style.css"
import "bootstrap/dist/js/bootstrap.min.js";
//import VueClipboard from 'vue3-clipboard'
// import '@/assets/css/style3.css';

import { Collapse, CollapseItem } from 'vant';
import { Icon } from 'vant';
import 'vant/lib/index.css'
// import "@/assets/css/exchange.less"
// import "@/assets/css/exchange.css"
// import "@/assets/css/trade-history.css"

import BaseButton from '@/components/UI/BaseButton.vue'
import BaseCard from '@/components/UI/BaseCard.vue'
import Toaster from "@meforma/vue-toaster";
// import BaseLayout from '@/components/UI/BaseLayout.vue'
// import BaseLayout2 from '@/components/UI/BaseLayout2.vue'
import BaseLayout3 from '@/components/UI/themes/theme1/BaseLayout3.vue'

/* Using FontAwesome Icons */
library.add(fas, far, fab);


// import { BootstrapVueIcons } from 'bootstrap-vue'
// import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'


//import { message } from './assets/js/resetMessage.js'


const app = createApp(App);
// 请求方法挂在vue实例上
app.config.globalProperties.$http = http;
app.config.globalProperties.$cfg = process.env;
app.config.globalProperties.$currency = "$";
app.config.globalProperties.$code = tmpl;
let _language = localStorage.getItem('lang');
//设置默认语言
if (_language== '' ||  _language == null){
    localStorage.setItem("lang",process.env.VUE_APP_LANG);
}
// Make BootstrapVue available throughout your project
//app.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//app.use(IconsPlugin)

// This declare the use of the scss file
import StyleUIMain from "./style-ui-main.js";

//app.use(BootstrapVue3)
app.use(FontAwesomeIcon)
app.use(router)
app.use(i18n)
app.use(store)
app.use(Icon)
app.use(Collapse);
app.use(CollapseItem);
app.use(StyleUIMain)

app.use(Toaster, {
  // One of the options
  position: "top",
  duration: 2000,
})
// app.use(ElementPlus)
app.mount('#app')
app.directive('init', {
  bind: function(el, binding, vnode) {
    vnode.context[binding.arg] = binding.value;
  }
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('base-button', BaseButton);
app.component('base-card', BaseCard);
// app.component('base-layout', BaseLayout);
// app.component('base-layout2', BaseLayout2);
app.component('base-layout3', BaseLayout3);
// 解决重复弹窗
//app.config.globalProperties.$message = message;

document.getElementById("app").style.maxWidth = "100%!important";




