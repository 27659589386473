
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
import moment from 'moment-timezone';
import "@/assets/scss/trade_history.scss";
import { formatSeconds2, getLocalTime } from "@/utils/chart.js";

export default {
	name: "Orderlist",
	data() {
		return {
			data : [],
			category : [],
			current_page :  1,
			last_page :  1,
			per_page :  10,
			total :  0,
			page :  1,
			currency : getLocal('currency') || 'RM',
			img_url : process.env.VUE_APP_IMAGE_URL,
			proprice : [],
			timer_get_price: null,
			timer_orderlist: null,
			orderlist: [],
			ftime: 0,
			getLocalTime: getLocalTime
		};
	},
	methods: {
		push_content(url,id)
		{
			this.$router.push(url+'?id='+id);
		},
		getprice() {
			this.$http("", "/home/api/get_price", {},'post')
			.then((result) => {
				if (result.code == '0001') {
					this.proprice = result.data;
					this.showOrderList();
				}
			})
		},
		getHoldOrderList() {
			this.$http("", "/home/api/orderlist", { hold_position: 1 },'post')
			.then((result) => {
				if (result.code == '0001') {
					this.orderlist = result.data.data;

					if(this.orderlist){
						if(this.orderlist.length >= 1){
							this.ftime = this.orderlist[0]['time'];
						}else{
							var timestamp = Date.parse(new Date());
							this.ftime = timestamp/1000;
						}
						
						var that = this;
						this.timer_get_price = setInterval(function() { that.getprice(); }, 1000);
						// this.timer_orderlist = setInterval(function() { that.showOrderList(); }, 1000);
				}
				
				}
			})
		},
		showOrderList() {
			var  html = '';
			// console.log(this.orderlist.length, 'this.orderlist.length')
			if(this.orderlist.length == 0){
				$('.trade_history_list .slider-left ul').html('');
				return false;
			}
			this.ftime++;
			var that = this;
			$.each(this.orderlist,function(k,v){
				
				if (typeof v === 'undefined') return;
				
				var timestamp = Date.parse(new Date());
				var  _end_time = (v.selltime - that.ftime);
				var baifenbi = (_end_time/v.endprofit)*100;
				var newprice = that.proprice[v.pid];
				var ostyle_class, ostyle_class2, ostyle_name;

				if(_end_time >0){
					
					var chaprice = newprice-v.buyprice;
					var closeprice = 0;
					var closeprice_class = '';
					if(v.ostyle == 0){
						ostyle_class = "buytop";
						ostyle_class2 = 'in_money';
						ostyle_name = "买涨";
						if(chaprice >0){
							closeprice = v.fee*(100*10+v.endloss*10)/1000;
							closeprice_class = 'in_money';
						}else if(chaprice <0){
							closeprice = v.fee*(-1);
							closeprice_class = 'out_money';
						}else{
							closeprice = v.fee;
							closeprice_class = '';
						}
					}else{
						ostyle_class = "buydown";
						ostyle_name = "买跌";
						ostyle_class2 = 'out_money';

						if(chaprice <0){
							closeprice = v.fee*(100*10+v.endloss*10)/1000;
							closeprice_class = 'in_money';
						}else if(chaprice >0){
							closeprice = v.fee*(-1);
							closeprice_class = 'out_money';
						}else{
							closeprice = v.fee;
							closeprice_class = '';
						}

					}

					html += '<li ng-repeat="o" class="">\
								<section>\
									<p style="margin: 0">\
										<span class="">'+v.ptitle+'</span>\
										<span class=" '+ostyle_class2+'"><i class="'+ostyle_class+'"></i>'+ostyle_name+'（'+this.currency+v.fee+'）</span>\
									</p>\
									<p style="margin: 0" class="">\
										'+v.buyprice+'-<span  class=" '+closeprice_class+'">'+newprice+'</span>\
									</p>\
									<p style="margin: 0" class="">'+getLocalTime(v.buytime)+'</p>\
								</section><section>\
									<p style="margin: 0px;" class=" '+closeprice_class+'">'+closeprice+'</p>\
									<p style="margin: 0" class="">'+formatSeconds2(_end_time)+'</p>\
								</section>\
								<article class="">\
								<span class="move_width" style="width: '+baifenbi+'%; transition-duration: 1s;">\
								</span>\
								<i>\
									<em></em>\
								</i>\
								</article>\
							</li>';
					
					$('.trade_history_list .slider-left ul').html(html);
				
				}else{
					that.orderlist.splice(k,1);
				}
			});
		},
		getdata(sw) {

			if(sw){
				this.data = [];
				this.page = 1;
				$('.notMore').css('display', 'none');
			}

			var data = {};
			var u = '/home/api/orderlist';

			this.$http("", u+"?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
					// console.log(result.data)
					
					if(_.size(result.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					} else {
						$.each(result.data.data,function(k,v){
							var closeprice = 0;
							var closeprice_class = '';
							var ostyle_class, ostyle_name;

							if(v.ostyle == 0){
								ostyle_class = "buytop";
								ostyle_name = v.ostyle_name;
							}else{
								ostyle_class = "buydown";
								ostyle_name =  v.ostyle_name;
							}

							if(v.is_win == 1){
								closeprice = v.fee*(100*10+v.endloss*10)/1000;
								closeprice_class = 'in_money';
							}else if(v.is_win == 2){
								closeprice = v.fee*(-1);
								closeprice_class = 'out_money';
							}else{
								closeprice = 0;
								closeprice_class = '';
							}

							result.data.data[k]['closeprice'] = closeprice;
							result.data.data[k]['closeprice_class'] = closeprice_class;
							result.data.data[k]['ostyle_class'] = ostyle_class;
							result.data.data[k]['ostyle_name'] = ostyle_name;
						});

						this.data         = _.union(this.data, result.data.data);
						this.current_page = result.data.current_page || 1;
						this.last_page    = result.data.last_page || 1;
						this.per_page     = result.data.per_page || 1;
						this.total        = result.data.total || 10;
						// this.data         = result.data.data;
							// console.log(this.data)
					}
				}

			});
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		parseDate(date)
		{
			return moment(date).format('YYYY/MM/DD');
		},
		changeCategory(type){

			$('.slider-right').css('transition-duration','300ms');
			$('.slider-left').css('transition-duration','300ms');
			$('.tab').removeClass('active');
			if(type == 0){
				this.page = 1;
				// get_price();
				// hold_order_list()
				// $('.uls').html(' ');
				// $('.slider-left').css('transform','translate(0px, 0px) translateZ(0px)');
				// $('.slider-right').css('transform','translate(100%, 0px) translateZ(0px)');
				$('.slider-left').addClass('active show');
				// $('.slider-left').removeClass('d-none');
				$('.slider-right').removeClass('active');
				$('.slider-right').removeClass('show');
				// $('.slider-right').addClass('d-none');
			}

			if(type == 1){

				// clearInterval(timer_get_price);
				// clearInterval(timer_orderlist);
				// listionhajax = setInterval("listionh()",1000);
				// is_ajax_list = 0;
				// orderedlist();
				// $('.slider-left').css('transform','translate(-100%, 0px) translateZ(0px)');
				// $('.slider-right').css('transform','translate(0px, 0px) translateZ(0px)');
				$('.slider-right').addClass('active show');
				// $('.slider-right').removeClass('d-none');
				$('.slider-left').removeClass('active');
				$('.slider-left').removeClass('show');
				// $('.slider-left').addClass('d-none');
				this.getdata(false);
			}

			$('.tab-' + type).addClass('active');
		}
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/');
		// console.log(token)
		// this.getdata(false);
		this.getHoldOrderList();
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function(){
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1;
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					that.page++;
					// that.getdata(false) ;
				}
			}
		});
	},
	unmounted() {
	}
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
	color: $black-100;
	font-size: 14px;
	background: $black-400;
}
.sub-mission-text2 {
	color: $gray-500!important;
	font-size: 14px;
	margin-top: auto;
}
.selected_cat {
	background: $orange!important;
	color: $white!important;
	padding-left: 5px;
	padding-right: 5px;
}
.outline-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161)!important;
	border-radius: 10px !important;
}
.movie-title {
	font-size: 17px;
	color: $black-300;
	font-weight: 700;
	padding-right: 5px;
	flex-shrink: 1;
}
.grade {
	font-weight: 700;
	color: $yellow-400;
	font-size: 15px;
}
.detail {
	line-height: 24px;
}
.line-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.ml-7 {
	margin-left: 7px;
}
.active_btm {
	width: 20px;
	border-bottom: 2px solid map-get($theme-colors, 'danger');
	margin-top: 5px;
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.flex-box-center {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}
.rank-text {
	font-size: 40px;
	font-weight: bold;
	color: $gray-500;
}
p{color:$gray-500;}
.up-case {
	text-transform: uppercase;
}
.trade_history_list ul,
.trade_history_list ul li {
    background: transparent
}
.mobile .section-title {
	justify-content: space-around;
}
.tab.active {
	color: $yellow-100;
}
.trade_history_list ul li p {
	margin-bottom: 0;
}
</style>

<template>
  <base-layout3
	:page-title="$t('trade_order_list')"
	:show-back-button="true"
	:show-footer="false"
	mode=""
	style="padding-bottom:100px"
  >
	<div class="trade_history_list">

			<h3 class="section-title">
				<span @click="this.changeCategory(0)" class="tab-0 tab active">{{$t('trade_position_details' )}}</span>
				<span @click="this.changeCategory(1)" class="tab-1 tab">{{$t('trade_history_details' )}}</span>
			</h3>
			<div class="tab-pane fade slider-left show active" id="trade_history_list" role="tabpanel">
				<ul>
				</ul>
			</div>
			<div class="tab-pane fade slider-right" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
				<ul class="">
					<li onclick="get_hold_order('+v.oid+')" v-for="(item, k) in  data"  :key="k" >
                        <section>
                            <p>
                                <span class="">{{item.ptitle}}</span>
                                <span :class="item.closeprice_class">
                                <i  :class="item.ostyle_class"></i>{{item.ostyle_name}}（{{currency}}{{item.fee}}）</span>
                            </p>
                            <p class="">
                                {{item.buyprice}}<span  :class="item.closeprice_class">{{item.sellprice}}</span>
                            </p>
                            <p class="">{{getLocalTime(item.buytime)}}</p>
                        </section>
						<section>
                            <p :class="item.closeprice_class">{{item.closeprice}}</p>
                            <p class="">{{getLocalTime(item.selltime)}}</p>
                        </section>
                    </li>
					<!-- <li  v-for="(item, k) in  data"  :key="k"  >
						<div class="title">
							<div>
								<h2 class="" style="color: #fff;font-size: 12px;" >{{item.number}}</h2>
								<span>{{item.title}}</span>
							</div>
							<span v-if="item.status==0" class="text-warning">{{$t('plan_order_view_distribute') }}</span>
							<span v-if="item.status==1" class="text-info">{{$t('plan_order_view_done') }}</span>
						</div>
						<div>
							<span>
								<font-awesome-icon :icon="['far', 'calendar']" class="me-auto" />
								{{ parseDate(item.time)}}
							</span>
							<br/>
							<div style="float: right;">{{currency}} {{item.money}}</div>
						</div>
					</li> -->
				</ul>
				<div class="col-12 pb-5" style="display: block;text-align: center;width:100%;">
					<span class="notMore text-white">{{$t('drag_more') }}</span>
				</div>
			</div>

	</div>
  </base-layout3>
</template>