<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
export default {
	name: "withdraw",
	components: {},
	data() {
		return {
			lock: 0,
			pay_method: 0,
			data: {},
			paymentgayway: {},
			draw_type: "credit2",
			total_rate: 0,
			currency: getLocal("currency") || "RM",
			phone: {
				val: "",
				isValid: true,
			},
			check: true,
			withdraw_amt: {
				val: 100,
				isValid: true,
			},
			secpassword: {
				val: "",
				isValid: true,
			},
			receive_account: {
				val: "",
				isValid: true,
			},
			receive_name: {
				val: "",
				isValid: true,
			},
			bank_code: {
				val: "",
				isValid: true,
			},
			withdraw: {},
			current_page: 1,
			last_page: 1,
			per_page: 10,
			total: 0,
			page: 1,
			balance: 0,
			bank_account: "",
			bank_branch: "",
			bank_account_name: "",
			withdraw_fee: "",
			damaliang: "",
			minmax: "",
			timelimit: "",
			formIsValid: true,
			isPayFrame: false,
			payurl: null,
			banks: [],
			isbanks: 0,
			isaccount: "0",
			isname: "0",
		};
	},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal("token") || "";
		this.token = token;
		if (!token) {
			this.$router.push("/login");
		}

		this._config();
		this._config_paymentgayway();
		this.getlog();
	},
	methods: {
		closeiframe() {
			this.isPayFrame = false;
		},
		withdraw_cal() {
			this.total_rate =
				this.withdraw_amt.val -
					this.withdraw_amt.val * 1 * (this.data.config.withdraw_fee / 100) ||
				0;
		},
		clearValidity(input) {
			this[input].isValid = true;
		},
		redirect(url) {
			this.$router.push(url);
		},
		getbanks(item){
			this.isaccount = item.info.isaccount||"0";
			this.isname    = item.info.isname||"0";

			if(item.isBank==1){
				this.banks=item.bank;
				this.isbanks=item.isBank;
				// console.log(item,"aaaaaaaaaaa")
			}
		},
		banklang(item){
			return _.isUndefined(item.info[this.$i18n.locale ])?item.title:item.info[this.$i18n.locale ];
		},
		_config_paymentgayway() {
			var that = this;
			this.$http("", "/home/api/withdrawgatway", {one_only:0}, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.paymentgayway = result.data;
						this.pay_method = this.paymentgayway.code;
						console.log(this.paymentgayway, 'paymentgayway')
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		validateForm() {
			this.formIsValid = true;
			// if (this.phone.val === "") {
			// 	this.phone.isValid = false;
			// 	this.formIsValid = false;
			// }
			if (this.withdraw_amt.val === "" || this.withdraw_amt.val === 0) {
				this.withdraw_amt.isValid = false;
				this.formIsValid = false;
			}
			if (this.secpassword.val === "") {
				this.secpassword.isValid = false;
				this.formIsValid = false;
			}

			if((this.isbanks==1 )){
				if (this.bank_code.val === "") {
						this.bank_code.isValid = false;
						this.formIsValid = false;
				}
				if (this.receive_account.val === "") {
						this.receive_account.isValid = false;
						this.formIsValid = false;
				}
				if (this.receive_name.val === "") {
						this.receive_name.isValid = false;
						this.formIsValid = false;
				}
			}

			if((this.isaccount=="1" )){
				if (this.receive_account.val === "") {
						this.receive_account.isValid = false;
						this.formIsValid = false;
				}
			}

			if((this.isname=="1" )){
				if (this.receive_name.val === "") {
						this.receive_name.isValid = false;
						this.formIsValid = false;
				}
			}



			if(_.isUndefined(this.pay_method)  || this.pay_method == null || _.size(this.pay_method)==0 ){
				this.formIsValid = false;
				this.$toast.error(this.$t('withdraw_payment_method_err'));
			}
		},
		submitForm() {
			var that = this;
			if (this.lock === 1) {
				return "";
			}
			this.lock = 1;
			if (!this.check) {
				this.lock = 0;
				return this.$toast.error(this.$t("login_check_err"));
			}
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}
			var data = {
				credit2: this.withdraw_amt.val,
				mobile: this.phone.val,
				pay_method: this.pay_method,
				draw_type: this.draw_type,
				account: this.data.member.bank_account,
				realname: this.data.member.bank_account_name,
				secpassword: this.secpassword.val
			};

			if (this.isbanks==1){
				data['bank_code'] = this.bank_code.val;
				data['receive_account'] = this.receive_account.val;
				data['receive_name'] = this.receive_name.val;
			}
			if (this.isname=="1"){
				data['receive_name'] = this.receive_name.val;
			}
			if (this.isaccount=="1"){
				data['receive_account'] = this.receive_account.val;
			}

			this.$http("", "/home/withdraw/withdraw", data, "post")
				.then((result) => {
					this.lock = 0;
					if (result.code == "0001") {
						this.$toast.success((result.message));
						this.secpassword.val = "";
						var url = result.data || null;
							setTimeout(function () {
								that.$router.push("/account/withdraw_log");
							}, 1000);
					} else if (result.code == "333") {
						var m =
							this.$t("withdraw_spend_required") +
							result.message +
							this.$t("withdraw_spend_requiredend");
						this.$toast.error(m);
					} else {
						this.lock = 0;
						this.$toast.error((result.message));
					}
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e);
				});
		},
		_config() {
			var that = this;
			this.$http("", "home/account/withdraw_config", {}, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.data = result.data;

						// if (this.data.member.is_bind == 0) {
						// 	this.$toast.error(this.$t("account_withdraw_verify_first"));

						// 	setTimeout(function () {
						// 		that.$router.push("/account/review");
						// 	}, 1000);
						// }

						// if (!this.data.member.bank_account) {
						// 	setTimeout(function () {
						// 		that.$router.push("/account/bank");
						// 	}, 500);
						// }

						this.bank_account = this.data.member.bank_account;
						var countstr =  _.size(this.bank_account.slice(0, -4));
						// console.log(("*").repeat(countstr))
						// console.log(this.bank_account.substr( -4))
						if(_.size(this.bank_account)>= 4)
							this.bank_account = ("*").repeat(countstr) +  this.bank_account.substr( -4);

						this.bank_branch = this.data.member.bank_branch;
						this.bank_account_name = this.data.member.bank_account_name;
						this.withdraw_fee = this.data.config.withdraw_fee;
						this.damaliang = this.data.damaliang;
						this.minmax = this.data.config.withdraw_min+" - "+ this.data.config.withdraw_max;
						this.timelimit = this.data.config.withdraw_time;

						that.phone.val = this.data.member.username;
						this.balance = this.data.member.credit2 || 0;
						this.withdraw_cal();

						if (this.data.member.bank_branch != null)
							this.bank_branch = this.data.member.bank_branch;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		getlog() {
			var that = this;
			var per_page = 12;
			var data = {
				type: "withdraw",
				per_page: per_page,
			};
			var a = this.$t("no_more");

			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					// console.log(_.size(result.data.data))
					this.withdraw = _.union(this.withdraw, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					// this.withdraw         = result.data.data;
					// console.log(this.withdraw)
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(a);
					}
				}
			});
		},
		refreshlog(num) {
			this.page = num;
			var per_page = 12;
			var data = {
				type: "withdraw",
				per_page: per_page,
			};
			this.withdraw = {};
			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					this.withdraw = _.union(this.withdraw, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					// this.data         = result.data.data;
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(this.$t("no_more"));
					}
				}
			});
		},
	},
	mounted() {
		var that = this;
		// window.addEventListener("scroll", function () {
		// 	if (!isMobile()) return;

		// 	var v = $(document).height() - $(window).height() - 1;
		// 	// console.log($(document).scrollTop(),v)
		// 	if ($(document).scrollTop() >= v) {
		// 		// console.log('我到底了');
		// 		if ($(".notMore").css("display") != "block") {
		// 			that.page++;
		// 			that.getlog();
		// 		}
		// 	}
		// });
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,
body {
	background: url(~@/assets/images/bg-black.png) !important;
}
.main-container {
	padding-bottom: 0;
}
.btn-select button.active {
	border: 1px solid $yellow-500;
	color: $yellow-500;
}
.btn-select button {
	position: relative;
	text-align: center;
	border: 1px solid $white;
	margin: 0 10px 10px 0;
	padding: 10px 10px;
	color: $white;
	border-radius: 12px;
	font-weight: bold;
}
.section-padding {
	padding: 20px 15px;
}
.recharge_amount {
	display: flex;
	font-size: 24px;
	color: $white;
	padding: 20px 0 0;
}
.recharge_amount .currency {
	width: 10%;
}
.recharge_amount input {
	width: 90%;
	margin-left: 20px;
	background: none;
	border: none;
	color: $yellow-500;
}
.recharge_name {
	display: flex;
	font-size: 18px;
	color: $white;
	padding: 20px 0;
}
.recharge_name .name {
	width: 20%;
}
.error_message{
	margin: 5px auto 0 0;
	font-size: 12px;
	color: map-get($theme-colors, 'danger');
}
.recharge_name input {
	width: 80%;
	margin-left: 20px;
	background: none;
	border: none;
	color: $yellow-500;
}
.termsandconditions {
	padding: 10px 0 20px;
}
label {
	display: inline-block;
}
.bottomMessage {
	padding: 40px 0;
	color: $yellow-500;
}
ul.details-list li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0 20px 0;
	border-bottom: solid 1px $black-300;
	color: $gray-400;
}
ul.details-list li > div:first-child {
	color: $yellow-500;
}
.listing-log .recharge .status-reject {
	color: map-get($theme-colors, 'danger');
}
.listing-log li {
	display: flex;
	color: $white;
	background: rgba($black-100, 0.6);
	border-bottom: dashed 1px $black-300;
	padding: 18px 30px;
	justify-content: space-between;
}
.listing-log .title {
	display: flex;
}
.listing-log .recharge {
	display: flex;
}
.listing-log .more {
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: right;
}
.form-control,
.form-control:focus {
	color: $white;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $gray-600;
	border-radius: 0;
	padding: 10px 10px 10px 10px;
	box-shadow: none;
}
.font-red {
	color: map-get($theme-colors, 'danger');
}
.paydetails {
	background: $black-400;
    // height: 100%;
	height: calc(100vh - 64px);
    position: fixed;
    width: 100%;
    // top: 54px;
    top: 0;
	left:0;
    z-index: 1000;

	a.close {
		position: fixed;
		color: $white;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		width: 100%;
		background: rgba($black-100, 0.376);
		bottom: 0;
		padding: 20px;
		z-index: 20;
	}
}

iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    height: calc(100vh - 54px);        /* Viewport-relative units */
    width: 100vw;
	z-index: 10;
	position: relative;
}
</style>
<template v-if="data.length != 0">
	<base-layout3
		:page-title="$t('account_withdraw_withdraw')"
		:show-logo="false"
		:showBackButton="true"
		mode="with-logo"
	>

		<div class="paydetails" :class="!isPayFrame ? 'd-none' : ''">
			<div style="position: absolute;top: 50%;right: 40vw;z-index:1">
				<div class="spinner-border" style="width: 5rem; height: 5rem;color:grey"></div>
			</div>
			<iframe :src="payurl" class="" ></iframe>
			<a @click="this.closeiframe()" class="close bg-dark">{{$t('trade_close')}}</a>
		</div>

		<div class="withdraw">
			<div class="main-container">
				<div>
					<h3 class="section-title">{{ $t("withdraw_payment_method") }}</h3>
					<div class="section-padding">
						<div class="btn-select">
							<button
									v-for="(item, k) in paymentgayway" :key="k"
								class="btn"
								:class="pay_method == item.code ? 'active' : ''"
								@click="pay_method = item.code;getbanks(item)"
							>
								{{banklang(item)}}
							</button>
						</div>
					</div>
					<!-- <div class="section-padding">
						<div class="btn-select">
							<button value="bank transfer" class="btn active">
								{{ $t("account_withdraw_bank_card") }}
							</button>
						</div>
					</div> -->
					<!-- <h3 class="section-title">{{ $t("withdraw_amount") }}</h3> -->
					<div class="section-padding">
						<!-- <div class="btn-select">
							<button
								value="100"
								class="btn"
								:class="withdraw_amt.val == 100 ? 'active' : ''"
								@click="withdraw_amt.val = 100"
							>
								{{ currency }}100
							</button>
							<button
								value="300"
								class="btn"
								:class="withdraw_amt.val == 300 ? 'active' : ''"
								@click="withdraw_amt.val = 300"
							>
								{{ currency }}300
							</button>
							<button
								value="600"
								class="btn"
								:class="withdraw_amt.val == 600 ? 'active' : ''"
								@click="withdraw_amt.val = 600"
							>
								{{ currency }}600
							</button>
							<button
								value="1000"
								class="btn"
								:class="withdraw_amt.val == 1000 ? 'active' : ''"
								@click="withdraw_amt.val = 1000"
							>
								{{ currency }}1000
							</button>
						</div>
						<div class="recharge_amount">
							<div class="currency">{{ currency }}</div>
							<input
								type="number"
								v-model="withdraw_amt.val"
								@keyup="withdraw_cal($event)"
							/>
						</div> -->

						<ul class="details-list">
							<li>
								<div >{{ $t("account_withdraw_balance") }}</div>
								<div>{{ currency }} {{ balance }}</div>
							</li>
							<li>
								<div style="width: 100%">{{ $t("withdraw_amount") }}</div>
									<input
										type="number"
										class="form-control text-end "
										v-model="withdraw_amt.val"
										@keyup="withdraw_cal($event)"
									/>
								<div class="currency">{{ currency }}</div>
							</li>
							<li>
								<div>{{ $t("account_withdraw_rate") }}</div>
								<div>{{ withdraw_fee }}%</div>
							</li>
							<li >
								<div>{{ $t("withdraw_total_payment") }}</div>
								<div>{{ currency }} {{ total_rate }}</div>
							</li>
							<li>
								<div style="width: 75%">
									{{ $t("withdraw_secpassword") }}
								</div>
								<div>
									<input
										type="password"
										class="form-control text-end "
										v-model="secpassword.val"
										:placeholder="$t('withdraw_secpassword')"
										@blur="clearValidity('phone')"
									/>
									<div v-if="!secpassword.isValid" class="error_message p10">
										{{ $t("withdraw_secpassword_err") }}
									</div>
								</div>
							</li>
							<li v-if="pay_method==0">
								<div>{{ $t("system_bank_card1") }}</div>
								<div>{{ bank_account }}</div>
							</li>
							<!-- <li>
								<div>{{ $t("account_withdraw_real_name") }}</div>
								<div>{{ bank_account_name }}</div>
							</li> -->

							<li v-if="isbanks|| isaccount=='1'">
								<div style="width: 75%">
									{{ $t("system_bank_card2") }}
								</div>
								<div>
									<input
										type="text"
										class="form-control text-end "
										v-model="receive_account.val"
										:placeholder="$t('system_bank_card2')"
										@blur="clearValidity('receive_account')"
									/>
									<div v-if="!receive_account.isValid" class="error_message p10">
										{{ $t("bank_account_err") }}
									</div>
								</div>
							</li>

							<li v-if="isbanks|| isaccount=='1'">
								<div style="width: 75%">
									{{ $t("accountt_withdraw_real_name") }}
								</div>
								<div>
									<input
										type="text"
										class="form-control text-end "
										v-model="receive_name.val"
										:placeholder="$t('accountt_withdraw_real_name')"
										@blur="clearValidity('receive_name')"
									/>
									<div v-if="!receive_name.isValid" class="error_message p10">
										{{ $t("accountt_withdraw_real_name") }}
									</div>
								</div>
							</li>


							<li v-if="isbanks">
								<div style="width: 75%">
									{{ $t("bank_branch_err") }}
								</div>
								<div>
									<select class="form-control"  v-model="bank_code.val"  style="background:black">
										<option value="" >--{{$t('bank_branch_err')}}--</option>
										<option :value="item.bank_code" v-for="(item, k) in  banks"  :key="k" >{{item.name}}</option>
									</select>
									<div v-if="!receive_name.isValid" class="error_message p10">
										{{ $t("bank_branch_err") }}
									</div>
								</div>
							</li>


							<li>
								<div>{{ $t("account_withdraw_damaling") }}</div>
								<div class="text-end">
									<span class="mr-4">{{ damaliang }}</span>
									<br/>
									<small class="font-red">【{{ $t("account_withdraw_damaling1") }}】</small>
								</div>
							</li>
							<li style="display:none">
								<div>{{ $t("account_withdraw_cash") }}</div>
								<div>
									<input
										type="radio"
										id="tac"
										value="credit2"
										v-model="draw_type"
									/>
									<label for="tac">{{ $t("account_withdraw_balance") }}</label>
								</div>
							</li>
							<li style="display:none">
								<div style="width: 100%">
									{{ $t("account_withdraw_mobile_phone") }}
								</div>
								<div>
									<input
										type="text"
										class="form-control text-end "
										v-model="phone.val"
										:placeholder="$t('account_withdraw_enter_mobile')"
										@blur="clearValidity('phone')"
									/>
									<div v-if="!phone.isValid" class="error_message p10">
										{{ $t("account_withdraw_mobile_phone") }}
									</div>
								</div>
							</li>
						</ul>

						<div class="termsandconditions">
							<input type="checkbox" value="1" v-model="check" checked />
							<a @click="redirect('/account/terms')">
								<label class="terms">
									{{ $t("account_withdraw_terms") }}
								</label>
							</a>
						</div>

						<div class="largebtncontainer" @click="submitForm()">
							<base-button mode="default-btn" type="submit" class="largeGoldButton">
								{{ $t("account_withdraw_confirm_withdraw") }}
							</base-button>
						</div>
					</div>
				</div>

				<div class="container p-4">
					<div class="bottomMessage">
						<p>1. {{ $t("account_withdraw_minmax") }} : {{minmax}}</p>
						<p class="text-danger"><b>2. {{ $t("account_withdraw_time") }} : {{timelimit}}</b></p>
						<p  @click="redirect('/account/secpassword')">3. {{ $t("account_withdraw_forgot_pass") }} <b class="text-danger"> {{ $t("account_withdraw_findback") }}</b></p>
					</div>
				</div>

				<div style="display:none">
					<h3 class="section-title">
						{{ $t("wallet_withdraw_log") }}
						<span class="iconRefresh" @click="refreshlog(1)">
							<font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
						</span>
					</h3>
					<ul class="listing-log">
						<li v-for="(item, k) in withdraw" :key="k">
							<div class="title">
								<div>
									<span
										><font-awesome-icon
											:icon="['far', 'calendar']"
											class="me-auto"
										/>
										{{ item.create_time }}</span
									>
								</div>
							</div>
							<div class="recharge">
								<div v-if="item.status == 1">
									<span class="status status-complete"
										><font-awesome-icon
											:icon="['fas', 'check']"
											class="me-auto"
										/>
										{{ $t("wallet_user_withdraw_success") }}
									</span>
									<br />
									<span
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>
								<div v-if="item.status == -1">
									<span class="status status-reject"
										><font-awesome-icon
											:icon="['fas', 'check']"
											class="me-auto"
										/>
										{{ $t("wallet_user_withdraw_fail") }}
									</span>
									<br />
									<span style="color: #fc445f"
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>
								<div v-if="item.status == 0">
									<span class="status" style="color: #fc7940"
										><font-awesome-icon
											:icon="['fas', 'clock']"
											class="me-auto"
										/>
										{{ $t("wallet_user_withdraw_pending") }}
									</span>
									<br />
									<span style="color: #fc7940"
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>
							</div>
						</li>
					</ul>

					<div
						class="col-12 pb-5"
						style="
							display: block;
							text-align: center;
							width: 100%;
							padding: 0.2rem 0;
						"
					>
						<span class="notMore text-white">{{ $t("drag_more") }}</span>
					</div>
				</div>
			</div>
		</div>
	</base-layout3>
</template>
