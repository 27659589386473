
<script>
import Post from "@/components/Notification.vue";
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
export default {
	name: "Notification",
	data() {
		return {
			data          : [],
			inbox         : [],
			current_page  :  1,
			last_page     :  1,
			per_page      :  10,
			total         :  0,
			page          :  1,
		};
	},
	components: {
		Post,
	},
	methods: {
		getPost() {
			var msg = this.$t('no_more');
			// console.log(msg)
			this.$http("", "/home/inbox/inbox_ajax?page="+this.page, {'per_page':this.per_page},'post')
			.then((result) => {
			this.data  = result.data;
			this.inbox = _.union(this.inbox , result.data.notification.data);

			this.current_page = result.data.notification.current_page || 1;
			this.last_page    = result.data.notification.last_page || 1;
				if(result.code == "0001") {
					if(_.size(result.data.notification.data)==0)
						$('.notMore').css('display', 'block').html(msg);
				}
			});
		},
		scroll() {
			if (!isMobile()) return;

			var v = $(document).height() - $(window).height() - 1;
			// console.log($(document).scrollTop(),v)
			if ($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if ($(".notMore").css("display") != "block") {
				this.page++;
				this.getPost();
				}
			}
		}
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/login');
		// console.log(token)
		this.getPost();
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", this.scroll);
	},
	unmounted() {
	},
	watch: {
		$route(to, from) {
			window.removeEventListener('scroll', this.scroll);
		},
	},

};
</script>

<style scoped>
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
}
</style>

<template>
  <base-layout3
	:page-title="$t('index_inbox')"
	:show-back-button="true"
	:show-footer="false"
	mode=""
  >
	<div class="container">
		<div class="main-container row ">
			<ul class="listing-announcement">
				<Post v-for="(item, i) in inbox" :key="i" :post="item" :data="data" />
			</ul>
			<div class="col-12 mt-5" style="display: block;text-align: center;width:100%;">
				<span class="notMore text-light">{{$t('drag_more')}}</span>
			</div>
		</div>
	</div>
  </base-layout3>
</template>