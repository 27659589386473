<template>

            <base-layout3
		:page-title="$t('account_review_account_bind')"
		:show-logo="false"
		:showBackButton="true"
		mode="with-logo"
            >
		<div class="container pt-4">

				<div class="main-container container">
					<ul class="text-white ">
						<li>
							<div style="font-size:12px;"> {{$t('account_review_upload_photo')}}：
								<span class="numbers float-end p-r-5"> {{max_upload}} </span>
							</div>
							<div class="task-item no-border ">
								<div class="images-box justify-content-center">
									<div class="item upload-div text-center">
										<img src="@/assets/images/img-upload.png" onclick="document.getElementById('file-input').click()" id="imageId" class="uploadForm img-fluid" style="float: unset;" />
									</div>
								</div>
								<div class="files" style="display: none">
									<input type="file" accept="image/*" name="thumbs[]" id="file-input" @change="triggerUpload($event)">
								</div>
								<div v-if="!files.isValid" class="error_message p10 text-center ">
									{{ $t("recharge_payment_pic_no_found") }}
								</div>
							</div>

							<div class="col-12 small text-center  mt-1">
								{{$t('account_review_note_1')}}<br>
								{{$t('account_review_note_2')}}
							</div>
						</li>
					</ul>

					<ul class="menu_listing">
						<!-- <li>
							<div class="menu_item">{{$t('account_review_social_id1')}}</div>
							<div class="col-7">
								<input type="tk" class="form-control b-none" :placeholder="$t('account_review_social_id')" v-model="wechat_id.val"
								@blur="clearValidity('wechat_id')"
								/>
								<div v-if="!wechat_id.isValid" class="error_message p10">
									{{ $t("account_review_social_id") }}
								</div>
							</div>
						</li> -->

						<li>
							<div class="menu_item">{{$t('account_review_social_id1')}}</div>
							<div class="col-7">
								<input type="tk" class="form-control b-none" :placeholder="$t('account_review_social_id')" v-model="identity_name.val"
								@blur="clearValidity('identity_name')"
								/>
								<div v-if="!identity_name.isValid" class="error_message p10">
									{{ $t("account_review_social_id1") }}
								</div>
							</div>
						</li>

						<li>
							<div class="menu_item">{{$t('account_identity')}}</div>
							<div class="col-7">
								<input type="tk" class="form-control b-none" :placeholder="$t('account_identity2')" v-model="identity.val"
								@blur="clearValidity('identity')"
								/>
								<div v-if="!identity.isValid" class="error_message p10">
									{{ $t("account_identity2") }}
								</div>
							</div>
						</li>

						<li style="display:none">
							<div class="menu_item">{{$t('account_review_name1')}}</div>
							<div class="col-7">
								<input type="tk" class="form-control b-none" :placeholder="$t('account_review_name')" v-model="wechat_name.val"
								@blur="clearValidity('wechat_name')"
								/>
								<div v-if="!wechat_name.isValid" class="error_message p10">
									{{ $t("account_review_name") }}
								</div>
							</div>
						</li>
						<li style="display:none">
							<div class="menu_item">{{$t('login_label_phone')}}</div>
							<div class="col-7">
								<input type="tk" class="form-control b-none" :placeholder="$t('login_label_phone')" v-model="contact_number.val"
								@blur="clearValidity('contact_number')"
								/>
								<div v-if="!contact_number.isValid" class="error_message p10">
									{{ $t("login_label_phone") }}
								</div>
							</div>
						</li>
					</ul>
				</div>

				<div class="main-container  mt-5" >
					<h3 class="section-title" style="display:none">{{$t('account_info_bank_card_info')}}</h3>

					<form @submit.prevent="submitForm">
						<ul class="menu_listing" style="display:none">
							<li>
								<div class="menu_item">{{$t('account_withdraw_real_name')}}</div>
								<div class="col-7">
									<input type="text" class="form-control b-none" :placeholder="$t('account_withdraw_real_name1')" v-model="bank_account_name.val"
										@blur="clearValidity('bank_account_name')"
										/>
										<div v-if="!bank_account_name.isValid" class="error_message p10">
											{{ $t("account_withdraw_real_name1") }}
										</div>
								</div>
							</li>
							<li>
								<div class="menu_item">{{$t('system_bank_card1')}}</div>
								<div class="col-7">
									<input type="text" class="form-control b-none" :placeholder="$t('system_bank_card')" v-model="bank_account.val"
										@blur="clearValidity('bank_account')"
										/>
										<div v-if="!bank_account.isValid" class="error_message p10">
											{{ $t("system_bank_card") }}
										</div>
								</div>
							</li>
							<li>
								<div class="menu_item">{{$t('bank_branch_err')}}</div>
								<div class="col-7">
									<input type="text" class="form-control b-none" :placeholder="$t('system_bank_branch')" v-model="bank_branch.val"
										@blur="clearValidity('bank_branch')"
										/>
										<div v-if="!bank_branch.isValid" class="error_message p10">
											{{ $t("system_bank_branch") }}
										</div>
								</div>
							</li>
						</ul>

						<div class="largeButtonContainer">
							<button type="submit" class="largeGoldButton opacity-unset" @click="submitForm()">{{$t('account_review_submit')}}</button>
						</div>
					</form>
				</div>
		</div>
	</base-layout3>
</template>

<script>
import $ from 'jquery';
import { getLocal} from '@/utils/local.js';
export default {
	name: "login",
	components: {},
	data() {
		return {
			lock:0,
			max_upload : "0/1",
			token : null,
			data:{

			},
			files: {
				val: "",
				isValid: true,
			},
			wechat_id: {
				val: "",
				isValid: true,
			},
			wechat_name: {
				val: "",
				isValid: true,
			},
			bank_account_name: {
				val: "",
				isValid: true,
			},
			bank_account: {
				val: "",
				isValid: true,
			},
			bank_branch: {
				val: "",
				isValid: true,
			},
			contact_number: {
				val: "",
				isValid: true,
			},
			identity: {
				val: "",
				isValid: true,
			},
			identity_name: {
				val: "",
				isValid: true,
			},
			formIsValid: true,
		};
	},
	mounted() {},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal('token') || '';
		this.token= token;
		if(!token)
			this.$router.push('/');
		this._config();
	},
	methods: {
		clearValidity(input) {
			this[input].isValid = true;
		},
		triggerUpload(e){
			const image = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(image);

			this.files.val = e.target.files;
			this.max_upload = "1/1";

			reader.onload = e =>{
				// this.previewImage = e.target.result;
				this.files.isValid = true;
				document.getElementById('imageId').src = e.target.result;
				// console.log( e.target.result);
			};
		},
		validateForm() {
			this.formIsValid = true;
			if (this.files.val === "") {
				this.files.isValid = false;
				this.formIsValid = false;
			}
			if (this.identity.val === "") {
				this.identity.isValid = false;
				this.formIsValid = false;
			}
			if (this.identity_name.val === "") {
				this.identity_name.isValid = false;
				this.formIsValid = false;
			}
			// if (this.wechat_id.val === "") {
			// 	this.wechat_id.isValid = false;
			// 	this.formIsValid = false;
			// }
			// if (this.wechat_name.val === "") {
			// 	this.wechat_name.isValid = false;
			// 	this.formIsValid = false;
			// }
			// if (this.bank_account_name.val === "") {
			// 	this.bank_account_name.isValid = false;
			// 	this.formIsValid = false;
			// }
			// if (this.bank_account.val === "") {
			// 	this.bank_account.isValid = false;
			// 	this.formIsValid = false;
			// }
			// if (this.bank_branch.val === "") {
			// 	this.bank_branch.isValid = false;
			// 	this.formIsValid = false;
			// }
		},
		submitForm() {
			var that = this;
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}

			this.files.isValid = true;

			var formData = new FormData($("#form1")[0]);
			formData.append('thumbs[]', this.files.val[0]);
			formData.append('wechat_id', this.wechat_id.val);
			formData.append('wechat_name', this.wechat_name.val);
			formData.append('bank_account_name', this.bank_account_name.val);
			formData.append('bank_account', this.bank_account.val);
			formData.append('bank_branch', this.bank_branch.val);
			formData.append('contact_number', this.contact_number.val);
			formData.append('identity', this.identity.val);
			formData.append('identity_name', this.identity_name.val);

			this.$http("", "/home/account/review", formData,'postform')
				.then((result) => {
					if(result.code == "0001"){
						this.$toast.success((result.message));
						var f = this.$route.query.f;
						setTimeout(function(){
							if(f=="true")
								that.$router.push('/account/withdraw');
							else
								that.$router.push('/account');
							// window.location.reload();
						}, 1000);

					}else{
						this.lock = 0;
						this.$toast.error(this.$t(result.message));
					}
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e)
				});
		},
		_config() {
			var that = this;
			this.$http("", "/home/account/withdraw_config.html", {},'post')
				.then((result) => {
					if(result.code == "0001"){
						this.data = result.data;

						if(this.data.member.is_bind==1 || (this.data.member.status!=0 && this.data.member.status !=3) ){
							if(this.data.member.status==1)
								this.$toast.success(this.$t('account_index_note'));
							if(this.data.member.status==2)
								this.$toast.success(this.$t('account_index_completed_information'));

							setTimeout(function(){
								that.$router.push('/account');
							}, 2000);

						}

						if(this.data.member.wechat_name!=null)
							this.wechat_name.val = this.data.member.wechat_name;
						if(this.data.member.wechat_id!=null)
							this.wechat_id.val = this.data.member.wechat_id;

						if(this.data.member.bank_branch!=null)
							this.bank_branch.val = this.data.member.bank_branch;

						if(this.data.member.bank_account_name!=null)
							this.bank_account_name.val = this.data.member.bank_account_name;

						if(this.data.member.bank_account!=null)
							this.bank_account.val = this.data.member.bank_account;

						if(this.data.member.contact_number!=null)
							this.contact_number.val = this.data.member.contact_number;

						if(this.data.member.identity_name!=null)
							this.identity_name.val = this.data.member.identity_name;
					}
				})
				.catch((e) => {
					console.log(e)
				});


		},
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container{
	padding-bottom : 0;
}

ul.menu_listing .menu_item {
	display: flex;
	align-items: center;
	justify-content: center;
}
ul.menu_listing li {
	display: flex;
	color: $white;
	border-bottom: solid 1px $black-300;
	padding: 18px 0;
	justify-content: space-between;
}
.b-none {
	border: none!important;
}
.form-control, .form-control:focus {
	color: $white;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $gray-600;
	border-radius: 0;
	padding: 10px 10px 10px 10px;
	box-shadow: none;
}
.p10{
	margin: 0 0 0 10px;
}
.container img {
	width: initial !important;;
}
</style>
