<script>
import { getLocal } from '@/utils/local.js';
import $ from 'jquery';
import { _ } from 'vue-underscore';
export default {
  data() {
    return {
      qrcode: [],
      user: {
        member: {
          username: null,
          member_uid: null,
          wechat_id: null,
          wechat_name: null,
          bank_account_name: null,
          bank_account: null,
          bank_branch: null,
        },
      },
      name: null,
      balance: 0,
      avatar: null,
      currency: getLocal('currency') || 'RM',
    };
  },
  created() {},
  updated() {},
  unmounted() {},
  beforeMount() {
    let token = getLocal('token') || '';
    if (!token) this.$router.push('/');
    // console.log(token)
    this.get_user_init();
  },
  methods: {
    get_user_init() {
      this.$http('', '/home/account', [], 'post')
        .then((result) => {
          // console.log(result)
          if (result.code == '0001') {
            this.user = result.data;
            // console.log(this.user)
            // console.log(this.user.length)
            var url =
              window.location.protocol + '//' + window.location.host + '/';
            // return console.log(url)
            this.qrcode =
              'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
              url +
              '/register?i=' +
              result.data.member.uid +
              '&choe=UTF-8';
            this.avatar = require('@/assets/images/avatar/' +
              this.user.member.avatar);
            this.name = this.user.member.name || null;
            this.balance = this.user.member.credit2 || null;

            // console.log(this.avatar)
          } else {
            this.signUp();
            // this.$toast.error(result.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    redirect(url) {
      setTimeout(() => this.$router.push(url), 200);
    },

    checkNull(item) {
      if (_.isUndefined(item) || item == null || _.size(item) == 0)
        return false;

      return true;
    },
    signUp() {
      this.$toast.show(this.$t('c_no_login'));
      setTimeout(() => {
        this.$store.commit('deleteSession');
        this.redirect('/login');
      }, 500);
      return false;
    },
    copyToClipboard(element) {
      var $temp = $("<input style='opacity:0;'>");
      $('body').append($temp);
      $temp.val($(element).text()).select();
      document.execCommand('copy');
      $temp.remove();

      this.$toast.success(this.$t('account_copied_referral_code'));
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

html,
body {
  background: url(~@/assets/images/bg-black.png) !important;
}
.avatar.avatar-90 {
  line-height: 90px;
  height: 90px;
  width: 90px;
}
.coverimg {
  background-size: cover;
  background-position: center top;
}
.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}
.avatar > img {
  width: 100%;
  vertical-align: top;
}
.rounded-circle {
  border-radius: 50% !important;
}
.header-user {
  margin-top: 20px;
}
.user-profile .amount {
  color: $yellow-400;
}
.user-profile h5 {
  margin-bottom: 0px;
}
.quickWrap {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 20px 10px;
}
.quickWrap li {
  width: 100%;
  height: 100%;
  text-align: center;
}
ul {
  margin: 0;
  list-style-type: none;
}
ul.menu_listing {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 20px 30px;
}
ul.menu_listing li {
  display: flex;
  color: $white;
  border-bottom: solid 1px $black-300;
  padding: 18px 0;
  justify-content: space-between;
}
ul.menu_listing .menu_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.menu_listing .icon {
  margin-right: 10px;
  margin-top: -3px;
}
</style>

<template v-if="user.length != 0">
  <base-layout3
    :page-title="$t('account_index_user_center')"
    :show-logo="false"
    :showBackButton="true"
    mode="with-logo"
  >
    <div class="container-fluid" style="padding-bottom: 86px">
      <ul class="menu_listing">
        <li>
          <div class="menu_item">
            <div>{{ $t('account_pic') }}</div>
          </div>
          <div class="avatar avatar- coverimg rounded-circle">
            <img :src="avatar" />
          </div>
        </li>
        <li>
          <div class="menu_item">
            <div>{{ $t('account_info_username') }}</div>
          </div>
          <div class="light-font-2">{{ user.member.username }}</div>
        </li>
        <li>
          <div class="menu_item">
            <div>{{ $t('reg_label_realname') }}</div>
          </div>
          <div class="light-font-2">{{ user.member.realname || '-' }}</div>
        </li>
        <!-- <li>
					<div class="menu_item">
						<div>{{$t('account_info_referral_code')}}</div>
					</div>
					<div class="light-font-2">
						<span id="referral_code">{{user.member.uid}}</span>
						&emsp;
						<font-awesome-icon
						:icon="['far', 'clone']"
						class="me-auto"
						@click="this.copyToClipboard('#referral_code')"
						/>
					</div>
				</li> -->
      </ul>

      <ul class="menu_listing">
        <li class="">
          {{ $t('account_info_account_infos') }}
        </li>
        <!-- <li>
					<div class="menu_item">
						<div>{{$t('account_info_wechat_id')}}</div>
					</div>
					<p class="light-font-2">
						<span v-if="!checkNull(user.member.wechat_id)">
							<a @click="$router.push('/account/review')" style="color:#007aff;text-decoration:underline;">{{$t('account_info_unbound')}}</a>
						</span>
						<span v-if="checkNull(user.member.wechat_id)">{{user.member.wechat_id}}</span>
					</p>
				</li> -->
        <!-- <li>
					<div class="menu_item">
						<div>{{$t('account_info_wechat_nickname')}}</div>
					</div>
					<p class="light-font-2">
						<span v-if="!checkNull(user.member.wechat_name)">
							<a @click="$router.push('/account/review')" style="color:#007aff;text-decoration:underline;">{{$t('account_info_unbound')}}</a>
						</span>
						<span v-if="checkNull(user.member.wechat_name)">{{user.member.wechat_name}}</span>
					</p>
				</li> -->
        <li @click="redirect('/account/password')">
          <div class="menu_item">
            <div>{{ $t('account_info_change_password') }}</div>
          </div>
          <div>
            <font-awesome-icon
              :icon="['fa', 'chevron-right']"
              class="me-auto"
            />
          </div>
        </li>
        <li @click="redirect('/account/secpassword')">
          <div class="menu_item">
            <div>{{ $t('account_info_change_secpassword') }}</div>
          </div>
          <div>
            <font-awesome-icon
              :icon="['fa', 'chevron-right']"
              class="me-auto"
            />
          </div>
        </li>
      </ul>

      <ul class="menu_listing">
        <li class="">
          {{ $t('account_info_bank_card_info') }}
        </li>
        <li @click="redirect('/account/bank')">
          <div class="menu_item">
            <div>{{ $t('account_info_account_name') }}</div>
          </div>
          <div class="light-font-2">
            {{ user.member.bank_account_name }}&emsp;<font-awesome-icon
              :icon="['fa', 'chevron-right']"
              class="me-auto"
            />
          </div>
        </li>
        <li @click="redirect('/account/bank')">
          <div class="menu_item">
            <div>{{ $t('account_info_bank_account') }}</div>
          </div>
          <div class="light-font-2">
            {{ user.member.bank_account }}&emsp;<font-awesome-icon
              :icon="['fa', 'chevron-right']"
              class="me-auto"
            />
          </div>
        </li>
        <li @click="redirect('/account/bank')">
          <div class="menu_item">
            <div>{{ $t('account_info_bank_branch') }}</div>
          </div>
          <div class="light-font-2">
            {{ user.member.bank_branch }}&emsp;<font-awesome-icon
              :icon="['fa', 'chevron-right']"
              class="me-auto"
            />
          </div>
        </li>
      </ul>
      <div class="largebtncontainer">
        <base-button
          mode="default-btn"
          type="submit"
          class="largeGoldButton"
          @click="signUp()"
          >{{ $t('account_index_logout') }}</base-button
        >
      </div>
    </div>
  </base-layout3>
</template>
