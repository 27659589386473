import tmpl from './plugin/template.js'
let theme = tmpl;
import(`@/assets/scss/themes/${theme}/${theme}.scss`);
import(`@/assets/scss/themes/${theme}/${theme}_reset.scss`);

// import "./assets/scss/main.scss";
// import "./assets/scss/reset.scss";

export default {
  install() {},
};
