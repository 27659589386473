

<script>
import moment from 'moment-timezone';
import {_} from 'vue-underscore';

export default {
	data() {
			return {
				id: '',
				data: {
					title: '',
					content: '',
					date: '',
				},
				list : [],
			};
		},
		methods: {
			parseDate(date)
			{
				return moment(date).format('YYYY/MM/DD');
			},
			get_title(item)
			{
				var find = _.find(this.list, function(i){
					return i.type_id== item.type;
				});
				if(!_.isUndefined(find)){
					return find.name[this.$i18n.locale];
				}
			},
			parseDate2(date)
			{
				return moment(date).format('HH:mm:s');
			},
		},
		mounted() {
			this.id = this.$route.query.id;
			if (this.id > 0 ) {
				this.$http("", "/home/inbox/detail", {nid:this.id})
				.then((result) => {
						this.list  = result.data.notification_type;
						if (typeof(result.data) != 'undefined' && (result.data) != ''  ) {
							this.data = result.data.notification;
							// console.log(this.data)
						}
				});
			}
		}
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.listing-announcement {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px 0px;
}
.listing-announcement {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}
.listing-announcement li {
    display: flex;
    color: $white;
    background: rgba($black-100,0.6);
    border-bottom: dashed 1px $black-300;
    padding: 18px 30px;
    justify-content: space-between;
}
.announcement-details {
    display: flex;
    flex-direction: column;
}
.announcement-details .top {
    display: flex;
    justify-content: space-between;
}
.announcement-details .details {
    margin-top: 20px;
    font-size: 14px;
}
</style>

<template>
  <base-layout3
	:page-title="$t('inbox_details')"
	:show-back-button="true"
  >
	<div class="containerfluid">
		<ul class="listing-announcement">
			<li class="announcement-details">
				<div class="top">
					<div class="title">
						<div class="icon"><img src="@/assets/images/svg2/inbox-announcement.svg" width="30" height="30" alt=""/></div>
						<div>
							<h2>{{ get_title(data) }}</h2>
							<span>{{data.title}}</span>
						</div>
					</div>
					<div>
						<small>{{ parseDate(data.send_date) }}</small>
						<br/>
						<small>{{ parseDate2(data.send_date) }}</small>
					</div>
				</div>
				<div class="details" v-html="data.content"></div>
			</li>
		</ul>
	</div>
  </base-layout3>
</template>