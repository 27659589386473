<template>
	<base-layout
		:show-logo="true"
		:page-title="$t('footer_home')"
		:show-footer="false"
		mode=""
	>

		<iframe :src="g_url" class=""></iframe>

	</base-layout>
</template>
<script>
import { getLocal} from '@/utils/local.js';
export default {
	data() {
		return {
			g_title : null,
			g_url   : null,
			brand   : null,
		};
	},
	created() {},
	mounted() {
			this.g_title = this.$route.query.title;
			this.g_url   = this.$route.query.url;
			this.g_url   = atob(this.g_url);
			console.log(this.g_url)
			this.brand   = this.$route.query.brand;
	},
	beforeMount() {
		let token  = getLocal('token') || null;
		this.login = token==null?false:true;
		this.img_url = process.env.VUE_APP_IMAGE_URL;
	},
	unmounted() {

	},
	components: {
	},
	methods: {
		redirect(url) {
			this.$router.push(url);
		},
	},
};
</script>

<style scoped>
iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    height: calc(100vh - 54px);        /* Viewport-relative units */
    width: 100vw;
}
</style>
