<script>
// import { getLocal } from '@/utils/local.js';
export default {
  data() {
      var lang = {
       // cn: '简体中文',
        en: 'English'
      };
     
     var env = process.env;
    if (env.VUE_APP_LANG_CN == '1'  ) {
        lang.cn = '简体中文';
    }
    return {
      // currency: getLocal('currency') || 'RM',
      lang: lang 
       // cn: '简体中文',
       // en: 'English',
      
      
      
    };
  },
  created() {},
  updated() {},
  unmounted() {},
  // beforeMount() {
  //   let token = getLocal('token') || '';
  //   if (!token) this.$router.push('/');
  // },
  methods: {
    switchLang(val) {
      if (val && this.$i18n.locale != val) {
        this.$i18n.locale = val;
        localStorage.setItem('lang', val);
        //this.$router.go(0);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,
body {
  background: url(~@/assets/images/bg-black.png) !important;
}
.avatar.avatar-90 {
  line-height: 90px;
  height: 90px;
  width: 90px;
}
.coverimg {
  background-size: cover;
  background-position: center top;
}
.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}
.avatar > img {
  width: 100%;
  vertical-align: top;
}
.rounded-circle {
  border-radius: 50% !important;
}
.header-user {
  margin-top: 20px;
}
.user-profile .amount {
  color: $yellow-400;
}
.user-profile h5 {
  margin-bottom: 0px;
}
.quickWrap {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 20px 10px;
}
.quickWrap li {
  width: 100%;
  height: 100%;
  text-align: center;
}
ul {
  margin: 0;
  list-style-type: none;
}
ul.menu_listing {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 20px 30px;
}
ul.menu_listing li {
  display: flex;
  color: $white;
  border-bottom: solid 1px $black-300;
  padding: 18px 0;
  justify-content: space-between;
}
ul.menu_listing .menu_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.menu_listing .icon {
  margin-right: 10px;
  margin-top: -3px;
}
.check-lang {
  position: absolute;
  right: 50px;
}
</style>

<!--<template v-if="user.length != 0">-->
<template>
  <base-layout3
    :page-title="$t('account_system_language')"
    :show-logo="flase"
    :showBackButton="true"
    :show-item="false"
    :show-footer="false"
    mode="with-logo"
  >
    <div class="container-fluid" style="padding-bottom: 86px">
      <ul class="menu_listing">
        <ul class="language-list">
          <li
            class="language-list-item"
            v-for="(item, index) in lang"
            :key="index"
            @click="switchLang(index)"
          >
            <span>
              {{ item }}
              <font-awesome-icon
                :icon="['fa', 'check']"
                class="me-auto check-lang"
                v-if="$i18n.locale == index"
              />
            </span>
          </li>
        </ul>
      </ul>
    </div>
  </base-layout3>
</template>
