<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["mode"],
};
</script>

<style scoped>
button {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
button.material {
  background: linear-gradient(#0c91d7, #2143bf);
}
button.material:hover,
button.material:active {
  background: linear-gradient(#0c91d7, #2143bf);
}
button.material.round {
  border-radius: 25px;
}
button.flat {
  background-color: transparent;
  color: #ffffff;
  box-shadow: 0 0 5px #d3def1;
}
button.flat:hover,
button.flat:active {
  background-color: #d2ddff;
}

button.dark {
  color: #fff;
  background-color: #212529;
  box-shadow: 3px 3px 1px #8ba9f7, 0 0 5px #8ba9f7;
}
button.outline-blue {
  border: solid 1px #2142bf;
  font-size: 18px;
  border-radius: 25px;
  background: #fff;
  color: #2142bf;
  width: 100%;
}

button.purchase-btn {
  border: 1px solid #eadc85;
  background: transparent;
  color: #eadc85;
  border-radius: 30px;
  font-size: 12px;
}
button.default-btn {
  border: 1px solid #eadc85;
  background: #1f1f1d;
  color: #eadc85;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600; 
  width: 100%;
  max-width: 192px;
  height: 50px;
  margin: auto;
}
button.theme2_btn{
  border: 1px solid #3499FF;
  background: #1C203A;
  color: #fff;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600; 
  width: 100%;
  max-width: 192px;
  height: 50px;
  margin: auto;
}
</style>
