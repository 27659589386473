<template v-if="data.length != 0">
  <base-layout3
    :page-title="$t('wallet_withdraw_log')"
    :show-logo="false"
    :showBackButton="true"
    mode="with-logo"
  >
    <div class="">
      <div class="main-container">
        <div>
          <h3 class="section-title">
            {{ $t("wallet_withdraw_log") }}
            <span class="iconRefresh" @click="refreshlog(1)">
              <font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
            </span>
          </h3>
          <ul class="listing-log">
            <li
              v-for="(item, k) in recharge"
              :key="k"
              @click="recharge_redirect(item)"
            >
              <div class="title">
                <div>
                  <h2>{{ item.id }} {{ item.order_no }}</h2>
                  <span
                    ><font-awesome-icon
                      :icon="['far', 'calendar']"
                      class="me-auto"
                    />
                    {{ item.create_time }}</span
                  >
                </div>
              </div>
              <div class="recharge">
                <div v-if="item.status == 1">
                  <span class="status status-complete text-success"
                    ><font-awesome-icon
                      :icon="['fas', 'check']"
                      class="me-auto"
                    />
                    {{ $t("wallet_user_cash_success") }}
                  </span>
                  <br />
                  <span class="text-success"
                    ><sup>{{ currency }}</sup
                    >{{ item.credit2 }}</span
                  >
                </div>
                <div v-if="item.status == -1">
                  <span class="status status-reject"
                    ><font-awesome-icon
                      :icon="['fas', 'check']"
                      class="me-auto"
                    />
                    {{ $t("wallet_user_cash_fail") }}
                  </span>
                  <br />
                  <span style="color: #fc445f"
                    ><sup>{{ currency }}</sup
                    >{{ item.credit2 }}</span
                  >
                </div>
                <div v-if="item.status == 0">
                  <span class="status" style="color: #fc7940"
                    ><font-awesome-icon
                      :icon="['fas', 'clock']"
                      class="me-auto"
                    />
                    {{ $t("wallet_user_withdraw_pending") }}
                  </span>
                  <br />
                  <span style="color: #fc7940"
                    ><sup>{{ currency }}</sup
                    >{{ item.credit2 }}</span
                  >
                </div>
              </div>
            </li>
          </ul>

          <div
            class="col-12 pb-5"
            style="display: block; text-align: center; width: 100%"
          >
            <span class="notMore text-white">{{ $t("drag_more") }}</span>
          </div>
        </div>
      </div>
    </div>
  </base-layout3>
</template>

<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
export default {
  name: "login",
  components: {},
  data() {
    return {
      lock: 0,
      pay_method: 0,
      data: {},
      recharge: {},
      currency: getLocal("currency") || "RM",
      realname: {
        val: "",
        isValid: true,
      },
      recharge_amt: {
        val: 100,
        isValid: true,
      },
      recharge_online_banking: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
      page: 1,
      formIsValid: true,
    };
  },
  created() {},
  updated() {},
  unmounted() {},
  beforeMount() {
    let token = getLocal("token") || "";
    this.token = token;
    if (!token) {
      this.$router.push("/login");
    } else {
      this.getlog();
    }
  },
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
    getlog() {
      var per_page = 12;
      var data = {
        type: "withdraw",
        per_page: per_page,
      };
      var that = this;
      var a = that.$t("no_more");
      this.$http(
        "",
        "/home/account/wallet_topup.html?page=" + this.page,
        data,
        "post"
      ).then((result) => {
        if (result.code == "0001") {
          this.recharge = _.union(this.recharge, result.data.data);
          this.current_page = result.data.current_page || 1;
          this.last_page = result.data.last_page || 1;
          this.per_page = result.data.per_page || 1;
          this.total = result.data.total || 10;
          this.data = result.data.data;
          // console.log(_.size(this.recharge))
          // console.log(this.recharge)
          if (_.size(result.data.data) == 0) {
            $(".notMore").css("display", "block").html(a);
          }
        }
      });
    },
    refreshlog(num) {
      this.page = num;
      var per_page = 12;
      var data = {
        type: "withdraw",
        per_page: per_page,
      };
      this.recharge = {};
      this.$http(
        "",
        "/home/account/wallet_topup.html?page=" + this.page,
        data,
        "post"
      ).then((result) => {
        if (result.code == "0001") {
          this.recharge = _.union(this.recharge, result.data.data);
          this.current_page = result.data.current_page || 1;
          this.last_page = result.data.last_page || 1;
          this.per_page = result.data.per_page || 1;
          this.total = result.data.total || 10;
          this.data = result.data.data;
          if (_.size(result.data.data) == 0) {
            $(".notMore").css("display", "block").html(this.$t("no_more"));
          }
        }
      });
    },
    scroll() {
      if (!isMobile()) return;

      var v = $(document).height() - $(window).height() - 1;
      // console.log($(document).scrollTop(),v)
      if ($(document).scrollTop() >= v) {
        // console.log('我到底了');
        if ($(".notMore").css("display") != "block") {
          this.page++;
          this.getlog();
        }
      }
    }
  },
  mounted() {
    var that = this;
    window.addEventListener("scroll", this.scroll);
  },
  watch: {
	  $route(to, from) {
		  window.removeEventListener('scroll', this.scroll);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container {
  padding-bottom: 0;
}
.btn-select button.active {
  border: 1px solid $yellow-500;
  color: $yellow-500;
}
.btn-select button {
  position: relative;
  text-align: center;
  border: 1px solid $white;
  margin: 1%;
  width: 31%;
  padding: 10px 10px;
  color: $white;
  border-radius: 12px;
  font-weight: bold;
}
.section-padding {
  padding: 20px 15px;
}
.recharge_amount {
  display: flex;
  font-size: 24px;
  color: $white;
  padding: 20px 0 0;
}
.recharge_amount .currency {
  width: 10%;
}
.recharge_amount input {
  width: 90%;
  margin-left: 20px;
  background: none;
  border: none;
  color: $yellow-500;
}
.recharge_name {
  display: flex;
  font-size: 18px;
  color: $white;
  padding: 20px 0;
}
.recharge_name .name {
  width: 20%;
}
.recharge_name input {
  width: 80%;
  margin-left: 20px;
  background: none;
  border: none;
  border-radius: 5px;
  color: $yellow-500;
  box-shadow: 0 0 5px $yellow-500;
}
.termsandconditions {
  padding: 10px 0 20px;
}
label {
  display: inline-block;
}
.bottomMessage {
  padding: 40px 0;
  color: $yellow-500;
}
.listing-log .recharge .status-reject {
  color: map-get($theme-colors, 'danger');
}
.listing-log li {
  display: flex;
  color: $white;
  background: rgba($black-100, 0.6);
  border-bottom: dashed 1px $black-300;
  padding: 18px 30px;
  justify-content: space-between;
}
.listing-log .title {
  display: flex;
}
.listing-log .recharge {
  display: flex;
}
.listing-log .more {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
