// 工具型方法,用于保存 localStorage

function saveLocal(key, value) {
  let obj = JSON.stringify(value); // 转字符串
  typeof value == 'string' ? window.localStorage.setItem(key, value) : window.localStorage.setItem(key, obj)
}
// 获取token
function getLocal(key) {
  let str = window.localStorage.getItem(key)
  let reg = /\{.*?\}/g
  let obj = reg.test(str);
  return obj ? JSON.parse(str) : str
}
// 移除token
function removeLocal(key) {
  window.localStorage.removeItem(key)
}
/* 限制几位小数点 */
function limit(n,val){
  let str = val.toString(),result = val;
  if(str.indexOf(".")>-1){
    let arr = str.split(".");
    if(arr[1].length>0){
      result = arr[0]+"."+arr[1].slice(0,n)
    }
  }
  return result
}

//判断是否是在app打开
function getIsApp() {
  var ua = navigator.userAgent;
  var isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //android终端
  var isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid && ua.indexOf("Version") > -1) {
    return true
  }
  if (isiOS && ua.indexOf('Safari') === -1) {
    return true;
  }
  return false;
}

/**
 ** 字符串序列化函数
 ** 说明：obj 需要序列化的对象 
 ** 调用：stringify(obj, sep, eq)
 **/
function stringify(obj, sep, eq) {
  sep = sep || '&';
  eq = eq || '=';
  let str = "";
  for (var k in obj) {
    str += k + eq + unescape(obj[k]) + sep
  }
  return str.slice(0, -1)
}

/**
 ** 字符串转对象函数
 ** 说明：str 需要转对象的字符串
 ** 调用：parse(str)
 **/
function parse(str) {
  var obj = new Object();
  var strs = str.split("&");
  for (var i = 0; i < strs.length; i++) {
    let index = strs[i].indexOf("=")
    obj[strs[i].slice(0, index)] = unescape(strs[i].slice(index + 1));
  }
  return obj;
}

/**
 ** 三位数自动加 ',' 函数
 ** 说明：nStr 
 ** toThousands(nStr)
 **/
function toThousands(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

/**
 ** 匹配相同key并返回对应key的值
 ** 说明：data 需要匹配的数组 key 需要匹配相同的key keyVal 匹配相同的key值是否相同 backKey 返回的backKey
 ** backName(data, key, keyVal, backKey)
 **/
function backName(data, key, keyVal, backKey) {
  let backVal = ''
  if (data) {
    data.forEach(el => {
      if (el[key] == keyVal) {
        backVal = el[backKey]
      }
    })
  }
  return backVal
}

/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
function accAdd(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
function accSub(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);

  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
function accDiv(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);

  var t1 = 0,
    t2 = 0,
    r1, r2, r3 = 0;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r3 = 0
  }
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r3 = 0
  }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  r3 = 0
  return (r1 / r2) * Math.pow(10, t2 - t1) - r3;
}
// 给Number类型增加一个mul方法，调用起来更加方便。
Number.prototype.mul = function (arg) {
  return accMul(this, arg);
};
//给Number类型增加一个div方法，调用起来更加方便。
Number.prototype.div = function (arg) {
  return accDiv(this, arg);
};
Number.prototype.toNonExponential = function () {
  var num = this || 0;
  var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
};

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
function accMul(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);

  var m = 0, s1 = arg1.toString(), s2 = arg2.toString(), r3 = 0;
  try {
    m += s1.split(".")[1].length;
  }
  catch (e) {
    r3 = 0
  }
  try {
    m += s2.split(".")[1].length;
  }
  catch (e) {
    r3 = 0
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m) - r3;
}

/**
 ** 保留几位小数函数
 ** 说明：根据不同场景需要保留不同得小数位
 ** 调用：fixed(n)
 **/
function saveFixed(n, str) {
  var val = (str || 0).toString();
  var result = val;
  if (val.indexOf('.')) {
    var arr = val.split('.');
    result = arr[0] + '.' + (arr[1] || '').slice(0, n || 2)
  }
  return Number(result);
}

/**
 ** JSON格式转字符串格式
 **/
function isJsonString(str) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
}
/**
 ** 获取本地缓存
 **/
function get(key) {
  var storage = window.localStorage;
  var value = storage && storage.getItem(key);
  if (isJsonString(value)) {
    return JSON.parse(value)
  }
  return value || ''
}
/**
 ** 删除本地缓存
 **/
function remove(key) {
  var storage = window.localStorage
  storage && storage.removeItem(key)
}
/**
 ** 设置本地缓存
 **/
function set(key, value) {
  var storage = window.localStorage
  if (typeof (value) == 'object') {
    value = JSON.stringify(value)
  }
  storage && value && storage.setItem(key, value)
}

/**
 ** 对接口参数进行处理
 **/
function dataStringify(obj, sep, eq) {
  sep = sep || '&';
  eq = eq || '=';
  let str = "";
  for (var k in obj) {
      str += k + eq + unescape(obj[k]) + sep
  }
  return str.slice(0, -1)
}

function form_ymdHis(xDate) {    
  return xDate.getFullYear().toString(10).substring(2)
      + (xDate.getMonth()+1).toString(10).padStart(2,'0')
      + xDate.getDate().toString(10).padStart(2,'0')
      + xDate.getHours().toString(10).padStart(2,'0')
      + xDate.getMinutes().toString(10).padStart(2,'0')  
      + xDate.getSeconds().toString(10).padStart(2,'0')
}


export {
  limit,
  saveLocal,
  getLocal,
  removeLocal,
  getIsApp,
  stringify,
  parse,
  toThousands,
  backName,
  isJsonString,
  get,
  remove,
  set,
  accAdd,
  accSub,
  accDiv,
  accMul,
  saveFixed,
  dataStringify,
  form_ymdHis
}
