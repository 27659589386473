<template v-if='data.length != 0 '>
	<base-layout3 :page-title="$t('transfer_index')" :show-logo="false" :showBackButton=true mode="with-logo">
		<div class="">
				<div class="main-container" >


				<base-modal @close="hideDialog" :open="dialogIsVisible">
					<!-- <base-button class="btn-close-rounded" @click="hideDialog">
						<font-awesome-icon :icon="['fas', 'close']"></font-awesome-icon>
					</base-button> -->
					<!-- <h3 class="modal-header"></h3> -->
					<div class="modal-body text-center" style="padding: 20px;">
						<h4 class="mb-3 p-2">转账中...</h4>
					</div>
				</base-modal>

						<div >
							<div class="bg-grey p-3 text-center">
								<div><img src="@/assets/images/svg2/wallet.svg" width="40" height="40" alt=""/> </div>
								<div>主账户余额</div>
								<div class="wallet-amount">
									<div class="currency">{{currency}}</div>{{mainwallet }}
										<div class="iconRefresh" @click="refreshbalance();">
											<font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
										</div>
									</div>
							</div>
							<div class="section-padding">
								<small class="small-title">从*</small>
								<select v-model="walletfrom" class="form-control">
									<option value="main">主账户 ({{mainwallet }})</option>
									<option :value="k"  v-for="(item, k) in  gamebrand"  :key="k"  >{{k }} ({{ walletlist[k]  }})</option>
								</select>

								<div align="center" style="margin-top: 15px;"><img src="@/assets/images/svg2/arrow-down.svg" width="30" height="30" alt=""/> </div>

								<small class="small-title">转至*</small>
								<select v-model="walletto" class="form-control">
									<option value="main">主账户  ({{mainwallet }})</option>
									<option :value="k" v-for="(item, k) in  gamebrand"  :key="k"   v-init="getgamewallet(k)">{{k }} ({{walletlist[k]  }})</option>
								</select>

								<small class="small-title">转账金额</small>
								<div class="transfer_amount">
									<div class="amount">
										<div class="currency">{{currency}}</div>
										<input type="number" placeholder="请输入转账金额" v-model="amount">
										<button value="max" class="btn float-end" style="padding:0;position: relative;" @click="setMax()">最大金额</button>
									</div>
								</div>
								<div class="btn-select mt-3">
									<button value="100" class="btn"  :class="amount==100 ?'active':''"  @click="amount=100;">{{currency}}100</button>
									<button value="300" class="btn"  :class="amount==300 ?'active':''"  @click="amount=300;">{{currency}}300</button>
									<button value="600" class="btn"  :class="amount==600 ?'active':''"  @click="amount=600;">{{currency}}600</button>
									<button value="1000" class="btn" :class="amount==1000 ?'active':''"  @click="amount=1000;">{{currency}}1000</button>
								</div>
								<div class="largebtncontainer mt-5" @click="gamewallettransfer()">
									<button type="submit" class="largeGoldButton">立即转账</button>
								</div>
								<div class="bottomMessage">
									<div class="text-center">
										<p class="font-white">如需帮助，请</p>
										<a href="#"  class="link-text">联系客服</a>
									</div>
								</div>
							</div>
						</div>

						<div>
							<h3 class="section-title" >
								{{$t('transfer_log' ) }}
								<span class="iconRefresh" @click='refreshlog(1)'>
									<font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
								</span>
							</h3>
								<ul class="listing-log">
									<li v-for="(item, k) in  withdraw"  :key="k" >
										<div class="title">
											<div>
												<h2>{{item.title}}
													<div v-if="item.game=='main'">- 【主账户】 </div>
													<div style="text-transform: uppercase;" v-if="item.game!='main'">- 【{{item.game}}】 </div>
												</h2>
												<span><font-awesome-icon :icon="['far', 'calendar']" class="me-auto" />{{ redate(item.create_time) }}</span>
											</div>
										</div>
										<div class="transfer">
											<span>{{item.num}}</span>
										</div>
									</li>
								</ul>
							<div class="col-12 pb-5" style="display: block;text-align: center;width:100%;">
								<span class="notMore text-white">{{$t('drag_more') }}</span>
							</div>
						</div>
				</div>
		</div>
	</base-layout3>
</template>
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { getLocal} from '@/utils/local.js';
import { isMobile} from '@/plugin/isMobile.js';
import BaseModal from "@/components/UI/BaseModal.vue";
export default {
	data() {
		return {
			lock:0,
			draw_type: 'credit2',
			currency: getLocal('currency') || 'RM',
			gametype   : [],
			gamebrand  : [],
			gamelist   : [],
			menulist   : {},
			walletlist : {},
			withdraw   : [],
			choosed    : [],
			type       : '',
			amount     : 0,
			mainwallet : 0,
			walletfrom : 'main',
			walletto   : '',
			formIsValid: true,
			dialogIsVisible: false,
		};
	},
	created() {},
	updated() {},
	unmounted() {},
	components: {
		BaseModal,
	},
	beforeMount() {
		let token = getLocal('token') || '';
		this.token= token;
		if(!token){
			this.$router.push('/');
		}

		this.getGamelist();
		this.getmainwallet();
		this.getlog();
		this.$root.$emit("bs::show::modal", "transferpop");
	},
	methods: {
		setMax() {
			this.amount = this.mainwallet*1;
		},
		refreshbalance() {
			var that = this;
			that.getmainwallet();
			_.each(that.gamebrand, function(item, k){
				if(that.walletto=='')
					that.walletto=k;
				// setTimeout(function(){
				// 		that.getgamewallet(k,true);
				// }, 100);
			});
		},
		gamewallettransfer() {
			// var that = this;
			if (this.lock === 1) { return ''; }

			if(this.amount <=0)
				return this.$toast.error(this.$t('transfer_err'));

			if(_.isUndefined(this.walletto)  || this.walletto == null || _.size(this.walletto)==0 )
				return this.$toast.error(this.$t('transfer_walletto_err'));

			this.dialogIsVisible=true;

			this.lock = 1;

			var data = {
				walletfrom    :this.walletfrom,
				walletto      :this.walletto,
				amount        :this.amount,
			}

			this.$http("", "/home/game/gamewallettransfer", data,'post')
				.then((result) => {
					this.dialogIsVisible=false;
					this.lock = 0;
					if(result.code == "0001"){
						// reset
						this.amount     = 0;
						this.walletto   = '';
						this.walletfrom = 'main';
						this.refreshbalance();
						this.refreshlog(1);
						this.$toast.success(this.$t('transfer_success'));

					}else{
						this.dialogIsVisible=false;
						this.lock = 0;
						this.$toast.error(this.$t(result.message));
					}
				})
				.catch((e) => {
					this.dialogIsVisible=false;
					this.lock = 0;
					console.log(e)
				});
		},
		redate(date) {
			return date.substring(0, date.length - 3);
		},
		getGamelist() {
			var that = this;
			this.$http("", "/home/game", "","get").then((result) => {
				if(result.code == "0001"){
					var list         = result.data || 0;
					that.gametype  = _.groupBy(list, 'game_type');
					that.gamebrand = _.groupBy(list, 'brand');
					that.gamelist  = list;
					// console.log(that.gamebrand)
					_.each(that.gametype, function(item, k){

						if(that.walletto=='')
							that.walletto=k;
						console.log(that.walletto)
						var a =  _.groupBy(item, 'brand');
						that.menulist[k] = a ;
						that.walletlist[k] = "-";
					});

					var name =null;
					if(_.isUndefined(that.gametype[that.type]))
						name =null;
					else
						name = that.gametype[that.type][0] || null;

					if(name!=null && that.game=='' )
						that.game = name.brand;
					var a1 =_.findIndex(that.gametype[that.type], function(item) {
						return item.brand == that.game ;
					});

					var a2 =_.filter(that.gametype[that.type], function(item) {
						return item.brand == that.game ;
					});

					if(a1!=-1)
						that.img = a1 +1 ;
					that.choosed = a2;


					var b = _.filter(list, function(item){
						return item.game_type=='video-poker' || item.game_type=='poker';
					});
					var b2 =  _.groupBy(b, 'brand');
					that.menulist['poker']       = b2;
					that.menulist['video-poker'] = b2;

					if(that.type=='poker' || that.type=='video-poker'){
						var c = _.filter(list, function(item){
							return (item.game_type=='video-poker' || item.game_type=='poker') && item.brand == that.game;
						});
						that.choosed  = c;
					}
						// console.log(that.menulist)
				}else{
					// return mui.toast(result.message,{ duration:'short', type:'div' })
				}
			});
		},
		getgamewallet(k) {
			var data = {
				game_code    : k,
			}
			// this.walletlist[k] = "-";
			this.$http("", "/home/game/gamewallet", data,'post')
			.then((result) => {
				if(result.code == "0001"){
					this.walletlist[k] = result.data.amount || 0;
				}
			});
		},
		getmainwallet() {
			var per_page = 12;
			var data = {
				type : 'gametransfer',
				per_page :per_page,
			}
			this.mainwallet = "-";
			this.$http("", "/home/game/mainwallet", data,'post')
			.then((result) => {
				if(result.code == "0001"){
					this.mainwallet = (result.data.credit2*1) || 0;
				}

			});
		},
		hideDialog() {
			this.dialogIsVisible = false;
		},
		refreshlog(num) {
			this.page = num;
			var per_page = 12;
			var data = {
				type : 'gametransfer',
				per_page :per_page,
			}
			this.$http("", "/home/account/incomelog.html?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
				// console.log(_.size(result.data.data))
					this.withdraw         = {};
					this.withdraw         = _.union(this.withdraw,result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.withdraw         = result.data.data;
					// console.log(this.withdraw)
					if(_.size(result.data.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					}
				}

			});
		},
		getlog() {
			var per_page = 12;
			var data = {
				type : 'gametransfer',
				per_page :per_page,
			}
			var a = this.$t('no_more');
			this.$http("", "/home/account/incomelog.html?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
				// console.log(_.size(result.data.data))
					this.withdraw         = _.union(this.withdraw,result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.withdraw         = result.data.data;
					// console.log(this.withdraw)
					if(_.size(result.data.data)==0){
						$('.notMore').css('display', 'block').html(a);
					}
				}

			});
		},
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function(){
			// console.log($(document).scrollTop(),v)
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1;
			// console.log($(document).scrollTop(),v)
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					that.page++;
					that.getlog() ;
				}
			}
		});
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container{
	padding-bottom : 0;
}
.form-control, .form-control:focus {
	color: $white;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $gray-600;
	border-radius: 0;
	padding: 10px 10px 10px 10px;
	box-shadow: none;
}
.section-padding {
	padding: 20px 30px;
}
.small-title {
	display: block;
	color: $yellow-500;
	padding-top: 15px;
}
select option {
	margin: 40px;
	background: rgba($black-100, 0.3);
	color: $white;
	text-shadow: 0 1px 0 rgba($black-100, 0.4);
}

.transfer_amount .amount {
	display: flex;
	width: 100%;
}
.transfer_amount input {
	width: 60%!important;
	margin-left: 20px;
	background: none;
	border: none;
	color: $yellow-500;
	height: fit-content;
}
.transfer_amount .currency {
	width: 10%;
	color: $white;
}
.btn-select button {
	position: relative;
	text-align: center;
	border: 1px solid $white;
	margin: 0 10px 10px 0;
	padding: 10px 10px;
	color: $white;
	border-radius: 12px;
	font-weight: bold;
}
.transfer_amount button {
	color: $white;
}
.btn-select button.active {
	border: 1px solid $yellow-500;
	color: $yellow-500;
}
.bg-grey {
	background-color: $black-600;
	color: $white;
}
.wallet-amount {
	display: flex;
	font-size: 20px;
	font-weight: bold;
	color: $yellow-500;
	padding: 0 0 0;
	justify-content: center;
	align-items: center;
}
.wallet-amount .currency {
	margin-right: 5px;
}
.iconRefresh {
	margin-left: 5px;
	font-size: 16px;
}
</style>
