<template>
	<base-layout3
		:page-title="$t('team_reports')"
		:show-back-button="true"
		mode=""
	>
		<div class="container my_team" >
			<div class="row row-cols-1 justify-content-center text-center">
				<!-- <div class="scrollRow">
					<ul class="ms-2">
						<li :class="type=='teamreport' ?'active':''">
							<a @click="teamreport()">
								{{$t('team_reports') }}
							</a>
							<b></b>
						</li>
						<li :class="type=='myteam' ?'active':''" >
							<a @click="myteam()">
								{{$t('team_reports_my_team') }}
							</a>
							<b></b>
						</li>
					</ul>
				</div> -->
				<div class="row text-white">
					<div class="col-6  flex-box-center-column position-relative px-2" style="font-size: 18px;"  @click='teamreport();'>
						<div :class="type=='teamreport' ?'active_btm':''">{{$t('team_reports') }}</div>
					</div>
					<div class="col-6 flex-box-center-column bold position-relative px-2" style="font-size: 18px;" @click='myteam();'>
						<div :class="type=='myteam' ?'active_btm':''">{{$t('team_reports_my_team') }}</div>
					</div>
					<div class="col-12 me-bar2" style=""></div>
				</div>
				<div  v-if="type=='teamreport'">
					<div class="row pt-3 pb-3">
							<div class="col-6" >
								<input id="start_time" name="start_time" type="date" class="form-control"  style="font-size: 12px;" />
							</div>
							<div class="col-6">
								<input id="end_time" name="end_time"  type="date" class="form-control"   style="font-size: 12px;"/>
							</div>
					</div>

					<div class="largeButtonContainer">
							<base-button mode="default-btn" type="submit" class="largeGoldButton" @click="teamreport">{{$t('team_reports_search')}}</base-button>
					</div>
					<div class="col mt-4">
						<div class="col-md-12 mb-3 mt-1 flex-box">
							<div>{{$t('team_reports_team_recharge')}}<br><span class="text-green">{{teamrecharge}}</span></div>
							<div>{{$t('team_reports_team_withdraw')}}<br><span class="text-green">{{teamwithdraw}}</span></div>
							<div>{{$t('team_reports_first_charge')}}<br><span class="text-green">{{downlinetotalrebate}}</span></div>
							<div>{{$t('team_reports_team_size')}}<br><span class="text-green">{{teamsize}}</span></div>
							<div>{{$t('team_reports_new_team')}}<br><span class="text-green">{{teamnewcomer}}</span></div>
							<div>{{$t('team_reports_downline_total_commision')}}<br><span class="text-green">{{downline_total_rebate}}</span></div>
						</div>
					</div>
					<div class="col mb-3">
						<ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
							<li class="nav-item col-4 text-center level" role="presentation">
								<button class="nav-link" :class="lvl=='1' ?'active':''" @click="lvl=1">{{$t('team_reports_level_1')}}</button>
							</li>
							<li class="nav-item col-4 text-center" role="presentation">
								<button class="nav-link" :class="lvl=='2' ?'active':''" @click="lvl=2">{{$t('team_reports_level_2')}}</button>
							</li>
							<li class="nav-item col-4 text-center" role="presentation">
								<button class="nav-link" :class="lvl=='3' ?'active':''" @click="lvl=3">{{$t('team_reports_level_3')}}</button>
							</li>
						</ul>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active"  v-if="lvl=='1'">
								<div class="row row-cols-12 gx-0 text-left">
									<div class="col-md-12 mb-3 mt-1 flex-box2">
										<div>{{$t('team_reports_recharge_amount')}}<br><span class="text-green">{{rechargeamount1}}</span></div>
										<div>{{$t('team_reports_team_recharge_num')}}<br><span class="text-green">{{rechargenumber1}}</span></div>
										<div>{{$t('team_reports_team_withdraw')}}<br><span class="text-green">{{withdrawamount1}}</span></div>
										<div>{{$t('team_reports_total_commission')}}<br><span class="text-green">{{totalcommission1}}</span></div>
									</div>
								</div>

							</div>

							<div class="tab-pane fade tab-pane fade show active" id="pills-level2" v-if="lvl=='2'">
								<div class="row row-cols-12 gx-0 text-left">
									<div class="col-md-12 mb-3 mt-1 flex-box2">
										<div>{{$t('team_reports_recharge_amount')}}<br><span class="text-green">{{rechargeamount2}}</span></div>
										<div>{{$t('team_reports_team_recharge_num')}}<br><span class="text-green">{{rechargenumber2}}</span></div>
										<div>{{$t('team_reports_team_withdraw')}}<br><span class="text-green">{{withdrawamount2}}</span></div>
										<div>{{$t('team_reports_total_commission')}}<br><span class="text-green">{{totalcommission2}}</span></div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade tab-pane fade show active" id="pills-level3" v-if="lvl=='3'" >
								<div class="row row-cols-12 gx-0 text-left">
									<div class="col-md-12 mb-3 mt-1 flex-box2">
										<div>{{$t('team_reports_recharge_amount')}}<br><span class="text-green">{{rechargeamount3}}</span></div>
										<div>{{$t('team_reports_team_recharge_num')}}<br><span class="text-green">{{rechargenumber3}}</span></div>
										<div>{{$t('team_reports_team_withdraw')}}<br><span class="text-green">{{withdrawamount3}}</span></div>
										<div>{{$t('team_reports_total_commission')}}<br><span class="text-green">{{totalcommission3}}</span></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="mt-4 text-white" style="padding-bottom: 50px;" v-if="type=='myteam'">
							<ul>
								<li style="padding-left:10px;padding-right:10px;">
									<div class="itemRecord">
										<div class="itemLeft">
											<p class="light-font-2" style="width:84px">{{ $t('myteam_user') }}</p>
										</div>
										<div class="itemLeft">
											<p class="light-font-2">{{ $t('myteam_recharge') }}</p>
										</div>
										<div class="itemLeft">
											<p class="light-font-2">{{ $t('vip_control_withdrawal') }}</p>
										</div>
										<div class="itemLeft">
											<p class="light-font-2">{{ $t('myteam_commission') }}</p>
										</div>
									</div>
								</li>
									<li  v-for="(item, k) in  teamdata"  :key="k" style="padding-left:10px;padding-right:10px;" >
										<div class="itemRecord" style="font-size:12px">
											<div class="itemLeft">
												<p class="light-font-2">
													<span class="cir" style="font-size:10px">{{'v'+item.level}}</span>{{ item.username}}
												</p>
											</div>
											<div class="itemLeft">
												<p class="light-font-2" >{{ (item.recharge || 0)  }}</p>
											</div>
											<div class="itemLeft">
												<p class="light-font-2">{{ (item.withdrawal || 0)  }}</p>
											</div>
											<div class="itemLeft">
												<p class="light-font-2">{{ (item.total_comm || 0)  }}</p>
											</div>
										</div>
									</li>

							</ul>

				</div>

			</div>
		</div>
	</base-layout3>
</template>

<script>
import { _ } from "vue-underscore";
import moment from 'moment-timezone';
import $ from 'jquery';
export default {
	name: "teamreport",
	components: {},
	data() {
		return {
			start_time_vue:  moment(new Date()).format("YYYY-MM-DD"),
			end_time_vue:  moment(new Date()).format("YYYY-MM-DD"),
			date: moment(new Date()).format("YYYY-MM-DD"),
			rechargeamount1: '',
			rechargenumber1: '',
			withdrawamount1: '',
			totalcommission1: '',
			rechargeamount2: '',
			rechargenumber2: '',
			withdrawamount2: '',
			totalcommission2: '',
			rechargeamount3: '',
			rechargenumber3: '',
			withdrawamount3: '',
			totalcommission3: '',
			teamrecharge: '',
			teamwithdraw: '',
			downlinetotalrebate: '',
			teamsize: '',
			teamnewcomer: '',
			downline_total_rebate: '',
			lock:0,
			lvl:1,
			type:'teamreport',
			teamdata:[],
		};
	},
	mounted() {},
	created() { this.teamreport(); },
	updated() {},
	unmounted() {},
	methods: {
		reset(){
				this.rechargeamount1 = "-";
				this.rechargenumber1 = "-";
				this.withdrawamount1 = "-";
				this.totalcommission1 = "-";
				this.rechargeamount2 = "-";
				this.rechargenumber2 = "-";
				this.withdrawamount2 = "-";
				this.totalcommission2 = "-";
				this.rechargeamount3 = "-";
				this.rechargenumber3 = "-";
				this.withdrawamount3 = "-";
				this.totalcommission3 = "-";
				this.teamrecharge = "-";
				this.teamwithdraw = "-";
				this.downlinetotalrebate = "-";
				this.teamsize = "-";
				this.teamnewcomer = "-";
				this.downline_total_rebate = "-";
				this.data = [];
		},
		myteam() {
			this.type="myteam";
			var that = this;
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.teamdata = [];
			this.$http("", "/home/account/myteam", {})
				.then((result) => {
					if (result.code === '0001') {
						this.teamdata = result.data.user;
						// console.log(this.teamdata)
					} else {
						this.$toast.error((result.message));
					}
					setTimeout(() => {
							that.lock = 0;
					}, 700);

				})
				.catch((e) => {
						console.log(e);
						this.lock = 0;
				});
		},
		teamreport() {
			var that = this;
			this.type="teamreport";
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.reset();
			var start_time = $("#start_time").val();
			var end_time  = $("#end_time").val();

			let obj = {
				start_time: start_time,
				end_time: end_time,
			};

			this.$http("", "/home/account/teamreport.html", obj)
				.then((result) => {
					if (result.code === '0001') {
						// this.start_time_vue  = moment(result.start).format("YYYY-MM-DD");
						// this.end_time_vue    = moment(result.end).format("YYYY-MM-DD");
						console.log(result.data)
						console.log(result.data.start)
						// console.log(moment(result.data.start).format("YYYY-MM-DD"))
						$("#start_time").val( moment(result.data.start).format("YYYY-MM-DD"))
						$("#end_time").val( moment(result.data.end).format("YYYY-MM-DD"))

						this.rechargeamount1 = result.data.recharge_amount_1;
						this.rechargenumber1 = result.data.recharge_number_1;
						this.withdrawamount1 = result.data.withdraw_amount_1;
						this.totalcommission1 = result.data.total_commission_1;
						this.rechargeamount2 = result.data.recharge_amount_2;
						this.rechargenumber2 = result.data.recharge_number_2;
						this.withdrawamount2 = result.data.withdraw_amount_2;
						this.totalcommission2 = result.data.total_commission_2;
						this.rechargeamount3 = result.data.recharge_amount_3;
						this.rechargenumber3 = result.data.recharge_number_3;
						this.withdrawamount3 = result.data.withdraw_amount_3;
						this.totalcommission3 = result.data.total_commission_3;
						this.teamrecharge = result.data.team_recharge;
						this.teamwithdraw = result.data.team_withdraw;
						this.downlinetotalrebate = result.data.downline_total_rebate;
						this.teamsize = result.data.team_size;
						this.teamnewcomer = result.data.new_team;
						this.downline_total_rebate = result.data.downline_total_rebate;
						// this.$toast.success(this.$t('common_load_done'));
						//this.skipPage(result.url);
					} else {
						this.$toast.error(this.$t(result.message));
					}
					setTimeout(() => {
							that.lock = 0;
						}, 700);

				})
				.catch((e) => {
						console.log(e);
						this.lock = 0;
				});
		},
	},
	watch: {},
};

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.text-green{
	color: #159399;
}
.flex-box {
		display:flex;
		flex-wrap:wrap;
		margin-top:50px;
}
.flex-box > div{
		flex: 0 0 31%;
		margin: 0px 1% 10px 1%;
		background: $white;
		color: $black-100;
		/* background-image: linear-gradient(#fff9d1, #e2ba38); */
		border-radius: 6px;
		padding:5px 0px;
}

.flex-box2 {
		display:flex;
		flex-wrap:wrap;
		margin-top:50px;
}
.flex-box2 > div{
		flex: 0 0 48%;
		margin: 0px 1% 10px 1%;
		background: $white;
		color: $black-100;
		/* background-image: linear-gradient(#fff9d1, #e2ba38); */
		border-radius: 6px;
		padding:5px 0px;
}

@media (max-width:767px) {
		.flex-box > div{
				flex: 0 0 31%;
		}
}
.level {
	text-align: center;
	/*
	width: 31%;
	margin: 1%;
	*/
}
.level h2 {
	background: $blue-dark;
	color: $white;
	width: 100%;
	font-size: 20px;
	padding: 10px 0;
}

.nav-pills .nav-link {
		background: none;
		border: 0;
		border-radius: 0.25rem;
		width: 100%;
		padding: 10px 0;
		box-shadow: 0 0 5px $gray-200;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
		color: #372b00;
		/* background: #2043bf; */
		background-image: linear-gradient(to bottom, map-get($theme-gradient-colors, 'primary'));
		border-radius: 0.25rem;
		width: 100%;
		padding: 10px 0;
}
.table > :not(:first-child) {
		border-top: 1px solid $black-100;
}

.search_btn {
		background: $blue-dark;
		border: 0;
		border-radius: 5px;
		padding: 5px;
		color: $white;
		width: 20%;
		max-width: 120px;
}

.date_style{
	padding: 5px;
	border: 0;
	border-radius: 5px;
}

p {
	margin-bottom: 0;
}
table {
	width: 100%;
}
table thead {
	background: #2043bf;
	color: $white;
}
table tbody {
	font-size: 14px;
	color: #000;
}
thead th {
	padding: 10px 16px;
}
tbody td {
	position: relative;
	padding: 8px 12px;
}
tbody tr {
	border-bottom: solid 1px #444;
}
tbody tr .success {
	color: #6bd554;
}
tbody tr .reject {
	color: #e75142;
}
tbody tr i {
	width: 16px;
	text-align: center;
}

.pagination a {
	color: #000;
}
.page-link {
	border: none;
}
.page-link:hover {
	z-index: 2;
	color: #0a58ca;
	background-color: $white;
	border-color: $white;
}
.page-link:focus {
	box-shadow: none;
}
.page-item.active .page-link {
	z-index: 3;
	color: $white;
	background-color: #2043bf;
}
.pagination > li > a,
.pagination > li > span {
	text-align: center;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	line-height: 20px;
	display: inline-block;
	padding-top: 10px;
	margin: 0 10px;
}
.bg-white{
	background:$white;
}
.form-control, .form-control:focus {
    color: $white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $gray-600;
    border-radius: 0;
    padding: 10px 10px 10px 10px;
    box-shadow: none;
}
.my_team .scrollRow ul {
    height: 100%;
    display: flex;
    align-items: center;
}
.my_team .scrollRow ul li.active a {
    color: $blue-dark!important;
}
.my_team .scrollRow ul li {
	padding: 0 0 0 10px;
}
.my_team .itemRecord {
    height: 40px;
    flex: 0 0 calc(100% - 1.08rem);
    border-bottom: 0.02rem solid rgba($gray-200, 0.439);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
.active_btm {
	&:after{
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 30px;
        left: 0;
        right: 0;
        bottom: -5px;
        margin: 0 auto;
        transition: all .3s ease-in-out;
		background-color: $yellow-200;
		background-image: linear-gradient(to bottom, map-get($theme-gradient-colors, 'primary'));
	}
}
.nav-link {
	color: map-get($theme-colors, 'primary');
}
</style>
