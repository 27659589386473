<template>
	<base-layout3 :page-title="$t('recharge_payment_success')" :show-logo="false" :showBackButton=true mode="with-logo">
		<div class="container pt-4" v-if='data.config.length != 0 '>
			<div class="main-container container" >
				<!-- <ul class="text-white details-list">
					<li class="d-block">
						<div style="font-size:12px;"> {{$t('account_review_upload_photo')}}</div>
						<br>
						<div class="task-item no-border image-upload">
							<div class="images-box justify-content-center">
								<div class="item upload-div text-center">
									<img src="@/assets/images/img-upload.png" @click="openImg()" id="imageId" class="uploadForm img-fluid" style="float: unset;" />
								</div>
							</div>
							<div class="files" style="display: none">
								<input type="file" accept="image/*" name="thumbs[]" id="file-input" @change="triggerUpload($event)">
							</div>
							<div v-if="!files.isValid" class="error_message p10 text-center ">
								{{ $t("recharge_payment_pic_no_found") }}
							</div>
						</div>
					</li>
				</ul> -->

				<h3 class="details-title" v-if="checkNull(paymentgayway.info)">
					<div>{{$t('recharge_payment_bank_transfer'+paymentgayway.code)}}</div>
					<small>	{{$t('recharge_payment_transfer_funds'+paymentgayway.code)}}</small>
				</h3>

				<ul class="menu_listing paymentinfo" v-if="checkNull(paymentgayway.info)">
					<!-- bank transfer -->
					<li v-if="paymentinfo.pay_method==0">
						<div>{{$t('account_info_account_name')}}</div>
						<div>
							<span id="b1" style="font-size: 12px;">
								<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_name2}}</span>
								<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_name}}</span> -->
								<span>{{paymentgayway.info.bank_account_name || null}}</span>
							</span>
							<button class="btn btn-copy-small" @click="copyToClipboard('#b1')">
								{{$t('task_detail_copy' )}}
							</button>
						</div>
					</li>
					<li v-if="paymentinfo.pay_method==0">
						<div>{{$t('recharge_payment_bank')}}</div>
						<div>
							<span id="b2" style="font-size: 12px;">
								<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_branch2}}</span>
								<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_branch}}</span> -->
								<span>{{paymentgayway.info.bank_account_branch || null}}</span>
							</span>
							<button class="btn btn-copy-small" @click="copyToClipboard('#b2')">
								{{$t('task_detail_copy' )}}
							</button>
						</div>
					</li>
					<li v-if="paymentinfo.pay_method==0">
						<div>{{$t('recharge_payment_bank_account_number')}}</div>
						<div>
							<span id="b3" style="font-size: 12px;">
								<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_no2}}</span>
								<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_no}}</span> -->
								<span>{{paymentgayway.info.bank_account_no || null}}</span>
							</span>
							<button class="btn btn-copy-small" @click="copyToClipboard('#b3')">
								{{$t('task_detail_copy' )}}
							</button>
						</div>
					</li>


					<!-- ERC20 transfer -->
					<li v-if="paymentinfo.pay_method==3 || paymentinfo.pay_method==4">
						<img :src="imgurl+'/uploads/'+paymentgayway.thumb"  class="rounded  mx-auto d-block" style="width: 100% !important;"/>
					</li>
					<li v-if="paymentinfo.pay_method==3 || paymentinfo.pay_method==4">
						<div>{{$t('recharge_wallet_addr')}}</div>
						<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 50%;">
							<span id="b6" style="font-size: 12px;">
								<span style="">{{paymentgayway.info.address || null}}</span>
							</span>
						</div>
						<div>
							<button class="btn btn-copy-small" @click="copyToClipboard('#b6')">
								{{$t('task_detail_copy' )}}
							</button>
						</div>
					</li>
					<li>
						<div class="menu_item">{{$t('recharge_payment_order_number' )}}</div>
						<div>
							<span id="copy2" style="font-size: 12px;">{{ paymentinfo.order_no }}</span>
							<button class="btn btn-copy-small" @click="copyToClipboard('#copy2')">
								{{$t('task_detail_copy' )}}
							</button>
						</div>
					</li>
				</ul>

				<ul class="menu_listing">
					<li v-if="paymentinfo.pay_method==0">
						<div class="menu_item">{{$t('recharge_index_name')}}</div>
						<div style="width:100%">
							<input
								type="text"
								style="font-size: 14px"
								name="realname"
								v-model="realname.val"
								class="form-control"
								:placeholder="$t('recharge_index_real_name')"
								@blur="clearValidity('realname')"
							/>

							<div v-if="!realname.isValid" class="error_message" style="margin:10px 20px 0 20px;font-size:12px;">
							{{ $t("recharge_index_real_name") }}
							</div>
						</div>
					</li>
					<li>
						<div>{{$t('recharge_index_amount' )}}</div>
						<div>{{currency}} {{ (paymentinfo.credit2*1 ) }}</div>
					</li>

					<li v-if="promotion==1">
						<div class="menu_item">代金券</div>
						<div >
							<select class="form-control"  v-model="promote_id" style="background: none!important;">
								<option value="" >--请选择代金券--</option>
								<option :value="item.id" v-for="(item, k) in  data.bonus"  :key="k" >{{item.name}}</option>
							</select>
						</div>
					</li>

					<li v-if="paymentinfo.pay_method==0">
						<div class="menu_item">{{$t('recharge_index_ref_no')}}</div>
						<div style="width:100%">
							<input
								type="text"
								style="font-size: 14px"
								name="ref_no"
								v-model="ref_no.val"
								class="form-control"
								:placeholder="$t('recharge_index_ref_no')"
								@blur="clearValidity('ref_no')"
							/>

							<div v-if="!ref_no.isValid" class="error_message" style="margin:10px 20px 0 20px;font-size:12px;">
								{{ $t("recharge_ref_no_err") }}
							</div>
						</div>
					</li>

					<li v-if="paymentinfo.pay_method==3 || paymentinfo.pay_method==4">
						<div class="menu_item">{{$t('recharge_txn_hash')}}</div>
						<div style="width:100%">
							<input
								type="text"
								style="font-size: 14px"
								name="txn_id"
								v-model="txn_id.val"
								class="form-control"
								:placeholder="$t('recharge_index_real_name')"
								@blur="clearValidity('txn_id')"
							/>

							<div v-if="!txn_id.isValid" class="error_message" style="margin:10px 20px 0 20px;font-size:12px;">
								{{ $t("recharge_txn_hash_err") }}
							</div>
						</div>
					</li>


					<li class="d-block" v-if="checkNull(paymentinfo.note)">
						<div>{{$t('recharge_payment_note')}}</div>
						<div>{{ paymentinfo.note }}</div>
					</li>
					<li class="d-block" v-if="reupload">
						<input type="checkbox" value="1" v-model="check">
						<label class="terms" style="display: inline;">
							<a href="{{viewtype}}/terms.html" target="_blank" style="color: white !important;">{{$t('recharge_index_terms' )}}</a>
						</label>
					</li>

				</ul>

				<div class="largeButtonContainer mt-5" v-if="reupload" style="margin-bottom: 20px;">
					<base-button mode="default-btn" type="submit" class="largeGoldButton opacity-unset"
						@click="paymentupload()"
						>
						{{$t('recharge_index_confirm_recharge')}}
					</base-button>
				</div>

			</div>

		</div>
	</base-layout3>
</template>

<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { getLocal} from '@/utils/local.js';
export default {
	name: "login",
	components: {},
	data() {
		return {
			lock:0,
			promotion:0,
			promote_id:null,
			token : null,
			img : null,
			paymentinfo:{
				credit2 : 0 ,
				realname : '',
				order_no : '',
			},
			realname: {
				val: "",
				isValid: true,
			},
			txn_id: {
				val: "",
				isValid: true,
			},
			ref_no: {
				val: "",
				isValid: true,
			},
			data :{
				config: {
					setting :{
						bank_account_name : null,
						bank_account_name2 : null,
						bank_account_branch2 : null,
						bank_account_branch : null,
						bank_account_no2 : null,
						bank_account_no : null,
					}
				}
			},
			check:true,
			files: {
				val: "",
				isValid: true,
			},
			formIsValid: true,
			reupload: false,
			imgurl: process.env.VUE_APP_IMAGE_URL,
			paymentgayway: {},
		};
	},
	mounted() {},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal('token') || '';
		this.token= token;
		if(!token)
			this.$router.push('/');
		this._config();
		this.getpaymentinfo();
	},
	methods: {
		clearValidity(input) {
			this[input].isValid = true;
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		openImg() {
			// if(this.checkNull(this.paymentinfo.thumbs))
			// 	return ;
			if(this.reupload)
				document.getElementById('file-input').click();
		},
		triggerUpload(e){
			const image = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(image);

			this.files.val = e.target.files;
			this.max_upload = "1/1";

			reader.onload = e =>{
				// this.previewImage = e.target.result;
				document.getElementById('imageId').src = e.target.result;
				this.files.isValid = true;
				// console.log( e.target.result);
			};
		},
		_config_paymentgayway() {
			var that = this;
			this.$http("", "/home/api/paymentgatway", {}, "post")
				.then((result) => {
					if (result.code == "0001") {
						var find = _.find(result.data, function(i){
							return i.code== that.paymentinfo.pay_method;
						});
						// return console.log(find)
						this.paymentgayway = find;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		paymentupload () {
			var that = this;
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.files.isValid = true;
			if(!this.check){
				this.lock = 0;
				return this.$toast.error(this.$t('login_check_err'));
			}
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}


			var formData = new FormData($("#form1")[0]);
			// formData.append('checkFile[]', this.files.val[0]);
			formData.append('promote_id', this.promote_id);
			if (this.paymentinfo.pay_method==0) {
				formData.append('extra', this.ref_no.val);
				formData.append('realname', this.realname.val);
			} else if (this.paymentinfo.pay_method==3 || this.paymentinfo.pay_method==4) {
				formData.append('extra', this.txn_id.val);
			}

			this.$http("", "/home/recharge/payment?id="+this.paymentinfo.order_no, formData,'postform')
				.then((result) => {
					if(result.code == "0001"){
						this.$toast.success((result.message));

						this.promote_id = result.data.event_id+"" || null;
						if(this.promote_id==0)
							this.promote_id = null;

						setTimeout(function(){
								that.$router.push('/account/wallet');
							// window.location.reload();
						}, 1000);

					}else{
						this.lock = 0;
						this.$toast.error(this.$t(result.message));
					}
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e)
				});
		},
		// validateForm() {
		// 	this.formIsValid = true;
		// 	// if (this.files.val === "") {
		// 	// 	this.files.isValid = false;
		// 	// 	this.formIsValid = false;
		// 	// }
		// 	if((this.paymentinfo.pay_method==3 || this.paymentinfo.pay_method==4)){
		// 		// console.log(123)
		// 		// console.log(this.txn_id.val)
		// 		if (this.txn_id.val === "") {
		// 			this.txn_id.isValid = false;
		// 			this.formIsValid = false;
		// 		}
		// 	} else if((this.paymentinfo.pay_method==0)){
		// 		if (this.ref_no.val === "") {
		// 			this.ref_no.isValid = false;
		// 			this.formIsValid = false;
		// 		}
		// 		if (this.realname.val === "") {
		// 			this.realname.isValid = false;
		// 			this.formIsValid = false;
		// 		}
		// 	}
		// },
		copyToClipboard (element){
			var $temp = $("<input>");
			$("body").append($temp);
			$temp.val($(element).text()).select();
			document.execCommand("copy");
			$temp.remove();
			this.$toast.success(this.$t('recharge_copy_success'));

		},
		submitForm() {
			var that = this;
			if (this.lock === 1) { return ''; }
			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}

			this.files.isValid = true;

			var formData = new FormData($("#form1")[0]);
			formData.append('thumbs[]', this.files.val[0]);
			formData.append('wechat_id', this.wechat_id.val);
			formData.append('wechat_name', this.wechat_name.val);
			formData.append('bank_account_name', this.bank_account_name.val);
			formData.append('bank_account', this.bank_account.val);
			formData.append('bank_branch', this.bank_branch.val);

			this.$http("", "/home/account/review", formData,'postform')
				.then((result) => {
					if(result.code == "0001"){
						this.$toast.success((result.message));
						var f = this.$route.query.f;
						setTimeout(function(){
							if(f=="true")
								that.$router.push('/account/withdraw');
							else
								that.$router.push('/account');
							// window.location.reload();
						}, 1000);

					}else{
						this.lock = 0;
						this.$toast.error(this.$t(result.message));
					}
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e)
				});
		},
		_config() {
			this.$http("", "/home/recharge/config", {},'post')
				.then((result) => {
					if(result.code == "0001"){
						this.data = result.data;
						this.promotion = this.data.config.setting.promotion;
						this.recharge_online_banking = this.data.config.setting.recharge_online_banking;
					}
				})
				.catch((e) => {
					console.log(e)
				});
		},
		getpaymentinfo() {
			this.$http("", "/home/recharge/paymentinfo", {id : this.$route.query.id},'post')
				.then((result) => {
					if(result.code == "0001"){
						this._config_paymentgayway();
						this.paymentinfo = result.data;
						this.promote_id = result.data.event_id+"" || null;
						if(this.promote_id==0)
							this.promote_id = null;

						if(this.paymentinfo.status==-1)
							this.reupload=true;

						if(!this.checkNull(this.paymentinfo.thumbs) && this.paymentinfo.status==0)
							this.reupload=true;

						if(this.checkNull(this.paymentinfo.thumbs) && !this.reupload){
							var img = process.env.VUE_APP_IMAGE_URL+"/uploads/"+this.paymentinfo.thumbs;
							document.getElementById('imageId').src = img;
						}
					}
				})
				.catch((e) => {
					console.log(e)
				});
		},
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container{
	padding-bottom : 0;
}

ul.menu_listing .menu_item {
	display: flex;
	align-items: center;
	justify-content: flex-start !important;
	width: 30%;
}
ul.menu_listing li {
	display: flex;
	color: $white;
	border-bottom: solid 1px #333;
	padding: 18px 0;
	justify-content: space-between;
}
.b-none {
	border: none!important;
}
.form-control, .form-control:focus {
	color: $white;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $gray-600;
	border-radius: 0;
	padding: 10px 10px 10px 10px;
	box-shadow: none;
}
.p10{
	margin: 0 0 0 10px;
}
.container img {
	width: initial !important;;
}
ul.details-list li {
	display: flex;
	justify-content: space-between;
	padding: 20px 0 20px 0;
	border-bottom: solid 1px $black-300;
}
.btn-copy-small {
    /* background-color: #999; */
    /* color: #000!important; */
    /* width: 60px; */
    font-size: 14px;
    /* padding: 5px 10px; */
    margin: -3px 5px 5px 15px;
    vertical-align: top;
}
.details-title {
    color: $white;
    display: block;
    margin-bottom: 0;
    padding: 0px 0px;
    font-size: 24px;
    position: relative;
}
.details-title small {
    font-size: 16px;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.form-control, .form-control:focus {
    color: $white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $gray-600;
    border-radius: 0;
    padding: 10px 10px 10px 10px;
    box-shadow: none;
}
select option {
    margin: 40px;
    background: rgba($black-100, 0.3);
    color: $white;
    text-shadow: 0 1px 0 rgba($black-100, 0.4);
}
ul.menu_listing {
    background: rgba($black-100, 0.376);
}
ul.menu_listing .menu_item {
	display: flex;
	align-items: center;
	justify-content: center;
}
ul.menu_listing li {
	display: flex;
	color: $white;
	border-bottom: solid 1px $black-300;
	padding: 18px 13px;
	justify-content: space-between;
	align-items: center;
}
ul.menu_listing.paymentinfo li {
	background: $black-100;
    border-radius: 10px;
    margin: 10px;
    padding: 10px 10px;
}
ul.menu_listing.paymentinfo li:first-child {
	border-left: 10px solid $blue;
}
ul.menu_listing.paymentinfo li:nth-child(2) {
	border-left: 10px solid $teal;
}
ul.menu_listing.paymentinfo li:nth-child(3) {
	border-left: 10px solid $blue-dark;
}
ul.menu_listing.paymentinfo li:nth-child(4) {
	border-left: 10px solid $yellow-500;
}
.btn-copy-small {
	color: map-get($theme-colors, 'danger');
}
</style>
