export const bgColors = ['#1b2741', '#ffffff', '#f2e9f5']
export const colors = ['#09b699', '#d74a5d', '#bd9fe5', '#79879a', '#7e8da3', '#ece3c2']

const ws_protocol = location.protocol === 'https:'  ? 'wss' : 'ws';

// export const huobiWsUrl =  ws_protocol + "://api.hadax.com/ws'
// export const huobiWsUrl =  ws_protocol + "://api.huobi.pro/ws/v1'
// export const huobiWsUrl =  ws_protocol + "://api.huobi.pro/ws'
export const huobiWsUrl2 = ws_protocol + '://api-aws.huobi.pro/ws'
// export const huobiWsUrl =  ws_protocol + '://forex.localhost:8383'
// export const huobiWsUrl2 =  ws_protocol + '://forex.localhost:8383'
  // export const huobiWsUrl =   "wss://web11.jobhunter.solutions/ws/"
//  export const huobiWsUrl =   ws_protocol + "://nnwshop.com/ws/"
export const huobiWsUrl =  ws_protocol + "://"+window.location.host+"/ws/"
//export const huobiWsUrl =  ws_protocol+"://45.194.16.24/ws/"

export const navList = [
  { text: '1分钟', opt: '1min' },
  { text: '5分钟', opt: '5min' },
  { text: '15分钟', opt: '15min' },
  { text: '30分钟', opt: '30min' },
  { text: '1小时', opt: '60min' },
  // { text: '4小时', opt: '4hour' },
  { text: '1天', opt: '1day' }
]