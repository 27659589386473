import {
    createStore
} from 'vuex'
import {
   // get,
    set,
    getLocal,
    removeLocal
} from '../utils/local'


export default createStore({
    state: {
        //token: getLocal('token') || "",
        token: getLocal('token') || "",
        userInfo: getLocal('projectInfo') || {} ,
        lang: getLocal('lang') || "",
        sk_info: getLocal('kkkkk') || "",
    },
    getters: {
        getUserInfo: state => {
            return state.userInfo
        },
        getToken: state => {
            return state.token
        }
    },
    mutations: {
        // 设置缓存 obj必须是一个对象 key 必须是state中的一个属性
        setSession(state, obj) {

            if (typeof (obj) == 'object') {
                for (let key in obj) {
                    set(key, obj[key])

                    if (key=='projectInfo') {
                        state['userInfo'] = obj[key];
                    }
                    else {
                        state[key] = obj[key]
                    }
                }
            } else {
                return
            }

        },
        deleteSession(state) {
            removeLocal('token');
            removeLocal('projectInfo');
            // 重新初始化state中属性
            state.token = ""
            state.userInfo = {}
        }
    }
});