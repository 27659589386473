<script>
import { _ } from 'vue-underscore';
import $ from 'jquery';
import { isMobile } from '@/plugin/isMobile.js';
import { getLocal } from '@/utils/local.js';
import moment from 'moment-timezone';
import { formatSeconds2, getLocalTime } from '@/utils/chart.js';
import { huobiWsUrl } from '@/views/mobile3/trade/config.js';
import { toFixed } from '@/utils/chart.js';

export default {
  name: 'Market',
  props: {
    closeNav: {
      type: Function,
      default: null,
    },
      showBurgerPage: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      data: [],
      allData: [],
      category: [],
      product: {},
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
      page: 1,
      currency: getLocal('currency') || 'RM',
      img_url: process.env.VUE_APP_IMAGE_URL,
      type: '',
      preType: '',
      orderlist: [],
      ftime: 0,
      index: -1,
      haurl: huobiWsUrl,
      socketK: {},
      toFixed: toFixed,
      categories: [],
      showCategoryBar: true,
    };
  },
  methods: {
    push_content(url, id) {
      this.$router.push(url + '?product=' + id);
      this.$emit('custom-event', id);
      this.closeNav();
    },

    callf(type, sw) {
      if (this.type === type) return;

      if (this.socketK.close) {
        this.socketK.send(
          JSON.stringify({
            action: 'unsub',
            msg: 'Market Reconnecting',
            sub: `market.${this.type ? this.type : 'all'}.ticker`,
          })
        );
        this.socketK.close();
      }
      this.type = type.cid || '';
      this.getdata(sw);
    },
    getProductCategories() {
      this.$http('', '/home/api/product_category', {}, 'post').then(
        (result) => {
          if (result.data.length > 1) {
            this.categories = result.data;
            console.log(this.categories);
            this.showCategoryBar = true;
          } else {
            this.showCategoryBar = false;
          }
        }
      );
    },
    getProductInfo() {
      var msg = "-";
      // console.log(msg)
      this.$http(
        '',
        '/home/api/product_info?page=' + this.page,
        { per_page: this.per_page },
        'post'
      ).then((result) => {
        console.log('Home Product list:')
        console.log(result.data)
        this.data = result.data;
        this.data.sort((a, b) => a.pid - b.pid);
        this.product = _.union(this.product, result.data);
				this.default_product = result.data[0];

        // this.current_page = result.data.current_page || 1;
        // this.last_page    = result.data.last_page || 1;
        if (result.code == '0001') {
          // if(_.size(result.data.data)==0)
          // 	$('.notMore').css('display', 'block').html(msg);

          this.handleInitWebsoket();
        }
      });
    },
    getdata(sw) {
      var msg = this.$t('no_more');
      // console.log(msg)

      if (sw) {
        this.data = [];
        this.page = 1;
        $('.notMore').css('display', 'none');
      }
      console.log(this.page, 'page');
      this.$http(
        '',
        '/home/api/product_list?page=' + this.page,
        { per_page: this.per_page, cid: this.type },
        'post'
      ).then((result) => {
        console.log('The data List:');
        console.log(result.data)
        this.data = _.union(this.data, result.data.data);
        this.data.sort((a, b) => a.pid - b.pid);
        this.current_page = result.data.current_page || 1;
        this.last_page = result.data.last_page || 1;
        this.per_page = result.data.per_page || 1;
        this.total = result.data.total || 12;
        console.log('Total');
        console.log(this.per_page)

        if (result.code == '0001') {
          if (_.size(result.data.data) == 0) {
            $('.notMore').css('display', 'block').html(msg);
          } else if (_.size(this.total) <= this.per_page) {
            $('.notMore').css('display', 'none');
          }
          window.addEventListener('scroll', this.scroll);
          this.handleInitWebsoket();
        }
      });
    },
    handleInitWebsoket(isReconnect = 0) {
      var self = this;

      self.socketK = new WebSocket(self.haurl);
      self.socketK.onopen = function () {
        if (isReconnect === 1) {
          console.log('reconnected', 'ws');
          self.socketK.send(
            JSON.stringify({
              action: 'sub',
              msg: 'Market Reconnected',
              sub: `market.${self.type ? self.type : 'all'}.ticker`,
            })
          );
        } else {
          console.log('connected', 'ws');
          self.socketK.send(
            JSON.stringify({
              action: 'sub',
              msg: 'Market Connected',
              sub: `market.${self.type ? self.type : 'all'}.ticker`,
            })
          );
        }
      };
      self.socketK.onmessage = function (event) {
        // console.log(event, 'event')
        if (typeof event.data !== 'undefined') {
          let blob = JSON.parse(event.data);

          if (typeof blob.type !== 'undefined' && blob.type === 'ping') {
            self.socketK.send(JSON.stringify({ pong: blob.type }));
          } else {
            var i;
            var len = self.data.length;
            // if (window.location.href.indexOf('local') > -1)
            //   console.log(len, 'len')
            for (i = 0; i < len; i++) {
              var v = self.data[i];

              // console.log(v.procode, 'v : ' + i)
              let req = 'market.' + v.procode.toLowerCase() + '.ticker';
              // console.log(req, blob.action);
              // console.log(blob.data.tick, 'data')
              if (req === blob.action) {
                let j = i;

                // console.log(self.product[j], 'product i: ' + i)

                if (typeof blob.data.tick !== 'undefined') {
                  let data = blob.data.tick;
                  // console.log(data, 'data')
                  if (typeof data.ptitle !== 'undefined')
                    self.data[j].ptitle = data.ptitle;
                  if (typeof data.price !== 'undefined')
                    self.data[j].Price = data.price;
                  if (typeof data.isopen !== 'undefined')
                    self.data[j].isopen = data.isopen;
                  if (typeof data.zf !== 'undefined') self.data[j].zf = data.zf;
                }

                // console.log(self.product[j], 'product : ' + i)
              }
            }
          }
        }
      };
      self.socketK.onclose = function () {
        if (this.reroute === false) {
          console.log('reconnecting', 'ws');
          self.handleInitWebsoket(1);
        }
      };
    },
    checkNull(item) {
      if (_.isUndefined(item) || item == null || _.size(item) == 0)
        return false;

      return true;
    },
    parseDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss');
    },
    scroll() {
      if (!isMobile()) return;

      var v = $(document).height() - $(window).height() - 100;
      // console.log($(document).scrollTop(), v)
      if ($(document).scrollTop() >= v) {
        // console.log('我到底了');
        if ($('.notMore').css('display') != 'block') {
          this.page++;
          this.getdata(false);
        }
      }
    },
  },
  beforeMount() {
    // let token = getLocal("token") || "";
    // if (!token) this.$router.push("/");
    this.img_url = process.env.VUE_APP_IMAGE_URL;
    if(this.showBurgerPage){
      this.getProductInfo();
      this.getProductCategories();
      this.getdata();
    }

    console.log(this.showBurgerPage,'this.showBurgerPage')

    document.querySelector('body').setAttribute('id', 'scrollable');
  },
  mounted() {
    var that = this;

    // window.addEventListener('scroll', this.scroll);
    // this.getProductInfo();
  },
  unmounted() {},
  watch: {
    $route(to, from) {
      if (this.socketK.close) {
        this.socketK.send(
          JSON.stringify({
            action: 'unsub',
            msg: 'Market Disconnected',
            sub: `market.${this.type ? this.type : 'all'}.ticker`,
          })
        );
        this.socketK.close();
      }

      window.removeEventListener('scroll', this.scroll);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,
body {
  height: 100%;
  margin: auto;
  overflow: initial;
  color: $black-100;
  font-size: 14px;
  background: $black-400;
}
.sub-mission-text2 {
  color: $gray-500 !important;
  font-size: 14px;
  margin-top: auto;
}
.selected_cat {
  background: map-get($theme-colors, 'danger') !important;
  color: $white !important;
  padding-left: 5px;
  padding-right: 5px;
}
.outline-sd {
  box-shadow: 0px 0px 6px rgba($black-100, 0.161) !important;
  border-radius: 10px !important;
}
.movie-title {
  font-size: 17px;
  color: $black-300;
  font-weight: 700;
  padding-right: 5px;
  flex-shrink: 1;
}
.grade {
  font-weight: 700;
  color: $yellow-500;
  font-size: 15px;
}
.detail {
  line-height: 24px;
}
.line-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ml-7 {
  margin-left: 7px;
}
.active_btm {
  background: -webkit-linear-gradient(
    map-get($theme-gradient-colors, 'primary')
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 30px;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    background-color: $yellow-500;
    background-image: linear-gradient(
      to bottom,
      map-get($theme-gradient-colors, 'primary')
    );
  }
}
.container {
  overflow-x: overlay;
}
.container > div {
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
}
.flex-box-center-column {
  color: $white;
  div {
    min-width: 100px;
    text-align: center;
  }
}
.flex-box-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.rank-text {
  font-size: 40px;
  font-weight: bold;
  color: $gray-500;
}
p {
  color: $gray-500;
}
.up-case {
  text-transform: uppercase;
}
.active_btm {
  margin-top: 0;
}
.trade_history_list ul li {
  height: 90px;
}
.trade_history_list ul,
.trade_history_list ul li {
  /* padding: 0 !important; */
  background: rgba($black-100, 0.376);
  border-top: none;
  border-bottom: 1px solid rgba($white, 0.502);
}
.trade_history_list ul {
  border-bottom: 0;
}
.trade_history_list ul li article span {
  margin-top: 3.84px;
  z-index: 6 !important;
}
.trade_history_list p,
.trade_history_list ul li section:nth-of-type(1) p:nth-of-type(3) {
  color: $white;
}
@media (max-width: 360px) {
  .buy-btn {
    font-size: 10px;
  }
}
.order-detail1 {
  background: $black-500;
  height: 100%;
  position: fixed;
  width: 100%;
  // top: 54px;
  top: 0;
  left: 0;
  z-index: 1000;

  td,
  th {
    border-top: 1px solid rgba($white, 0.376);
    border-bottom: 1px solid rgba($white, 0.376);
    margin: 20px;
    padding: 20px 30px;
  }

  a.close {
    position: fixed;
    color: $white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    width: 100%;
    background: rgba($black-100, 0.376);
    bottom: 0;
    padding: 20px;
  }
}
.product > div {
  padding: 20px;
  text-align: center;
  background: rgba($black-600, 0.396);
  border-radius: 12px;
  margin: 20px auto;
  height: calc(100% - 60px);
}
.product-icon {
  width: 25px;
}
table {
  margin-top: 20px;

  > :not(:first-child) {
    border-top: 2px solid $gray-600;
  }
  thead {
    // display: block;
  }
  tbody {
    // display: block;
    /*
		height: 300px;
		overflow-y: scroll; */
  }
  td td:first-child {
    text-align: left;
    padding-left: 20px;
    width: 1%;
  }
  tr {
    height: 65px;

    &:hover {
      cursor: pointer;
    }
    th,
    td {
      text-align: center;
      border: none !important;
      width: 1%;
      vertical-align: middle;
      padding-left: 0;
      padding-right: 0%;
      font-size: 12px;
    }
    th {
      // padding-left: 28px;
      font-size: 14px;
    }
    td.text-success {
      color: map-get($theme-colors, 'success') !important;
      font-weight: bolder;
      font-size: 14px;

      span {
        font-weight: normal;
      }
    }
    td.text-danger {
      color: map-get($theme-colors, 'danger') !important;
      font-weight: bolder;
      font-size: 16px;

      span {
        font-weight: normal;
      }
    }
  }
}
.buy-btn {
  background: $brown-300;
  border-radius: 12px;
  font-size: 12px;
}
</style>

<template>
  <div class="container pt-1 pb-1 product">
      <div class="p-0">
        <table class="table">
          <tbody>
            <tr
              v-for="(item, k) in product"
              :key="k"
              @click="push_content('/trade/exchange', item.procode)"
            >
              <td style="white-space: nowrap">
                <img
                  :src="img_url + '/uploads/' + item.img"
                  :alt="item.title"
                  class="product-icon"
                />&nbsp; {{ item.ptitle }}
              </td>
              <td
                :class="{
                  'text-success': item.zf >= 0,
                  'text-danger': item.zf < 0,
                }"
              >
                {{ toFixed(item.Price ? item.Price : 0, 4) }} <br /><span
                  >{{ item.zf }}%</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>
