<template v-if="data.length != 0">
	<base-layout3
		:page-title="$t('recharge_index_recharge')"
		:show-logo="false"
		:showBackButton="true"
		mode="with-logo"
	>
		<div class="">
			<div class="main-container">
				<div>
					<!-- <h3 class="section-title">{{ $t("recharge_payment_method") }}</h3> -->
					<!-- <div class="section-padding">
						<div class="btn-select">
							<button
								 v-for="(item, k) in paymentgayway" :key="k"
								class="btn"
								:class="pay_method == item.code ? 'active' : ''"
								@click="pay_method = item.code"
							>
								{{item.title}}
							</button>
						</div>
					</div> -->
					<h3 class="section-title">{{ $t("recharge_index_amount") }}</h3>
					<div class="section-padding">
						<div class="btn-select">
							<button
								value="100"
								class="btn"
								:class="recharge_amt.val == 100 ? 'active' : ''"
								@click="recharge_amt.val = 100"
							>
								{{ currency }}100
							</button>
							<button
								value="300"
								class="btn"
								:class="recharge_amt.val == 300 ? 'active' : ''"
								@click="recharge_amt.val = 300"
							>
								{{ currency }}300
							</button>
							<button
								value="600"
								class="btn"
								:class="recharge_amt.val == 600 ? 'active' : ''"
								@click="recharge_amt.val = 600"
							>
								{{ currency }}600
							</button>
							<button
								value="1000"
								class="btn"
								:class="recharge_amt.val == 1000 ? 'active' : ''"
								@click="recharge_amt.val = 1000"
							>
								{{ currency }}1000
							</button>
						</div>
						<div class="recharge_amount">
							<div class="currency">{{ currency }}</div>
							<input type="number" v-model="recharge_amt.val" class="form-control"  />
						</div>
						<div class="recharge_name">
							<div class="name">{{ $t("recharge_index_name") }}</div>
							<div style="width:100%">
							<input
								type="text"
								style="font-size: 14px"
								name="realname"
								v-model="realname.val"
								class="form-control"
								:placeholder="$t('recharge_index_real_name')"
								@blur="clearValidity('realname')"
							/>

							<div v-if="!realname.isValid" class="error_message" style="margin:10px 20px 0 20px;font-size:12px;">
							{{ $t("recharge_index_real_name") }}
								</div>
							</div>
						</div>

						<div class="termsandconditions">
							<input type="checkbox" value="1" v-model="check" checked />
							<a @click="redirect('/account/terms')">
								<label class="terms">
									{{ $t("recharge_index_terms") }}
								</label>
							</a>
						</div>

						<div class="bottomMessage">
							<p>{{ $t("maximum_amount") }}</p>
							<p>{{ $t("recharge_note_2") }}</p>
						</div>

						<div class="largebtncontainer" @click="submitForm()">
							<base-button mode="default-btn" type="submit" class="largeGoldButton">
								{{ $t("recharge_payment_confirm_submit") }}
							</base-button>
						</div>
					</div>
				</div>

				<!-- <div>
					<h3 class="section-title">
						{{ $t("wallet_recharge_log") }}
						<span class="iconRefresh" @click="refreshlog(1)">
							<font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
						</span>
					</h3>
					<ul class="listing-log">
						<li
							v-for="(item, k) in recharge"
							:key="k"
							@click="recharge_redirect(item)"
						>
							<div class="title">
								<div>
									<h2>{{ item.id }} {{ item.order_no }}</h2>
									<span
										><font-awesome-icon
											:icon="['far', 'calendar']"
											class="me-auto"
										/>
										{{ item.create_time }}</span
									>
								</div>
							</div>
							<div class="recharge">
								<div v-if="item.status == 1">
									<span class="status status-complete"
										><font-awesome-icon
											:icon="['fas', 'check']"
											class="me-auto"
										/>
										{{ $t("wallet_user_cash_success") }}
									</span>
									<br />
									<span
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>
								<div v-if="item.status == -1">
									<span class="status status-reject"
										><font-awesome-icon
											:icon="['fas', 'check']"
											class="me-auto"
										/>
										{{ $t("wallet_user_cash_fail") }}
									</span>
									<br />
									<span style="color: #fc445f"
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>
								<div v-if="item.status == 0">
									<span class="status" style="color: #fc7940"
										><font-awesome-icon
											:icon="['fas', 'clock']"
											class="me-auto"
										/>
										{{ $t("wallet_user_cash_pending") }}
									</span>
									<br />
									<span style="color: #fc7940"
										><sup>{{ currency }}</sup
										>{{ item.credit2 }}</span
									>
								</div>

								<div class="more" v-if="item.status != 1">
									<font-awesome-icon
										:icon="['fas', 'chevron-right']"
										class="me-auto ml-5"
									/>
								</div>
							</div>
						</li>
					</ul>

					<div
						class="col-12 pb-5"
						style="display: block; text-align: center; width: 100%"
					>
						<span class="notMore text-white">{{ $t("drag_more") }}</span>
					</div>
				</div> -->
			</div>
		</div>
	</base-layout3>
</template>

<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
export default {
	name: "login",
	components: {},
	data() {
		return {
			lock: 0,
			pay_method: 0,
			data: {},
			paymentgayway: {},
			recharge: {},
			currency: getLocal("currency") || "RM",
			realname: {
				val: "",
				isValid: true,
			},
			recharge_amt: {
				val: 100,
				isValid: true,
			},
			recharge_online_banking: 0,
			current_page: 1,
			last_page: 1,
			per_page: 10,
			total: 0,
			page: 1,
			formIsValid: true,
		};
	},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal("token") || "";
		this.token = token;
		if (!token) {
			this.$router.push("/login");
		} else {
			this._config();
			this._config_paymentgayway();
			this.getlog();
		}
	},
	methods: {
		clearValidity(input) {
			this[input].isValid = true;
		},
		validateForm() {
			this.formIsValid = true;
			// if (this.realname.val === "") {
			// 	this.realname.isValid = false;
			// 	this.formIsValid = false;
			// }
			if (this.recharge_amt.val === "" || this.recharge_amt.val === 0) {
				this.recharge_amt.isValid = false;
				this.formIsValid = false;
			}
		},
		redirect(url) {
			this.$router.push(url);
		},
		recharge_redirect(item) {
			if (item.status != 1)
				this.$router.push("/account/recharge_payment?id=" + item.order_no);
		},
		submitForm() {
			var that = this;
			if (this.lock === 1) {
				return "";
			}
			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}
			var data = {
				// realname: this.realname.val,
				credit2: this.recharge_amt.val,
				pay_method: this.pay_method,
			};
			this.$http("", "/home/recharge/index", data, "post")
				.then((result) => {
					if (result.code == "0001") {
						// this.$toast.success((result.message));
						setTimeout(function () {
							that.$router.push("/account/recharge_payment?id=" + result.data);
						}, 1000);
					} else {
						if (result.data && typeof result.data !== 'undefined') {
							setTimeout(function () {
								that.$router.push("/account/recharge_payment?id=" + result.data.order_no);
							}, 1000);
							return;
						}
						this.lock = 0;
						this.$toast.error((result.message));
					}
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e);
				});
		},
		_config() {
			this.$http("", "/home/index/config", {}, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.data = result.data;
						this.recharge_online_banking =
							this.data.config.setting.recharge_online_banking;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		_config_paymentgayway() {
			this.$http("", "/home/api/paymentgatway", {}, "post")
				.then((result) => {
					if (result.code == "0001") {
						// console.log(result.data)
						this.paymentgayway = result.data;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		getlog() {
			var per_page = 12;
			var data = {
				type: "recharge",
				per_page: per_page,
			};
			var that = this;
			var a = that.$t("no_more");
			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					this.recharge = _.union(this.recharge, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					this.data = result.data.data;
					// console.log(_.size(this.recharge))
					// console.log(this.recharge)
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(a);
					}
				}
			});
		},
		refreshlog(num) {
			this.page = num;
			var per_page = 12;
			var data = {
				type: "recharge",
				per_page: per_page,
			};
			this.recharge = {};
			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					this.recharge = _.union(this.recharge, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					this.data = result.data.data;
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(this.$t("no_more"));
					}
				}
			});
		},
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function () {
			// console.log(234)
			if (!isMobile()) return;

			var v = $(document).height() - $(window).height() - 1;
			// console.log($(document).scrollTop(),v)
			if ($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if ($(".notMore").css("display") != "block") {
					that.page++;
					that.getlog();
				}
			}
		});
	},
	watch: {},
};
</script>
<style scoped>
.main-container {
	padding-bottom: 0;
}
.btn-select button.active {
	border: 1px solid #8B8848;
	color: #efbc2f;
	background: linear-gradient(to left, #1F1F1D, #040404);
}
.btn-select button {
	position: relative;
	text-align: center;
	border: 1px solid #575757;
	margin: 1%;
	width: 31%;
	padding: 10px 10px;
	color: #fff;
	border-radius: 12px;
	font-weight: normal;
	background: linear-gradient(to left, #1F1F1D, #040404);
}
.section-padding {
	padding: 20px 15px;
}
.recharge_amount {
	display: flex;
	font-size: 24px;
	color: #fff;
	padding: 20px 0 0;
}
.recharge_amount .currency {
	width: 20%;
}
.recharge_amount input {
	width: 80%;
	max-width:450px;
	margin-left: 0px;
	background: none;
	border: none;
	color: #efbc2f;
	box-shadow: 0 0 5px #efbc2f;
	font-size: inherit;
}
.recharge_name {
	display: flex;
	font-size: 18px;
	color: #fff;
	padding: 20px 0;
}
.recharge_name .name {
	width: 20%;
}
.recharge_name input {
	width: 80%;
	margin-left: 20px;
	background: none;
	border: none;
	border-radius: 5px;
	color: #efbc2f;
	box-shadow: 0 0 5px #efbc2f;
}
.termsandconditions {
	padding: 10px 0 20px;
}
label {
	display: inline-block;
}
.bottomMessage {
	padding: 0px 0 10px 0;
	color: #efbc2f;
}
.listing-log .recharge .status-reject {
	color: #fc445f;
}
.listing-log li {
	display: flex;
	color: #fff;
	background: rgba(0, 0, 0, 0.6);
	border-bottom: dashed 1px #333;
	padding: 18px 30px;
	justify-content: space-between;
}
.listing-log .title {
	display: flex;
}
.listing-log .recharge {
	display: flex;
}
.listing-log .more {
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: right;
}
</style>
